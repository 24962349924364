import React, { Component } from "react";
import './ButtonSettings.css'
import { Button, Icon, Input, Tooltip } from "antd";
import DebouncedInput from "../DebouncedInput";
import DebouncedTextarea from "../DebouncedTextarea";

interface Props {
  buttonObject: any,
  changeButtonButtonID: any,
  changeButtonClass: any,
  changeButtonOnClick: any,
  changeButtonAttributes: any,
}
type MyState = {
  buttonSettingsVisible: boolean,
};
class ButtonSettings extends Component<Props, MyState>{
  state = {
    buttonSettingsVisible: false,
  };

  componentWillMount(): void {
  }

  render() {
    const inputWidth = 340;
    return (
      <div>
        <div className="form_input_picker__setting_box">
          <div className="content-input__tooltip_box">
            <span className="content-input__label">ID</span>
          </div>
          <DebouncedInput
            title=""
            onChange={this.props.changeButtonButtonID}
            size="small"
            placeholder="Enter custom ID"
            style={{width: inputWidth}}
            defaultValue={this.props.buttonObject.settings.custom_id ? this.props.buttonObject.settings.custom_id : ''}
          // value={}
          />
        </div>

        <div className="form_input_picker__setting_box">
          <div className="content-input__tooltip_box">
            <span className="content-input__label">Classes</span>
          </div>
          <DebouncedInput
            title=""
            onChange={this.props.changeButtonClass}
            size="small"
            placeholder="Enter class"
            style={{width: inputWidth}}
            defaultValue={this.props.buttonObject.settings.classes ? this.props.buttonObject.settings.classes : ''}
          // value={}
          />
        </div>

        <div className="form_input_picker__setting_box">
          <div className="content-input__tooltip_box">
            <span className="content-input__label">onclick</span>
          </div>
          <DebouncedInput
            title=""
            onChange={this.props.changeButtonOnClick}
            size="small"
            placeholder="ga('send', 'event', 'Video', 'play', 'cats.mp4');"
            style={{width: inputWidth}}
            defaultValue={this.props.buttonObject.settings.onclick ? this.props.buttonObject.settings.onclick : ''}
          // value={}
          />
        </div>

        <div className="form_input_picker__setting_box">
          <div className="content-input__tooltip_box">
            
            <span className="content-input__label">Attributes</span>
          </div>
          <DebouncedTextarea
            onChange={this.props.changeButtonAttributes}
            placeholder='data-attr-1="cat"&#10;data-attr-2="dog"'
            style={{width: inputWidth}}
            defaultValue={this.props.buttonObject.settings.attributes ? this.props.buttonObject.settings.attributes : ''}
          // value={}
          />
        </div>

      </div>
    )
  }
}

export default ButtonSettings;
