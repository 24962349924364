import React from "react";

interface Props {
  children: React.ReactNode[];
  gap?: "small" | "medium";
  containerRef?: React.RefObject<HTMLDivElement>;
}

const VerInputGroup = (props: Props): JSX.Element => {
  const { children, gap, containerRef } = props;
  const gaps = {
    small: 20,
    medium: 30,
  };

  return (
    <div
      ref={containerRef}
      className="ver-input-group"
      style={{ gap: gaps[gap || "medium"] }}
    >
      {children}
    </div>
  );
};

export default VerInputGroup;
