import React, {Component} from "react";
import {Button, Form, Input, message} from "antd";
import {api} from "../../data/urls";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {openNotification} from "../../helpers/openNotification";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import {compareEditedValueWithCurrent} from "../../helpers/editor/compareEditedValueWithCurrent";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import trimStringTo from "../../helpers/strings/trimStringTo";
import _ from "lodash";

interface Props {
    websites: any,
    blogs: any,
    blogPosts: any,
    clearUpdateBlogMetaDescription: any,
    auth: any,

    currentBlog: any,
    currentWebsite: any,

    saveBlogDataInStore: any,

    blogMetaDescriptionUpdateWaitingForServer: any,
    blogMetaDescriptionUpdateToggleInvalidAttempt: any,
    blogMetaDescriptionUpdateErrorMessage: any,
    meta_description: string,
    setMetaDescription: (meta_description: string) => void,
}
const { TextArea } = Input;

type MyState = { };
class UpdateBlogMetaDescriptionForm extends Component<Props, MyState>{
    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        // We set the new state only when we change a settings active website, blog or page, post.
        // We want to set up an initial value of a new picked website. blog or page, post.
        if((this.props.currentWebsite.subdomain !== nextProps.currentWebsite.subdomain) || (this.props.currentBlog.url !== nextProps.currentBlog.url)){
            this.props.setMetaDescription(nextProps.currentBlog.meta_description);
            this.props.clearUpdateBlogMetaDescription();
        }
    }

    handleMetaDescriptionUpdate = (e:any):void => {
        e.preventDefault();

        this.props.blogMetaDescriptionUpdateWaitingForServer(true);

        //We want to remove all errors from inputs on a new request.
        this.props.clearUpdateBlogMetaDescription();

        let newMetaDescription = this.props.meta_description;

        let blogPostUrlToServer = this.props.currentBlog.url;
  

        let accessToken = this.props.auth.accessToken;
        let apiUrl = api.blogs.updateBlog.prefix + this.props.currentBlog.id + api.blogs.updateBlog.postfix;
        axios.patch(apiUrl, {meta_description: newMetaDescription, subdomain: this.props.currentWebsite.subdomain}, {...returnAuthHeaderForAJAX(accessToken)})
            .then(() => {
                const dataToStore = {
                  data: {
                    ...this.props.currentBlog,
                    meta_description: newMetaDescription
                  }
                }
                // Server gives the object of the saved blog. Throw it in the store.
                this.props.saveBlogDataInStore(dataToStore, this.props.currentBlog.id);

                let successMessage = <span>Successfully changed the blog description to <b>{newMetaDescription}</b>.</span>;
                if(newMetaDescription === ''){
                    successMessage = <span>Successfully removed the blog description. Now it's blank.</span>;
                }

                fireAnalyticsEvent.fireCrisp(CrispEvents.changeBlogMetaDescription, {
                  subdomain: this.props.currentWebsite.subdomain,
                  blog_url: this.props.currentBlog.url,
                  meta_description: trimStringTo(newMetaDescription, 100),
                });
            })
            .catch(error => {
                if(error.response){
                    let errorData = error.response.data;



                    if (errorData.detail !== undefined){
                        let message = errorData.detail;

                        let errorObjectKey = getErrorKey(errorData);
                        let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                        fireAnalyticsEvent.fireCrisp(CrispEvents.changeBlogMetaDescriptionError, {
                          error_type: errorObjectKey,
                          error_message: errorObjectValue,
                          subdomain: this.props.currentWebsite.subdomain,
                          meta_description: trimStringTo(newMetaDescription, 100),
                          blog_url: this.props.currentBlog.url,
                        }, true);

                        // This happens when user tries to change a website, blog which doesn't exist or doesn't belog to the user.
                        openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                    }

                    if (errorData.locked !== undefined){
                        let message = errorData.locked;
                        openNotification('Denied', message, 'OK', 'warn');
                    }

                    if (errorData.meta_description !== undefined){
                        let message = errorData.meta_description.join(' ');
                        this.props.blogMetaDescriptionUpdateErrorMessage(message);
                        this.props.blogMetaDescriptionUpdateToggleInvalidAttempt(true);
                    }
                }
            })
            .then(response => {
                // always executed
                this.props.blogMetaDescriptionUpdateWaitingForServer(false);
            });
    };

    handleInputChange = (e:any) => {
        this.props.setMetaDescription(e.target.value);
    };

    render(){
        return (
            <form onSubmit={this.handleMetaDescriptionUpdate} className="settings-box__form" style={{display: "flex", gap: "10px", width: "300px", alignItems: "flex-start"}}>
                <div className="settings-box__input_box" style={{margin: 0, flexGrow: 1}}>
                    <TextArea
                        name="meta_description"
                        id="meta_description"
                        required={false}
                        placeholder="Enter meta description"
                        autosize={{ minRows: 2, maxRows: 10 }}
                        value={this.props.meta_description}
                        onChange={this.handleInputChange}
                        style={{width: "100%", marginBottom: 0}}
                    />
                    <div className="settings-box__error_message" style={{opacity: this.props.blogs.blogMetaDescriptionUpdateErrorMessage === undefined ? 0 : 1, marginTop: !this.props.blogs.blogMetaDescriptionUpdateErrorMessage ? 0 : "3px"}}>
                        {this.props.blogs.blogMetaDescriptionUpdateErrorMessage}
                    </div>
                </div>
                <Button
                    className="settings-box__form_save"
                    icon='save'
                    type="primary"
                    loading={this.props.blogs.isWaitingForBlogMetaDescriptionUpdateResponse}
                    disabled={this.props.blogs.isWaitingForBlogMetaDescriptionUpdateResponse}
                    htmlType="submit"
                    hidden={compareEditedValueWithCurrent(this.props.currentBlog.meta_description, this.props.meta_description)}
                    style={{bottom: 0}}
                />
            </form>
        )
    }
}

export default UpdateBlogMetaDescriptionForm;
