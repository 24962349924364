import React, { useEffect, useRef } from "react";
import { Button, Checkbox, Icon, Input, Switch, Tooltip } from "antd";
import { InputProps } from "./interfaces";
import { buttonStyles } from "../../../data/buttonStyles";
import { callToActionOptions } from "../../../data/callToActionOptions";
import { checkIsFormOld } from "../../../helpers/editor/checkIsFormOld";
import DebouncedInput from "../../DebouncedInput";
import checkIsButtonHrefDisabled from "../../../helpers/editor/checkIsButtonHrefDisabled";
import FormInputsPicker from "../../dashboard/FormInputsPicker";
import AfterFormSubmissionConfig from "../../dashboard/AfterFormSubmissionConfig";
import CaptchaConfig from "../captcha/CaptchaConfig";
import { generateComponentDataPath } from "../../../helpers/editor/generateComponentDataPath";
import { useCurrentInputData } from "./useCurrentInputData";
import _ from "lodash";
import ContentInputLabel from "../../ui_components/ContentInputLabel";
import HorInputGroup from "../../ui_components/HorInputGroup";

const Cta = (props: InputProps): JSX.Element => {
  const {
    content,
    currentInputData,
    saveContentInStore,
    generator,
    contentType,
    toggleNewEditionsDetected,
    displayMoveUpDownButtons,
    currentWebsite,
    displayButtonStyleEditorOpener,
    setState,
    state,
    displayButtonPluginsDrawerOpener,
    componentType,
    isFirstComponentBgDark,
    displayActiveOptionsPicker,
    auth,
    displayFormIntegrationsChooser,
  } = props;

  const currentInputDataRef = useCurrentInputData(currentInputData);

  let currentActiveCtaOption = state.activeOption;

  let onChangeBottomTextHandler = (value: any) => {
    saveContentInStore(
      generator.changeCtaBottomInfo(
        { [contentType]: currentInputDataRef.current },
        value
      )
    );
    toggleNewEditionsDetected(true);
  };
  let displayBottomTextChanger = () => {
    // the bottom text is only display in header and cta_form because it is not needed on other components and we do not pass "is dark bg" on other components.
    if (componentType === "header" || componentType === "cta_form") {
      let currentBottomText = currentInputDataRef.current.ctaBottomInfo.markup;

      return (
        <div style={{marginTop: 20}}>
        <ContentInputLabel>Bottom info</ContentInputLabel>
        <DebouncedInput
          onChange={onChangeBottomTextHandler}
          defaultValue={currentBottomText}
          placeholder={"No credit card required."}
          className="content-input__input"
          id={"bottom-text-" + contentType}
        />
        </div>
      );
    } else {
      return null;
    }
  };

  if (currentActiveCtaOption === callToActionOptions.buttons) {
    // We force all buttons to white in Nav when the first component of a page has black, accent or mature bg.
    let shouldDisplayForcedWhiteButtonsWarning =
      componentType === "nav" && isFirstComponentBgDark;

    // let doesLastButtonHavePluginActionInsteadOfHref = checkIsButtonHrefDisabled(lastItem, currentWebsite);
    // let maxItemsReached = lastItem.title !== '' && (doesLastButtonHavePluginActionInsteadOfHref === true);
    let buttonsCount = content.buttons.length;
    let maxItemsReached =
      currentInputDataRef.current.maxButtons === buttonsCount;
    let onlyOneButton = currentInputDataRef.current.maxButtons === 1; //If only one button available, then no need to delete an item.

    if (onlyOneButton) {
      buttonsCount = 1;
    }

    let buttonsInputs = content.buttons.map((item: any, key: number) => {
      let doesButtonHavePluginActionInsteadOfHref = checkIsButtonHrefDisabled(
        item,
        currentWebsite
      );

      let onChangeButtonPillPrefixHandler = (value: any) => {
        saveContentInStore(
          generator.changeButtonPillPrefix(
            { [contentType]: currentInputDataRef.current },
            value,
            key
          )
        );
        toggleNewEditionsDetected(true);
      };
      let onChangeButtonTitleHandler = (value: any) => {
        saveContentInStore(
          generator.changeButtonTitle(
            { [contentType]: currentInputDataRef.current },
            key,
            value
          )
        );
        toggleNewEditionsDetected(true);
      };
      let onChangeButtonHrefHandler = (value: any) => {
        saveContentInStore(
          generator.changeButtonHref(
            { [contentType]: currentInputDataRef.current },
            key,
            value
          )
        );
        toggleNewEditionsDetected(true);
      };

      return (
        <div
          className={"content-input__cluster "}
          key={"buttons-fields-" + key}
        >
          {onlyOneButton === false && (
            <Button
              onClick={() => {
                toggleNewEditionsDetected(true);
                saveContentInStore(
                  generator.deleteButton(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    key
                  )
                );
              }}
              htmlType="button"
              className="content-input__control_cluster"
              shape="circle"
              title="Remove this button"
              icon="cross"
              size="small"
            />
          )}

          {displayMoveUpDownButtons(
            key,
            buttonsCount,
            () => {
              saveContentInStore(
                generator.moveButtonUp(
                  {
                    [contentType]: currentInputDataRef.current,
                  },
                  key
                )
              );
            },
            () => {
              saveContentInStore(
                generator.moveButtonDown(
                  {
                    [contentType]: currentInputDataRef.current,
                  },
                  key
                )
              );
            }
          )}

          {content.buttons[key].title !== "" &&
            displayButtonStyleEditorOpener(
              content.buttons[key],
              () => {
                setState({
                  ...state,
                  currentCtaButtonIndex: key, //we need to know the index of a button to edit it
                  buttonStylesOptions: currentInputDataRef.current.buttonTypes,
                  currentButtonObject: content.buttons[key],
                });
              },
              shouldDisplayForcedWhiteButtonsWarning
            )}

          {/*If this button has an active styleType === 'pill' we show additional field - for prefix.*/}

          <HorInputGroup gap="small">
            {content.buttons[key].settings.appearance.styleType !==
              buttonStyles.mobileAppStore.typeTitle && (
              <Input.Group compact style={{display: "flex"}}>
                {content.buttons[key].title !== "" &&
                  content.buttons[key].settings.appearance.styleType ===
                    buttonStyles.pill.typeTitle && (
                    <DebouncedInput
                      onChange={onChangeButtonPillPrefixHandler}
                      defaultValue={content.buttons[key].pillPrefix}
                      placeholder="Whoa"
                      className="content-input__input"
                      style={{width: 64}}
                    />
                  )}
                <DebouncedInput
                  onChange={onChangeButtonTitleHandler}
                  defaultValue={content.buttons[key].title}
                  placeholder="Sign Up"
                  className="content-input__input"
                />
              </Input.Group>
            )}
            <div className="content-input__input">
              <DebouncedInput
                onChange={onChangeButtonHrefHandler}
                defaultValue={content.buttons[key].href}
                placeholder="/sign-up"
                className=""
                disabled={doesButtonHavePluginActionInsteadOfHref}
                addonAfter={displayButtonPluginsDrawerOpener(item, key)}
              />
            </div>
          </HorInputGroup>
          {doesButtonHavePluginActionInsteadOfHref === false &&
            content.buttons[key].href !== "" &&
            content.buttons[key].title !== "" && (
              <Checkbox
                checked={content.buttons[key].isTargetBlank}
                onChange={(event: any) => {
                  saveContentInStore(
                    generator.changeButtonIsTargetBlank(
                      {
                        [contentType]: currentInputDataRef.current,
                      },
                      key,
                      event.target.checked
                    )
                  );
                  toggleNewEditionsDetected(true);
                }}
                style={{marginTop: 10}}
              >
                Open in a new tab
              </Checkbox>
            )}
        </div>
      );
    });

    return (
      <div>
        {displayActiveOptionsPicker(generator.changeCtaActiveOption)}
        <div className="content-input__group">
          {buttonsInputs}
          {maxItemsReached === false && (
            <Button
              size="small"
              style={{ marginBottom: 10 }}
              onClick={() => {
                toggleNewEditionsDetected(true);
                saveContentInStore(
                  generator.addEmptyButtonIfNeeded({
                    [contentType]: currentInputDataRef.current,
                  })
                );
              }}
              icon="plus"
            >
              Add button
            </Button>
          )}
          {buttonsCount > 0 && displayBottomTextChanger()}
        </div>
      </div>
    );
  } else if (currentActiveCtaOption === callToActionOptions.form) {
    let isFormOldSingleEmailFieldType = checkIsFormOld(content.form); //learn more about this check in checkIsFormOld.ts. tl;dr: we add new properties to old form objects. And 'jsCodeOnSuccess' is one of the new props.

    let onChangeCtaEmailFormButtonTitleHandler = (value: any) => {
      saveContentInStore(
        generator.changeCtaEmailFormButtonTitle(
          { [contentType]: currentInputDataRef.current },
          value
        )
      );
      toggleNewEditionsDetected(true);
    };

    return (
      <div>
        {displayActiveOptionsPicker(generator.changeCtaActiveOption)}
        <div className="content-input__label_box" style={{marginTop: 16}}>
          <div className="content-input__label">Input fields</div>
        </div>
        {
          <FormInputsPicker
            form={content.form}
            auth={auth}
            currentWebsite={currentWebsite}
            displayMoveUpDownButtons={displayMoveUpDownButtons}
            isFormOldSingleEmailFieldType={isFormOldSingleEmailFieldType}
            saveContentInStore={saveContentInStore}
            toggleNewEditionsDetected={toggleNewEditionsDetected}
            contentType={contentType}
            currentInputData={currentInputDataRef.current}
          />
        }
        <div className="content-input__label_box">
          <div className="content-input__label">Submit button</div>
        </div>
        {displayButtonStyleEditorOpener(content.form.button, () => {
          setState({
            ...state,
            buttonStylesOptions: [buttonStyles.regular.typeTitle], // only regular type buttons for a form
            currentButtonObject: content.form.button,
          });
        })}
        <DebouncedInput
          onChange={onChangeCtaEmailFormButtonTitleHandler}
          defaultValue={content.form.button.title}
          placeholder={"Subscribe"}
          className="content-input__input"
          id={contentType}
        />

        {displayBottomTextChanger()}

        {displayFormIntegrationsChooser(content)}

        {isFormOldSingleEmailFieldType === false && (
          <AfterFormSubmissionConfig
            form={content.form}
            auth={auth}
            saveContentInStore={saveContentInStore}
            toggleNewEditionsDetected={toggleNewEditionsDetected}
            contentType={contentType}
            currentInputData={currentInputDataRef.current}
            componentType={componentType}
            currentWebsite={currentWebsite}
            componentDataPath={generateComponentDataPath(state)}
          />
        )}

        <CaptchaConfig
          currentWebsite={currentWebsite}
          componentType={componentType}
        />

        <div style={{ marginTop: 30 }}>
          <div className="content-input__label_box">
          <span className="content-input__label">Send URL parameters</span>
            <Tooltip
              title={
                <div>
                  Send the current URL parameters along with the form data.
                </div>
              }
            >
              <Icon
                className="content-input__tooltip_icon"
                type="info-circle"
              />
            </Tooltip>
          </div>
          <Switch
            onClick={() => {
              props.saveContentInStore(
                generator.toggleFormPreserveParamsActive({
                  [props.contentType]: props.currentInputData,
                })
              );
              props.toggleNewEditionsDetected(true);
            }}
            checked={
              !_.get(content, "form.settings.disablePreserveParams", false)
            }
          />
        </div>
      </div>
    );
  }
  return null;
};

export default Cta;
