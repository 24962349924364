import React, { Component } from "react";
import "./AfterFormSubmissionConfig.css";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { Button, Drawer, Icon, Input, Switch, Tag, Tooltip } from "antd";
import EmptyImage from "../../img/other/empty.svg";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { urls } from "../../data/urls";
import MyButton from "../MyButton";
import trimStringTo from "../../helpers/strings/trimStringTo";
import PopupMenu from "../editor/popups/PopupMenu";
import { getPopupDataFromInputData } from "../../helpers/editor/getPopupDataFromInputData";
import { generatePopupDrawerClass } from "../../helpers/generatePopupDrawerClass";
import { checkIfPopupExists } from "../../helpers/checkIfPopupExists";
import { ComponentDataPaths } from "../../helpers/types/popupTypes";
import DrawerTitle from "../editor/DrawerTitle";
import ContentInputLabel from "../ui_components/ContentInputLabel";
interface Props {
  auth: any;
  form: any;

  saveContentInStore: any;
  toggleNewEditionsDetected: any;
  contentType: any;
  currentInputData: any;
  currentWebsite: any;
  componentType: string;

  componentDataPath: ComponentDataPaths;
}
type MyState = {
  currentRedirectUrl: string;
  afterFormDrawerVisible: boolean;
  currentCode: string;
  showSaveRedirectUrlButtonSuccess: boolean;
  showSaveCodeButtonSuccess: boolean;
};
const { TextArea } = Input;

class AfterFormSubmissionConfig extends Component<Props, MyState> {
  constructor(props) {
    super(props);
    this.state = {
      currentRedirectUrl: "",
      afterFormDrawerVisible: false,
      currentCode: "",
      showSaveRedirectUrlButtonSuccess: false,
      showSaveCodeButtonSuccess: false,
    };
  }

  componentWillMount(): void {
    this.setState({
      currentRedirectUrl: this.props.form.settings.successRedirect.url,
      afterFormDrawerVisible: false,
      currentCode: this.props.form.settings.jsCodeOnSuccess.code,
    });
  }

  // componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
  //     // We set the new state only when we change a settings active website.
  //     // We want to set up an initial value of a new picked website.
  //     if(this.props.currentWebsiteSubdomain !== nextProps.currentWebsiteSubdomain){
  //         this.setState({currentWebsiteCustomDomain: nextProps.currentWebsiteCustomDomain});
  //         // We also clear errors when switch a website.
  //         this.props.clearUpdateCustomDomainInput();
  //     }
  // }

  showAfterFormDrawer = () => {
    this.setState({
      afterFormDrawerVisible: true,
    });
  };
  closeAfterFormDrawer = () => {
    this.setState({
      afterFormDrawerVisible: false,
    });
  };

  generator = new generateContentDataObject() as any;

  displayRedirectConfig = () => {
    let isFormRedirectActive = this.props.form.settings.successRedirect
      .isActive;
    let isFormRedirectIsTargetBlank = this.props.form.settings.successRedirect
      .isTargetBlank;
    let isFormRedirectPassValuesActive = this.props.form.settings
      .successRedirect.passValuesInGet;

    // let's detect
    let redirectUrlPlaceholder = "domain.com"; //default
    if (
      this.props.currentWebsite.custom_domain &&
      this.props.currentWebsite.custom_domain !== ""
    ) {
      //if has custom domain
      redirectUrlPlaceholder = this.props.currentWebsite.custom_domain;
    }
    redirectUrlPlaceholder = "https://" + redirectUrlPlaceholder;
    redirectUrlPlaceholder = redirectUrlPlaceholder + "/success";

    let redirectOnOff = () => {
      return (
        <Switch
          checkedChildren={<Icon type="check" />}
          unCheckedChildren={<span>off</span>}
          defaultChecked={isFormRedirectActive}
          onChange={handleAfterFormSubmissionRedirectActive}
        />
      );
    };

    let redirectConfig = () => {
      if (isFormRedirectActive) {
        return (
          <div>
            <Tooltip
              placement="bottomRight"
              title={
                <div>
                  Open the redirect page destination in a new tab (
                  <Icon type="block" />) or stay on the same page (
                  <Icon type="home" />
                  ).
                </div>
              }
            >
              <Switch
                style={{ marginRight: 5 }}
                defaultChecked={isFormRedirectIsTargetBlank}
                checkedChildren={<Icon type="block" />}
                unCheckedChildren={<Icon type="home" />}
                onChange={handleFormRedirectIsTargetBlank}
              />
            </Tooltip>
            <Tooltip
              placement="bottomRight"
              title={
                <div>
                  Pass the form data in URL (GET parameters):{" "}
                  <b>
                    {redirectUrlPlaceholder}
                    /?EMAIL=john@gmail.com&FULL_NAME=John%20Doe
                  </b>
                </div>
              }
            >
              <Switch
                defaultChecked={isFormRedirectPassValuesActive}
                checkedChildren={<Icon type="eye" />}
                unCheckedChildren={<Icon type="eye-invisible" />}
                onChange={handleFormRedirectPassValueInURLactive}
              />
            </Tooltip>
          </div>
        );
      } else {
        return null;
      }
    };

    let handleFormRedirectIsTargetBlank = () => {
      this.props.saveContentInStore(
        this.generator.toggleFormRedirectIsTargetBlank({
          [this.props.contentType]: this.props.currentInputData,
        })
      );
      this.props.toggleNewEditionsDetected(true);
    };

    let handleFormRedirectPassValueInURLactive = () => {
      this.props.saveContentInStore(
        this.generator.toggleFormRedirectPassValues({
          [this.props.contentType]: this.props.currentInputData,
        })
      );
      this.props.toggleNewEditionsDetected(true);
    };

    let handleInputChange = (e: any) => {
      this.setState({ currentRedirectUrl: e.target.value });
    };
    let handleAfterFormSubmissionUrlUpdate = (e: any): void => {
      e.preventDefault();
      this.props.saveContentInStore(
        this.generator.changeFormRedirectUrl(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentRedirectUrl
        )
      );
      this.props.toggleNewEditionsDetected(true);
      launchSuccessfulSaveAnimation();
    };

    let handleAfterFormSubmissionRedirectActive = (): void => {
      this.props.saveContentInStore(
        this.generator.toggleFormRedirectUrlActive({
          [this.props.contentType]: this.props.currentInputData,
        })
      );
      this.props.toggleNewEditionsDetected(true);
    };

    let launchSuccessfulSaveAnimation = () => {
      this.setState({
        showSaveRedirectUrlButtonSuccess: true,
      });

      setTimeout(() => {
        this.setState({
          showSaveRedirectUrlButtonSuccess: false,
        });
      }, 1500);
    };

    return (
      <div>
        <ContentInputLabel>Redirect to URL</ContentInputLabel>
        <form
          onSubmit={handleAfterFormSubmissionUrlUpdate}
          className="settings-box__form"
        >
          <div className="settings-box__input_box">
            <Tooltip
              trigger={"focus"}
              title={
                <div>
                  Example: <br /> <b>{redirectUrlPlaceholder}</b>
                </div>
              }
              placement="top"
            >
              <Input
                name="form_success_redirect"
                id="form_success_redirect"
                required={false}
                placeholder={"Enter full URL"}
                type="text"
                value={this.state.currentRedirectUrl}
                addonBefore={redirectOnOff()}
                addonAfter={redirectConfig()}
                onChange={handleInputChange}
                disabled={!isFormRedirectActive}
              />
            </Tooltip>
            {/*<div className="settings-box__error_message" style={{opacity: this.props.websites.customDomainUpdateErrorMessage === undefined ? 0 : 1}}>*/}
            {/*</div>*/}
          </div>
          <MyButton
            className="settings-box__form_save"
            icon="save"
            showButtonSuccess={this.state.showSaveRedirectUrlButtonSuccess}
            type="primary"
            htmlType="submit"
            hidden={
              this.props.form.settings.successRedirect.url ===
              this.state.currentRedirectUrl
            }
          />
        </form>
      </div>
    );
  };
  displayDiaformConfig = () => {
    let handleAfterFormSubmissionDiaformActive = (): void => {
      this.props.saveContentInStore(
        this.generator.toggleFormDiaformActive({
          [this.props.contentType]: this.props.currentInputData,
        })
      );
      this.props.toggleNewEditionsDetected(true);
    };

    let isDiaformActive = this.props.form.settings.messages.isActive;

    let diaformOnOff = () => {
      return (
        <div style={{ marginBottom: 10 }}>
          <Switch
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<span>off</span>}
            defaultChecked={isDiaformActive}
            onChange={handleAfterFormSubmissionDiaformActive}
          />
        </div>
      );
    };

    return (
      <div>
        <div className="content-input__label_box">
          <div className="content-input__tooltip_box">
            <Tooltip
              placement="left"
              title={
                <div style={{ width: 350 }}>
                  Add one or more messenger-like speech bubbles. <br />
                  They will appear one-by-one after a form has been successfully
                  submitted.
                  <br />
                  <br />
                  It will look like this: <br />
                  <br />
                  <img
                    style={{ width: "100%" }}
                    src={urls.assets.diaformGif}
                    alt="Diaform demo."
                  />
                  <br />
                  <br />
                  <span style={{ opacity: 0.6 }}>
                    Hint: you can use basic HTML tags.
                  </span>
                </div>
              }
            >
              <Icon
                className="content-input__tooltip_icon"
                type="info-circle"
              />
              'Success' message bubbles:
            </Tooltip>
          </div>
        </div>
        {diaformOnOff()}
      </div>
    );
  };
  displayJScodeConfig = () => {
    let handleAfterFormSubmissionCodeUpdate = (e: any): void => {
      e.preventDefault();
      this.props.saveContentInStore(
        this.generator.changeFormAfterCode(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentCode
        )
      );
      this.props.toggleNewEditionsDetected(true);
      launchSuccessfulSaveAnimation();
    };

    let handleAfterFormSubmissionCodeActive = (): void => {
      this.props.saveContentInStore(
        this.generator.toggleFormCodeActive({
          [this.props.contentType]: this.props.currentInputData,
        })
      );
      this.props.toggleNewEditionsDetected(true);
    };

    let handleInputChange = (e: any) => {
      this.setState({ currentCode: e.target.value });
    };

    let isCodeActive = this.props.form.settings.jsCodeOnSuccess.isActive;

    let codeOnOff = () => {
      return (
        <div style={{ marginBottom: 10 }}>
          <Switch
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<span>off</span>}
            defaultChecked={isCodeActive}
            onChange={handleAfterFormSubmissionCodeActive}
          />
        </div>
      );
    };

    let launchSuccessfulSaveAnimation = () => {
      this.setState({
        showSaveCodeButtonSuccess: true,
      });

      setTimeout(() => {
        this.setState({
          showSaveCodeButtonSuccess: false,
        });
      }, 1500);
    };

    return (
      <div>
        <ContentInputLabel>Run JavaScript code</ContentInputLabel>
        {codeOnOff()}
        <form
          onSubmit={handleAfterFormSubmissionCodeUpdate}
          className="settings-box__form"
        >
          <div style={{ width: "100%" }} className="settings-box__input_box">
            <TextArea
              name="form_success_code"
              id="form_success_code"
              required={false}
              placeholder={"window.Intercom('trackEvent', 'form_submission');"}
              value={this.state.currentCode}
              onChange={handleInputChange}
              disabled={!isCodeActive}
              autosize={{ minRows: 4, maxRows: 10 }}
              style={{ fontFamily: "monospace", width: 355 }}
            />
          </div>
          <MyButton
            className="settings-box__form_save"
            icon="save"
            showButtonSuccess={this.state.showSaveCodeButtonSuccess}
            type="primary"
            htmlType="submit"
            hidden={
              this.props.form.settings.jsCodeOnSuccess.code ===
              this.state.currentCode
            }
          />
        </form>
      </div>
    );
  };

  displayActionsPicker = () => {
    const isPopupConfigVisible =
      this.props.componentType && this.props.componentType !== "popups";
    return (
      <div style={{marginTop: 20}}>
        {isPopupConfigVisible && (
          <div className="after_form_submission_config__action_box">
            <PopupMenu
              popupType="form"
              contentType={this.props.contentType}
              currentInputData={this.props.currentInputData}
              saveContentInStore={this.props.saveContentInStore}
              toggleNewEditionsDetected={this.props.toggleNewEditionsDetected}
              componentDataPath={this.props.componentDataPath}
            />
          </div>
        )}
        <div className="after_form_submission_config__action_box">
          {this.displayRedirectConfig()}
        </div>
        {/*<div className="after_form_submission_config__action_box">*/}
        {/*{this.displayDiaformConfig()}*/}
        {/*</div>*/}
        <div className="after_form_submission_config__action_box">
          {this.displayJScodeConfig()}
        </div>
      </div>
    );
  };

  render() {
    let isFormRedirectActive = this.props.form.settings.successRedirect
      .isActive;
    let isDiaformActive = this.props.form.settings.messages.isActive;
    let isCodeActive = this.props.form.settings.jsCodeOnSuccess.isActive;
    const { currentInputData, componentDataPath, contentType } = this.props;

    const popupData = getPopupDataFromInputData(
      currentInputData,
      "form",
      componentDataPath,
      contentType
    );
    const isPopupActive =
      popupData.isActive &&
      checkIfPopupExists(popupData.popupId, this.props.currentWebsite);
    let isAnythingActive =
      isFormRedirectActive || isDiaformActive || isCodeActive || isPopupActive;

    let actionsCount = 0;

    if (isFormRedirectActive) {
      actionsCount += 1;
    }
    if (isDiaformActive) {
      actionsCount += 1;
    }
    if (isCodeActive) {
      actionsCount += 1;
    }
    if (isPopupActive) {
      actionsCount += 1;
    }
    let activeActionsText = `Manage action${
      actionsCount > 1 ? "s" : ""
    } (${actionsCount})`;

    let activeActionsInfo = (
      <span>
        {isPopupActive && (
          <Tag className="after_form_submission_config__tag" color="purple">
            Popup <Icon type="check" />
          </Tag>
        )}
        {isFormRedirectActive && (
          <Tooltip
            placement="left"
            title={trimStringTo(
              this.props.form.settings.successRedirect.url,
              100
            )}
          >
            <Tag className="after_form_submission_config__tag" color="volcano">
              Redirect <Icon type="check" />
            </Tag>
          </Tooltip>
        )}
        {isDiaformActive && (
          <Tag className="after_form_submission_config__tag" color="blue">
            Diaform <Icon type="check" />
          </Tag>
        )}
        {isCodeActive && (
          <Tooltip
            placement="left"
            title={
              <code>
                {trimStringTo(
                  this.props.form.settings.jsCodeOnSuccess.code,
                  100
                )}
              </code>
            }
          >
            <Tag className="after_form_submission_config__tag" color="magenta">
              JS code <Icon type="check" />
            </Tag>
          </Tooltip>
        )}
      </span>
    );

    let manageActionsJSX = (
      <div style={{ display: "flex" }}>
        <Button
          htmlType="button"
          type="default"
          icon="edit"
          onClick={this.showAfterFormDrawer}
          size="small"
        >
          {activeActionsText}
        </Button>{" "}
        <span style={{ marginLeft: 12, display: "block" }}>
          {activeActionsInfo}
        </span>
      </div>
    );

    let addActionsJSX = (
      <div className="content-input__integrations_empty">
        <img
          src={EmptyImage}
          alt="Empty bin image"
          style={{ marginBottom: "10px" }}
        />
        <br />
        <div style={{}}>No actions set up.</div>
        <br />
        <Button
          type="default"
          onClick={this.showAfterFormDrawer}
          htmlType="button"
          size="small"
          icon="plus"
        >
          Add actions
        </Button>
      </div>
    );

    return (
      <div style={{ marginTop: 30 }}>
        <div className="content-input__label_box">
        <div className="content-input__label">Actions after submission</div>
        </div>

        {isAnythingActive === true && manageActionsJSX}
        {isAnythingActive === false && addActionsJSX}

        <Drawer
          title={
            <DrawerTitle 
            title="Actions after submission"
            onClose={this.closeAfterFormDrawer}
            />
          }
          width={450}
          closable={false}
          onClose={this.closeAfterFormDrawer}
          visible={this.state.afterFormDrawerVisible}
          className={"edit-component-drawer " + generatePopupDrawerClass(this.props.componentType)}
        >
          {this.displayActionsPicker()}
        </Drawer>
      </div>
    );
  }
}

export default AfterFormSubmissionConfig;
