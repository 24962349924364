import React, {Component} from "react";
import './CustomFonts.css'
import {Button, Checkbox, Icon, Input, Popover, Radio} from "antd";
import FontPicker from "font-picker-react";
import {googleFontsAPIkey, googleFontsEnabledClassname, projectUrl} from "../../data/constants";
import {api} from "../../data/urls";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {openNotification} from "../../helpers/openNotification";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import {websiteFontOptions} from "../../data/websiteFontOptions";
import {getWebsiteProtocol} from "../../helpers/getWebsiteProtocol";
import _ from "lodash";

interface Props {
    auth: any,
    currentWebsite: any,
    saveWebsiteDataInStore: any,
    websites: any,
    toggleWaitingForResponseOnFontChange: any,
    activeTitlesFontFamily: string,
    activeTextFontFamily: string,
}

class CustomFonts extends Component<Props>{
    state = {
        beingActivated: false,
        newChangesToSave: false,
        isSaveButtonVisible: false,
        activeTitlesFontFamily: this.props.activeTitlesFontFamily,
        activeTextFontFamily: this.props.activeTextFontFamily,
    };

    displayFontPickers = () => {
        return (
            <div className="custom-fonts__items_box">
                <div className="custom-fonts__title_box">
                    <span className="apply-font-title custom-fonts__title">Title</span>
                    <div className="custom-fonts__picker">
                        <FontPicker
                            onChange={nextFont => {
                                this.setState({
                                    activeTitlesFontFamily: nextFont.family,
                                    newChangesToSave: true,
                                });
                            }}
                            activeFontFamily={this.state.activeTitlesFontFamily}
                            apiKey={googleFontsAPIkey}
                            variants={['700']}
                            limit={200}
                            pickerId="title"
                        />
                    </div>
                </div>
                <hr className="custom-fonts__divider"/>
                <div className="custom-fonts__text_box">
                    <span className="apply-font-text custom-fonts__text">
                        <div className="custom-fonts__text_item">Text</div>
                        <div className="custom-fonts__text_item">0123</div>
                    </span>
                    <div className="custom-fonts__picker">
                        <FontPicker
                            onChange={nextFont =>{
                                this.setState({
                                    activeTextFontFamily: nextFont.family,
                                    newChangesToSave: true,
                                });
                            }}
                            activeFontFamily={this.state.activeTextFontFamily}
                            apiKey={googleFontsAPIkey}
                            variants={['regular', '700']}
                            limit={200}
                            pickerId="text"
                        />
                    </div>
                </div>
            </div>
        );
    };

    saveNewCustomFonts = (message?:any) => {
        this.props.toggleWaitingForResponseOnFontChange(true);
        this.setState({beingActivated: true});

        let accessToken = this.props.auth.accessToken;

        let newFonts = {
            title: this.state.activeTitlesFontFamily,
            text: this.state.activeTextFontFamily,
        };

        let apiUrl = api.websites.updateWebsite.prefix + this.props.currentWebsite.subdomain + api.websites.updateWebsite.postfix;
        axios.patch(apiUrl, {custom_fonts_set: newFonts}, {...returnAuthHeaderForAJAX(accessToken)})
            .then(() => {
                const dataToStore = {
                  data: {
                    ...this.props.currentWebsite,
                    custom_fonts_set: newFonts
                  }
                }
                this.props.saveWebsiteDataInStore(dataToStore, this.props.currentWebsite.subdomain);

                this.setState({
                    newChangesToSave: false,
                });
            })
            .catch(error => {
                if(error.response){
                    let errorData = error.response.data;
                    if (errorData.detail !== undefined){
                        let message = errorData.detail;

                        let errorObjectKey = getErrorKey(errorData);
                        let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                        // This happens when user tries to change a website which doesn't exist or doesn't belog to the user.
                        openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                    }

                    if (errorData.locked !== undefined){
                        let message = errorData.locked;
                        openNotification('Denied', message, 'OK', 'warn');
                    }

                    if (errorData.active_font_classname !== undefined){
                        let message = errorData.subdomain.join(' ');
                        openNotification('Denied', message, 'OK', 'warn');
                    }
                }
            })
            .then(response => {
                // always executed
                this.props.toggleWaitingForResponseOnFontChange(false);
                this.setState({beingActivated: false});
            });
    };

    setDefaultCustomFonts = () => {
        // We need to set some fonts when user activates custom fonts for his website. We see his previous fonts classname and get the font title from there.
        let currentFontsClassname = this.props.currentWebsite.active_font_classname;

        let websiteFontOptionsCount = websiteFontOptions.length;
        for(let i = 0; i < websiteFontOptionsCount; i++){
            if(websiteFontOptions[i].fontClassname === currentFontsClassname){
                let currentDefaultCustomFont = websiteFontOptions[i].fontTitle;
                // out predefined font pairs use single font for both titles and text (and buttons)
                this.setState({
                    activeTitlesFontFamily: currentDefaultCustomFont,
                    activeTextFontFamily: currentDefaultCustomFont,
                });
                break;
            }
        }
    };

    displayActivateCustomFont = (isLoading:boolean) => {
        let handler = () => {
            this.setDefaultCustomFonts();

            this.props.toggleWaitingForResponseOnFontChange(true);
            this.setState({beingActivated: true});

            let accessToken = this.props.auth.accessToken;
            let apiUrl = api.websites.updateWebsite.prefix + this.props.currentWebsite.subdomain + api.websites.updateWebsite.postfix;
            axios.patch(apiUrl, {active_font_classname: googleFontsEnabledClassname}, {...returnAuthHeaderForAJAX(accessToken)})
                .then(() => {
                    const dataToStore = {
                      data: {
                        ...this.props.currentWebsite,
                        active_font_classname: googleFontsEnabledClassname
                      }
                    }
                    this.props.saveWebsiteDataInStore(dataToStore, this.props.currentWebsite.subdomain);

                    // Tell a user that the operation is successful.
                    let fullWebsiteUrl = 'https://' + this.props.currentWebsite.subdomain + '.' + projectUrl;

                    if(this.props.currentWebsite.is_custom_domain_active){
                        let websiteProtocol = getWebsiteProtocol(this.props.currentWebsite);
                        fullWebsiteUrl = websiteProtocol + this.props.currentWebsite["custom_domain"];
                    }
                    let newFontMessage = (<span>Successfully activated custom fonts for <a target="_blank" href={fullWebsiteUrl}>{fullWebsiteUrl}</a></span>);
                    this.saveNewCustomFonts(newFontMessage);

                    // openNotification('Success', newFontMessage, 'Close', 'success', 5);

                })
                .catch(error => {
                    if(error.response){
                        let errorData = error.response.data;
                        if (errorData.detail !== undefined){
                            let message = errorData.detail;

                            let errorObjectKey = getErrorKey(errorData);
                            let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                            // This happens when user tries to change a website which doesn't exist or doesn't belog to the user.
                            openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                        }

                        if (errorData.locked !== undefined){
                            let message = errorData.locked;
                            openNotification('Denied', message, 'OK', 'warn');
                        }

                        if (errorData.active_font_classname !== undefined){
                            let message = errorData.subdomain.join(' ');
                            openNotification('Denied', message, 'OK', 'warn');
                        }
                    }
                })
                .then(response => {
                    // always executed
                    this.props.toggleWaitingForResponseOnFontChange(false);
                    this.setState({beingActivated: false});
                });
        };
        return (
            <div className="custom-fonts__button_wrapper">
                <Button
                    loading={isLoading}
                    icon="font-size"
                    onClick={() => handler()}
                >
                    More fonts</Button>
            </div>
        );
    };

    displaySaveButton = (isLoading:boolean) => {
        return (
            <div className="custom-fonts__save_button_box">
                <Button
                    onClick={() => {
                        this.saveNewCustomFonts();
                    }}
                    type="primary"
                    loading={isLoading}
                    icon="save">Apply fonts</Button>
            </div>
        );
    };

    render(){
        let customFontsActive = this.props.currentWebsite.active_font_classname === googleFontsEnabledClassname;
        let isLoading = this.props.websites.isWaitingForChangeFontServerResponse;

        return (<div className={"custom-fonts " + (customFontsActive ? " custom-fonts--active " : ' ')}>
            {customFontsActive === false && this.displayActivateCustomFont(isLoading)}
            {customFontsActive === true && this.displayFontPickers()}
            {customFontsActive === true && this.state.newChangesToSave && this.displaySaveButton(isLoading)}
        </div>);
    }
}

export default CustomFonts;
