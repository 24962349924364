import React, {Component} from "react";
import './FontOptionItem.css'
import {api} from "../../data/urls";
import {getCurrentWebsiteSubdomain} from "../../helpers/getCurrentWebsiteSubdomain";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {projectUrl} from "../../data/constants";
import {openNotification} from "../../helpers/openNotification";
import SpinnerBox from "./SpinnerBox";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import {getWebsiteProtocol} from "../../helpers/getWebsiteProtocol";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import _ from "lodash";

interface Props {
    fontClassname: string,
    fontTitle: string,
    fontInfo: string,
    auth: any,
    currentWebsite: any,
    saveWebsiteDataInStore: any,
    websites: any,
    toggleWaitingForResponseOnFontChange: any,
}

class FontOptionItem extends Component<Props>{
    state = {
        beingActivated: false,
    };

    componentDidMount(): void {
    }

    applyNewFont = (fontClassname:string) => {
        this.props.toggleWaitingForResponseOnFontChange(true);
        this.setState({beingActivated: true});

        let accessToken = this.props.auth.accessToken;
        let apiUrl = api.websites.updateWebsite.prefix + this.props.currentWebsite.subdomain + api.websites.updateWebsite.postfix;
        axios.patch(apiUrl, {active_font_classname: fontClassname}, {...returnAuthHeaderForAJAX(accessToken)})
        .then(() => {
                const dataToStore = {
                  data: {
                    ...this.props.currentWebsite,
                    active_font_classname: fontClassname
                  }
                }
                this.props.saveWebsiteDataInStore(dataToStore, this.props.currentWebsite.subdomain);

                fireAnalyticsEvent.fireCrisp(CrispEvents.changeFont, {
                  subdomain: this.props.currentWebsite.subdomain,
                  new_font: this.props.currentWebsite.active_font_classname,
                });

            })
            .catch(error => {
                if(error.response){
                    let errorData = error.response.data;
                    if (errorData.detail !== undefined){
                        let message = errorData.detail;

                        let errorObjectKey = getErrorKey(errorData);
                        let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                        fireAnalyticsEvent.fireCrisp(CrispEvents.changeFontError, {
                          error_type: errorObjectKey,
                          error_message: errorObjectValue,
                          subdomain: this.props.currentWebsite.subdomain,
                          new_font: fontClassname,
                        }, true);

                        // This happens when user tries to change a website which doesn't exist or doesn't belog to the user.
                        openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                    }

                    if (errorData.locked !== undefined){
                        let message = errorData.locked;
                        openNotification('Denied', message, 'OK', 'warn');
                    }

                    if (errorData.active_font_classname !== undefined){
                        let message = errorData.subdomain.join(' ');
                        openNotification('Denied', message, 'OK', 'warn');
                    }
                }
            })
            .then(response => {
                // always executed
                this.props.toggleWaitingForResponseOnFontChange(false);
                this.setState({beingActivated: false});
            });
    };

    render(){
        let isActive = this.props.currentWebsite.active_font_classname === this.props.fontClassname;
        let isLoading = this.props.websites.isWaitingForChangeFontServerResponse;

        let overlayClassName = ' font-option-item__card_overlay ';
        if(this.state.beingActivated){
            overlayClassName += ' font-option-item__card_overlay--spinner-visible ';
        }else if(isLoading){
            overlayClassName += ' font-option-item__card_overlay--visible ';
        }

        return (
            <button
                onClick={() => {
                    this.applyNewFont(this.props.fontClassname)
                }}
                className={isActive ? "font-option-item font-option-item--active" : "font-option-item"}>

                <div className="font-option-item__content">

                    <span className={"font-option-item__examples " + this.props.fontClassname}>
                        <h1 className="font-option-item__title_example">Title</h1>
                        <div className="font-option-item__text_example">Text</div>
                        <div className="font-option-item__numbers_example">0123</div>
                    </span>

                    <span className="font-option-item__info"><b>{this.props.fontTitle}</b> {this.props.fontInfo}</span>
                </div>

                <div className={overlayClassName}>
                    <div className="font-option-item__spinner">
                        <SpinnerBox text="Applying new font..."/>
                    </div>
                </div>
            </button>
        )
    }
}

export default FontOptionItem;
