import React from "react";
import { Icon, Radio, Tooltip } from "antd";
import Search from "antd/lib/input/Search";
import generateFormIntegrationDataObject from "../../../../helpers/editor/generateFormIntegrationDataObject";
import { toggleNewWebsitePageEditionsDetected } from "../../../../store/websitePages/actions";
import { saveWebsiteNewFormIntegrationInState } from "../../../../store/websites/actions";
import { connect } from "react-redux";
import { SaveConnectedIntegrationsInServer } from "../../../../helpers/types/globalTypes";
import ContentInputLabel from "../../../ui_components/ContentInputLabel";

interface Props {
  currentFormIntegrationTitle: string;
  currentWebsite: any;
  websites: any;
  currentFormIntegrationWebhookMethod: string;

  saveConnectedIntegrationsInServer: SaveConnectedIntegrationsInServer;
  setParentState: (newState: any) => void;

  toggleNewWebsitePageEditionsDetected: (payload: boolean) => void;
  saveWebsiteNewFormIntegrationInState: (payload: any) => void;
}

const WebhookInputs = (props: Props): JSX.Element => {
  const {
    currentFormIntegrationTitle,
    currentFormIntegrationWebhookMethod,
    currentWebsite,
    saveConnectedIntegrationsInServer,
    websites,
    setParentState,
  } = props;

  const onChangeWebhookMethod = (e) => {
    setParentState({
      currentFormIntegrationWebhookMethod: e.target.value,
    });
  };

  return (
    // The "content-input" className is taken from the <ContentInput> JSX component. We can't use it for the integration connection goals so we copied the markup (label, tooltip, inputs).
    <li className="content-input">
      <ContentInputLabel
        title={<div>Create a Custom Webhook. Paste the Webhook URL here.</div>}
      >
        Enter webhook URL
      </ContentInputLabel>
      <div className="content-input__input_box">
        <Search
          onSearch={(value: any) => {
            // Detect component changes because when a new connection is added, it is automatically applied to the form which was being edited
            props.toggleNewWebsitePageEditionsDetected(true);

            const newConnectedIntegrationObject =
              generateFormIntegrationDataObject.webhook(
                currentFormIntegrationTitle,
                value,
                currentFormIntegrationWebhookMethod
              );

            // Save the new integration of the website in the Store:
            props.saveWebsiteNewFormIntegrationInState({
              newConnectedIntegrationObject,
              currentWebsiteSubdomain: currentWebsite.subdomain,
            });

            // Also save the new integration of the website in the server:
            saveConnectedIntegrationsInServer(newConnectedIntegrationObject);

            // Apply the newly added integration to the target form:
          }}
          enterButton={
            <span style={{ verticalAlign: "middle" }}>
              Save{" "}
              <Icon
                type={
                  websites.isWaitingForFormIntegrationsServerResponse
                    ? "loading"
                    : "save"
                }
              />
            </span>
          }
          placeholder="Custom Webhook URL"
          id={currentFormIntegrationTitle}
        />
      </div>
      <div style={{marginTop: 10}}>
        <Radio.Group
          onChange={onChangeWebhookMethod}
          defaultValue={currentFormIntegrationWebhookMethod}
        >
          <Radio.Button value="GET">GET</Radio.Button>
          <Radio.Button value="POST">POST</Radio.Button>
        </Radio.Group>
      </div>
    </li>
  );
};

const mapActionsToProps = {
  toggleNewWebsitePageEditionsDetected,
  saveWebsiteNewFormIntegrationInState,
};
export default connect(null, mapActionsToProps)(WebhookInputs);
