import { Button } from "antd";
import React from "react";

interface Props {
  title: string | JSX.Element;
  onClose?: () => void;
}

const DrawerTitle = (props: Props): JSX.Element => {
  const { title, onClose } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span className="edition-drawer-title">{title}</span>
      {!!onClose && (
        <Button
          icon="close"
          onClick={onClose}
          size="small"
          type="link"
          style={{ color: "rgba(0,0,0,0.66)" }}
        />
      )}
    </div>
  );
};

export default DrawerTitle;
