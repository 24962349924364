import React, {Component} from "react";
import axios from "axios";
import {api} from "../../data/urls";
import {Button, Dropdown, Icon, Input, Menu, Select, Switch, Tooltip, Checkbox} from "antd";
import {formFields} from "../../data/formFields";
import './FormInputsPicker.css'
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import DebouncedInput from "../DebouncedInput";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import DebouncedTextarea from '../DebouncedTextarea';
import DebouncedInputNumber from "../DebouncedInputNumber";
interface Props {
    auth: any,
    form: any,

    displayMoveUpDownButtons: any,

    saveContentInStore: any,
    toggleNewEditionsDetected: any,
    currentInputData: any,
    currentWebsite: any,
    contentType: any,

    isFormOldSingleEmailFieldType: boolean
}

class FormInputsPicker extends Component<Props>{
    dropdownContainerRef = React.createRef<HTMLDivElement>();
    dropdownChangeContainerRef = React.createRef<HTMLDivElement>();
    componentWillMount(): void {
        this.setState({
            advancedFieldsSettingsVisible: []
        });

        if(this.props.isFormOldSingleEmailFieldType){
            let getNewFormObject = (form:any) => {
                // things to do:
                /*
                * 1. settings.jsCodeOnSuccess
                *   settings.jsCodeOnSuccess.isActive = false
                *   settings.jsCodeOnSuccess.code = ''
                *
                * 2. add a new field in the form.fields.items[] array.
                *
                * 3. "successRedirect":  has 2 new props: isActive: false and  url: ''

                  4.  "messages":  has 2 new props:  isActive: false and items: []
                *
                * There may be some more props added but we do not really care. Because we just launch createFormContentObject to create a new form object with a email only field.
                *
                * But we need to keep the form integrations and button settings, so we only override settings (got new props) and fields (got a single email field),
                * */

                let freshFormObject = this.generator.createFormContentObject({
                    items: [this.generator.createFormFieldContentObject({
                        label: '' //because not one has labels before the complex forms update
                    })]
                });

                return {
                    ...form,
                    fields: freshFormObject["fields"],
                    settings: freshFormObject["settings"],
                }
            };

            // initiate a correct form object and add a default email input to it because all old forms has a email field only.
            let newFormObject = getNewFormObject(this.props.form);

            // save the form immediately in the state
            this.props.saveContentInStore(this.generator.changeFormObject({[this.props.contentType]: this.props.currentInputData}, newFormObject));
        }
    }

    state: {
        advancedFieldsSettingsVisible:any[]
    };

    generator = new generateContentDataObject();

    render(){
        let displayTypesMenu = (callback:any, activeMenuItemTitle?:string) =>{
            // see: formFields.ts
            return (
                <Menu onClick={callback}>
                    <Menu.Item key={formFields.email.title} disabled={activeMenuItemTitle === formFields.email.title}>
                        <span>
                            <Icon type={formFields.email.iconType} /> <span className="form_input_picker__type_title">{formFields.email.humanTitle}</span>
                        </span>
                    </Menu.Item>
                    <Menu.Item key={formFields.full_name.title} disabled={activeMenuItemTitle === formFields.full_name.title}>
                        <span>
                            <Icon type={formFields.full_name.iconType} /> <span className="form_input_picker__type_title">{formFields.full_name.humanTitle}</span>
                        </span>
                    </Menu.Item>
                    <Menu.Item key={formFields.message.title} disabled={activeMenuItemTitle === formFields.message.title}>
                        <span>
                            <Icon type={formFields.message.iconType} /> <span className="form_input_picker__type_title">{formFields.message.humanTitle}</span>
                        </span>
                    </Menu.Item>
                    <Menu.Item key={formFields.url.title} disabled={activeMenuItemTitle === formFields.url.title}>
                        <span>
                            <Icon type={formFields.url.iconType} /> <span className="form_input_picker__type_title">{formFields.url.humanTitle}</span>
                        </span>
                    </Menu.Item>
                    <Menu.Item key={formFields.dropdown.title} disabled={activeMenuItemTitle === formFields.dropdown.title}>
                        <span>
                            <Icon type={formFields.dropdown.iconType} /> <span className="form_input_picker__type_title">{formFields.dropdown.humanTitle}</span>
                        </span>
                    </Menu.Item>
                    <Menu.Item key={formFields.multiple_choice.title} disabled={activeMenuItemTitle === formFields.multiple_choice.title}>
                        <span>
                            <Icon type={formFields.multiple_choice.iconType} /> <span className="form_input_picker__type_title">{formFields.multiple_choice.humanTitle}</span>
                        </span>
                    </Menu.Item>
                    <Menu.Item key={formFields.phone.title} disabled={activeMenuItemTitle === formFields.phone.title}>
                        <span>
                            <Icon type={formFields.phone.iconType} /> <span className="form_input_picker__type_title">{formFields.phone.humanTitle}</span>
                        </span>
                    </Menu.Item>
                    <Menu.Item key={formFields.checkbox.title} disabled={activeMenuItemTitle === formFields.checkbox.title}>
                        <span>
                            <Icon type={formFields.checkbox.iconType} /> <span className="form_input_picker__type_title">{formFields.checkbox.humanTitle}</span>
                        </span>
                    </Menu.Item>
                    <Menu.Item key={formFields.other_short_text.title} disabled={activeMenuItemTitle === formFields.other_short_text.title}>
                        <span>
                            <Icon type={formFields.other_short_text.iconType} /> <span className="form_input_picker__type_title">{formFields.other_short_text.humanTitle}</span>
                        </span>
                    </Menu.Item>
                    <Menu.Item key={formFields.file.title} disabled={activeMenuItemTitle === formFields.file.title}>
                        <span>
                            <Icon type={formFields.file.iconType} /> <span className="form_input_picker__type_title">{formFields.file.humanTitle}</span>
                        </span>
                    </Menu.Item>
                </Menu>
            );
        };  

        let displayChangeFieldTitleSelector = (formFieldItem:any, formFieldIndex:number) =>{
            // When we change title of a field we also change icon (for builder) and HTML 'type' attr

            let changeFormFieldTitleHandler = (e:any) => {
                let newFieldTitle = e.key;
                this.props.saveContentInStore(this.generator.changeFormFieldTitle({[this.props.contentType]: this.props.currentInputData}, formFieldIndex, newFieldTitle));
                this.props.toggleNewEditionsDetected(true);
            };

            return (
                <Dropdown trigger={['click']} overlay={displayTypesMenu(changeFormFieldTitleHandler, formFieldItem.title)} getPopupContainer={() => this.dropdownChangeContainerRef.current}>
                    <Button
                        icon={formFields[formFieldItem.title].iconType}
                    >
                        <Icon style={{marginLeft: 5}} type="down" />
                    </Button>
                </Dropdown>
            );


        };

        let addNewFormField = (e:any) => {
            let newFieldTitle = e.key;
            this.props.saveContentInStore(this.generator.addNewFormField({[this.props.contentType]: this.props.currentInputData}, newFieldTitle));
            this.props.toggleNewEditionsDetected(true);
        };

        let displayFormField = (formFieldItem:any, key:number, formFieldsCount:number) => {
            let showSettingsAddon = (
                <Tooltip placement="topRight" title="Show advanced field settings: placeholder, name, custom attributes, ID.">
                    <Button
                        onClick={() => {this.setState({
                                advancedFieldsSettingsVisible:
                                    [...this.state.advancedFieldsSettingsVisible, key]
                            }
                        )}}
                        size="small"
                        icon="setting"/>
                </Tooltip>
            );

            let hideSettingsAddon = (
              <Tooltip placement="topRight" title="Hide advanced settings.">
                  <Button
                      type="primary"
                      onClick={() => {
                          let newArray = [...this.state.advancedFieldsSettingsVisible]
                          const index = newArray.indexOf(key);
                          if (index > -1) {
                            newArray.splice(index, 1);
                          }
                          this.setState({
                                advancedFieldsSettingsVisible:
                                    [...newArray]
                            }
                        )
                      }
                    }
                      size="small"
                      icon="setting"/>
              </Tooltip>
            );

            const advancedSettingsFieldWidth = 290;

            let returnNameSetting = (formFieldItem:any) => {
                let handleNameBlurInput = (value:any) => {
                    let formValue = value.replaceAll(' ', '-'); // because spaces in Name may break the form
                    this.props.saveContentInStore(this.generator.changeFormFieldName({[this.props.contentType]: this.props.currentInputData}, key, formValue));
                    this.props.toggleNewEditionsDetected(true);
                };

                return (
                  <div className="form_input_picker__setting_box">
                    <div className="content-input__tooltip_box">
                        <span className="content-input__label">Name</span>
                    </div>
                    <DebouncedInput
                        title=""
                        onChange={handleNameBlurInput}
                        size="small"
                        style={{width: advancedSettingsFieldWidth, }}
                        placeholder="EMAIL"
                        defaultValue={formFieldItem.name}
                        // value={}
                    />
                  </div>
                );
            };

            let returnPlaceholderSetting = (formFieldItem:any) => {
              let handlePlaceholderBlurInput = (value:any) => {
                  this.props.saveContentInStore(this.generator.changeFormFieldPlaceholder({[this.props.contentType]: this.props.currentInputData}, key, value));
                  this.props.toggleNewEditionsDetected(true);
              };

              if(formFieldItem.title === formFields.dropdown.title && formFieldItem.required === false){
                // no place to display Placeholder of a select input when it is not required - the palceholder in this case is the first possible option.
                return null;
              }else{
                return (
                  <div className="form_input_picker__setting_box">
                    <div className="content-input__tooltip_box">
                    <span className="content-input__label">Placeholder</span>
                    </div>
                    <DebouncedInput
                        title=""
                        onChange={handlePlaceholderBlurInput}
                        size="small"
                        style={{width: advancedSettingsFieldWidth, }}
                        placeholder="Enter your email"
                        defaultValue={formFieldItem.placeholder}
                        // value={}
                    />
                  </div>
                );
              }
            };

            let returnNoResultsTextSettings = (formFieldItem:any) => {
              let handleOnChange = (value:any) => {
                  this.props.saveContentInStore(this.generator.changeFormFieldNoResultsText({[this.props.contentType]: this.props.currentInputData}, key, value));
                  this.props.toggleNewEditionsDetected(true);
              };

              if(formFieldItem.title !== formFields.multiple_choice.title){
                return null;
              }else{
                return (
                  <div className="form_input_picker__setting_box">
                    <div className="content-input__tooltip_box">
                        <span className="content-input__label">No results text</span>
                        <Tooltip placement="topRight" title={<div>Text displayed when no options match the search query.</div>}>
                            <Icon className="content-input__tooltip_icon" type="info-circle" />
                        </Tooltip>
                    </div>
                    <DebouncedInput
                        title=""
                        onChange={handleOnChange}
                        size="small"
                        style={{width: advancedSettingsFieldWidth, }}
                        placeholder="No results match"
                        defaultValue={formFieldItem.noResultsText}
                    />
                  </div>
                );
              }
            };

            let returnAttributesSetting = (formFieldItem:any) => {
              let handlePlaceholderBlurInput = (value:any) => {
                  this.props.saveContentInStore(this.generator.changeFormFieldAttributes({[this.props.contentType]: this.props.currentInputData}, key, value));
                  this.props.toggleNewEditionsDetected(true);
              };

              return (
                <div className="form_input_picker__setting_box">
                  <div className="content-input__tooltip_box">
                    <span className="content-input__label">Attributes</span>
                  </div>
                  <DebouncedTextarea
                      onChange={handlePlaceholderBlurInput}
                      style={{width: advancedSettingsFieldWidth, }}
                      placeholder='data-attr-1="cat"&#10;data-attr-2="dog"'
                      defaultValue={formFieldItem.settings.attributes ? formFieldItem.settings.attributes : ''}
                      // value={}
                  />
                </div>
              );
            };

            let returnDefaultValueSetting = (formFieldItem:any) => {
              let handleOnChange = (value:any) => {
                  this.props.saveContentInStore(this.generator.changeFormFieldDefaultValue({[this.props.contentType]: this.props.currentInputData}, key, value));
                  this.props.toggleNewEditionsDetected(true);
              };

              let input = <DebouncedInput
                onChange={handleOnChange}
                style={{width: advancedSettingsFieldWidth, }}
                placeholder='Enter value'
                defaultValue={formFieldItem.settings.defaultValue ? formFieldItem.settings.defaultValue : ''}
                size="small"
              />
              if (formFieldItem.title === formFields.message.title) {
                input = <DebouncedTextarea
                  onChange={handleOnChange}
                  style={{width: advancedSettingsFieldWidth, }}
                  placeholder='Enter value'
                  defaultValue={formFieldItem.settings.defaultValue ? formFieldItem.settings.defaultValue : ''}
                />
              }

              return (
                <div className="form_input_picker__setting_box">
                  <div className="content-input__tooltip_box">
                      <span className="content-input__label">Default value</span>
                  </div>
                  {input}
                </div>
              );
            };

            const returnIsHiddenSetting = (formFieldItem:any) => {
              let handleOnChange = (value:any) => {
                  this.props.saveContentInStore(this.generator.changeFormFieldIsHidden({[this.props.contentType]: this.props.currentInputData}, key, value));
                  this.props.toggleNewEditionsDetected(true);
              };
              if (formFieldItem.title === formFields.file.title) {
                return null;
              }

              return (
                <div className="form_input_picker__setting_box">
                  <div className="content-input__tooltip_box">
                      <span className="content-input__label">Hidden</span>
                  </div>
                  <div style={{width: advancedSettingsFieldWidth, display: "flex", alignItems: "center"}}>
                    <Switch 
                    size="small" 
                    onChange={
                      (value) => {
                        handleOnChange(value);
                      }
                    }
                    checked={formFieldItem.settings.isHidden}
                    />
                  </div>
                </div>
              );
            };

            let returnClassesSetting = (formFieldItem:any) => {
              let handleClassesBlurInput = (value:any) => {
                  this.props.saveContentInStore(this.generator.changeFormFieldClasses({[this.props.contentType]: this.props.currentInputData}, key, value));
                  this.props.toggleNewEditionsDetected(true);
              };

              return (
                <div className="form_input_picker__setting_box">
                  <div className="content-input__tooltip_box">
                      <span className="content-input__label">Classes</span>
                  </div>
                  <DebouncedInput
                      title=""
                      onChange={handleClassesBlurInput}
                      size="small"
                      style={{width: advancedSettingsFieldWidth, }}
                      placeholder="my-email-input  my-field"
                      defaultValue={formFieldItem.settings.classes ? formFieldItem.settings.classes : ''}
                      // value={}
                  />
                </div>
              );
            };

            let returnIdSetting = (formFieldItem:any) => {
              let handleIdBlurInput = (value:any) => {
                  this.props.saveContentInStore(this.generator.changeFormFieldId({[this.props.contentType]: this.props.currentInputData}, key, value));
                  this.props.toggleNewEditionsDetected(true);
              };

              return (
                <div className="form_input_picker__setting_box">
                  <div className="content-input__tooltip_box">
                      <span className="content-input__label">ID</span>
                  </div>
                  <DebouncedInput
                      title=""
                      onChange={handleIdBlurInput}
                      size="small"
                      style={{width: advancedSettingsFieldWidth, }}
                      placeholder="Custom ID"
                      defaultValue={formFieldItem.settings.id ? formFieldItem.settings.id : ''}
                      // value={}
                  />
                </div>
              );
            };

            let returnMaskSetting = (formFieldItem:any) => {
              let handleMaskBlurInput = (value:any) => {
                  this.props.saveContentInStore(this.generator.changeFormFieldMask({[this.props.contentType]: this.props.currentInputData}, key, value));
                  this.props.toggleNewEditionsDetected(true);
              };

              return (
                <div className="form_input_picker__setting_box">
                  <div className="content-input__tooltip_box">
                      <span className="content-input__label">Mask</span>
                      <Tooltip placement="topRight" title={
                      <div>
                        Add a mask to organize the data input. 
                        <br /><br />
                        Rules: <br />
                        <b>0</b> - any digit <br />
                        <b>a</b> - any letter <br />
                        <b>*</b> - any char <br />
                        <b>{'{}'}</b> - fixed part <br />
                        <b>[]</b> - optional <br />
                        <br />
                        Examples:<br /> 
                        <code>+{'{49}'} 00 000 000</code><br />
                        <code>#000000</code><br />
                        <code>00-00-00</code><br />
                      </div>}>
                          <Icon className="content-input__tooltip_icon" type="info-circle" />
                      </Tooltip>
                  </div>
                  <DebouncedInput
                      title=""
                      onChange={handleMaskBlurInput}
                      size="small"
                      style={{width: advancedSettingsFieldWidth, }}
                      placeholder="+{49} 00 000 000"
                      defaultValue={formFieldItem.settings.mask ? formFieldItem.settings.mask : ''}
                      // value={}
                  />
                </div>
              );
            };

            let returnMaxFilesSettings = (formFieldItem:any) => {
              let handleOnChange = (value:any) => {
                  this.props.saveContentInStore(this.generator.changeFormFieldMaxFiles({[this.props.contentType]: this.props.currentInputData}, key, value));
                  this.props.toggleNewEditionsDetected(true);
              };

              if(formFieldItem.title !== formFields.file.title){
                return null;
              }else{
                return (
                  <div className="form_input_picker__setting_box">
                    <div className="content-input__tooltip_box">
                        <span className="content-input__label">Maximum files</span>
                    </div>
                    <DebouncedInputNumber
                        title=""
                        onChange={handleOnChange}
                        size="small"
                        style={{width: advancedSettingsFieldWidth, }}
                        placeholder="10"
                        defaultValue={formFieldItem.maxFiles || 1}
                        min={1}
                        max={24}
                    />
                  </div>
                );
              }
            };

            let returnFileTypesSettings = (formFieldItem:any) => {
              let handleOnChange = (value:any) => {
                  this.props.saveContentInStore(this.generator.changeFormFieldAllowedFileTypes({[this.props.contentType]: this.props.currentInputData}, key, value));
                  this.props.toggleNewEditionsDetected(true);
              };

              if(formFieldItem.title !== formFields.file.title){
                return null;
              }else{
                return (
                  <div className="form_input_picker__setting_box">
                    <div className="content-input__tooltip_box">
                        <span className="content-input__label">Allowed files</span>
                        <Tooltip placement="topRight" title={<div>Specify the list of allowed file types. Separate with a semicolon. Leave empty to allow all file types.</div>}>
                            <Icon className="content-input__tooltip_icon" type="info-circle" />
                        </Tooltip>
                    </div>
                    <DebouncedInput
                      title=""
                      onChange={handleOnChange}
                      size="small"
                      style={{width: advancedSettingsFieldWidth, }}
                      placeholder="image/*;video/*"
                      defaultValue={formFieldItem.allowedFileTypes}
                    />
                  </div>
                );
              }
            };

            let displayAdvancedSettings = (formFieldItem:any) => {
              let shouldDisplayMaskSetting = false;

              let isCheckbox = formFieldItem.title !== formFields.checkbox.title;

              if (
                formFieldItem.title !== formFields.message.title  // no mask for large text field (textarea)
                && formFieldItem.title !== formFields.email.title // no mask for email inputs (to make sure users do not break their forms)
                && isCheckbox // no mask for checkbox
                && formFieldItem.title !== formFields.dropdown.title // no mask for dropdown (option - select)
                && formFieldItem.title !== formFields.multiple_choice.title
                && formFieldItem.title !== formFields.file.title
              ){ 
                shouldDisplayMaskSetting = true;
              }

              let shouldDisplayPlaceholderSetting = false;
              if (
                isCheckbox  // no placeholder for checkbox
              ){
                shouldDisplayPlaceholderSetting = true;
              }
              const hasDefaultValueSettings = formFieldItem.title === formFields.message.title || 
              formFieldItem.title === formFields.other_short_text.title ||
              formFieldItem.title === formFields.email.title ||
              formFieldItem.title === formFields.url.title || 
              formFieldItem.title === formFields.phone.title ||
              formFieldItem.title === formFields.full_name.title;


              return (
                <div className="form_input_picker__field_box__advanced_settings">
                  {returnNameSetting(formFieldItem)}
                  {shouldDisplayPlaceholderSetting && returnPlaceholderSetting(formFieldItem)}
                  {returnMaxFilesSettings(formFieldItem)}
                  {returnFileTypesSettings(formFieldItem)}
                  {returnNoResultsTextSettings(formFieldItem)}
                  {returnIdSetting(formFieldItem)}
                  {shouldDisplayMaskSetting && returnMaskSetting(formFieldItem)}
                  {returnClassesSetting(formFieldItem)}
                  {returnAttributesSetting(formFieldItem)}
                  {hasDefaultValueSettings && returnDefaultValueSetting(formFieldItem)}
                  {returnIsHiddenSetting(formFieldItem)}
                </div>
              )
            };

            // check if the current key is in the advancedFieldsSettingsVisible array
            let isSettingsVisible = false;
            if(this.state.advancedFieldsSettingsVisible && this.state.advancedFieldsSettingsVisible.indexOf(key) !== -1){
                isSettingsVisible = true;
            }

            let handleLabelChangeInput = (value:any) => {
                this.props.saveContentInStore(this.generator.changeFormFieldLabel({[this.props.contentType]: this.props.currentInputData}, key, value));
                this.props.toggleNewEditionsDetected(true);
            };

            let handleDropdownOptionsBlurInput = (value:any) => {
                this.props.saveContentInStore(this.generator.changeFormDropdownItems({[this.props.contentType]: this.props.currentInputData}, key, value));
                this.props.toggleNewEditionsDetected(true);
            };

            let isCheckbox = formFieldItem.title !== formFields.checkbox.title;
            let placeholderText = isCheckbox ? 'Form field label' : 'Label (accepts HTML)';
            
            return (
                <div key={"field-" + key} className={"form_input_picker__field_box " + (isSettingsVisible ? ' form_input_picker__field_box--opened ' : ' ')} ref={this.dropdownChangeContainerRef}>
                    <div className="form_input_picker__field_box__primary_settings">
                      <div className="form_input_picker__field_wrapper">
                          <InputGroup className="form_input_picker__field">

                              {displayChangeFieldTitleSelector(formFieldItem, key)}

                              <Tooltip title={<div>
                                  <b style={{color: 'red'}}>*</b> &mdash; the field is required (must be filled in by a visitor before the form submission). <br/><br/>
                                  <b style={{color: 'var(--piter)'}}>○</b> &mdash; the field is optional.
                              </div>}>
                                  <Button
                                      className="form_input_picker__required_button"
                                      htmlType="button"

                                      onClick={(event: any) => {
                                          this.props.saveContentInStore(this.generator.toggleFormFieldRequired({[this.props.contentType]: this.props.currentInputData}, key));
                                          this.props.toggleNewEditionsDetected(true);
                                      }}>
                                      {formFieldItem.required ? (<b style={{color: 'red'}}>*</b>) : (<b style={{color: 'var(--piter)'}}>○</b>)}
                                  </Button>
                              </Tooltip>

                              <DebouncedInput
                                  title="The field label (info)."
                                  placeholder={placeholderText}
                                  defaultValue={formFieldItem.label}
                                  onChange={handleLabelChangeInput}
                                  addonAfter={isSettingsVisible ? hideSettingsAddon : showSettingsAddon}
                              />

                          </InputGroup>

                          {/*Dropdown has also a special field for editing it's select options*/}
                          {(formFieldItem.title === formFields.dropdown.title || formFieldItem.title === formFields.multiple_choice.title) && <DebouncedInput
                style={{marginTop: 5}}
                              // We store select options as an array but edit as a string. Divider - ;
                defaultValue={formFieldItem.dropdownItems.join(';')}
                onChange={handleDropdownOptionsBlurInput}
                size="small"
                placeholder={'Male;Female;Other'}
                addonBefore={<Tooltip
                                  placement="left"
                                  title={
                                  <div>
                                      Edit the dropdown select options here. Write all options in a row.<br/>Use <b>;</b> as a separator.<br/><br/>
                                      Example: <b>Male;Female;Other</b>
                                  </div>
                              }><Icon type="question-circle" /></Tooltip>}
              />}
                      </div>

                      <div className="form_input_picker__field_controls">
                        <Button
                            onClick={(event:any) => {
                                this.props.toggleNewEditionsDetected(true);
                                this.props.saveContentInStore(this.generator.deleteFormField({[this.props.contentType]: this.props.currentInputData}, key));
                            }}
                            htmlType="button"
                            className="form_input_picker__field_delete"
                            shape="circle"
                            title="Delete this field"
                            icon="delete"
                            size="small"/>

                        {this.props.displayMoveUpDownButtons(
                            key,
                            formFieldsCount,
                            () => {
                                this.props.saveContentInStore(this.generator.moveFormFieldUp({[this.props.contentType]: this.props.currentInputData}, key));
                                this.props.toggleNewEditionsDetected(true);

                                // On each more we also hide all advanced settings inputs because if we don't the opened advanced settings will not move.
                                this.setState({
                                    advancedFieldsSettingsVisible: []
                                });
                            },
                            () => {
                                this.props.saveContentInStore(this.generator.moveFormFieldDown({[this.props.contentType]: this.props.currentInputData}, key));
                                this.props.toggleNewEditionsDetected(true);

                                // On each more we also hide all advanced settings inputs because if we don't the opened advanced settings will not move.
                                this.setState({
                                    advancedFieldsSettingsVisible: []
                                });
                            }
                        )}
                    </div>
                    </div>
                    {isSettingsVisible && displayAdvancedSettings(formFieldItem)}
                </div>
            );
        };

        let displayAllFormFields = () => {
            let form = this.props.form;
            let formFieldsCount = form.fields.items.length;

            if(formFieldsCount === 0){
                return (null);
            }else{
                let formFieldsJSX = [];
                for(let i = 0; i < formFieldsCount; i++){
                    formFieldsJSX.push(displayFormField(form.fields.items[i], i, formFieldsCount));
                }
                return (<div>
                    {formFieldsJSX}
                </div>);
            }
        };

        const InputGroup = Input.Group;

        return (
            <div style={{ marginBottom: 30 }} ref={this.dropdownContainerRef}>
                <div>
                    {displayAllFormFields()}
                </div>

                <Dropdown className="form_input_picker__add_new_box" trigger={['click']} overlay={displayTypesMenu(addNewFormField)} getPopupContainer={() => this.dropdownContainerRef.current}>
                        <Button size="small" icon="plus">
                          Add new form field
                        </Button>
                </Dropdown>
            </div>
        )
    }
}

export default FormInputsPicker;
