import React from "react";
import { Icon, Tooltip } from "antd";
import Search from "antd/lib/input/Search";
import { urls } from "../../../../data/urls";
import generateFormIntegrationDataObject from "../../../../helpers/editor/generateFormIntegrationDataObject";
import { toggleNewWebsitePageEditionsDetected } from "../../../../store/websitePages/actions";
import { saveWebsiteNewFormIntegrationInState } from "../../../../store/websites/actions";
import { connect } from "react-redux";
import { SaveConnectedIntegrationsInServer } from "../../../../helpers/types/globalTypes";
import ContentInputLabel from "../../../ui_components/ContentInputLabel";

interface Props {
  currentFormIntegrationTitle: string;
  currentWebsite: any;
  websites: any;
  saveConnectedIntegrationsInServer: SaveConnectedIntegrationsInServer;

  toggleNewWebsitePageEditionsDetected: (payload: boolean) => void;
  saveWebsiteNewFormIntegrationInState: (payload: any) => void;
}

const MailchimpInputs = (props: Props): JSX.Element => {
  const {
    currentFormIntegrationTitle,
    currentWebsite,
    saveConnectedIntegrationsInServer,
    websites,
  } = props;

  // The "content-input" className is taken from the <ContentInput> JSX component. We can't use it for the integration connection goals so we copied the markup (label, tooltip, inputs).
  return (
    <li className="content-input">
      <ContentInputLabel
        title={
          <div>
            Please copy the <code>action=""</code> attribute contents:
            <br />
            <br />
            <a
              href={urls.assets.mailchimpIntegrationAction}
              style={{ display: "block" }}
              target="_blank"
            >
              <img
                style={{ cursor: "pointer", width: "100%" }}
                src={urls.assets.mailchimpIntegrationAction}
                alt="Mailchimp integraion guide image."
              />
            </a>
            <br />
            Read this{" "}
            <a
              style={{
                color: "white",
                textDecoration: "underline",
              }}
              href={urls.guides.mailchimpIntegration}
              target="_blank"
            >
              simple tutorial <Icon type="link" />
            </a>{" "}
            if you need help.
          </div>
        }
      >
        Enter the Mailchimp action
      </ContentInputLabel>
      <div className="content-input__input_box">
        <Search
          onSearch={(value: any) => {
            // Detect component changes because when a new connection is added, it is automatically applied to the form which was being edited
            props.toggleNewWebsitePageEditionsDetected(true);

            const newConnectedIntegrationObject =
              generateFormIntegrationDataObject.mailchimp(
                currentFormIntegrationTitle,
                value
              );

            // Save the new integration of the website in the Store:
            props.saveWebsiteNewFormIntegrationInState({
              newConnectedIntegrationObject,
              currentWebsiteSubdomain: currentWebsite.subdomain,
            });

            // Also save the new integration of the website in the server:
            saveConnectedIntegrationsInServer(newConnectedIntegrationObject);

            // Apply the newly added integration to the target form:
          }}
          enterButton={
            <span style={{ verticalAlign: "middle" }}>
              Save{" "}
              <Icon
                type={
                  websites.isWaitingForFormIntegrationsServerResponse
                    ? "loading"
                    : "save"
                }
              />
            </span>
          }
          placeholder="https://cool.us18.list-manage.com/subscribe/post?u=b5a4c18c515b0b1b6f7f7f272&amp;id=5238fd87f4"
          id={currentFormIntegrationTitle}
        />
      </div>
    </li>
  );
};

const mapActionsToProps = {
  toggleNewWebsitePageEditionsDetected,
  saveWebsiteNewFormIntegrationInState,
};
export default connect(null, mapActionsToProps)(MailchimpInputs);
