import React, { useEffect } from "react";
import { FC } from "react";
import { InputProps } from "./interfaces";
import _ from "lodash";
import { connect } from "react-redux";
import { Button } from "antd";
import { useCurrentInputData } from "./useCurrentInputData";
import { openNotification } from "../../../helpers/openNotification";
import {
  DEFAULT_ERROR_MESSAGE,
  notificationDuration,
} from "../../../data/constants";
import "./Marketsy.scss";
import { getActiveDomain } from "../../../helpers/websites/getActiveDomain";
import ContentInputLabel from "../../ui_components/ContentInputLabel";

interface Props extends InputProps {}

const Marketsy: FC<any> = (props: Props) => {
  const {
    generator,
    saveContentInStore,
    contentType,
    currentInputData,
    toggleNewEditionsDetected,
    currentWebsite
  } = props;

  const currentInputDataRef = useCurrentInputData(currentInputData);

  const getWidget = () => {
    if (!(window as any).MarketsyIntegration) {
      openNotification(
        "Error: Can't connect to Marketsy",
        DEFAULT_ERROR_MESSAGE,
        "OK",
        "error",
        notificationDuration.medium
      );
      return;
    }
    const websiteUrl = `https://${getActiveDomain(currentWebsite)}`;
    (window as any).MarketsyIntegration.showPopup(websiteUrl)
      .then((obj) => {
        if (!obj) return;
        const { url, id } = obj;
        if (url && id) {
          saveContentInStore(
            generator.changeMarketsyData(
              { [contentType]: currentInputDataRef.current },
              url.split("&seed=")[0],
              id.split("-")[0]
            )
          );
          toggleNewEditionsDetected(true);
        }
      })
      .catch((error) => {
        console.error("error: ", error);
        openNotification(
          "Error: Can't connect to Marketsy",
          DEFAULT_ERROR_MESSAGE,
          "OK",
          "error",
          notificationDuration.medium
        );
      });
  };

  return (
    <div>
      <ContentInputLabel title="Sell digital products via Marketsy.ai">Widget to show</ContentInputLabel>
      <Button type="primary" onClick={getWidget}>
        Select widget
      </Button>
    </div>
  );
};

export const mapStateToProps = (state: any) => {
  return {
    websitesPages: state.websitesPages,
  };
};
export default connect(null, {})(Marketsy);
