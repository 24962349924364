import React, { Component } from "react";
import axios from "axios";
import "./BlogEditorPostControls.css";
import {
  Button,
  Icon,
  Tooltip,
  Modal,
  Input,
  Radio,
  Select,
  Popover,
  Popconfirm,
  Drawer,
} from "antd";
import { getCurrentWebsite } from "../../helpers/getCurrentWebsite";
import { Link } from "react-router-dom";
import { getCurrentBlogPost } from "../../helpers/blog/getCurrentBlogPost";
import { getFullPostUrl } from "../../helpers/editor/getFullPostUrl";
import addNoCacheParamToURL from "../../helpers/editor/addNoCacheParamToURL";
import { getCurrentBlog } from "../../helpers/blog/getCurrentBlog";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { api, urls } from "../../data/urls";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import { openNotification } from "../../helpers/openNotification";
import { getErrorValue } from "../../helpers/editor/getErrorValue";
import { getErrorKey } from "../../helpers/editor/getErrorKey";
import trimStringTo from "../../helpers/strings/trimStringTo";
import slugify from "../../helpers/strings/slugify";
import { values } from "lodash";
import _ from "lodash";
import OpenGraphImageUploader from "../dashboard/OpenGraphImageUploader";
import { blogPostPreDefaultUrl, defaultBlogUrl } from "../../data/constants";
import { projectUrl } from "../../data/constants";
import { getWebsiteProtocol } from "../../helpers/getWebsiteProtocol";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import SettingsDrawerContent from "../dashboard/SettingsDrawerContent";
import { GetCurrentStoreData } from "../../store/websites/types";
import UnsavedChangesModal from "../dashboard/UnsavedChangesModal";
import { getActiveDomain } from "../../helpers/websites/getActiveDomain";
import { formatToURLPath } from "../../helpers/strings/formatToURLPath";
import { LoadStatus } from "../../enums/enums";
import { getCurrentPageId } from "../../helpers/getCurrentPageId";
import SkeletonLoader from "../dashboard/SkeletonLoader";

interface Props {
  user: any;
  websites: any;
  blogs: any;
  blogPosts: any;
  auth: any;
  publishPost: any;
  routePostUrl: any;
  toggleBlogPostChangeInProgress: any;
  publishPostToggleWaitingForServer: any;
  saveBlogPostDataInStore: any;

  history: any;

  saveImageInfoInDB: any;
  removeImageFromDB: any;

  saveWebsiteDataInStore: any;
  saveWebsitePageDataInStore: any;
  publishPage: any;
  saveBlogDataInStore: any;
  drawerWidth: number | string;
  setDrawerWidth: any;
  getCurrentStoreData: GetCurrentStoreData;
  getSinglePostLoadStatus: any;
  getSingleBlogLoadStatus: any;
  getSingleWebsiteLoadStatus: any;
}
interface MyState {
  openPostUrl: ""; // for link to open published post
  modalVisible: boolean;
  selectedPostStatus: "public" | "draft" | "direct_url";
  selectedPostUrl: string;

  publishPlug: string;
  confirmLoading: boolean;

  currentBlogPost: any;

  saveImageInfoInDB: any;
  removeImageFromDB: any;

  isSettingsDrawerVisible: boolean;
  isUnsavedChangesModalVisible: boolean;
}

class BlogEditorPostControls extends Component<Props, MyState> {
  componentWillMount(): void {
    this.setState({
      openPostUrl: "",
      modalVisible: false,
      selectedPostStatus: "public",
      selectedPostUrl: "",
      isSettingsDrawerVisible: false,
      isUnsavedChangesModalVisible: false,
    });
  }
  showModal = () => {
    this.setState({
      isUnsavedChangesModalVisible: true,
    });
  };
  hideModal = () => {
    this.setState({
      isUnsavedChangesModalVisible: false,
    });
  };
  onDiscard = () => {
    this.setState({
      isUnsavedChangesModalVisible: false,
      isSettingsDrawerVisible: false,
    });
  };
  showDrawer = () => {
    this.setState({
      isSettingsDrawerVisible: true,
    });
  };
  hideDrawer = () => {
    const { currentWebsite } = this.props.getCurrentStoreData();
    if (
      _.get(currentWebsite, "hasUnsavedChanges") &&
      this.state.isSettingsDrawerVisible
    ) {
      this.showModal();
      return;
    }
    this.setState({
      isSettingsDrawerVisible: false,
    });
  };

  // if blogs and post then buttons
  // also  if post_body then publish/open

  openModalPublishPost(selectedPostUrl) {
    if (selectedPostUrl.length > 150) {
      let trimmedPostUrl = trimStringTo(selectedPostUrl, 150);
      this.setState({
        modalVisible: true,
        selectedPostUrl: formatToURLPath(
          trimmedPostUrl.replace(/\//g, "")
        ),
      });
    } else {
      this.setState({
        modalVisible: true,
        selectedPostUrl: formatToURLPath(
          selectedPostUrl.replace(/\//g, "")
        ),
      });
    }
  }

  closeModalPublishPost = (e) => {
    this.setState({
      modalVisible: false,
    });
  };

  handlePublishPostBatch = (currentWebsite, currentBlog, currentBlogPost) => {
    this.setState({
      publishPlug: "The post is publishing...",
      confirmLoading: true,
      selectedPostStatus: currentBlogPost.status,
    });

    // Publish post status: draft / public / direct_url
    this.props.toggleBlogPostChangeInProgress(true);
    this.props.publishPostToggleWaitingForServer(true);
    let accessToken = this.props.auth.accessToken;

    let apiUrl =
      api.blogPosts.updateBlogPost.prefix +
      currentWebsite.subdomain +
      "/" +
      currentBlogPost.url +
      api.blogPosts.updateBlogPost.postfix;

    let postData = {
      status: this.state.selectedPostStatus,
      subdomain: currentWebsite.subdomain,
    };

    if (this.state.selectedPostUrl !== currentBlogPost.url) {
      postData["url"] = formatToURLPath(this.state.selectedPostUrl);
    }

    axios
      .patch(apiUrl, postData, { ...returnAuthHeaderForAJAX(accessToken) })
      .then(() => {
        // We got a response from the server with an updated blog post data - save it in the redux store.
        this.props.saveBlogPostDataInStore(
          {
            data: {
              ...currentBlogPost,
              ...postData,
            }
          },
          currentBlog,
          this.props.blogPosts.items,
          currentBlogPost.url
        );
        const updatedPost = this.props.getCurrentStoreData().currentBlogPost;

        let newPublicationStateMessage;
        let currentBlogUrl = currentBlog.url;

        if (updatedPost.status === "public") {
          let fullPostUrl = getFullPostUrl(
            currentWebsite,
            currentBlog.url,
            updatedPost.url
          );

          newPublicationStateMessage = (
            <span>
              The post has been published. <br />
              It can be accessed here:
              <br />
              <a target="_blank" href={addNoCacheParamToURL(fullPostUrl)}>
                {trimStringTo(fullPostUrl, 70)}
              </a>
            </span>
          );

          openNotification(
            "Done",
            newPublicationStateMessage,
            "Close",
            "success",
            5
          );

          fireAnalyticsEvent.fireCrisp(CrispEvents.publishBlogPost, {
            subdomain: currentWebsite.subdomain,
            post_url: updatedPost.url,
          });
        }

        if (updatedPost.status === "draft") {
          newPublicationStateMessage = (
            <span>
              The post has been unpublished. Visitors from the Internet can not
              access it.
            </span>
          );

          openNotification(
            "Done",
            newPublicationStateMessage,
            "Close",
            "success",
            5
          );

          fireAnalyticsEvent.fireCrisp(CrispEvents.draftBlogPost, {
            subdomain: currentWebsite.subdomain,
            post_url: updatedPost.url,
          });
        }

        if (updatedPost.status === "direct_url") {
          let fullPostUrl = getFullPostUrl(
            currentWebsite,
            currentBlog.url,
            updatedPost.url
          );

          newPublicationStateMessage = (
            <span>
              The post has been accessed only by link{" "}
              <a target="_blank" href={fullPostUrl}>
                {trimStringTo(fullPostUrl, 70)}
              </a>
              . <br />
              But the post is not published on the blog home page
            </span>
          );

          openNotification(
            "Done",
            newPublicationStateMessage,
            "Close",
            "success",
            5
          );

          fireAnalyticsEvent.fireCrisp(CrispEvents.directUrlBlogPost, {
            subdomain: currentWebsite.subdomain,
            post_url: updatedPost.url,
          });
        }

        this.setState({
          selectedPostStatus: updatedPost.status,
          modalVisible: false,
          confirmLoading: false,
        });

        if (currentBlogUrl === "") {
          currentBlogUrl = defaultBlogUrl;
        }
      })
      .catch((error) => {
        // handle error
        if (error.response) {
          let errorData = error.response.data;

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);

          if (errorData.status === "public") {
            fireAnalyticsEvent.fireCrisp(
              CrispEvents.publishBlogPostError,
              {
                error_type: errorObjectKey,
                error_message: errorObjectValue,
                subdomain: currentWebsite.subdomain,
                post_url: errorData.url,
              },
              true
            );
          }

          if (errorData.status === "draft") {
            fireAnalyticsEvent.fireCrisp(
              CrispEvents.draftBlogPostError,
              {
                error_type: errorObjectKey,
                error_message: errorObjectValue,
                subdomain: currentWebsite.subdomain,
                post_url: errorData.url,
              },
              true
            );
          }

          if (errorData.status === "direct_url") {
            fireAnalyticsEvent.fireCrisp(
              CrispEvents.directUrlBlogPostError,
              {
                error_type: errorObjectKey,
                error_message: errorObjectValue,
                subdomain: currentWebsite.subdomain,
                post_url: errorData.url,
              },
              true
            );
          }

          if (errorData.url !== undefined) {
            let message = errorData.url;
            openNotification("Denied", "Post URL: " + message, "OK", "warn");
          }

          console.log(errorData);

          this.setState({
            confirmLoading: false,
          });
        }
      })
      .then((response) => {
        // always executed
        this.props.toggleBlogPostChangeInProgress(false);
        this.props.publishPostToggleWaitingForServer(false);
      });
  };

  handleChangeInputPostUrl = (value) => {
    this.setState({
      selectedPostUrl: value,
    });
  };

  handleChangePostStatus = (value) => {
    this.setState({
      selectedPostStatus: value,
    });
  };

  displayPublishPostModal = (currentPost) => {
    if (
      this.props.websites.dataFetched &&
      this.props.blogs.dataFetched
    ) {
      let currentWebsite = getCurrentWebsite(this.props.websites.items);
      let currentBlog = getCurrentBlog(this.props.blogs.items, currentWebsite);
      let currentBlogUrl = currentBlog.url;

      if (currentBlogUrl !== "") {
        currentBlogUrl = "/" + defaultBlogUrl;
      }

      const { Option } = Select;

      let currentWebsiteUrl = "";
      if (currentWebsite.is_custom_domain_active) {
        let websiteProtocol = getWebsiteProtocol(currentWebsite);
        currentWebsiteUrl = websiteProtocol + currentWebsite.custom_domain;
      } else {
        currentWebsiteUrl =
          "https://" + currentWebsite.subdomain + "." + projectUrl;
      }

      return (
        <Modal
          title="Publish post"
          visible={this.state.modalVisible}
          onOk={() =>
            this.handlePublishPostBatch(
              currentWebsite,
              currentBlog,
              currentPost
            )
          }
          okButtonProps={{
            disabled: this.state.selectedPostUrl === "",
          }}
          confirmLoading={this.state.confirmLoading}
          onCancel={this.closeModalPublishPost}
          className={"blog-editor-post-controls__modal"}
          zIndex={1020}
        >
          <div className="blog-editor-post-controls__modal-item">
            <h3>Post visibility</h3>
            <div>
              <Select
                value={this.state.selectedPostStatus}
                style={{ width: 120 }}
                onChange={(value) => this.handleChangePostStatus(value)}
              >
                <Option value="public">Public</Option>
                <Option value="draft">Draft</Option>
                <Option value="direct_url">Direct URL</Option>
              </Select>
            </div>
          </div>

          <div className="blog-editor-post-controls__modal-item">
            <h3>Post URL</h3>
            <div>
              <Input
                name="url"
                id="url"
                required={false}
                placeholder="post-url"
                type="text"
                value={this.state.selectedPostUrl}
                addonBefore={currentWebsiteUrl + currentBlogUrl + "/"}
                onChange={(e) => this.handleChangeInputPostUrl(e.target.value)}
              />
            </div>
          </div>

          <div className="blog-editor-post-controls__modal-item">
            <h3>Thumbnail</h3>
            <div className="blog-editor-post-controls__input_label">
              <div>
                <Popover
                  content={
                    <div style={{ width: 300 }}>
                      Open Graph image is displayed when users share your post
                      in social networks and messengers. If you do not set this
                      image, then Twitter, Facebook, Telegram and other services
                      will pick a random image from your post or page.
                    </div>
                  }
                >
                  <Icon type="info-circle" />
                </Popover>
              </div>
              <div className="settings-box__input_info_text">
                Social networks image:{" "}
                <span className="settings-box__input_info_subtext">
                  a.k.a. Open Graph image (og:image)
                </span>
              </div>
            </div>
            <OpenGraphImageUploader
              settingsType={"blogPost"}
              saveBlogPostDataInStore={this.props.saveBlogPostDataInStore}
              auth={this.props.auth}
              currentWebsite={currentWebsite}
              currentBlogPost={currentPost}
              currentBlog={currentBlog}
              saveImageInfoInDB={this.props.saveImageInfoInDB}
              removeImageFromDB={this.props.removeImageFromDB}
              blogPosts={this.props.blogPosts}
              apiUrl={
                api.blogPosts.updateBlogPost.prefix +
                currentWebsite.subdomain +
                "/" +
                currentPost.url +
                api.blogPosts.updateBlogPost.postfix
              }
            />
          </div>

          {/*<div>*/}
          {/*  You can change post settings later*/}
          {/*</div>*/}
        </Modal>
      );
    }
  };

  render() {
    if (!this.props.websites.dataFetched || !this.props.blogs.dataFetched) {
      return <div><SkeletonLoader width="128px" /></div>
    }
    if (!this.props.user.dataFetched ) {
      return <div><SkeletonLoader width="128px" /></div>
    }
    
      let currentWebsite = getCurrentWebsite(this.props.websites.items);

      if (currentWebsite === undefined) {
        return null;
      } else {
        let currentWebsiteSubdomain = currentWebsite.subdomain;

        let currentBlog = getCurrentBlog(
          this.props.blogs.items,
          currentWebsite
        );

        const { currentBlogPost } = this.props.getCurrentStoreData();

        const postId = getCurrentPageId();

        const singlePostStatus = this.props.getSinglePostLoadStatus(postId);
        if (singlePostStatus === LoadStatus.ERROR) {
          return null;
        }
        if (singlePostStatus !== LoadStatus.LOADED) {
          return <div><SkeletonLoader width="128px" /></div>
        }
        const singleBlogStatus = this.props.getSingleBlogLoadStatus(currentBlog);
        if (singleBlogStatus !== LoadStatus.LOADED) {
          return <div><SkeletonLoader width="128px" /></div>
        }
        const singleWebsiteStatus = this.props.getSingleWebsiteLoadStatus(currentWebsite);
        if (singleWebsiteStatus !== LoadStatus.LOADED) {
          return <div><SkeletonLoader width="128px" /></div>
        }

        if (currentBlog === undefined) {
          return null;
        }

        if (currentBlogPost === undefined) {
          return null;
        }

        let fullPostUrl = getFullPostUrl(
          currentWebsite,
          currentBlog.url,
          this.props.routePostUrl
        );

        let ifAnyChangesBeingSaved: boolean = false;
        ifAnyChangesBeingSaved =
          this.props.blogPosts.isWaitingForSaveBlogPostSchemeResponse;

        let ifAnyUnsavedChangesExist: boolean = false;
        ifAnyUnsavedChangesExist =
          this.props.blogPosts.blogPostNewEditionsDetected;

        let ifWaitingForPublishPostResponse =
          this.props.blogPosts.isWaitingForPublishPostResponse;

        let publishPostButtonJSX = null;
        let openPostButtonJSX = null;
        let preFilledPostUrl = currentBlogPost.url;

        if (currentBlogPost.url.includes(blogPostPreDefaultUrl) === true) {
          preFilledPostUrl = currentBlogPost.title;
        }

        publishPostButtonJSX = (
          <>
            <Button
              htmlType="button"
              // disabled={ifAnyUnsavedChangesExist || ifWaitingForPublishPostResponse}
              icon={"eye"}
              type="primary"
              size="small"
              loading={
                this.props.blogPosts.isWaitingForSaveBlogPostSchemeResponse ||
                this.props.blogPosts
                  .isWaitingForSaveBlogPostMainTitleSchemeResponse ||
                this.props.blogPosts.blogPostMainTitleNewEditionsDetected ||
                this.props.blogPosts.blogPostNewEditionsDetected
              }
              onClick={() => this.openModalPublishPost(preFilledPostUrl)}
            >
              {" "}
              {currentBlogPost.status === "draft"
                ? "Publish post"
                : "Visibility"}
            </Button>
            {this.displayPublishPostModal(currentBlogPost)}
          </>
        );

        if (
          currentBlogPost.status === "public" ||
          currentBlogPost.status === "direct_url"
        ) {
          let protocol = "https://";
          if (process.env.REACT_APP_API_URL === "http://app.unicorn.com:8000") {
            protocol = "http://";
          }
          let openPostUrl = `${protocol}${getActiveDomain(currentWebsite)}`;
          openPostUrl += !currentBlog.url ? "/" : `/${currentBlog.url}/`;
          openPostUrl += currentBlogPost.url;
          openPostButtonJSX = (
            <li className="blog-editor-post-controls__item">
              <a
                onClick={() => {
                  window.open(addNoCacheParamToURL(openPostUrl), "_blank");
                }}
              >
                {" "}
                <Button
                  htmlType="button"
                  type="primary"
                  className="js-tour__open-post"
                  size="small"
                  icon={
                    // ifAnyChangesBeingSaved? 'loading' :
                    "link"
                  }
                >
                  Open post
                </Button>
              </a>
            </li>
          );
        }

        let publishPostButtonWrapJSX = null;
        if (ifAnyUnsavedChangesExist) {
          publishPostButtonWrapJSX = (
            <Tooltip
              title={
                <span>
                  Please <b>save changes</b> before publishing the post{" "}
                  <Icon type="arrow-down" />
                </span>
              }
            >
              {publishPostButtonJSX}
            </Tooltip>
          );
        } else {
          publishPostButtonWrapJSX = publishPostButtonJSX;
        }

        let currentBlogUrl = currentBlog.url;
        if (currentBlog.url === "") {
          currentBlogUrl = defaultBlogUrl;
        }

        return (
          <>
            <ul className="blog-editor-post-controls">
              <li className="blog-editor-post-controls__item">
                <Button
                  ghost
                  size="small"
                  type="default"
                  htmlType="button"
                  onClick={this.showDrawer}
                >
                  <Icon type="setting" />
                  Settings
                </Button>
              </li>

              <li className="blog-editor-post-controls__item">
                {publishPostButtonWrapJSX}
              </li>

              {openPostButtonJSX}
            </ul>
            <Drawer
              title="Settings"
              visible={this.state.isSettingsDrawerVisible}
              onClose={this.hideDrawer}
              className="settings-drawer"
              zIndex={1000}
              width={this.props.drawerWidth}
              afterVisibleChange={(visible) => {
                if (visible) {
                  document.body.style.removeProperty("overflow");
                  document.body.style.removeProperty("touch-action");
                }
              }}
              destroyOnClose
            >
              <SettingsDrawerContent
                history={this.props.history}
                saveImageInfoInDB={this.props.saveImageInfoInDB}
                removeImageFromDB={this.props.removeImageFromDB}
                saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
                saveWebsitePageDataInStore={
                  this.props.saveWebsitePageDataInStore
                }
                publishPage={this.props.publishPage}
                type="blog"
                saveBlogDataInStore={this.props.saveBlogDataInStore}
                saveBlogPostDataInStore={this.props.saveBlogPostDataInStore}
                publishPost={this.props.publishPost}
                setDrawerWidth={this.props.setDrawerWidth}
              />
            </Drawer>
            <Modal
              title="You have unsaved changes"
              visible={this.state.isUnsavedChangesModalVisible}
              onCancel={this.hideModal}
              centered
              width={300}
              footer={null}
            >
              <UnsavedChangesModal onDiscard={this.onDiscard} />
            </Modal>
          </>
        );
      }
   
  }
}

export default BlogEditorPostControls;
