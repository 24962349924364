import React from "react";
import { connect } from "react-redux";
import { getCurrentStoreData } from "../../store/websites/thunks";
import { GetCurrentStoreData } from "../../store/websites/types";
import "./CmsSidebar.scss";

interface Props {
  getCurrentStoreData: GetCurrentStoreData;
}

const CmsSidebar = (props: Props): JSX.Element => {
  const { getCurrentStoreData } = props;
  const { currentWebsite } = getCurrentStoreData();

  return (
    <div className="cms-sidebar">
      <iframe
        src={`https://app.stage-unicorn.win/mars/${currentWebsite.id}/collections/`}
      ></iframe>
    </div>
  );
};

export default connect(null, {
  getCurrentStoreData,
})(CmsSidebar);
