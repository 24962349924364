import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";
import { PageComponentsItem } from "../../store/pageComponents/types";

type CreateAdvancedComponentAsync = (payload: {
  pageComponentItem: PageComponentsItem;
  meta: any;
}) => Promise<AxiosResponse<any>>;

export const createAdvancedComponentAsync: CreateAdvancedComponentAsync = ({
  pageComponentItem,
  meta,
}) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${api.websitePages.fetchAdvancedComponents}?v=${meta.cache}&component_id=${pageComponentItem.original_component_id}&full=true`;
    axios
      .get(apiUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
