import React, { useEffect, useRef } from "react";
import { Button, Checkbox, Popconfirm } from "antd";
import { InputProps } from "./interfaces";
import DebouncedInput from "../../DebouncedInput";
import classNames from "classnames";
import { useCurrentInputData } from "./useCurrentInputData";
import HorInputGroup from "../../ui_components/HorInputGroup";

const List = (props: InputProps): JSX.Element => {
  // If hasGroups - then edit group's title first, then only items. Group without a title is not displayed.
  const {
    content,
    currentInputData,
    saveContentInStore,
    generator,
    contentType,
    toggleNewEditionsDetected,
    displayMoveUpDownButtons,
    displayPictogramEditorOpener,
  } = props;

  const currentInputDataRef = useCurrentInputData(currentInputData);

  let hasGroups = currentInputDataRef.current.hasGroups === true;
  let groupTitleHasPictogram =
    currentInputDataRef.current.groupTitleHasPictogram === true;
  let groupTitleHasSubtitle =
    currentInputDataRef.current.groupTitleHasSubtitle === true;
  let groupTitleHasTitle =
    currentInputDataRef.current.groupTitleHasTitle === true;
  let maxGroupsReached =
    content.groups.length === currentInputDataRef.current.maxGroups;
  let allListItems = content.items;

  let groupsInputs = content.groups.map((groupItem: any, groupKey: number) => {
    // Get only this group items. If a component doesn't support groups, then all items are placed in one group with groupIndex = 0 by default and thisGroupItemsArray === allListItems.
    let thisGroupItemsArray = allListItems.filter((item: any) => {
      if (item.groupIndex == groupKey) {
        return true;
      }
    });
    let lastItem = thisGroupItemsArray[thisGroupItemsArray.length - 1];
    let maxItemsReached = lastItem.title !== ""; //when we create a new ghost item we set its title to '', if the last item has real title that means we did not create a ghost item because maximum is reached.
    let thisGroupItemsArrayCount = maxItemsReached
      ? thisGroupItemsArray.length
      : thisGroupItemsArray.length - 1; // if max reached, the whole array is the items, if max not reached, the last item is a ghost empty item and should not be counted

    let itemsInputs = allListItems.map((listItem: any, itemKey: number) => {
      if (
        // If the list hasGroups and the current group has any title, we offer list items to be edited.
        // If the list has no groups (means all items are in one group), we should show the list items inputs right away.
        (hasGroups && !!groupItem.title) ||
        !hasGroups
      ) {
        // Also make sure we are displaying only items of current group.
        if (groupKey === listItem.groupIndex) {
          let onChangeListItemTitleHandler = (value: any) => {
            saveContentInStore(
              generator.changeListItemTitle(
                {
                  [contentType]: currentInputDataRef.current,
                },
                itemKey,
                value,
                groupKey
              )
            );
            toggleNewEditionsDetected(true);
          };
          let onChangeListItemHrefHandler = (value: any) => {
            saveContentInStore(
              generator.changeListItemHref(
                {
                  [contentType]: currentInputDataRef.current,
                },
                itemKey,
                value,
                groupKey
              )
            );
            toggleNewEditionsDetected(true);
          };

          return (
            <div
              className={classNames(
                "content-input__cluster",
                "content-input__cluster--hide-move-buttons",
                "content-input__cluster--hide-move-buttons--no-add-btn",
                {
                  "content-input__cluster--with-ghost": !maxItemsReached,
                }
              )}
              data-add-new="Add new list item ⤵"
              key={itemKey}
            >
              <Button
                onClick={() => {
                  toggleNewEditionsDetected(true);
                  saveContentInStore(
                    generator.deleteListItem(
                      {
                        [contentType]: currentInputDataRef.current,
                      },
                      itemKey,
                      groupKey
                    )
                  );
                }}
                htmlType="button"
                className="content-input__control_cluster"
                shape="circle"
                title="Delete this list item"
                icon="cross"
                size="small"
              />

              {/*We display move buttons like this (no with the displayMoveUpDownButtons function) because group list items are store in a single array and we can not get an index of a list item within a group.*/}
              {thisGroupItemsArrayCount > 1 && listItem.title !== "" && (
                <>
                  <Button
                    onClick={() => {
                      toggleNewEditionsDetected(true);
                      saveContentInStore(
                        generator.moveListItemUp(
                          {
                            [contentType]: currentInputDataRef.current,
                          },
                          itemKey
                        )
                      );
                    }}
                    htmlType="button"
                    className="content-input__control_cluster content-input__control_cluster--move-up content-input__control_cluster--top"
                    shape="circle"
                    title="Move this item up"
                    icon="up"
                    size="small"
                  />
                  <Button
                    onClick={() => {
                      toggleNewEditionsDetected(true);
                      saveContentInStore(
                        generator.moveListItemDown(
                          {
                            [contentType]: currentInputDataRef.current,
                          },
                          itemKey
                        )
                      );
                    }}
                    htmlType="button"
                    className="content-input__control_cluster content-input__control_cluster--move-down content-input__control_cluster--bottom"
                    shape="circle"
                    title="Move this item down"
                    icon="down"
                    size="small"
                  />
                </>
              )}
              <HorInputGroup gap="small">
                <DebouncedInput
                  className="content-input__input"
                  onChange={onChangeListItemTitleHandler}
                  defaultValue={listItem.title}
                  placeholder="Enter list item title"
                />
                {listItem.title !== "" && (
                  <DebouncedInput
                    className="content-input__input"
                    onChange={onChangeListItemHrefHandler}
                    defaultValue={listItem.href}
                    placeholder="/learn-more"
                  />
                )}
              </HorInputGroup>
              {listItem.href !== "" && listItem.title !== "" && (
                <Checkbox
                  style={{marginTop: 10}}
                  className="content-input__input"
                  checked={listItem.isTargetBlank}
                  onChange={(event: any) => {
                    saveContentInStore(
                      generator.changeListItemIsTargetBlank(
                        {
                          [contentType]: currentInputDataRef.current,
                        },
                        itemKey,
                        event.target.checked
                      )
                    );
                    toggleNewEditionsDetected(true);
                  }}
                >
                  Open in a new tab
                </Checkbox>
              )}
            </div>
          );
        }
      } else {
        return null;
      }
    });

    //if hasGroups === true that means user can add more groups, delete them and edit. A group is a cluster then.
    let groupClusterClassname = hasGroups ? "content-input__cluster " : "";

    let onChangeGroupTitleHandler = (value: any) => {
      saveContentInStore(
        generator.changeGroupTitle(
          { [contentType]: currentInputDataRef.current },
          groupKey,
          value,
          contentType
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangeGroupSubtitleHandler = (value: any) => {
      saveContentInStore(
        generator.changeGroupSubtitle(
          { [contentType]: currentInputDataRef.current },
          groupKey,
          value,
          contentType
        )
      );
      toggleNewEditionsDetected(true);
    };

    return (
      <div className={groupClusterClassname} key={groupKey}>
        {hasGroups && groupItem.title && groupItem.title !== "" && (
          <Popconfirm
            placement="bottomRight"
            title={
              <span>
                Delete the group{" "}
                <b>
                  {groupItem.title && groupItem.title !== ""
                    ? groupItem.title
                    : ""}
                </b>{" "}
                and all its items?
              </span>
            }
            onConfirm={() => {
              toggleNewEditionsDetected(true);
              saveContentInStore(
                generator.deleteGroup(
                  {
                    [contentType]: currentInputDataRef.current,
                  },
                  groupKey,
                  contentType
                )
              );
            }}
            // onCancel={}
            okText="Delete"
            cancelText="Cancel"
          >
            <Button
              htmlType="button"
              className="content-input__control_cluster content-input__control_cluster--center"
              shape="circle"
              title="Delete this group with all items"
              icon="cross"
              size="small"
            />
          </Popconfirm>
        )}

        {displayMoveUpDownButtons(
          groupKey,
          content.groups.length,
          () => {
            saveContentInStore(
              generator.moveGroupUp(
                {
                  [contentType]: currentInputDataRef.current,
                },
                groupKey,
                contentType
              )
            );
          },
          () => {
            saveContentInStore(
              generator.moveGroupDown(
                {
                  [contentType]: currentInputDataRef.current,
                },
                groupKey,
                contentType
              )
            );
          }
        )}

        {hasGroups && (
          <div className="content-input__group">
            {/*if hasGroups === false we have only one group (index = 0) which can not be deleted and modified. We also can no create more groups.*/}
            {groupTitleHasPictogram &&
              displayPictogramEditorOpener(groupItem.pictogram, groupKey)}
            {groupTitleHasTitle && (
              <DebouncedInput
                onChange={onChangeGroupTitleHandler}
                defaultValue={content.groups[groupKey].title}
                placeholder="Enter group title"
                className="content-input__input"
              />
            )}

            {groupTitleHasSubtitle && (
              <DebouncedInput
                onChange={onChangeGroupSubtitleHandler}
                defaultValue={content.groups[groupKey].subtitle}
                placeholder="Enter group subtitle"
                className="content-input__input"
              />
            )}
          </div>
        )}

        {/*itemsInputs - inputs of list element*/}
        <div>{itemsInputs}</div>
      </div>
    );
  });

  return (
    <div>
      {groupsInputs}
      {hasGroups && maxGroupsReached === false && (
        <Button
          size="small"
          onClick={() => {
            toggleNewEditionsDetected(true);
            saveContentInStore(
              generator.addEmptyGroupIfNeeded(
                { [contentType]: currentInputDataRef.current },
                contentType
              )
            );
          }}
          icon="plus"
        >
          Add new group of links
        </Button>
      )}
    </div>
  );
};

export default List;
