import { createUniqueFormFieldName } from "./../createUniqueFormFieldName";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import { pictogramOptions } from "../../data/pictogramOptions";
import { logoOptions } from "../../data/logoOptions";
import { callToActionOptions } from "../../data/callToActionOptions";
import { abstractIconsCount } from "../../data/abstractIconsCount";
import { DEFAULT_IMAGES_CDN, pressLogos, publicUrl, urls } from "../../data/urls";
import {
  DEFAULT_DIRECTORY_ITEM_HTML,
  DEFAULT_DIRECTORY_TOP_SECTION_HTML,
  DEFAULT_GOOGLE_SHEETS_URL,
  defaultLogoHeight,
  defaultUploadedPictogramHeight,
  draftJsEditorTitle,
  lightboxActivationWidth,
  monthNames,
  plainHTMLEditorTitle,
} from "../../data/constants";
import { pricingItemDetailIconOptions } from "../../data/pricingItemDetailIconOptions";
import moveArrayItem from "../moveArrayItem";
import { formFields } from "../../data/formFields";
import generateRandomNumerousId from "../generateRandomNumerousId";
import formatVideoSourceUrl from "./formatVideoSourceUrl";
import { checkIfChrome } from "./checkIfChrome";
import { ComponentDataPaths, PopupType } from "../types/popupTypes";
import _ from "lodash";
import { CodeInputData } from "../../enums/CodeInputData";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import {
  CustomColorData,
  FilterData,
  PricingPeriodTabPlacement,
} from "../types/globalTypes";
import { v4 as uuidv4 } from 'uuid';
/*
This class is the cornerstone of the content edition mechanics.

The setUp* methods are called only once during the life of any component. When called, the methods build some inputs settings: their labels, tooltips, info text etc. This is needed because some components may need specific info and tooltips: e.g. image size advice. They also insert some default dummy data.
The change* methods are called when changing a particular component in the <ContentInput> component. Once called they change a piece of a component, e.g. image url or a button href.

FAQ:
Q: Why are the setUp* methods called from within particular components?
A: Because I want the page components JSX entities (e.g., <Header01/>) to hold the default content data.

Q: How many content inputs a component can have?
A: A component can have as much content inputs as needed.

Q: Are they all reusable?
A: Some common ones (like image, text) are. But there are a lot of exceptions (e.g., roadmaps are unique and must be filled in in a special way each).

Q: What is "priority"?
A: The "priority" defines the order of the content fields. The title comes first usually.
*/

const defaultTextEditorType = checkIfChrome()
  ? draftJsEditorTitle
  : plainHTMLEditorTitle; // DraftJS works stable only on Chrome so we set plain html for other browsers (safari and FF).
class generateContentDataObject {
  // arguments via: https://stackoverflow.com/questions/11796093/named-parameters-in-javascript

  createButtonContentObject = ({
    buttonType = linkOrButtonOptions.link,
    buttonTitle = "Get it",
    buttonHref = "/",
    buttonIsTargetBlank = true,
    buttonStyleType = buttonStyles.regular.typeTitle,
    buttonColor = buttonStyles.regular.accentBg,
    buttonMobileAppStoreType = buttonStyles.mobileAppStore.ios,
    buttonDownloadType = buttonStyles.download.windows,
    pillPrefix = "More",

    isRelayUTM = false,

    stripeProductId = undefined, // if connected this becomes a string
    successfulPaymentUrl = "",
    cancelPaymentUrl = "",
    stripePaymentMode = "payment",

    pillColor = buttonStyles.pill.blue,
    ghostColor = buttonStyles.ghost.accentOutline,
    onClickJs = "",
  } = {}) => {
    return {
      type: buttonType, // types: submit (for forms), link, scroll to a component, scroll to a component on another page, open popup
      title: buttonTitle,
      href: buttonHref,

      isRelayUTM: isRelayUTM,

      stripeProductId: stripeProductId,
      successfulPaymentUrl: successfulPaymentUrl,
      cancelPaymentUrl: cancelPaymentUrl,
      stripePaymentMode: stripePaymentMode,

      pillPrefix: pillPrefix,
      isTargetBlank: buttonIsTargetBlank,
      settings: {
        appearance: {
          styleType: buttonStyleType, //regular, app store branded, pill etc.
          color: buttonColor, //if regular, pick one of the style: accentBg, whiteBg, blackOutline etc
          mobileAppStoreType: buttonMobileAppStoreType, //is the app store branded styleType is selected, pick ios or android branding for the button
          downloadType: buttonDownloadType, // if download styleType is selected, pick from the osx, windows or google chrome extensions store logos
          pillColor: pillColor,
          ghostColor: ghostColor,
        },
        rating: {
          isActive: false,
          score: "", // check for an empty string to detect is a button has rating style
          proofMarkup: "",
        },
        pictogram: {
          isActive: false,
          content: this.createPictogramContentObject(),
        },
        badge: {
          isActive: false,
          text: "",
          style: "default",
        },
        // added on 9th august 2021
        custom_id: "",
        classes: "",
        onclick: onClickJs,
        attributes: "",
        popup: {
          isActive: false,
          popupId: "",
        },
      },
    };
  };
  createFormContentObject = ({
    buttonTitle = "Subscribe",
    buttonStyleType = buttonStyles.regular.typeTitle,
    buttonColor = buttonStyles.regular.accentBg,
    items = [this.createFormFieldContentObject()],
    isDefaultEmpty = false,
  } = {}) => {
    return {
      connectedIntegrationsIds: [] as any[], //currently only one active integration is possible. Todo: make multiple choices
      fields: {
        items: isDefaultEmpty ? [] : items, //todo
        agreement: {}, //todo
      },
      settings: {
        successRedirect: {
          isActive: false,
          url: "",
          passValuesInGet: false,
          isTargetBlank: true,
        },
        messages: {
          isActive: false,
          items: [
            "Well done! You have been successfully subscribed.",
            "Have a great day!",
          ], //array of strings
        },
        jsCodeOnSuccess: {
          isActive: false,
          code: "",
        },
        popup: {
          isActive: true,
          popupId: "popup-01-success_default",
        },
      },
      button: this.createButtonContentObject({
        buttonType: linkOrButtonOptions.submit,
        buttonTitle: isDefaultEmpty ? "" : buttonTitle,
        buttonStyleType: buttonStyleType,
        buttonColor: buttonColor,
      }),
    };
  };
  createFormFieldContentObject = ({
    title = formFields.email.title, // this will allow us to pick iconType and type (for HTML) from formFields object
    required = formFields.email.required,
    label = formFields.email.label,
    name = formFields.email.name,
    customHtmlAttributes = [],
    dropdownItems = [
      "Assistant",
      "Audio",
      "Coach",
      "Design",
      "Ecommerce",
      "Email",
      "Guide",
      "Marketing",
    ], //we store dropdownItems of each field even if it is email field. Why? Because if user changes type from dropdown to email then back to dropdown he/she will have the dropdown items saved.
    settings = {
      //some advanced settings.
      id: "", // added  on 9th of July 2021
      attributes: "", // added  on 9th of July 2021
      classes: "", // added  on 9th of July 2021,
      mask: "", //added on August 4th 2021.
    },
    placeholder = formFields.email.placeholder,
    noResultsText = "No results match",
    maxFiles = 1,
    allowedFileTypes = "",
  } = {}) => {
    return {
      title: title,
      required: required,
      label: label,
      name: name,
      placeholder: placeholder,
      customHtmlAttributes: customHtmlAttributes,
      settings: settings,
      dropdownItems: dropdownItems,
      noResultsText,
      maxFiles,
      allowedFileTypes,
    };
  };

  createPictogramContentObject = ({
    type = pictogramOptions.abstractIcon.title, // if type === '' then no icon to display
    abstractIconId = (
      Math.floor(Math.random() * abstractIconsCount) + 1
    ).toString() as string,
    lineaIconCategory = "",
    lineaIconSrc = "",
    emojiSrc = "1f381.svg", //e.g.: '132312.svg'
    uploadedSrc = "",
    uploadedUUID = undefined,
    uploadedHeight = defaultUploadedPictogramHeight,
  } = {}) => {
    // an icon from a set, an emoji or an abstract icon.
    return {
      type: type,
      abstractIconId: abstractIconId,
      lineaIconSrc: lineaIconSrc,
      emojiSrc: emojiSrc,
      uploadedSrc: uploadedSrc,
      uploadedUUID: uploadedUUID,
      uploadedHeight: uploadedHeight,
    };
  };

  setUpPictograms = ({
    fieldTitle = { default: "Icon" },
    label = { default: "Edit the icon:" },
    labelTooltip = { default: "" },
    fieldInfo = { default: "" },
    priority = 50,
    items = [{ pictogram: this.createPictogramContentObject() }],
  } = {}) => {
    return {
      pictograms: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,
        content: {
          items: items,
        },
      },
    };
  };

  changePictogramsItemType = (
    currentInputObject: any,
    pictogramType: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pictograms.content.items[
      index
    ].pictogram.type = pictogramType;

    return newInputObject;
  };
  changePictogramsItemEmojiSrc = (
    currentInputObject: any,
    emojiSrc: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pictograms.content.items[
      index
    ].pictogram.emojiSrc = emojiSrc;

    return newInputObject;
  };
  changePictogramsItemLineaIconSrc = (
    currentInputObject: any,
    lineaIconSrc: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pictograms.content.items[
      index
    ].pictogram.lineaIconSrc = lineaIconSrc;

    return newInputObject;
  };
  changePictogramsItemAbstractIconId = (
    currentInputObject: any,
    abstractIconId: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pictograms.content.items[
      index
    ].pictogram.abstractIconId = abstractIconId;

    return newInputObject;
  };

  changePictogramsItemUploadedSrc = (
    currentInputObject: any,
    uploadedSrc: string,
    index: number,
    width?: number,
    height?: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pictograms.content.items[
      index
    ].pictogram.uploadedSrc = uploadedSrc;
    newInputObject.pictograms.content.items[index].pictogram.width = width;
    newInputObject.pictograms.content.items[index].pictogram.height = height;

    return newInputObject;
  };
  changePictogramsItemUploadedUUID = (
    currentInputObject: any,
    uploadedUUID: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pictograms.content.items[
      index
    ].pictogram.uploadedUUID = uploadedUUID;

    return newInputObject;
  };
  changePictogramsItemUploadedAlt = (
    currentInputObject: any,
    alt: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pictograms.content.items[index].pictogram.alt = alt;

    return newInputObject;
  };
  changePictogramsItemUploadedHeight = (
    currentInputObject: any,
    uploadedHeight: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pictograms.content.items[
      index
    ].pictogram.uploadedHeight = uploadedHeight;

    return newInputObject;
  };

  createGroupContentObject = ({
    title = "Authentic Design",
    subtitle = "speed",
    // text = 'Unicorn Platform is a powerful website builder for startups, solo-entrepreneurs and hackers.',
    // isHighlighted = false,
    pictogram = this.createPictogramContentObject(),
  } = {}) => {
    return {
      title: title,
      subtitle: subtitle,
      pictogram: pictogram,
    };
  };

  addEmptyGroupIfNeeded = (currentInputObject: any, contentType: string) => {
    let newInputObject = { ...currentInputObject };

    let inputObjectArray = newInputObject[contentType].content.groups;
    let inputObjectArrayLength = inputObjectArray.length;

    let isCurrentGroupsCountEqualsMax: boolean;

    if (inputObjectArrayLength === currentInputObject.list.maxGroups) {
      isCurrentGroupsCountEqualsMax = true;
    } else {
      isCurrentGroupsCountEqualsMax = false;
    }

    if (isCurrentGroupsCountEqualsMax === false) {
      // HEre we offer the "add group" button.
      let firstGroupItem = newInputObject[contentType].content.groups[0];
      let newGroupItem: any;
      if (firstGroupItem !== undefined) {
        newGroupItem = this.createGroupContentObject({
          title: firstGroupItem.title,
          subtitle: firstGroupItem.subtitle,
          pictogram: this.createPictogramContentObject({
            type: firstGroupItem.pictogram.type,
            uploadedSrc: firstGroupItem.pictogram.uploadedSrc,
          }),
        });
      } else {
        newGroupItem = this.createGroupContentObject();
      }

      // So we create a group. It must be populated with an empty ghost item so user can add more items to the group.

      // First, let's get the index of the newly created group:
      let createdGroupIndex = inputObjectArrayLength;
      // Now let's create a ghost empty item. We don't know which content object generator to use, so we check contentType and use an appropriate function.
      if (contentType === "list") {
        newInputObject[contentType].content.items.push(
          this.createListItemContentObject({
            title: "",
            href: "",
            groupIndex: createdGroupIndex,
          })
        );
      } else if (contentType === "posts") {
        //     todo
      } else if (contentType === "features") {
        //     todo
      }

      newInputObject[contentType].content.groups.push(newGroupItem);

      return newInputObject;
    } else {
      // if no addition is needed, simply pass the original object.
      return currentInputObject;
    }
  };

  changeGroupTitle = (
    currentInputObject: any,
    groupIndex: number,
    title: string,
    contentType: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject[contentType].content.groups[groupIndex].title = title; //contentType can be 'links', 'features', 'roadmap' etc
    return newInputObject;
  };
  changeGroupSubtitle = (
    currentInputObject: any,
    groupIndex: number,
    subtitle: string,
    contentType: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject[contentType].content.groups[groupIndex].subtitle = subtitle; //contentType can be 'links', 'features', 'roadmap' etc

    return newInputObject;
  };
  changeGroupPictogramType = (
    currentInputObject: any,
    pictogramType: string,
    groupIndex: number,
    contentType: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject[contentType].content.groups[
      groupIndex
    ].pictogram.type = pictogramType;

    return newInputObject;
  };
  changeGroupPictogramEmojiSrc = (
    currentInputObject: any,
    emojiSrc: string,
    groupIndex: number,
    contentType: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject[contentType].content.groups[
      groupIndex
    ].pictogram.emojiSrc = emojiSrc;

    return newInputObject;
  };
  changeGroupPictogramLineaIconSrc = (
    currentInputObject: any,
    lineaIconSrc: string,
    groupIndex: number,
    contentType: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject[contentType].content.groups[
      groupIndex
    ].pictogram.lineaIconSrc = lineaIconSrc;

    return newInputObject;
  };
  changeGroupPictogramAbstractIconId = (
    currentInputObject: any,
    abstractIconId: string,
    groupIndex: number,
    contentType: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject[contentType].content.groups[
      groupIndex
    ].pictogram.abstractIconId = abstractIconId;

    return newInputObject;
  };

  changeGroupPictogramUploadedSrc = (
    currentInputObject: any,
    uploadedSrc: string,
    groupIndex: number,
    contentType: string,
    width?: any,
    height?: any
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject[contentType].content.groups[
      groupIndex
    ].pictogram.uploadedSrc = uploadedSrc;
    newInputObject[contentType].content.groups[
      groupIndex
    ].pictogram.width = width;
    newInputObject[contentType].content.groups[
      groupIndex
    ].pictogram.height = height;

    return newInputObject;
  };
  changeGroupPictogramUploadedUUID = (
    currentInputObject: any,
    uploadedUUID: string,
    groupIndex: number,
    contentType: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject[contentType].content.groups[
      groupIndex
    ].pictogram.uploadedUUID = uploadedUUID;

    return newInputObject;
  };
  changeGroupPictogramUploadedAlt = (
    currentInputObject: any,
    alt: string,
    groupIndex: number,
    contentType: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject[contentType].content.groups[groupIndex].pictogram.alt = alt;

    return newInputObject;
  };
  changeGroupPictogramUploadedHeight = (
    currentInputObject: any,
    uploadedHeight: string,
    groupIndex: number,
    contentType: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject[contentType].content.groups[
      groupIndex
    ].pictogram.uploadedHeight = uploadedHeight;

    return newInputObject;
  };

  deleteGroup = (
    currentInputObject: any,
    groupIndex: number,
    contentType: string
  ) => {
    let newInputObject = { ...currentInputObject };
    // Delete the group:
    newInputObject[contentType].content.groups.splice(groupIndex, 1);

    // Also delete the content.items of this group:
    let itemsCount = newInputObject[contentType].content.items.length;
    for (let i = 0; i < itemsCount; i++) {
      let currentItem = newInputObject[contentType].content.items[i];
      if (currentItem.groupIndex === groupIndex) {
        //Make all the items of this group 'undefined'
        delete newInputObject[contentType].content.items[i];
      }

      //We need to decrease some groupIndex'es by 1. Why?
      // Initially we had something such group indexes: 0, 1, 2, 3, 4 . Then, when we deleted, let's say, the group of index 2, we have this: 0, 1, 3, 4.
      // But we need this row to be an arithmetic progression: 0, 1, 2, 3. So we subtract one (1) from the groupIndex'es of some elements:
      if (currentItem.groupIndex > groupIndex) {
        newInputObject[contentType].content.items[i].groupIndex =
          newInputObject[contentType].content.items[i].groupIndex - 1;
      }
    }
    // Kill all 'undefined' items
    newInputObject[contentType].content.items = newInputObject[
      contentType
    ].content.items.filter(function(element: any) {
      return element !== undefined;
    });

    // newInputObject = this.addEmptyGroupIfNeeded(newInputObject, contentType);

    return newInputObject;
  };

  moveGroupDown = (
    currentInputObject: any,
    index: number,
    contentType: string
  ) => {
    let newInputObject = { ...currentInputObject };
    let currentGroupArray = newInputObject[contentType].content.groups;
    let newGroupArray = [...currentGroupArray];
    moveArrayItem(newGroupArray, index, index + 1);

    // In the first iteration we only mark new indexes, but not set them. Why? Imagine this: we need to move all items with groupIndex === 3 down.
    // We need to:
    // 1) increase the items of this groupIndex by 1
    // 2) decrease the items of the next group by 1
    // But if we do the step 1), the items will have a new increased index and will be affected during the step 2)
    // That is why we first mark each change...
    let itemsCount = newInputObject[contentType].content.items.length;
    for (let i = 0; i < itemsCount; i++) {
      let currentItem = newInputObject[contentType].content.items[i];

      //all list items of this group has increased index +1
      if (currentItem.groupIndex === index) {
        currentItem.groupIndex = "changeto" + (currentItem.groupIndex + 1);
      }

      //all list items of the next neighbour group has increased index -1
      if (currentItem.groupIndex === index + 1) {
        currentItem.groupIndex = "changeto" + (currentItem.groupIndex - 1);
      }
    }

    //...and only then perform the change:
    for (let i = 0; i < itemsCount; i++) {
      let currentItem = newInputObject[contentType].content.items[i];
      if (
        typeof currentItem.groupIndex === "string" &&
        currentItem.groupIndex.indexOf("changeto") !== -1
      ) {
        currentItem.groupIndex = parseInt(
          currentItem.groupIndex.replace("changeto", "")
        );
      }
    }

    newInputObject[contentType].content.groups = newGroupArray;
    return newInputObject;
  };
  moveGroupUp = (
    currentInputObject: any,
    index: number,
    contentType: string
  ) => {
    // Read about the login in the moveGroupDown method.
    let newInputObject = { ...currentInputObject };
    let currentGroupArray = newInputObject[contentType].content.groups;
    let newGroupArray = [...currentGroupArray];
    moveArrayItem(newGroupArray, index, index - 1);

    let itemsCount = newInputObject[contentType].content.items.length;
    for (let i = 0; i < itemsCount; i++) {
      let currentItem = newInputObject[contentType].content.items[i];

      //all list items of this group has increased index -1
      if (currentItem.groupIndex === index) {
        currentItem.groupIndex = "changeto" + (currentItem.groupIndex - 1);
      }

      //all list items of the next neighbour group has increased index +1
      if (currentItem.groupIndex === index - 1) {
        currentItem.groupIndex = "changeto" + (currentItem.groupIndex + 1);
      }
    }

    for (let i = 0; i < itemsCount; i++) {
      let currentItem = newInputObject[contentType].content.items[i];
      if (
        typeof currentItem.groupIndex === "string" &&
        currentItem.groupIndex.indexOf("changeto") !== -1
      ) {
        currentItem.groupIndex = parseInt(
          currentItem.groupIndex.replace("changeto", "")
        );
      }
    }

    newInputObject[contentType].content.groups = newGroupArray;
    return newInputObject;
  };

  createFeatureContentObject = ({
    title = "Authentic Design",
    subtitle = "speed",
    text = "Unicorn Platform is a powerful website builder for startups, solo-entrepreneurs and hackers.",
    isHighlighted = false,
    highlightedInfo = "",
    isCentered = false,
    pictogram = this.createPictogramContentObject(),
    button = this.createButtonContentObject({
      buttonType: linkOrButtonOptions.link,
      buttonTitle: "",
      buttonHref: "",
      buttonStyleType: buttonStyles.ghost.typeTitle,
      ghostColor: buttonStyles.ghost.accentOutline,
      buttonIsTargetBlank: false,
    }),
  } = {}) => {
    return {
      title: title,
      subtitle: subtitle,
      text: text,
      isHighlighted: isHighlighted,
      highlightedInfo: highlightedInfo,
      isCentered: isCentered,
      pictogram: pictogram,
      button: button,
    };
  };

  setUpFeatures = ({
    fieldTitle = { default: "Features" },
    label = { default: "Edit the features:" },
    labelTooltip = {
      default: "Leave the title field blank to remove a feature.",
    },
    fieldInfo = { default: "" },
    priority = 500,

    maxItems = 99,

    hasPictogram = true,
    hasText = true,
    hasButton = true,

    hasSubtitle = false,
    hasHighlight = false, //some Feature components offer highlighting an item (e.g. with a border).
    hasHighlightInfo = false,

    buttonTypes = [buttonStyles.ghost.typeTitle],
    isDefaultEmpty = false,

    items = [
      this.createFeatureContentObject({ title: "Fully Responsive" }),
      this.createFeatureContentObject({
        title: "Handmade Animations",
        button: this.createButtonContentObject({
          buttonType: linkOrButtonOptions.link,
          buttonTitle: "Learn more",
          buttonHref: "/",
          buttonStyleType: buttonStyles.ghost.typeTitle,
          ghostColor: buttonStyles.ghost.accentOutline,
        }),
      }),
      this.createFeatureContentObject({ title: "Built to Inspire" }),
      this.createFeatureContentObject({ title: "Countless Components" }),
      this.createFeatureContentObject({ title: "Made by Indie" }),
      this.createFeatureContentObject({ title: "Inner Pages" }),

      // The last ghost item which is used to add new items.
      this.createFeatureContentObject({
        title: "",
        text: "",
      }),
    ],
  } = {}) => {
    return {
      features: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,

        maxItems: maxItems, //not used at this moment
        buttonTypes: buttonTypes,
        hasSubtitle: hasSubtitle,
        hasText: hasText,
        hasPictogram: hasPictogram,
        hasHighlight: hasHighlight,
        hasHighlightInfo: hasHighlightInfo,
        hasButton: hasButton,
        settings: {
          editor: defaultTextEditorType,
        },
        content: {
          items: isDefaultEmpty ? [] : items,
        },
      },
    };
  };

  addEmptyFeatureIfNeeded = (currentInputObject: any) => {
    let newInputObject = { ...currentInputObject };

    // let inputObjectArray = newInputObject.features.content.items;
    // let inputObjectArrayLength = inputObjectArray.length;
    // let lastInput = inputObjectArray[inputObjectArrayLength - 1];
    // if(lastInput === undefined || lastInput.title !== '') {
    if (true) {
      let newFeatureItem: any;
      if (newInputObject.features.content.items.length > 0) {
        // if we have a feature in the set, let's clone its properties so user will have a very similar new object
        let firstFeatureItem = { ...newInputObject.features.content.items[0] };
        newFeatureItem = this.createFeatureContentObject({
          title: "",
          text: firstFeatureItem.text,
          isHighlighted: false,
          isCentered: firstFeatureItem.isCentered,
          pictogram: this.createPictogramContentObject({
            type: firstFeatureItem.pictogram.type,
            abstractIconId: firstFeatureItem.pictogram.abstractIconId,
            lineaIconSrc: firstFeatureItem.pictogram.lineaIconSrc,
            emojiSrc: firstFeatureItem.pictogram.emojiSrc,
            uploadedSrc: firstFeatureItem.pictogram.uploadedSrc,
          }),
        });
      } else {
        // if the first feature is not presented in the set, enter dummy data.
        newFeatureItem = this.createFeatureContentObject({
          title: "",
          isHighlighted: false,
        });
      }

      newInputObject.features.content.items.push(newFeatureItem);

      return newInputObject;
    } else {
      // if no addition is needed, simply pass the original object.
      return currentInputObject;
    }
  };

  changeFeatureTitle = (
    currentInputObject: any,
    index: number,
    newTitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[index].title = newTitle;

    return newInputObject;
  };
  changeFeatureSubtitle = (
    currentInputObject: any,
    index: number,
    newSubtitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[index].subtitle = newSubtitle;

    return newInputObject;
  };
  changeFeatureText = (
    currentInputObject: any,
    index: number,
    newText: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[index].text = newText;

    return newInputObject;
  };
  changeFeatureButtonHref = (
    currentInputObject: any,
    index: number,
    newHref: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[index].button.href = newHref;

    return newInputObject;
  };
  changeFeatureButtonIsTargetBlank = (
    currentInputObject: any,
    index: number,
    isTargetBlank: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[
      index
    ].button.isTargetBlank = isTargetBlank;

    return newInputObject;
  };
  changeFeatureIsHighlighted = (
    currentInputObject: any,
    index: number,
    isHighlighted: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[index].isHighlighted = isHighlighted;

    return newInputObject;
  };
  changeFeatureHighlightedInfo = (
    currentInputObject: any,
    index: number,
    highlightedInfo: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[
      index
    ].highlightedInfo = highlightedInfo;

    return newInputObject;
  };

  changeFeatureButtonTitle = (
    currentInputObject: any,
    index: number,
    title: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[index].button.title = title;

    return newInputObject;
  };

  // Note: index comes last here because it's an optional argument in the buttonGhostColorChangeHandler() (<ContentInput/>).
  changeFeatureButtonGhostColor = (
    currentInputObject: any,
    newColor: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[
      index
    ].button.settings.appearance.ghostColor = newColor;

    return newInputObject;
  };
  changeFeatureButtonStyleType = (
    currentInputObject: any,
    newStyleType: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[
      index
    ].button.settings.appearance.styleType = newStyleType;

    return newInputObject;
  };

  changeFeatureButtonColor = (
    currentInputObject: any,
    newColor: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[
      index
    ].button.settings.appearance.color = newColor;

    return newInputObject;
  };
  changeFeatureButtonPillColor = (
    currentInputObject: any,
    newColor: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[
      index
    ].button.settings.appearance.pillColor = newColor;

    return newInputObject;
  };
  changeFeatureButtonPillPrefix = (
    currentInputObject: any,
    newPrefix: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[index].button.pillPrefix = newPrefix;
    return newInputObject;
  };
  changeFeatureButtonMobileAppStoreType = (
    currentInputObject: any,
    newMobileAppStoreType: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[
      index
    ].button.settings.appearance.mobileAppStoreType = newMobileAppStoreType;

    return newInputObject;
  };

  changeFeaturePictogramType = (
    currentInputObject: any,
    pictogramType: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[index].pictogram.type = pictogramType;

    return newInputObject;
  };
  changeFeaturePictogramEmojiSrc = (
    currentInputObject: any,
    emojiSrc: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[index].pictogram.emojiSrc = emojiSrc;

    return newInputObject;
  };
  changeFeaturePictogramLineaIconSrc = (
    currentInputObject: any,
    lineaIconSrc: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[
      index
    ].pictogram.lineaIconSrc = lineaIconSrc;

    return newInputObject;
  };
  changeFeaturePictogramAbstractIconId = (
    currentInputObject: any,
    abstractIconId: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[
      index
    ].pictogram.abstractIconId = abstractIconId;

    return newInputObject;
  };

  changeFeaturePictogramUploadedSrc = (
    currentInputObject: any,
    uploadedSrc: string,
    index: number,
    width?: number,
    height?: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[
      index
    ].pictogram.uploadedSrc = uploadedSrc;
    newInputObject.features.content.items[index].pictogram.width = width;
    newInputObject.features.content.items[index].pictogram.height = height;

    return newInputObject;
  };
  changeFeaturePictogramUploadedUUID = (
    currentInputObject: any,
    uploadedUUID: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[
      index
    ].pictogram.uploadedUUID = uploadedUUID;

    return newInputObject;
  };
  changeFeaturePictogramUploadedAlt = (
    currentInputObject: any,
    alt: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[index].pictogram.alt = alt;

    return newInputObject;
  };
  changeFeaturePictogramUploadedHeight = (
    currentInputObject: any,
    uploadedHeight: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items[
      index
    ].pictogram.uploadedHeight = uploadedHeight;

    return newInputObject;
  };

  deleteFeature = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.features.content.items.splice(index, 1);
    return newInputObject;
  };
  moveFeatureUp = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentFeatureArray = newInputObject.features.content.items;
    let newFeatureArray = [...currentFeatureArray];
    moveArrayItem(newFeatureArray, index, index - 1);
    newInputObject.features.content.items = newFeatureArray;
    return newInputObject;
  };
  moveFeatureDown = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentFeatureArray = newInputObject.features.content.items;
    let newFeatureArray = [...currentFeatureArray];
    moveArrayItem(newFeatureArray, index, index + 1);
    newInputObject.features.content.items = newFeatureArray;
    return newInputObject;
  };

  createPricingItemDetailContentObject = ({
    title = "1 workspace",
    icon = pricingItemDetailIconOptions.greenTick,
    hoverInfo = "",
    iconOptions = [
      pricingItemDetailIconOptions.greenTick,
      pricingItemDetailIconOptions.redCross,
    ],
  } = {}) => {
    return {
      title: title,
      icon: icon,
      hoverInfo: hoverInfo,
      iconOptions: iconOptions,
    };
  };

  //todo: add/edit/delete PricingItemDetail

  createPlansComparisionTableDetailObject = ({
    title = "1 workspace",
    icon = pricingItemDetailIconOptions.greenTick,
    iconOptions = [
      pricingItemDetailIconOptions.greenTick,
      pricingItemDetailIconOptions.redCross,
      pricingItemDetailIconOptions.none,
    ],
  } = {}) => {
    return {
      title: title,
      icon: icon,
      iconOptions: iconOptions,
    };
  };

  createPlansComparisionTableRowsHeadersObject = ({
    title = "HTML export",
    hoverInfo = "",
  } = {}) => {
    return {
      title: title,
      hoverInfo: hoverInfo,
    };
  };

  createPricingContentObject = ({
    monthlyPrice = 0 as number | string,
    yearlyPrice = "" as number | string,
    title = "Starter",
    currencySymbol = "$",
    monthlyPeriod = "/month",
    yearlyPeriod = "/year",

    primaryInfo = "Perfect for beginners.",
    secondaryInfo = "Per user.",
    secondaryInfoYearly = "",

    pictogram = this.createPictogramContentObject({
      type: pictogramOptions.emoji.title,
      emojiSrc: "1f6f4.svg",
    }),
    button = this.createButtonContentObject({
      buttonType: linkOrButtonOptions.link,
      buttonTitle: "Start now",
      buttonHref: "#",
      buttonStyleType: buttonStyles.regular.typeTitle,
      buttonColor: buttonStyles.regular.accentBg,
      buttonIsTargetBlank: true,
    }),
    yearlyButton = this.createButtonContentObject({
      buttonHref: "", // Important: even though we generate a full button object (via .createButtonContentObject()), we use only its href and plugins. The yearly buttons exactly the same as monthly. This is done this way to decrease the amount of inputs in the editor and keep it minimalistic.
    }),
    isHighlighted = false,
    isMonthlyButtonSameAsYearly = true, //if true, the pricing component item (plan) has a different button for the yearly tab. Added Sep 18 2020.
    hasIndividualInfo = false,

    comparisionTableDetailsSet = [
      this.createPlansComparisionTableDetailObject({
        title: "",
        icon: pricingItemDetailIconOptions.redCross,
      }),
      this.createPlansComparisionTableDetailObject({
        title: "",
        icon: pricingItemDetailIconOptions.greenTick,
      }),
      this.createPlansComparisionTableDetailObject({
        title: "",
        icon: pricingItemDetailIconOptions.greenTick,
      }),
    ],

    detailsSet = [
      this.createPricingItemDetailContentObject({
        title: "All integrations",

        icon: pricingItemDetailIconOptions.greenTick,
        hoverInfo: "",
      }),
      this.createPricingItemDetailContentObject({
        title: "1 agent",

        icon: pricingItemDetailIconOptions.greenTick,
        hoverInfo: "",
      }),
      this.createPricingItemDetailContentObject({
        title: "1 workspace",
        icon: pricingItemDetailIconOptions.greenTick,
        hoverInfo: "",
      }),

      this.createPricingItemDetailContentObject({
        title: "Premium support",
        icon: pricingItemDetailIconOptions.redCross,
        hoverInfo: "",
      }),
      this.createPricingItemDetailContentObject({
        title: "Mobile apps",
        icon: pricingItemDetailIconOptions.redCross,
        hoverInfo: "",
      }),

      this.createPricingItemDetailContentObject({
        title: "",
      }), //ghost item
    ],
  } = {}) => {
    return {
      monthlyPrice: monthlyPrice,
      yearlyPrice: yearlyPrice,
      title: title,
      currencySymbol: currencySymbol,
      monthlyPeriod: monthlyPeriod,
      yearlyPeriod: yearlyPeriod,

      primaryInfo: primaryInfo,
      secondaryInfo: secondaryInfo,
      secondaryInfoYearly,

      pictogram: pictogram,
      button: button,
      yearlyButton,
      isHighlighted: isHighlighted,
      isMonthlyButtonSameAsYearly,
      hasIndividualInfo,

      detailsSet: detailsSet,
      comparisionTableDetailsSet: comparisionTableDetailsSet,
    };
  };

  setUpPricing = ({
    fieldTitle = { default: "Pricing table" },
    label = { default: "Edit the pricing plans:" },
    labelTooltip = { default: "" },
    fieldInfo = { default: "" },
    priority = 600,

    maxItems = 60,

    yearlyBonusText = "+2 free months",

    comparisionTableHeadersSet = [
      this.createPlansComparisionTableRowsHeadersObject({
        title: "Premium support",
      }),
      this.createPlansComparisionTableRowsHeadersObject({
        title: "HTML export",
      }),
      this.createPlansComparisionTableRowsHeadersObject({ title: "Free SSL" }),
    ],
    items = [
      this.createPricingContentObject({}),
      this.createPricingContentObject({}),
      this.createPricingContentObject({}),
    ],

    bottomButtonInfoMarkup = "Enterprise starts from $0.9 per agent. Contact us for exclusive service.",
    bottomButton = this.createButtonContentObject({
      buttonType: linkOrButtonOptions.link,
      buttonTitle: "Contact us",
      buttonHref: "#",
      buttonStyleType: buttonStyles.regular.typeTitle,
      buttonColor: buttonStyles.regular.blackOutline,
      buttonIsTargetBlank: true,
    }),

    buttonTypes = [
      buttonStyles.ghost.typeTitle,
      buttonStyles.regular.typeTitle,
      buttonStyles.download.typeTitle,
    ],

    hasHighlight = true,
    hasYearlyPrice = true,
    hasTitle = true,
    hasEditableIcon = true,
    hasPrimaryInfo = true,
    hasSecondaryInfo = true,
    hasPictogram = true,
    hasDetailsSet = true,
    hasComparisionTableRows = false,
    isYearlyActiveByDefault = false,
    isDefaultEmpty = false,
  } = {}) => {
    return {
      pricing: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,
        yearlyBonusText: yearlyBonusText, //Shows up when switched so yearly tab: https://take.ms/IhiGC

        bottomButtonInfoMarkup: bottomButtonInfoMarkup,
        bottomButton: bottomButton,

        buttonTypes: buttonTypes,

        hasPictogram: hasPictogram,
        hasTitle: hasTitle,
        hasYearlyPrice: hasYearlyPrice,
        hasPrimaryInfo: hasPrimaryInfo,
        hasSecondaryInfo: hasSecondaryInfo,
        hasHighlight: hasHighlight,
        hasDetailsSet: hasDetailsSet,
        hasEditableIcon: hasEditableIcon,
        hasComparisionTableRows: hasComparisionTableRows,
        isYearlyActiveByDefault,

        maxItems: maxItems,
        content: {
          items: isDefaultEmpty ? [] : items,
          comparisionTableHeadersSet: isDefaultEmpty
            ? []
            : comparisionTableHeadersSet,
        },
      },
    };
  };

  addEmptyPricingComparisionTableRowsHeader = (currentInputObject: any) => {
    let newInputObject = { ...currentInputObject };

    let clonedRowItem = this.createPlansComparisionTableRowsHeadersObject({
      title: "",
    });

    // also add a new item to each Pricing table
    let pricingPlansCount = newInputObject.pricing.content.items.length;
    for (let i = 0; i < pricingPlansCount; i++) {
      let newItem = this.createPlansComparisionTableDetailObject({
        title: "",
        icon: pricingItemDetailIconOptions.greenTick,
      });
      newInputObject.pricing.content.items[i].comparisionTableDetailsSet.push(
        newItem
      );
    }

    newInputObject.pricing.content.comparisionTableHeadersSet.push(
      clonedRowItem
    );

    return newInputObject;
  };
  deletePricingComparisionTableRowsHeader = (
    currentInputObject: any,
    tableRowsHeaderIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    // delete the header from the list (comparisionTableHeadersSet)
    newInputObject.pricing.content.comparisionTableHeadersSet.splice(
      tableRowsHeaderIndex,
      1
    );

    // also delete each Pricing table's comparisionTableDetailsSet item, because if a table row doesn't have a header, the items of the row are gone too.
    // the index of the comparisionTableDetailsSet item is tableRowsHeaderIndex
    let pricingPlansCount = newInputObject.pricing.content.items.length;
    for (let i = 0; i < pricingPlansCount; i++) {
      newInputObject.pricing.content.items[i].comparisionTableDetailsSet.splice(
        tableRowsHeaderIndex,
        1
      );
    }

    return newInputObject;
  };
  movePricingRowUp = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentHeaders =
      newInputObject.pricing.content.comparisionTableHeadersSet;
    let currentColumns = newInputObject.pricing.content.items;
    let newHeaders = [...currentHeaders];
    let newColumns = [...currentColumns];
    newColumns.forEach((column) => {
      moveArrayItem(column.comparisionTableDetailsSet, index, index - 1);
    });
    moveArrayItem(newHeaders, index, index - 1);
    newInputObject.pricing.content.comparisionTableHeadersSet = newHeaders;
    newInputObject.pricing.content.items = newColumns;
    return newInputObject;
  };
  movePricingRowDown = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentHeaders =
      newInputObject.pricing.content.comparisionTableHeadersSet;
    let currentColumns = newInputObject.pricing.content.items;
    let newHeaders = [...currentHeaders];
    let newColumns = [...currentColumns];
    newColumns.forEach((column) => {
      moveArrayItem(column.comparisionTableDetailsSet, index, index + 1);
    });
    moveArrayItem(newHeaders, index, index + 1);
    newInputObject.pricing.content.comparisionTableHeadersSet = newHeaders;
    newInputObject.pricing.content.items = newColumns;
    return newInputObject;
  };

  changePricingComparisionTableRowsHeaderTitle = (
    currentInputObject: any,
    index: number,
    newTitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.comparisionTableHeadersSet[
      index
    ].title = newTitle;

    return newInputObject;
  };
  changePricingComparisionTableRowsHeaderHoverInfo = (
    currentInputObject: any,
    index: number,
    newHoverInfo: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.comparisionTableHeadersSet[
      index
    ].hoverInfo = newHoverInfo;

    return newInputObject;
  };

  changePlansComparisionTableDetailTitle = (
    currentInputObject: any,
    newTitle: string,
    comparisionTableDetailIndex: number,
    pricingTableIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      pricingTableIndex
    ].comparisionTableDetailsSet[comparisionTableDetailIndex].title = newTitle;

    return newInputObject;
  };
  changePlansComparisionTableDetailIcon = (
    currentInputObject: any,
    oldIcon: string,
    comparisionTableDetailIndex: number,
    pricingTableIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };

    // default value to set.
    let newIcon = pricingItemDetailIconOptions.none;

    // Swap circularly icon from cross to tick on change.
    if (oldIcon === pricingItemDetailIconOptions.redCross) {
      newIcon = pricingItemDetailIconOptions.greenTick;
    } else if (oldIcon === pricingItemDetailIconOptions.greenTick) {
      newIcon = pricingItemDetailIconOptions.none;
    } else if (oldIcon === pricingItemDetailIconOptions.none) {
      newIcon = pricingItemDetailIconOptions.redCross;
    }

    newInputObject.pricing.content.items[
      pricingTableIndex
    ].comparisionTableDetailsSet[comparisionTableDetailIndex].icon = newIcon;

    return newInputObject;
  };

  addEmptyPricingIfNeeded = (currentInputObject: any) => {
    let newInputObject = { ...currentInputObject };

    let inputObjectArray = newInputObject.pricing.content.items;
    let inputObjectArrayLength = inputObjectArray.length;

    let isCurrentItemsCountEqualsMax: boolean;
    if (inputObjectArrayLength === currentInputObject.pricing.maxItems) {
      isCurrentItemsCountEqualsMax = true;
    } else {
      isCurrentItemsCountEqualsMax = false;
    }

    if (isCurrentItemsCountEqualsMax === false) {
      // if last input was edited we need to create another last input for user to fill in.
      // we don't have an "add an item" button - instead, we simply create an empty ghost inputs group for user to fill in.
      let isCurrentFirstItemUndefined =
        newInputObject.pricing.content.items[0] === undefined;
      let firstPricingItem = { ...newInputObject.pricing.content.items[0] };
      let clonedPricingItem = this.createPricingContentObject({});
      clonedPricingItem.monthlyPrice = "";

      clonedPricingItem.pictogram = this.createPictogramContentObject({
        type:
          isCurrentFirstItemUndefined === false
            ? firstPricingItem.pictogram.type
            : pictogramOptions.emoji.title,
      });

      if (
        isCurrentFirstItemUndefined === false &&
        firstPricingItem.yearlyPrice !== ""
      ) {
        //if the original item had a yearly price, we keep it, but set to zero to make it less confusing.
        clonedPricingItem.yearlyPrice = 0;
      }
      clonedPricingItem.isHighlighted = false;

      if (isCurrentFirstItemUndefined === false) {
        clonedPricingItem.primaryInfo = firstPricingItem.primaryInfo;
        clonedPricingItem.secondaryInfo = firstPricingItem.secondaryInfo;

        for (let i = 0; i < firstPricingItem.detailsSet.length; i++) {
          clonedPricingItem.detailsSet[
            i
          ] = this.createPricingItemDetailContentObject({
            title: firstPricingItem.detailsSet[i].title,
            icon: firstPricingItem.detailsSet[i].icon,
            hoverInfo: firstPricingItem.detailsSet[i].hoverInfo,
          });
        }

        for (
          let i = 0;
          i < firstPricingItem.comparisionTableDetailsSet.length;
          i++
        ) {
          clonedPricingItem.comparisionTableDetailsSet[
            i
          ] = this.createPlansComparisionTableDetailObject({
            title: firstPricingItem.comparisionTableDetailsSet[i].title,
            icon: firstPricingItem.comparisionTableDetailsSet[i].icon,
          });
        }
      }

      newInputObject.pricing.content.items.push(clonedPricingItem);

      return newInputObject;
    } else {
      // if no addition is needed, simply pass the original object.
      return currentInputObject;
    }
  };

  changePricingYearlyBonusText = (
    currentInputObject: any,
    newYearlyBonusText: string
  ) => {
    let newInputObject = { ...currentInputObject };

    newInputObject.pricing.yearlyBonusText = newYearlyBonusText;

    return newInputObject;
  };

  changePricingTabText = (
    currentInputObject: any,
    newTabText: string,
    tab: PricingPeriodTabPlacement
  ) => {
    let newInputObject = { ...currentInputObject };

    if (tab === "left") {
      newInputObject.pricing.leftTabText = newTabText;
    }
    if (tab === "right") {
      newInputObject.pricing.rightTabText = newTabText;
    }

    return newInputObject;
  };

  changePricingMonthlyPrice = (
    currentInputObject: any,
    index: number,
    newMonthlyPrice: string | number
  ) => {
    let newInputObject = { ...currentInputObject };
    // newInputObject = this.addEmptyPricingIfNeeded(newInputObject);
    newInputObject.pricing.content.items[index].monthlyPrice = newMonthlyPrice;

    return newInputObject;
  };
  changePricingYearlyPrice = (
    currentInputObject: any,
    index: number,
    newYearlyPrice: string | number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[index].yearlyPrice = newYearlyPrice;

    return newInputObject;
  };
  changePricingCurrencySymbol = (
    currentInputObject: any,
    index: number,
    newCurrencySymbol: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items.map((item: any, index: number) => {
      //change the symbol of each pricing item;
      newInputObject.pricing.content.items[
        index
      ].currencySymbol = newCurrencySymbol;
    });

    return newInputObject;
  };
  changePricingMonthlyPeriod = (
    currentInputObject: any,
    index: number,
    newMonthlyPeriod: string
  ) => {
    let newInputObject = { ...currentInputObject };
    // newInputObject.pricing.content.items.map((item:any, index:number) => {
    //change the text of each pricing item;
    newInputObject.pricing.content.items[
      index
    ].monthlyPeriod = newMonthlyPeriod;
    // });

    return newInputObject;
  };
  changePricingYearlyPeriod = (
    currentInputObject: any,
    index: number,
    newYearlyPeriod: string
  ) => {
    let newInputObject = { ...currentInputObject };
    // newInputObject.pricing.content.items.map((item:any, index:number) => {
    //change the text of each pricing item;
    newInputObject.pricing.content.items[index].yearlyPeriod = newYearlyPeriod;
    // });

    return newInputObject;
  };
  changePricingTitle = (
    currentInputObject: any,
    index: number,
    newTitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[index].title = newTitle;

    return newInputObject;
  };
  changePricingPrimaryInfo = (
    currentInputObject: any,
    index: number,
    newPrimaryInfo: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[index].primaryInfo = newPrimaryInfo;

    return newInputObject;
  };
  changePricingSecondaryInfo = (
    currentInputObject: any,
    index: number,
    newSecondaryInfo: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].secondaryInfo = newSecondaryInfo;

    return newInputObject;
  };
  changePricingSecondaryInfoYearly = (
    currentInputObject: any,
    index: number,
    newSecondaryInfoYearly: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].secondaryInfoYearly = newSecondaryInfoYearly;

    return newInputObject;
  };
  changePricingIsYearlyActiveByDefault = (
    currentInputObject: any,
    isYearlyActiveByDefault: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.isYearlyActiveByDefault = isYearlyActiveByDefault; //added on Sep 11 2020, so be careful when checking it - it is underfined for old components

    return newInputObject;
  };
  changePricingButtonHref = (
    currentInputObject: any,
    index: number,
    newHref: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[index].button.href = newHref;

    return newInputObject;
  };
  changePricingButtonIsTargetBlank = (
    currentInputObject: any,
    index: number,
    isTargetBlank: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].button.isTargetBlank = isTargetBlank;

    return newInputObject;
  };
  changePricingButtonTitle = (
    currentInputObject: any,
    index: number,
    title: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[index].button.title = title;

    return newInputObject;
  };

  // Note: index comes last here because it's an optional argument in the buttonGhostColorChangeHandler() (<ContentInput/>).
  changePricingButtonGhostColor = (
    currentInputObject: any,
    newColor: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].button.settings.appearance.ghostColor = newColor;

    return newInputObject;
  };
  changePricingButtonColor = (
    currentInputObject: any,
    newColor: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].button.settings.appearance.color = newColor;

    return newInputObject;
  };
  changePricingButtonPillColor = (
    currentInputObject: any,
    newColor: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].button.settings.appearance.pillColor = newColor;

    return newInputObject;
  };
  changePricingButtonMobileAppStoreType = (
    currentInputObject: any,
    newMobileAppStoreType: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].button.settings.appearance.mobileAppStoreType = newMobileAppStoreType;

    return newInputObject;
  };
  changePricingButtonStyleType = (
    currentInputObject: any,
    newStyleType: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].button.settings.appearance.styleType = newStyleType;

    return newInputObject;
  };
  changePricingButtonIsRelayUTM = (
    currentInputObject: any,
    index: number,
    isRelayUTM: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[index].button.isRelayUTM = isRelayUTM;

    return newInputObject;
  };

  changePricingButtonID = (
    currentInputObject: any,
    index: number,
    custom_id: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].button.settings.custom_id = custom_id;

    return newInputObject;
  };
  changePricingButtonClass = (
    currentInputObject: any,
    index: number,
    classes: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].button.settings.classes = classes;

    return newInputObject;
  };
  changePricingButtonOnClick = (
    currentInputObject: any,
    index: number,
    onclick: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].button.settings.onclick = onclick;

    return newInputObject;
  };
  changePricingButtonAttributes = (
    currentInputObject: any,
    index: number,
    attributes: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].button.settings.attributes = attributes;

    return newInputObject;
  };

  changePricingButtonStripeProductId = (
    currentInputObject: any,
    index: number,
    stripeProductId: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].button.stripeProductId = stripeProductId;

    return newInputObject;
  };
  changePricingButtonStripePaymentMode = (
    currentInputObject: any,
    index: number,
    stripePaymentMode: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].button.stripePaymentMode = stripePaymentMode;

    return newInputObject;
  };
  changePricingButtonSuccessfulPaymentUrl = (
    currentInputObject: any,
    index: number,
    successfulPaymentUrl: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].button.successfulPaymentUrl = successfulPaymentUrl;

    return newInputObject;
  };
  changePricingButtonCancelPaymentUrl = (
    currentInputObject: any,
    index: number,
    cancelPaymentUrl: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].button.cancelPaymentUrl = cancelPaymentUrl;

    return newInputObject;
  };

  changePricingYearlyButtonHref = (
    currentInputObject: any,
    index: number,
    newHref: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[index].yearlyButton.href = newHref;
    //n.b.: yearlyButton was added on Sep 2020 and old object do not have it. We initiate yearlyButton here: changePricingIsMonthlyButtonSameAsYearly()
    return newInputObject;
  };

  changePricingYearlyButtonIsRelayUTM = (
    currentInputObject: any,
    index: number,
    isRelayUTM: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].yearlyButton.isRelayUTM = isRelayUTM;

    return newInputObject;
  };

  changePricingYearlyButtonID = (
    currentInputObject: any,
    index: number,
    custom_id: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].yearlyButton.settings.custom_id = custom_id;

    return newInputObject;
  };
  changePricingYearlyButtonClass = (
    currentInputObject: any,
    index: number,
    classes: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].yearlyButton.settings.classes = classes;

    return newInputObject;
  };
  changePricingYearlyButtonOnClick = (
    currentInputObject: any,
    index: number,
    onclick: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].yearlyButton.settings.onclick = onclick;

    return newInputObject;
  };
  changePricingYearlyButtonAttributes = (
    currentInputObject: any,
    index: number,
    attributes: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].yearlyButton.settings.attributes = attributes;

    return newInputObject;
  };

  changePricingYearlyButtonStripeProductId = (
    currentInputObject: any,
    index: number,
    stripeProductId: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].yearlyButton.stripeProductId = stripeProductId;

    return newInputObject;
  };
  changePricingYearlyButtonStripePaymentMode = (
    currentInputObject: any,
    index: number,
    stripePaymentMode: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].yearlyButton.stripePaymentMode = stripePaymentMode;

    return newInputObject;
  };
  changePricingYearlyButtonSuccessfulPaymentUrl = (
    currentInputObject: any,
    index: number,
    successfulPaymentUrl: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].yearlyButton.successfulPaymentUrl = successfulPaymentUrl;

    return newInputObject;
  };
  changePricingYearlyButtonCancelPaymentUrl = (
    currentInputObject: any,
    index: number,
    cancelPaymentUrl: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].yearlyButton.cancelPaymentUrl = cancelPaymentUrl;

    return newInputObject;
  };

  changePricingPictogramType = (
    currentInputObject: any,
    pictogramType: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[index].pictogram.type = pictogramType;

    return newInputObject;
  };
  changePricingPictogramEmojiSrc = (
    currentInputObject: any,
    emojiSrc: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[index].pictogram.emojiSrc = emojiSrc;

    return newInputObject;
  };
  changePricingPictogramLineaIconSrc = (
    currentInputObject: any,
    lineaIconSrc: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].pictogram.lineaIconSrc = lineaIconSrc;

    return newInputObject;
  };
  changePricingPictogramAbstractIconId = (
    currentInputObject: any,
    abstractIconId: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].pictogram.abstractIconId = abstractIconId;

    return newInputObject;
  };

  changePricingPictogramUploadedSrc = (
    currentInputObject: any,
    uploadedSrc: string,
    index: number,
    width?: number,
    height?: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].pictogram.uploadedSrc = uploadedSrc;
    newInputObject.pricing.content.items[index].pictogram.width = width;
    newInputObject.pricing.content.items[index].pictogram.height = height;

    return newInputObject;
  };
  changePricingPictogramUploadedUUID = (
    currentInputObject: any,
    uploadedUUID: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].pictogram.uploadedUUID = uploadedUUID;

    return newInputObject;
  };
  changePricingPictogramUploadedAlt = (
    currentInputObject: any,
    alt: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[index].pictogram.alt = alt;

    return newInputObject;
  };
  changePricingPictogramUploadedHeight = (
    currentInputObject: any,
    uploadedHeight: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].pictogram.uploadedHeight = uploadedHeight;

    return newInputObject;
  };

  changePricingIsHighlighted = (
    currentInputObject: any,
    newIsHighlighted: boolean,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].isHighlighted = newIsHighlighted;

    return newInputObject;
  };
  changePricingIsMonthlyButtonSameAsYearly = (
    currentInputObject: any,
    newIsMonthlyButtonSameAsYEarly: boolean,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].isMonthlyButtonSameAsYearly = newIsMonthlyButtonSameAsYEarly;
    if (
      newInputObject.pricing.content.items[index].yearlyButton === undefined
    ) {
      newInputObject.pricing.content.items[
        index
      ].yearlyButton = this.createButtonContentObject({ buttonHref: "" });
    }
    return newInputObject;
  };
  changePricingHasIndividualInfo = (
    currentInputObject: any,
    newHasIndividualInfo: boolean,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[
      index
    ].hasIndividualInfo = newHasIndividualInfo;
    return newInputObject;
  };

  addEmptyPricingDetailIfNeeded = (
    currentInputObject: any,
    pricingTableIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };

    let inputObjectArray =
      newInputObject.pricing.content.items[pricingTableIndex].detailsSet;
    let inputObjectArrayLength = inputObjectArray.length;
    let lastInput = inputObjectArray[inputObjectArrayLength - 1];

    if (lastInput === undefined || lastInput.title !== "") {
      // if last input was edited we need to create another last input for user to fill in.
      // we don't have an "add an item" button - instead, we simply create an empty ghost inputs group for user to fill in.
      let firstPricingDetailItem = {
        ...newInputObject.pricing.content.items[pricingTableIndex]
          .detailsSet[0],
      };
      let clonedPricingItem = this.createPricingItemDetailContentObject({
        title: "",
        hoverInfo: firstPricingDetailItem.hoverInfo,
      });

      newInputObject.pricing.content.items[pricingTableIndex].detailsSet.push(
        clonedPricingItem
      );

      return newInputObject;
    } else {
      // if no addition is needed, simply pass the original object.
      return currentInputObject;
    }
  };
  changePricingDetailTitle = (
    currentInputObject: any,
    newTitle: string,
    detailIndex: number,
    pricingTableIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[pricingTableIndex].detailsSet[
      detailIndex
    ].title = newTitle;
    newInputObject = this.addEmptyPricingDetailIfNeeded(
      newInputObject,
      pricingTableIndex
    );

    return newInputObject;
  };
  changePricingDetailIcon = (
    currentInputObject: any,
    oldIcon: string,
    detailIndex: number,
    pricingTableIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    let newIcon = pricingItemDetailIconOptions.redCross;
    // Swap icon from cross to tick on change.
    if (oldIcon === pricingItemDetailIconOptions.redCross) {
      newIcon = pricingItemDetailIconOptions.greenTick;
    }

    newInputObject.pricing.content.items[pricingTableIndex].detailsSet[
      detailIndex
    ].icon = newIcon;

    return newInputObject;
  };

  changePricingDetailHoverInfo = (
    currentInputObject: any,
    newHoverInfo: string,
    detailIndex: number,
    pricingTableIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[pricingTableIndex].detailsSet[
      detailIndex
    ].hoverInfo = newHoverInfo;

    return newInputObject;
  };

  deleteDetail = (
    currentInputObject: any,
    detailIndex: number,
    pricingTableIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items[pricingTableIndex].detailsSet.splice(
      detailIndex,
      1
    );
    newInputObject = this.addEmptyPricingDetailIfNeeded(
      newInputObject,
      pricingTableIndex
    );
    return newInputObject;
  };
  moveDetailUp = (
    currentInputObject: any,
    detailIndex: number,
    pricingTableIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    let currentDetails =
      newInputObject.pricing.content.items[pricingTableIndex].detailsSet;
    let newDetails = [...currentDetails];
    moveArrayItem(newDetails, detailIndex, detailIndex - 1);
    newInputObject.pricing.content.items[
      pricingTableIndex
    ].detailsSet = newDetails;
    return newInputObject;
  };
  moveDetailDown = (
    currentInputObject: any,
    detailIndex: number,
    pricingTableIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    let currentDetails =
      newInputObject.pricing.content.items[pricingTableIndex].detailsSet;
    let newDetails = [...currentDetails];
    moveArrayItem(newDetails, detailIndex, detailIndex + 1);
    newInputObject.pricing.content.items[
      pricingTableIndex
    ].detailsSet = newDetails;
    return newInputObject;
  };

  deletePricing = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.pricing.content.items.splice(index, 1);
    // newInputObject = this.addEmptyPricingIfNeeded(newInputObject);
    return newInputObject;
  };
  movePricingUp = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentPricingArray = newInputObject.pricing.content.items;
    let newPricingArray = [...currentPricingArray];
    moveArrayItem(newPricingArray, index, index - 1);
    newInputObject.pricing.content.items = newPricingArray;
    return newInputObject;
  };
  movePricingDown = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentPricingArray = newInputObject.pricing.content.items;
    let newPricingArray = [...currentPricingArray];
    moveArrayItem(newPricingArray, index, index + 1);
    newInputObject.pricing.content.items = newPricingArray;
    return newInputObject;
  };

  createLogoContentObject = ({
    type = logoOptions.uploaded.title,
    height = defaultLogoHeight,
    href = "",
    isTargetBlank = true,
    uploadedSrc = "", //full url to the item
    uuid = "", //Uploadcare image uuid used to identify image in the Uploadcare DB and in my DB. Used only for uploaded images.
    // bg = '', //Todo
  } = {}) => {
    return {
      type: type,
      height: height,
      href: href,
      isTargetBlank: isTargetBlank,
      uploadedSrc: uploadedSrc,
      uuid: uuid,
    };
  };
  setUpLogos = ({
    fieldTitle = { default: "Logos" },
    label = { default: "Edit the logos:" },
    labelTooltip = { default: "" },
    fieldInfo = { default: "" },
    priority = 400,

    maxItems = 40,
    isDefaultEmpty = false,

    items = [
      this.createLogoContentObject({
        uploadedSrc:
        publicUrl + pressLogos.prefix + "cnn.svg",
      }),
      this.createLogoContentObject({
        uploadedSrc:
          publicUrl +
          pressLogos.prefix +
          "lifehacker.svg",
      }),
      this.createLogoContentObject({
        uploadedSrc:
          publicUrl + pressLogos.prefix + "mashable.svg",
      }),
      this.createLogoContentObject({
        uploadedSrc:
          publicUrl +
          pressLogos.prefix +
          "the-guardian.svg",
      }),

      this.createLogoContentObject({
        uploadedSrc:
          publicUrl +
          pressLogos.prefix +
          "the-next-web.svg",
      }),
      this.createLogoContentObject({
        uploadedSrc:
          publicUrl +
          pressLogos.prefix +
          "ycombinator.svg",
      }),
      this.createLogoContentObject({
        uploadedSrc:
          publicUrl + pressLogos.prefix + "forbes.svg",
      }),
      this.createLogoContentObject({
        uploadedSrc:
          publicUrl +
          pressLogos.prefix +
          "the-verge.svg",
        height: 40,
      }),
    ],
  } = {}) => {
    return {
      logos: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,

        maxItems: maxItems,
        content: {
          items: isDefaultEmpty ? [] : items,
        },
      },
    };
  };

  addEmptyLogoIfNeeded = (currentInputObject: any, uploadedSrc?: string) => {
    let newInputObject = { ...currentInputObject };

    let inputObjectArray = newInputObject.logos.content.items;
    let inputObjectArrayLength = inputObjectArray.length;

    let isCurrentItemsCountEqualsMax: boolean;
    if (inputObjectArrayLength === currentInputObject.logos.maxItems) {
      isCurrentItemsCountEqualsMax = true;
    } else {
      isCurrentItemsCountEqualsMax = false;
    }

    if (isCurrentItemsCountEqualsMax === false) {
      newInputObject.logos.content.items.push(
        this.createLogoContentObject({ type: "" })
      );
      return currentInputObject;
    } else {
      // if no addition is needed, simply pass the original object.
      return currentInputObject;
    }
  };

  changeLogosItemUploadedSrc = (
    currentInputObject: any,
    uploadedSrc: string,
    index: number,
    width: number,
    height: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.logos.content.items[index].uploadedSrc = uploadedSrc;
    newInputObject.logos.content.items[index].source_width = width;
    newInputObject.logos.content.items[index].source_height = height;
    // newInputObject = this.addEmptyLogoIfNeeded(newInputObject, uploadedSrc);

    return newInputObject;
  };
  changeLogosItemUUID = (
    currentInputObject: any,
    UUID: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.logos.content.items[index].uuid = UUID;

    return newInputObject;
  };
  changeLogosItemAlt = (
    currentInputObject: any,
    alt: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.logos.content.items[index].alt = alt;

    return newInputObject;
  };
  changeLogosItemHeight = (
    currentInputObject: any,
    height: number,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.logos.content.items[index].height = height;

    return newInputObject;
  };
  changeLogosItemType = (
    currentInputObject: any,
    logoType: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.logos.content.items[index].type = logoType;

    return newInputObject;
  };

  changeLogosItemHref = (
    currentInputObject: any,
    index: number,
    newHref: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.logos.content.items[index].href = newHref;

    return newInputObject;
  };

  changeLogosItemIsTargetBlank = (
    currentInputObject: any,
    index: number,
    isTargetBlank: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.logos.content.items[index].isTargetBlank = isTargetBlank;

    return newInputObject;
  };

  deleteLogoItem = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.logos.content.items.splice(index, 1);
    // newInputObject = this.addEmptyLogoIfNeeded(newInputObject);
    return newInputObject;
  };

  moveLogoItemUp = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentLogoArray = newInputObject.logos.content.items;
    let newLogoArray = [...currentLogoArray];
    moveArrayItem(newLogoArray, index, index - 1);
    newInputObject.logos.content.items = newLogoArray;
    return newInputObject;
  };
  moveLogoItemDown = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentLogoArray = newInputObject.logos.content.items;
    let newLogoArray = [...currentLogoArray];
    moveArrayItem(newLogoArray, index, index + 1);
    newInputObject.logos.content.items = newLogoArray;
    return newInputObject;
  };

  // start of mockups
  createMockupsContentObject = ({
    type = "mobile", // mobile / desktop / tablet
    deviceId = "iphone_11_pro_max",
    optionId = "space_grey",
    fileName = "apple_iphone_se_2020_black.png",
    humanTitle = "MacBook Air",
  } = {}) => {
    return {
      humanTitle,
      type,
      deviceId,
      optionId,
      fileName,
    };
  };

  setUpMockups = ({
    fieldTitle = {
      default: "Device",
    },
    label = {
      default: "Device mockup image:",
    },
    labelTooltip = {
      default: "Choose a device to present your application.",
    },
    fieldInfo = {
      default: "",
    },
    priority = 10,

    items = [
      //    prefill this in each particular component.
      this.createMockupsContentObject(),
    ],
  } = {}) => {
    // Use this function to set pick mockups.
    return {
      mockups: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,

        content: {
          items: items,
        },
      },
    };
  };

  changeMockupDevice = (
    currentInputObject: any,
    newDeviceId: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.mockups.content.items[index].deviceId = newDeviceId;

    return newInputObject;
  };
  changeMockupOption = (
    currentInputObject: any,
    newOptionId: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.mockups.content.items[index].optionId = newOptionId;

    return newInputObject;
  };

  changeMockupHumanTitle = (
    currentInputObject: any,
    newHumanTitle: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.mockups.content.items[index].humanTitle = newHumanTitle;

    return newInputObject;
  };
  changeMockupFileName = (
    currentInputObject: any,
    newFileName: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.mockups.content.items[index].fileName = newFileName;

    return newInputObject;
  };
  // end of mockups

  createGraphicsContentObject = ({
    imageUrl = "",
    videoUrl = "",
    imageUUID = "",
    href = "",
    width = "" as string | number,
    height = "" as string | number,
    uploadButtonInfo = "",
    codeSnippet = "",
  } = {}) => {
    return {
      [GraphicsOptions.image]: {
        url: imageUrl,
        uuid: imageUUID,
        href: href, //can open an url or open a popup
        width: width,
        height: height,
        uploadButtonInfo: uploadButtonInfo,
      },
      [GraphicsOptions.video]: {
        youtube: {
          // this field is called "youtube" but it actually stores vimeo, youtube, mp4 urls and  wistia embed code.
          url: videoUrl,
        },
      },
      [GraphicsOptions.HTML]: {
        codeSnippet,
      },
    };
  };

  setUpGraphics = ({
    fieldTitle = {
      [GraphicsOptions.image]: "Image",
      [GraphicsOptions.video]: "Video",
      default: "Image",
    },
    label = {
      [GraphicsOptions.image]: "Upload an image:",
      [GraphicsOptions.video]: "Enter video URL (YouTube, Vimeo, .mp4):",
      default: "Upload an image:",
    },
    labelTooltip = {
      [GraphicsOptions.image]:
        "Upload an image from your computer, your cloud of a direct URL.",
      [GraphicsOptions.video]:
        "Enter URL of a YouTube or Vimeo video. You can also paste a direct URL to a video file (must end on '.mp4') or a Wistia video embed code.",
      default: "",
    },
    fieldInfo = {
      [GraphicsOptions.image]: "",
      [GraphicsOptions.video]: "",
      default: "",
    },
    priority = 50,

    maxItems = 1,
    userCanAddItems = false,

    items = [
      //    prefill this in each particular component.
      this.createGraphicsContentObject(),
    ],

    activeOption = GraphicsOptions.image,
  } = {}) => {
    // Use this function to set up photo, video, screenshots in mockups.
    // Some components, e.g.: Team, will only have availableOptions = [GraphicsOptions.image], but other components such as Header will offer more: availableOptions = [GraphicsOptions.image, GraphicsOptions.video],
    // To declare the options edit the data/availableOptions.ts file
    return {
      graphics: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,
        maxItems: maxItems,
        userCanAddItems: userCanAddItems, // if userCanAddItems === false, then we start with pre-created N items where N = maxItems. We also can not delete items.

        activeOption: activeOption,

        content: {
          items: items,
        },
      },
    };
  };
  changeGraphicsImageUrl = (
    currentInputObject: any,
    newUrl: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.graphics.content.items[index].image.url = newUrl;
    newInputObject = this.addEmptyGraphicsIfNeeded(newInputObject);

    return newInputObject;
  };
  changeGraphicsImageUUID = (
    currentInputObject: any,
    newUUID: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.graphics.content.items[index].image.uuid = newUUID;

    return newInputObject;
  };
  changeGraphicsImageWidth = (
    currentInputObject: any,
    newWidth: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.graphics.content.items[index].image.width = newWidth;

    return newInputObject;
  };
  changeGraphicsImageHeight = (
    currentInputObject: any,
    newHeight: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.graphics.content.items[index].image.height = newHeight;

    return newInputObject;
  };
  changeGraphicsImageAlt = (
    currentInputObject: any,
    newAlt: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.graphics.content.items[index].image.alt = newAlt;

    return newInputObject;
  };

  changeGraphicsYoutubeVideoUrl = (
    currentInputObject: any,
    newUrl: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    // this field is called "youtube" but it actually stores vimeo, youtube, mp4 urls and  wistia embed code.
    newInputObject.graphics.content.items[
      index
    ].video.youtube.url = formatVideoSourceUrl(newUrl); //we need to format the URL before saving to avoid doing this job again on backend
    newInputObject = this.addEmptyGraphicsIfNeeded(newInputObject);

    return newInputObject;
  };
  changeGraphicsCodeSnippet = (
    currentInputObject: any,
    newCodeSnippet: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    _.set(
      newInputObject,
      `graphics.content.items[${index}][${GraphicsOptions.HTML}].codeSnippet`,
      newCodeSnippet
    );
    newInputObject = this.addEmptyGraphicsIfNeeded(newInputObject);

    return newInputObject;
  };

  changeGraphicsActiveOption = (
    currentInputObject: any,
    newActiveOption: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.graphics.activeOption = newActiveOption;

    return newInputObject;
  };

  addEmptyGraphicsIfNeeded = (currentInputObject: any) => {
    let newInputObject = { ...currentInputObject };

    let inputObjectArray = newInputObject.graphics.content.items;
    let inputObjectArrayLength = inputObjectArray.length;

    let isCurrentItemsCountEqualsMax: boolean;
    if (inputObjectArrayLength === currentInputObject.graphics.maxItems) {
      isCurrentItemsCountEqualsMax = true;
    } else {
      isCurrentItemsCountEqualsMax = false;
    }

    if (currentInputObject.graphics.userCanAddItems === false) {
      // if no addition can be performed, simply pass the original object.
      return currentInputObject;
    } else if (isCurrentItemsCountEqualsMax === false) {
      let lastInput = inputObjectArray[inputObjectArrayLength - 1];

      if (
        // if currently image is active and the last item doesn't have an image
        (currentInputObject.graphics.activeOption === GraphicsOptions.image &&
          lastInput[GraphicsOptions.image].url !== "") ||
        // OR if currently video is active and the last item doesn't have a video url
        (currentInputObject.graphics.activeOption === GraphicsOptions.video &&
          lastInput[GraphicsOptions.video].youtube.url !== "") // this field is called "youtube" but it actually stores vimeo, youtube, mp4 urls and  wistia embed code.
      ) {
        // if last input was edited we need to create another last input for user to fill in.
        // we don't have an "add an item" button - instead, we simply create an empty ghost inputs group for user to fill in.
        let clonedButtonItem = this.createGraphicsContentObject({});

        newInputObject.graphics.content.items.push(clonedButtonItem);

        return newInputObject;
      }
    } else {
      // if no addition is needed, simply pass the original object.
      return currentInputObject;
    }
  };

  deleteGraphics = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.graphics.content.items.splice(index, 1);
    newInputObject = this.addEmptyGraphicsIfNeeded(newInputObject);
    return newInputObject;
  };

  moveGraphicsUp = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentGraphicsArray = newInputObject.graphics.content.items;
    let newGraphicsArray = [...currentGraphicsArray];
    moveArrayItem(newGraphicsArray, index, index - 1);
    newInputObject.graphics.content.items = newGraphicsArray;
    return newInputObject;
  };
  moveGraphicsDown = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentGraphicsArray = newInputObject.graphics.content.items;
    let newGraphicsArray = [...currentGraphicsArray];
    moveArrayItem(newGraphicsArray, index, index + 1);
    newInputObject.graphics.content.items = newGraphicsArray;
    return newInputObject;
  };

  setUpSubtitleText = ({
    fieldTitle = { default: "Subtitle" },
    label = { default: "Enter the text below title:" },
    labelTooltip = {
      default:
        "Leave this field blank to remove the subtitle. Hit 'Enter' for a new paragraph. 'Shift' + 'Enter' to break a line.",
    },
    fieldInfo = { default: "" },
    priority = 800,
    subtitleText = "Unicorn Platform is a powerful website builder for startups, solo-entrepreneurs and hackers. Try it for free.",
    isDefaultEmpty = false,
  } = {}) => {
    return {
      subtitleText: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,
        settings: {
          editor: defaultTextEditorType, //old subtitleText doesn't have this setting by default.
        },
        content: {
          markup: isDefaultEmpty ? "" : subtitleText,
        },
      },
    };
  };
  changeSubtitleTextMarkup = (currentInputObject: any, newMarkup: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.subtitleText.content.markup = newMarkup;

    return newInputObject;
  };
  changeItemTextEditorType = (
    currentInputObject: any,
    contentType: string,
    newEditorType: any
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject[contentType].settings.editor = newEditorType; //use when user changes an editor to another type. E.g. from the default Draftjs to plain html.

    return newInputObject;
  };

  setUpText = ({
    fieldTitle = { default: "Text" },
    label = { default: "Enter the text:" },
    labelTooltip = {
      default:
        "Hit 'Enter' for a new paragraph. 'Shift' + 'Enter' to break a line.",
    },
    fieldInfo = { default: "" },
    priority = 50,
    items = [
      {
        markup:
          "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.",
      },
    ],
  } = {}) => {
    return {
      text: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        settings: {
          editor: defaultTextEditorType,
        },
        labelTooltip: labelTooltip,
        priority: priority,
        content: {
          items: items,
        },
      },
    };
  };
  changeTextMarkup = (
    currentInputObject: any,
    newMarkup: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.text.content.items[index].markup = newMarkup;

    return newInputObject;
  };

  // Can be buttons of a email form.
  setUpCta = ({
    fieldTitle = {
      [callToActionOptions.buttons]: "Call to action buttons",
      [callToActionOptions.form]: "Call to action form",
      default: "Call to action",
    },
    label = {
      [callToActionOptions.buttons]: "Edit the buttons:",
      [callToActionOptions.form]: "Set up the form fields and button:", // the form has 2 items to edit: button and the integrations. I have hardcoded the integrations label so we write here in this label about button.
      default: "Edit the Call to action:",
    },
    labelTooltip = {
      [callToActionOptions.buttons]:
        "CTA (call to action) is what you want your visitors to do: sign up, learn more or read an article.",
      [callToActionOptions.form]:
        "Add or remove form fields to collect different data.",
      default: "Edit the Call to action:",
    },
    fieldInfo = {
      [callToActionOptions.buttons]: "",
      [callToActionOptions.form]: "",
      default: "",
    },
    priority = 120,

    activeOption = callToActionOptions.buttons, //see availableOptions.ts to view available options for a particular category or component.

    buttonTypes = [
      buttonStyles.regular.typeTitle,
      buttonStyles.mobileAppStore.typeTitle,
      buttonStyles.download.typeTitle,
      buttonStyles.ghost.typeTitle,
      buttonStyles.pill.typeTitle,
    ],

    ctaBottomInfo = "",

    formButtonTitle = "Subscribe",
    formButtonStyleType = buttonStyles.regular.typeTitle,
    formButtonStyleColor = buttonStyles.regular.accentBg,

    maxButtons = 99,
    buttons = [
      this.createButtonContentObject({
        buttonType: linkOrButtonOptions.link,
        buttonTitle: "Get it",
        buttonHref: "/sign-up",
        buttonStyleType: buttonStyles.regular.typeTitle,
        buttonColor: buttonStyles.regular.accentBg,
        buttonIsTargetBlank: false,
        pillPrefix: "Whoa!",
      }),

      this.createButtonContentObject({
        buttonType: linkOrButtonOptions.link,
        buttonTitle: "Learn more",
        buttonHref: "/product",
        buttonStyleType: buttonStyles.regular.typeTitle,
        buttonColor: buttonStyles.regular.blackOutline,
        buttonIsTargetBlank: false,
        pillPrefix: "New",
      }),
    ],
    isDefaultEmpty = false,
    form = this.createFormContentObject({
      buttonTitle: formButtonTitle,
      buttonStyleType: formButtonStyleType,
      buttonColor: formButtonStyleColor,
      isDefaultEmpty,
    }),
  } = {}) => {
    return {
      cta: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,
        activeOption: activeOption,
        buttonTypes: buttonTypes,
        maxButtons: maxButtons,
        ctaBottomInfo: {
          // a text line below a CTA. E.g. "No credit card required." or "Unsubscribe anytime.". Only for Headers and Forms.
          markup: ctaBottomInfo,
        },
        content: {
          [callToActionOptions.buttons]: isDefaultEmpty ? [] : buttons,
          [callToActionOptions.form]: form,
        },
      },
    };
  };

  changeCtaBottomInfo = (currentInputObject: any, newMarkup: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.ctaBottomInfo.markup = newMarkup;

    return newInputObject;
  };
  changeButtonHref = (
    currentInputObject: any,
    index: number,
    newHref: string
  ) => {
    let newInputObject = { ...currentInputObject };
    // newInputObject = this.addEmptyButtonIfNeeded(newInputObject);
    newInputObject.cta.content.buttons[index].href = newHref;

    return newInputObject;
  };
  changeButtonTitle = (
    currentInputObject: any,
    index: number,
    title: string
  ) => {
    let newInputObject = { ...currentInputObject };
    // newInputObject = this.addEmptyButtonIfNeeded(newInputObject);
    newInputObject.cta.content.buttons[index].title = title;

    return newInputObject;
  };
  changeButtonIsTargetBlank = (
    currentInputObject: any,
    index: number,
    isTargetBlank: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.buttons[index].isTargetBlank = isTargetBlank;

    return newInputObject;
  };
  changeCtaButtonIsRelayUTM = (
    currentInputObject: any,
    index: number,
    isRelayUTM: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.buttons[index].isRelayUTM = isRelayUTM;

    return newInputObject;
  };

  changeCtaButtonID = (
    currentInputObject: any,
    index: number,
    custom_id: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.buttons[index].settings.custom_id = custom_id;

    return newInputObject;
  };
  changeCtaButtonClass = (
    currentInputObject: any,
    index: number,
    classes: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.buttons[index].settings.classes = classes;

    return newInputObject;
  };
  changeCtaButtonOnClick = (
    currentInputObject: any,
    index: number,
    onclick: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.buttons[index].settings.onclick = onclick;

    return newInputObject;
  };
  changeCtaButtonAttributes = (
    currentInputObject: any,
    index: number,
    attributes: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.buttons[index].settings.attributes = attributes;

    return newInputObject;
  };

  changeCtaButtonStripeProductId = (
    currentInputObject: any,
    index: number,
    stripeProductId: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.buttons[index].stripeProductId = stripeProductId;

    return newInputObject;
  };
  changeCtaButtonSuccessfulPaymentUrl = (
    currentInputObject: any,
    index: number,
    successfulPaymentUrl: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.buttons[
      index
    ].successfulPaymentUrl = successfulPaymentUrl;

    return newInputObject;
  };
  changeCtaButtonStripePaymentMode = (
    currentInputObject: any,
    index: number,
    stripePaymentMode: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.buttons[
      index
    ].stripePaymentMode = stripePaymentMode;

    return newInputObject;
  };
  changeCtaButtonCancelPaymentUrl = (
    currentInputObject: any,
    index: number,
    cancelPaymentUrl: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.buttons[
      index
    ].cancelPaymentUrl = cancelPaymentUrl;

    return newInputObject;
  };

  // Note: index comes last here because it's an optional argument in the buttonGhostColorChangeHandler() (<ContentInput/>).
  changeButtonGhostColor = (
    currentInputObject: any,
    newColor: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.buttons[
      index
    ].settings.appearance.ghostColor = newColor;

    return newInputObject;
  };

  changeButtonColor = (
    currentInputObject: any,
    newColor: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.buttons[
      index
    ].settings.appearance.color = newColor;

    return newInputObject;
  };
  changeButtonPillColor = (
    currentInputObject: any,
    newColor: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.buttons[
      index
    ].settings.appearance.pillColor = newColor;

    return newInputObject;
  };
  changeButtonPillPrefix = (
    currentInputObject: any,
    newPrefix: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.buttons[index].pillPrefix = newPrefix;
    return newInputObject;
  };

  changeButtonMobileAppStoreType = (
    currentInputObject: any,
    newMobileAppStoreType: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.buttons[
      index
    ].settings.appearance.mobileAppStoreType = newMobileAppStoreType;

    return newInputObject;
  };
  changeButtonStyleType = (
    currentInputObject: any,
    newStyleType: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.buttons[
      index
    ].settings.appearance.styleType = newStyleType;

    return newInputObject;
  };

  addEmptyButtonIfNeeded = (currentInputObject: any) => {
    // we have an "add an item" button
    let newInputObject = { ...currentInputObject };

    let inputObjectArray = newInputObject.cta.content.buttons;
    let inputObjectArrayLength = inputObjectArray.length;

    let isCurrentItemsCountEqualsMax: boolean;
    if (inputObjectArrayLength === currentInputObject.cta.maxButtons) {
      isCurrentItemsCountEqualsMax = true;
    } else {
      isCurrentItemsCountEqualsMax = false;
    }

    if (
      isCurrentItemsCountEqualsMax === false //add if max is no reached
    ) {
      // if last input was edited we need to create another last input for user to fill in.
      let clonedButtonItem = this.createButtonContentObject();
      clonedButtonItem.href = "";
      clonedButtonItem.title = "";

      newInputObject.cta.content.buttons.push(clonedButtonItem);

      return newInputObject;
    } else {
      // if no addition is needed, simply pass the original object.
      return currentInputObject;
    }
  };

  deleteButton = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.buttons.splice(index, 1);
    // newInputObject = this.addEmptyButtonIfNeeded(newInputObject);
    return newInputObject;
  };
  moveButtonUp = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentButtonArray = newInputObject.cta.content.buttons;
    let newButtonArray = [...currentButtonArray];
    moveArrayItem(newButtonArray, index, index - 1);
    newInputObject.cta.content.buttons = newButtonArray;
    return newInputObject;
  };
  moveButtonDown = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentButtonArray = newInputObject.cta.content.buttons;
    let newButtonArray = [...currentButtonArray];
    moveArrayItem(newButtonArray, index, index + 1);
    newInputObject.cta.content.buttons = newButtonArray;
    return newInputObject;
  };

  changeCtaActiveOption = (
    currentInputObject: any,
    newActiveOption: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.activeOption = newActiveOption;

    return newInputObject;
  };

  activateCtaEmailFormIntegration = (
    currentInputObject: any,
    newIntegrationId: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.connectedIntegrationsIds = [
      newIntegrationId,
    ];

    return newInputObject;
  };
  changeCtaEmailFormButtonTitle = (
    currentInputObject: any,
    newButtonTitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.button.title = newButtonTitle;

    return newInputObject;
  };
  changeCtaEmailFormStyleType = (
    currentInputObject: any,
    newStyleType: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.button.settings.appearance.styleType = newStyleType;

    return newInputObject;
  };
  changeCtaEmailFormColor = (currentInputObject: any, newColor: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.button.settings.appearance.color = newColor;

    return newInputObject;
  };

  changeFormObject = (currentInputObject: any, newFormObject: any) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form = { ...newFormObject };

    return newInputObject;
  };

  addNewFormField = (currentInputObject: any, newFormFieldType: string) => {
    let newInputObject = { ...currentInputObject };

    // If a field with the required name already exists, we add a number to the end of the name. E.g. MESSAGE -> MESSAGE2
    const newFieldName = createUniqueFormFieldName(
      formFields[newFormFieldType].name,
      newInputObject.cta.content.form.fields.items
    );

    let newFormFieldObject = this.createFormFieldContentObject({
      title: formFields[newFormFieldType].title,
      required: formFields[newFormFieldType].required, // usually false for every field, but not for checkbox
      label: formFields[newFormFieldType].label,
      name: newFieldName,
      placeholder: formFields[newFormFieldType].placeholder,
    });

    newInputObject.cta.content.form.fields.items.push(newFormFieldObject);

    return newInputObject;
  };

  deleteFormField = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.fields.items.splice(index, 1);
    return newInputObject;
  };
  moveFormFieldUp = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentFormFieldArray = newInputObject.cta.content.form.fields.items;
    let newFormFieldArray = [...currentFormFieldArray];
    moveArrayItem(newFormFieldArray, index, index - 1);
    newInputObject.cta.content.form.fields.items = newFormFieldArray;
    return newInputObject;
  };
  moveFormFieldDown = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentFormFieldArray = newInputObject.cta.content.form.fields.items;
    let newFormFieldArray = [...currentFormFieldArray];
    moveArrayItem(newFormFieldArray, index, index + 1);
    newInputObject.cta.content.form.fields.items = newFormFieldArray;
    return newInputObject;
  };

  changeFormFieldTitle = (
    currentInputObject: any,
    index: number,
    newTitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.fields.items[index].title = newTitle;
    return newInputObject;
  };
  toggleFormFieldRequired = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };

    let currentRequiredState =
      newInputObject.cta.content.form.fields.items[index].required;
    newInputObject.cta.content.form.fields.items[
      index
    ].required = !currentRequiredState; // reverse it
    return newInputObject;
  };
  changeFormFieldLabel = (
    currentInputObject: any,
    index: number,
    newLabel: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.fields.items[index].label = newLabel;
    return newInputObject;
  };
  changeFormFieldName = (
    currentInputObject: any,
    index: number,
    newName: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.fields.items[index].name = newName;
    return newInputObject;
  };
  changeFormFieldPlaceholder = (
    currentInputObject: any,
    index: number,
    newPlaceholder: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.fields.items[
      index
    ].placeholder = newPlaceholder;
    return newInputObject;
  };
  changeFormFieldNoResultsText = (
    currentInputObject: any,
    index: number,
    text: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.fields.items[
      index
    ].noResultsText = text;
    return newInputObject;
  };
  changeFormFieldMaxFiles = (
    currentInputObject: any,
    index: number,
    maxFiles: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.fields.items[
      index
    ].maxFiles = maxFiles;
    return newInputObject;
  };
  changeFormFieldAllowedFileTypes = (
    currentInputObject: any,
    index: number,
    allowedFileTypes: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.fields.items[
      index
    ].allowedFileTypes = allowedFileTypes;
    return newInputObject;
  };
  changeFormFieldId = (
    currentInputObject: any,
    index: number,
    newId: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.fields.items[index].settings.id = newId;
    return newInputObject;
  };
  changeFormFieldMask = (
    currentInputObject: any,
    index: number,
    newMask: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.fields.items[index].settings.mask = newMask;
    return newInputObject;
  };
  changeFormFieldClasses = (
    currentInputObject: any,
    index: number,
    newClasses: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.fields.items[
      index
    ].settings.classes = newClasses;
    return newInputObject;
  };
  changeFormFieldAttributes = (
    currentInputObject: any,
    index: number,
    newAttributes: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.fields.items[
      index
    ].settings.attributes = newAttributes;
    return newInputObject;
  };
  changeFormFieldDefaultValue = (
    currentInputObject: any,
    index: number,
    newDefaultValue: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.fields.items[
      index
    ].settings.defaultValue = newDefaultValue;
    return newInputObject;
  };
  changeFormFieldIsHidden = (
    currentInputObject: any,
    index: number,
    newIsHidden: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.fields.items[
      index
    ].settings.isHidden = newIsHidden;
    return newInputObject;
  };

  changeFormDropdownItems = (
    currentInputObject: any,
    index: number,
    newDropdownItems: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.fields.items[
      index
    ].dropdownItems = newDropdownItems.split(";");
    return newInputObject;
  };

  toggleFormPreserveParamsActive = (currentInputObject: any) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.settings.disablePreserveParams = !newInputObject
      .cta.content.form.settings.disablePreserveParams;
    return newInputObject;
  };
  toggleFormRedirectUrlActive = (currentInputObject: any) => {
    let newInputObject = { ...currentInputObject };
    //reverse
    newInputObject.cta.content.form.settings.successRedirect.isActive = !newInputObject
      .cta.content.form.settings.successRedirect.isActive;
    return newInputObject;
  };
  toggleFormRedirectIsTargetBlank = (currentInputObject: any) => {
    let newInputObject = { ...currentInputObject };
    //reverse
    newInputObject.cta.content.form.settings.successRedirect.isTargetBlank = !newInputObject
      .cta.content.form.settings.successRedirect.isTargetBlank;
    return newInputObject;
  };
  toggleFormRedirectPassValues = (currentInputObject: any) => {
    let newInputObject = { ...currentInputObject };
    //reverse
    newInputObject.cta.content.form.settings.successRedirect.passValuesInGet = !newInputObject
      .cta.content.form.settings.successRedirect.passValuesInGet;
    return newInputObject;
  };
  changeFormRedirectUrl = (currentInputObject: any, newUrl: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.settings.successRedirect.url = newUrl;
    return newInputObject;
  };

  toggleFormCodeActive = (currentInputObject: any) => {
    let newInputObject = { ...currentInputObject };
    //reverse
    newInputObject.cta.content.form.settings.jsCodeOnSuccess.isActive = !newInputObject
      .cta.content.form.settings.jsCodeOnSuccess.isActive;
    return newInputObject;
  };
  changeFormAfterCode = (currentInputObject: any, newCode: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.settings.jsCodeOnSuccess.code = newCode;
    return newInputObject;
  };

  toggleFormDiaformActive = (currentInputObject: any) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.cta.content.form.settings.messages.isActive = !newInputObject
      .cta.content.form.settings.messages.isActive;
    return newInputObject;
  };

  togglePopupActive = (
    currentInputObject: any,
    newIsActive: boolean,
    componentDataPath: ComponentDataPaths,
    popupType: PopupType,
    contentType: string
  ) => {
    const newInputObject = { ...currentInputObject };
    const path = _.get(componentDataPath, [`${popupType}`, `${contentType}`]);

    if (!path) {
      return newInputObject;
    }

    const modifiedInputObject = _.set(
      newInputObject,
      `${path}.popup.isActive`,
      newIsActive
    );

    return modifiedInputObject;
  };

  changePopupId = (
    currentInputObject: any,
    newPopupId: string,
    componentDataPath: ComponentDataPaths,
    popupType: PopupType,
    contentType: string
  ) => {
    const newInputObject = { ...currentInputObject };
    const path = _.get(componentDataPath, [`${popupType}`, `${contentType}`]);

    if (!path) {
      return newInputObject;
    }

    const modifiedInputObject = _.set(
      newInputObject,
      `${path}.popup.popupId`,
      newPopupId
    );

    return modifiedInputObject;
  };

  setUpTitle = ({
    fieldTitle = { default: "Title" },
    label = { default: "Enter the title:" },
    labelTooltip = { default: "Leave this field blank to remove the title." },
    fieldInfo = { default: "" },
    priority = 1000,
    titleText = "Meet the Perfect Website Builder for Busy Makers",
    isDefaultEmpty = false,
  } = {}) => {
    return {
      title: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,

        content: {
          markup: isDefaultEmpty ? "" : titleText,
          badge: {
            text: "",
            style: "default",
          },
          subheading: {
            text: "",
            style: "default",
          },
          pictogram: this.createPictogramContentObject(),
        },
      },
    };
  };
  changeTitleMarkup = (currentInputObject: any, newMarkup: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.title.content.markup = newMarkup;

    return newInputObject;
  };

  setUpNav = ({
    logoFieldTitle = { default: "Company logo" },
    logoLabel = { default: "Change the logo:" },
    logoFieldInfo = { default: "Recommended shape: square" },
    logoLabelTooltip = { default: "" },
    logoPriority = 1000,

    logoHref = "/",
    // logoSrc = publicUrl + '/img/logos/unicorn-platform-logo.svg',
    logoSrc = "https://app.unicornplatform.com/static/img/logos/unicorn-platform-logo.svg", // we use absolute URL because when we export the default logo path will contain STATIC_URL which contains a '/' at the beginning and the logo will fail to load on a local machine. We replaced all STATIC_URLs in the Django template when exporting but we can not replace the navLogo URL because it is diplayed in a unique way.
    logoAlt = "Logo",
    logoHeight = 30,
    imageUUID = "",

    companyTitle = "",

    activeOption = callToActionOptions.buttons,

    buttonsFieldTitle = { default: "Buttons" },
    buttonsLabel = { default: "Change the buttons:" },
    buttonsFieldInfo = { default: "" },
    buttonsLabelTooltip = { default: "" },
    buttonsPriority = 750,

    navLinksListPriority = 850,
    version = 2,
  } = {}) => {
    /*How nav works:

        * We have fields of many types: logo, brand name, cta, links etc etc. Those are content.
        * We also have a settings field called "Nav type". "Nav type" can be changed.
        * When "Nav type" is changed, the content is filled in this new type markup, user doesn't need to re-enter the data.
        * */

    return {
      settings: {
        scheme: 1,
        style: {
          color: "", //color is set automatically depending on the BG options of the first component on a current page
        },
        isFixed: {
          desktop: false,
          tablets: false,
          mobiles: false,
        },
        version,
      },
      componentData: {
        // navLogo stays unique (we could use "graphics"), because some specific logic may come (e.g., fetch logo from footer or settings).
        navLogo: {
          fieldTitle: logoFieldTitle,
          label: logoLabel,
          fieldInfo: logoFieldInfo,
          labelTooltip: logoLabelTooltip,
          priority: logoPriority,
          content: {
            href: logoHref,
            src: logoSrc,
            logoHeight: logoHeight,
            uuid: imageUUID,
            alt: logoAlt,
            companyTitle: {
              text: companyTitle,
            },
          },
        },
        cta: this.setUpCta({
          activeOption: callToActionOptions.buttons,
          priority: buttonsPriority,
          buttonTypes: [
            buttonStyles.regular.typeTitle,
            buttonStyles.mobileAppStore.typeTitle,
            buttonStyles.download.typeTitle,
            buttonStyles.ghost.typeTitle,
          ],
          labelTooltip: {
            [callToActionOptions.buttons]:
              "CTA (call to action) is what you want your visitors to do: sign up, buy or book a call.",
            [callToActionOptions.form]:
              "Our designer recommends you to have a single field without labels in the Nav form.",
            default: "Edit the Call to action:",
          },
          form: this.createFormContentObject({
            buttonTitle: "Subscribe",
            buttonStyleType: buttonStyles.regular.typeTitle,
            buttonColor: buttonStyles.regular.accentBg,
            items: [
              this.createFormFieldContentObject({
                title: formFields.email.title, // this will allow us to pick iconType and type (for HTML) from formFields object
                required: formFields.email.required,
                label: "",
                name: formFields.email.name,
              }),
            ],
          }),
        }).cta,
        navLinks: this.setUpNavLinks({
          priority: navLinksListPriority, //more than buttonsPriority
          fieldTitle: {
            default: "Navigation links",
          },
          label: {
            default: "Edit the nav links:",
          },
          labelTooltip: {
            default:
              "These nav links are changed globally across the all website pages.",
          },
          topLevelItems: [
            this.createNavLinksItemContentObject({
              title: "Features",
              href: "#",
              id: generateRandomNumerousId(),
            }),
          ],
        }).navLinks,
      },
    };
  };

  changeNavLogoSrc = (
    currentInputObject: any,
    newSrc: string,
    width: number,
    height: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.componentData.navLogo.content.src = newSrc;
    newInputObject.componentData.navLogo.content.source_width = width;
    newInputObject.componentData.navLogo.content.source_height = height;
    return newInputObject;
  };
  changeNavLogoUUID = (currentInputObject: any, newUUID: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.componentData.navLogo.content.uuid = newUUID;
    return newInputObject;
  };
  changeNavLogoHeight = (currentInputObject: any, logoHeight: number) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.componentData.navLogo.content.logoHeight = logoHeight;

    return newInputObject;
  };
  changeNavLogoAlt = (currentInputObject: any, newAlt: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.componentData.navLogo.content.alt = newAlt;

    return newInputObject;
  };
  changeNavCompanyTitleText = (currentInputObject: any, newText: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.componentData.navLogo.content.companyTitle.text = newText;
    return newInputObject;
  };
  toggleNavFixed = (currentInputObject: any, isFixed: boolean) => {
    let newInputObject = { ...currentInputObject };
    // We have 3 different isFixed options, but we do not differentiate them. If all === true, the nav is fixed everywhere, and vice versa.
    newInputObject.settings.isFixed.desktop = isFixed;
    newInputObject.settings.isFixed.tablets = isFixed;
    newInputObject.settings.isFixed.mobiles = isFixed;
    return newInputObject;
  };

  createNavLinksItemContentObject = ({
    title = "Features",
    href = "#",
    isTargetBlank = true,
    id = generateRandomNumerousId(), // must be unique
  } = {}) => {
    return {
      title: title,
      href: href,
      isTargetBlank: isTargetBlank,
      id,
    };
  };

  // navLinks can have both dropdowns and regular links as item
  setUpNavLinks = ({
    fieldTitle = { default: "Navigation links" },
    label = { default: "Edit the nav links:" },
    labelTooltip = { default: "" },
    fieldInfo = { default: "" },
    priority = 400,

    dropdownItems = {},
    // {
    // 	"3": [{},{},],
    // 	"2": [{},{},]
    // }

    topLevelItems = [
      //can be regular links or dropdown parents
      this.createNavLinksItemContentObject({
        title: "Features",
        href: "#",
        id: generateRandomNumerousId(),
      }),
    ],
  } = {}) => {
    return {
      navLinks: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,

        content: {
          topLevelItems,
          dropdownItems,
        },
      },
    };
  };

  addEmptyTopLevelNavLinksItem = (currentInputObject: any) => {
    let newInputObject = { ...currentInputObject };
    let allItemsArray = newInputObject.navLinks.content.topLevelItems;

    let allItemsArrayLength = allItemsArray.length;
    let lastInput = allItemsArray[allItemsArrayLength - 1];

    let clonedNavLinksItem = this.createNavLinksItemContentObject({
      title: "",
      href: "",
      isTargetBlank: lastInput ? lastInput.isTargetBlank : false,
      id: generateRandomNumerousId(),
    });

    newInputObject.navLinks.content.topLevelItems.push(clonedNavLinksItem);

    return newInputObject;
  };
  changeTopLevelNavLinksItemTitle = (
    currentInputObject: any,
    index: number,
    title: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.navLinks.content.topLevelItems[index].title = title;

    return newInputObject;
  };
  changeTopLevelNavLinksItemHref = (
    currentInputObject: any,
    index: number,
    newHref: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.navLinks.content.topLevelItems[index].href = newHref;

    return newInputObject;
  };
  changeTopLevelNavLinksItemIsTargetBlank = (
    currentInputObject: any,
    index: number,
    isTargetBlank: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.navLinks.content.topLevelItems[
      index
    ].isTargetBlank = isTargetBlank;

    return newInputObject;
  };
  deleteTopLevelNavLinksItem = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    //del all dropdown items if any
    let id = newInputObject.navLinks.content.topLevelItems[index].id;
    let thisDropdownItemsArray =
      newInputObject.navLinks.content.dropdownItems[JSON.stringify(id)];
    if (thisDropdownItemsArray === undefined) {
      //this means this top level nav link doesn't have any dropdown children
    } else {
      delete newInputObject.navLinks.content.dropdownItems[JSON.stringify(id)];
    }

    // del the link
    newInputObject.navLinks.content.topLevelItems.splice(index, 1);

    //return the modified object
    return newInputObject;
  };

  // top level items are either regular links or dropdown holders
  moveTopLevelNavLinksItemUp = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentNavLinksItem = newInputObject.navLinks.content.topLevelItems;
    let newNavLinksItem = [...currentNavLinksItem];
    moveArrayItem(newNavLinksItem, index, index - 1);
    newInputObject.navLinks.content.topLevelItems = newNavLinksItem;
    return newInputObject;
  };
  moveTopLevelNavLinksItemDown = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentNavLinksItem = newInputObject.navLinks.content.topLevelItems;
    let newNavLinksItem = [...currentNavLinksItem];
    moveArrayItem(newNavLinksItem, index, index + 1);
    newInputObject.navLinks.content.topLevelItems = newNavLinksItem;
    return newInputObject;
  };

  addEmptyDropdownNavLinksItem = (
    currentInputObject: any,
    parentId: number
  ) => {
    let newInputObject = { ...currentInputObject };
    let allItemsArray = newInputObject.navLinks.content.dropdownItems;

    let allItemsArrayLength = allItemsArray.length;
    let lastInput = allItemsArray[allItemsArrayLength - 1];

    let clonedNavLinksItem = this.createNavLinksItemContentObject({
      title: "",
      href: "",
      isTargetBlank: lastInput ? lastInput.isTargetBlank : false,
      id: generateRandomNumerousId(),
    });

    let thisDropdownItemsArray =
      newInputObject.navLinks.content.dropdownItems[JSON.stringify(parentId)];
    if (thisDropdownItemsArray === undefined) {
      //this means we try to add a dropdown child to the dropdown set which does not exist at the moment. Let's create the array (set) first
      newInputObject.navLinks.content.dropdownItems[
        JSON.stringify(parentId)
      ] = [];
    }
    newInputObject.navLinks.content.dropdownItems[
      JSON.stringify(parentId)
    ].push(clonedNavLinksItem);

    return newInputObject;
  };
  changeNavDropdownLinksItemTitle = (
    currentInputObject: any,
    index: number,
    title: string,
    parentId: any
  ) => {
    let newInputObject = { ...currentInputObject };
    let thisDropdownItemsArray =
      newInputObject.navLinks.content.dropdownItems[JSON.stringify(parentId)];
    if (thisDropdownItemsArray === undefined) {
      //this means this top level nav link doesn't have any dropdown children
    } else {
      newInputObject.navLinks.content.dropdownItems[JSON.stringify(parentId)][
        index
      ].title = title;
    }

    return newInputObject;
  };
  changeNavDropdownLinksItemHref = (
    currentInputObject: any,
    index: number,
    newHref: string,
    parentId: any
  ) => {
    let newInputObject = { ...currentInputObject };
    let thisDropdownItemsArray =
      newInputObject.navLinks.content.dropdownItems[JSON.stringify(parentId)];
    if (thisDropdownItemsArray === undefined) {
      //this means this top level nav link doesn't have any dropdown children
    } else {
      newInputObject.navLinks.content.dropdownItems[JSON.stringify(parentId)][
        index
      ].href = newHref;
    }

    return newInputObject;
  };
  changeNavDropdownLinksItemIsTargetBlank = (
    currentInputObject: any,
    index: number,
    isTargetBlank: boolean,
    parentId: any
  ) => {
    let newInputObject = { ...currentInputObject };
    let thisDropdownItemsArray =
      newInputObject.navLinks.content.dropdownItems[JSON.stringify(parentId)];
    if (thisDropdownItemsArray === undefined) {
      //this means this top level nav link doesn't have any dropdown children
    } else {
      newInputObject.navLinks.content.dropdownItems[JSON.stringify(parentId)][
        index
      ].isTargetBlank = isTargetBlank;
    }

    return newInputObject;
  };
  deleteNavDropdownLinksItem = (
    currentInputObject: any,
    index: number,
    parentId: number
  ) => {
    let newInputObject = { ...currentInputObject };
    let thisDropdownItemsArray =
      newInputObject.navLinks.content.dropdownItems[JSON.stringify(parentId)];
    if (thisDropdownItemsArray === undefined) {
      //this means this parent (top level nav link) doesn't have any dropdown children
    } else {
      newInputObject.navLinks.content.dropdownItems[
        JSON.stringify(parentId)
      ].splice(index, 1);

      if (
        newInputObject.navLinks.content.dropdownItems[JSON.stringify(parentId)]
          .length === 0
      ) {
        delete newInputObject.navLinks.content.dropdownItems[
          JSON.stringify(parentId)
        ];
      }
    }

    //return the modified object
    return newInputObject;
  };
  moveNavDropdownItemLinksItemUp = (
    currentInputObject: any,
    parentId: number,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    let currentNavLinksItem =
      newInputObject.navLinks.content.dropdownItems[JSON.stringify(parentId)];
    let newNavLinksItem = [...currentNavLinksItem];
    moveArrayItem(newNavLinksItem, index, index - 1);
    newInputObject.navLinks.content.dropdownItems[
      JSON.stringify(parentId)
    ] = newNavLinksItem;
    return newInputObject;
  };
  moveNavDropdownItemLinksItemDown = (
    currentInputObject: any,
    parentId: number,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    let currentNavLinksItem =
      newInputObject.navLinks.content.dropdownItems[JSON.stringify(parentId)];
    let newNavLinksItem = [...currentNavLinksItem];
    moveArrayItem(newNavLinksItem, index, index + 1);
    newInputObject.navLinks.content.dropdownItems[
      JSON.stringify(parentId)
    ] = newNavLinksItem;
    return newInputObject;
  };

  createListItemContentObject = ({
    title = "Authentic design",
    href = "#",
    isTargetBlank = true,
    groupIndex = 0,
    // tags = [createTagContentObject()]
    // text
  } = {}) => {
    return {
      title: title,
      href: href,
      isTargetBlank: isTargetBlank,
      groupIndex: groupIndex,
    };
  };

  setUpList = ({
    fieldTitle = { default: "Links list" },
    label = { default: "Edit the links list:" },
    labelTooltip = { default: "" },
    fieldInfo = { default: "" },
    priority = 400,
    isDefaultEmpty = false,

    maxItems = 16,

    hasGroups = false,
    maxGroups = 3,
    groupTitleHasTitle = true,
    groupTitleHasPictogram = true, // read a long comment on this field here: frontend/src/components/editor/ContentInput.tsx : CMD+F 'currentElementHasPictogramInGroups', near main render().
    groupTitleHasSubtitle = false,
    groups = [this.createGroupContentObject()],

    items = [
      this.createListItemContentObject({
        title: "Authentic design",
        href: "/product-design",
      }),

      // The last ghost item which is used to add new items.
      this.createListItemContentObject({ title: "", href: "" }),

      // Please note that if you want to create a list of 2 predefined groups you need to create ghost items for both groupIndexes. Example:
      // this.createListItemContentObject({title: 'Modern tech', href: '/stack', groupIndex: 0}),
      // this.createListItemContentObject({title: '', href: '', groupIndex: 0}),
    ],
  } = {}) => {
    return {
      list: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,

        maxItems: maxItems, //not used at this moment

        hasGroups: hasGroups,
        maxGroups: maxGroups,
        groupTitleHasTitle: groupTitleHasTitle,
        groupTitleHasPictogram: groupTitleHasPictogram,
        groupTitleHasSubtitle: groupTitleHasSubtitle,

        content: {
          groups: isDefaultEmpty ? [] : groups,
          items: isDefaultEmpty ? [] : items,
        },
      },
    };
  };

  addEmptyListItemIfNeeded = (currentInputObject: any, groupIndex: number) => {
    let newInputObject = { ...currentInputObject };
    let allItemsArray = newInputObject.list.content.items;

    // Get only this group items. If a component doesn't support groups, we do not pass the groupIndex argument and groupIndex get default value (0). That actually means that all items are placed in one group with groupIndex = 0 by default and this can not be changed.
    let thisGroupItemsArray = allItemsArray.filter(
      (item: any, index: number) => {
        if (item.groupIndex == groupIndex) {
          return true;
        }
      }
    );

    let thisGroupArrayLength = thisGroupItemsArray.length;
    let lastInput = thisGroupItemsArray[thisGroupArrayLength - 1];

    if (lastInput === undefined || lastInput.title !== "") {
      // if last input was edited we need to create another last input for user to fill in.
      // we don't have an "add list item" button - instead, we simply create an empty ghost list item inputs group for user to fill in.
      let firstListItem = { ...thisGroupItemsArray[0] };

      let clonedListItem = this.createListItemContentObject({
        title: "",
        href: "",
        isTargetBlank: lastInput ? firstListItem.isTargetBlank : false,
        groupIndex: groupIndex,
      });

      newInputObject.list.content.items.push(clonedListItem);

      return newInputObject;
    } else {
      // if no addition is needed, simply pass the original object.
      return currentInputObject;
    }
  };

  changeListItemTitle = (
    currentInputObject: any,
    index: number,
    title: string,
    groupIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.list.content.items[index].title = title;
    newInputObject = this.addEmptyListItemIfNeeded(newInputObject, groupIndex);

    return newInputObject;
  };
  changeListItemHref = (
    currentInputObject: any,
    index: number,
    newHref: string,
    groupIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.list.content.items[index].href = newHref;
    newInputObject = this.addEmptyListItemIfNeeded(newInputObject, groupIndex);

    return newInputObject;
  };
  changeListItemIsTargetBlank = (
    currentInputObject: any,
    index: number,
    isTargetBlank: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.list.content.items[index].isTargetBlank = isTargetBlank;

    return newInputObject;
  };
  deleteListItem = (
    currentInputObject: any,
    index: number,
    groupIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.list.content.items.splice(index, 1);
    newInputObject = this.addEmptyListItemIfNeeded(newInputObject, groupIndex);
    return newInputObject;
  };

  moveListItemUp = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentListItem = newInputObject.list.content.items;
    let newListItem = [...currentListItem];
    moveArrayItem(newListItem, index, index - 1);
    newInputObject.list.content.items = newListItem;
    return newInputObject;
  };
  moveListItemDown = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentListItem = newInputObject.list.content.items;
    let newListItem = [...currentListItem];
    moveArrayItem(newListItem, index, index + 1);
    newInputObject.list.content.items = newListItem;
    return newInputObject;
  };

  setUpFooter = ({
    copyrightFieldTitle = { default: "Copyright text" },
    copyrightLabel = { default: "Change the text:" },
    copyrightFieldInfo = { default: "Disclaimer or other text statement." },
    copyrightLabelTooltip = { default: "" },
    copyrightPriority = 500,

    copyrightMarkup = "All rights reserved.",

    activeScheme = 1,

    socialLinksFieldTitle = { default: "Social networks links" },
    socialLinksLabel = { default: "Enter the links group title and items:" },
    socialLinksFieldInfo = { default: "" },
    socialLinksLabelTooltip = { default: "" },
    socialLinksPriority = 750,
  } = {}) => {
    /*How nav works:

        * We have fields of many types: logo, brand name, cta, links etc etc. Those are content.
        * We also have a settings field called "Footer type". "Footer type" can be changed.
        * When "Footer type" is changed, the content is filled in this new type markup, user doesn't need to re-enter the data.
        * */

    return {
      settings: {
        scheme: activeScheme,
        //"bg_color" // we set bg color in frontend/src/components/dashboard/BackgroundColorPicker.tsx because we can not set one default BG color for all footers (they have different default BG color)
      },
      componentData: {
        text: this.setUpText({
          fieldTitle: copyrightFieldTitle,
          label: copyrightLabel,
          fieldInfo: copyrightFieldInfo,
          labelTooltip: copyrightLabelTooltip,
          priority: copyrightPriority,

          items: [
            {
              markup: copyrightMarkup,
            },
          ],
        }).text,

        socialLinks: this.setSocialLinks({
          fieldTitle: socialLinksFieldTitle,
          label: socialLinksLabel,
          fieldInfo: socialLinksFieldInfo,
          labelTooltip: socialLinksLabelTooltip,
          priority: socialLinksPriority,
          title: "",
        }).socialLinks,
      },
    };
  };

  changeFooterScheme = (
    currentInputObject: any,
    newFooterSchemeIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.settings.scheme = newFooterSchemeIndex;
    return newInputObject;
  };
  changeFooterBgColor = (currentInputObject: any, newFooterBgColor: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.settings.bg_color = newFooterBgColor;
    if (newInputObject.settings.customBackgroundColor) {
      newInputObject.settings.customBackgroundColor.isActive = false;
    }
    return newInputObject;
  };
  changeFooterCustomBgColor = (
    currentInputObject: any,
    newCustomBgData: CustomColorData
  ) => {
    const newInputObject = { ...currentInputObject };
    newInputObject.settings.customBackgroundColor = {
      ...newInputObject.settings.customBackgroundColor,
      ...newCustomBgData,
    };
    return newInputObject;
  };
  addFooterContent = (
    currentInputObject: any,
    newFooterContentTitle: string,
    newFooterContentObject: any
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.componentData[
      newFooterContentTitle
    ] = newFooterContentObject;
    return newInputObject;
  };
  changeFooterContentPieceHasProperty = (
    currentInputObject: any,
    contentInput: string,
    itemToHave: string,
    newHasValue: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.componentData[contentInput][itemToHave] = newHasValue;
    return newInputObject;
  };

  setSocialLinks = ({
    fieldTitle = { default: "Social networks" },
    label = { default: "Copy and paste the links:" },
    labelTooltip = { default: "Icons will be applied automatically." },
    fieldInfo = { default: "Twitter, Facebook, Product Hunt, etc." },
    priority = 50,
    hasTitle = false,
    title = "Follow us",
    isDefaultEmpty = false,
    items = [
      {
        href: urls.twitter,
      },
      {
        href: urls.productHuntV2,
      },
      {
        href: "",
      },
    ],
  } = {}) => {
    return {
      socialLinks: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,
        hasTitle: hasTitle,
        content: {
          title: isDefaultEmpty ? "" : title,
          items: isDefaultEmpty
            ? [
                {
                  href: "",
                },
              ]
            : items,
        },
      },
    };
  };

  changeSocialLinksTitle = (currentInputObject: any, newTitle: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.socialLinks.content.title = newTitle;
    return newInputObject;
  };

  changeSocialLinkHref = (
    currentInputObject: any,
    index: number,
    newHref: string
  ) => {
    let newInputObject = { ...currentInputObject };

    newInputObject.socialLinks.content.items[index].href = newHref; // we need to add the href BEFORE checking if a new empty input is needed
    newInputObject = this.addEmptySocialLinkIfNeeded(newInputObject);

    return newInputObject;
  };
  addEmptySocialLinkIfNeeded = (currentInputObject: any) => {
    let newInputObject = { ...currentInputObject };

    let inputObjectArray = newInputObject.socialLinks.content.items;
    let inputObjectArrayLength = inputObjectArray.length;
    let lastInput = inputObjectArray[inputObjectArrayLength - 1];
    if (lastInput === undefined || lastInput.href !== "") {
      // if last input was edited we need to create another last input for user to fill in.
      // we don't have an "add link" button - instead, we simply create an empty ghost link inputs group for user to fill in.
      let clonedLinkItem = { href: "" };

      newInputObject.socialLinks.content.items.push(clonedLinkItem);

      return newInputObject;
    } else {
      // if no addition is needed, simply pass the original object.
      return currentInputObject;
    }
  };
  deleteSocialLink = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.socialLinks.content.items.splice(index, 1);
    newInputObject = this.addEmptySocialLinkIfNeeded(newInputObject);
    return newInputObject;
  };
  moveSocialLinkUp = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentSocialLinkArray = newInputObject.socialLinks.content.items;
    let newSocialLinkArray = [...currentSocialLinkArray];
    moveArrayItem(newSocialLinkArray, index, index - 1);
    newInputObject.socialLinks.content.items = newSocialLinkArray;
    return newInputObject;
  };
  moveSocialLinkDown = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentSocialLinkArray = newInputObject.socialLinks.content.items;
    let newSocialLinkArray = [...currentSocialLinkArray];
    moveArrayItem(newSocialLinkArray, index, index + 1);
    newInputObject.socialLinks.content.items = newSocialLinkArray;
    return newInputObject;
  };

  setUpContacts = ({
    fieldTitle = { default: "Contact info items" },
    label = { default: "Add contacts:" },
    labelTooltip = { default: "" },
    fieldInfo = { default: "Email, phone or address." },
    priority = 45, // a little less than social links

    maxItems = 6,

    hasMap = false,
    mapHasTitle = false,
    isMapEmbedded = false, //if false map is a clickable URL
    hasContactsItems = false,
    contactsItemHasTitle = false,

    isDefaultEmpty = false,
    map = {
      mapTitle: "Visit Our Place!",
      url: "https://goo.gl/maps/F2pQZPq4tYXSoBX38",
    },
    items = [
      {
        title: "Email us:",
        body: "hi@unicornplatform.com",
      },
      {
        title: "Tel:",
        body: "+1 212-777-1000",
      },
      {
        title: "Address:",
        body: "45 Grand Central Terminal, NY 10017, USA",
      },
      {
        title: "",
        body: "",
      },
    ],
  } = {}) => {
    return {
      contacts: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,
        maxItems: maxItems,
        hasContactsItems: hasContactsItems,
        hasMap: hasMap,
        contactsItemHasTitle: contactsItemHasTitle,
        mapHasTitle: mapHasTitle,
        isMapEmbedded: isMapEmbedded,
        content: {
          items: isDefaultEmpty
            ? [
                {
                  title: "",
                  body: "",
                },
              ]
            : items,
          map: map,
        },
      },
    };
  };

  changeContactsMapTitle = (
    currentInputObject: any,
    newContactsMapTitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.contacts.content.map.mapTitle = newContactsMapTitle;
    return newInputObject;
  };
  changeContactsMapURL = (
    currentInputObject: any,
    newContactsMapURL: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.contacts.content.map.url = newContactsMapURL;
    return newInputObject;
  };

  changeContactItemTitle = (
    currentInputObject: any,
    index: number,
    newTitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.contacts.content.items[index].title = newTitle;
    return newInputObject;
  };
  changeContactItemBody = (
    currentInputObject: any,
    index: number,
    newBody: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.contacts.content.items[index].body = newBody;
    newInputObject = this.addEmptyContactIfNeeded(newInputObject);

    return newInputObject;
  };

  addEmptyContactIfNeeded = (currentInputObject: any) => {
    let newInputObject = { ...currentInputObject };

    let inputObjectArray = newInputObject.contacts.content.items;
    let inputObjectArrayLength = inputObjectArray.length;
    let lastInput = inputObjectArray[inputObjectArrayLength - 1];

    let isCurrentItemsCountEqualsMax: boolean;
    if (inputObjectArrayLength === currentInputObject.maxItems) {
      isCurrentItemsCountEqualsMax = true;
    } else {
      isCurrentItemsCountEqualsMax = false;
    }

    if (
      lastInput === undefined ||
      (lastInput.body !== "" && isCurrentItemsCountEqualsMax === false)
    ) {
      // if last input was edited we need to create another last input for user to fill in.
      // we don't have an "add link" button - instead, we simply create an empty ghost link inputs group for user to fill in.
      let clonedLinkItem = { body: "" };

      newInputObject.contacts.content.items.push(clonedLinkItem);

      return newInputObject;
    } else {
      // if no addition is needed, simply pass the original object.
      return currentInputObject;
    }
  };
  deleteContact = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.contacts.content.items.splice(index, 1);
    newInputObject = this.addEmptyContactIfNeeded(newInputObject);
    return newInputObject;
  };
  moveContactUp = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentContactArray = newInputObject.contacts.content.items;
    let newContactArray = [...currentContactArray];
    moveArrayItem(newContactArray, index, index - 1);
    newInputObject.contacts.content.items = newContactArray;
    return newInputObject;
  };
  moveContactDown = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentContactArray = newInputObject.contacts.content.items;
    let newContactArray = [...currentContactArray];
    moveArrayItem(newContactArray, index, index + 1);
    newInputObject.contacts.content.items = newContactArray;
    return newInputObject;
  };

  createFaqContentObject = ({
    title = "What is Unicorn Platform?",
    text = "Unicorn Platform is a powerful website builder for startups, solo-entrepreneurs and hackers.",
    subtitle = "",
    isHighlighted = false,
  } = {}) => {
    return {
      title: title,
      text: text,
      subtitle: subtitle,
      isHighlighted: isHighlighted,
    };
  };

  setUpFaq = ({
    fieldTitle = { default: "F.A.Q." },
    label = { default: "Edit the F.A.Q. items:" },
    labelTooltip = {
      default: "Leave the title field blank to remove an item.",
    },
    fieldInfo = { default: "" },
    priority = 500,
    isDefaultEmpty = false,

    maxItems = 999,

    hasSubtitle = false,
    hasHighlight = false, //some Faq components offer highlighting an item (e.g. with a border).
    hasHighlightInfo = false,

    items = [
      this.createFaqContentObject({
        title: "What is Unicorn Platform?",
        text:
          "Unicorn Platform is a landing page builder for startups and makers. You can quickly create a beautiful website for a mobile/desktop app, for a SaaS product, for a plugin, an open-source project, a SMM tool etc.",
      }),

      this.createFaqContentObject({
        title: "What are the advantages on this builder?",
        text:
          "The key advantage is the approach. We are building a landing page generator for startups only. That means, we pay all the attention to startups-only demands. In particular, we will provide not-so-popular integrations, specific components and templates.",
      }),
      this.createFaqContentObject({
        title:
          "Do I need to know HTML/CSS to build a website with Unicorn Platform?",
        text:
          "Zero coding skills required to build a website. Although, if you are allowed to add any custom code to tune your website if you want to.",
      }),
      this.createFaqContentObject({
        title: "How to collect emails with Unicorn Platform?",
        text:
          "Use one of the prebuilt forms to collect emails of your visitor. We provide integrations with popular email marketing tools such as Mailchimp so your data will be automatically sent there (we do not store the data so it is safe). We also keep creating integrations with other popular services, so if you need something specific - just ask.",
      }),

      // The last ghost item which is used to add new items.
      this.createFaqContentObject({
        title: "",
        text: "",
      }),
    ],
  } = {}) => {
    return {
      faq: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,

        maxItems: maxItems, //not used at this moment
        hasSubtitle: hasSubtitle,
        hasHighlight: hasHighlight,
        hasHighlightInfo: hasHighlightInfo,
        settings: {
          editor: defaultTextEditorType,
        },
        content: {
          items: isDefaultEmpty
            ? [
                this.createFaqContentObject({
                  title: "",
                  text: "",
                }),
              ]
            : items,
        },
      },
    };
  };

  addEmptyFaqIfNeeded = (currentInputObject: any) => {
    let newInputObject = { ...currentInputObject };

    let inputObjectArray = newInputObject.faq.content.items;
    let inputObjectArrayLength = inputObjectArray.length;
    let lastInput = inputObjectArray[inputObjectArrayLength - 1];
    if (lastInput === undefined || lastInput.title !== "") {
      // if last input was edited we need to create another last input for user to fill in.
      // we don't have an "add faq item" button - instead, we simply create an empty ghost item inputs group for user to fill in.
      let firstFaqItem = { ...newInputObject.faq.content.items[0] };

      let clonedFaqItem = this.createFaqContentObject({
        title: "",
        subtitle:
          lastInput !== undefined
            ? firstFaqItem.subtitle
            : "What are the advantages on this builder",
        text:
          lastInput !== undefined
            ? firstFaqItem.text
            : "The key advantage is the approach. We are building a landing page generator for startups only. That means, we pay all the attention to startups-only demands. In particular, we will provide not-so-popular integrations, specific components and templates. Uh, and Unicorn magic.",
        isHighlighted: false,
      });

      newInputObject.faq.content.items.push(clonedFaqItem);

      return newInputObject;
    } else {
      // if no addition is needed, simply pass the original object.
      return currentInputObject;
    }
  };

  changeFaqTitle = (
    currentInputObject: any,
    index: number,
    newTitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject = this.addEmptyFaqIfNeeded(newInputObject);
    newInputObject.faq.content.items[index].title = newTitle;

    return newInputObject;
  };
  changeFaqSubtitle = (
    currentInputObject: any,
    index: number,
    newSubtitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject = this.addEmptyFaqIfNeeded(newInputObject);
    newInputObject.faq.content.items[index].subtitle = newSubtitle;

    return newInputObject;
  };
  changeFaqText = (currentInputObject: any, index: number, newText: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject = this.addEmptyFaqIfNeeded(newInputObject);
    newInputObject.faq.content.items[index].text = newText;

    return newInputObject;
  };

  deleteFaq = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.faq.content.items.splice(index, 1);
    newInputObject = this.addEmptyFaqIfNeeded(newInputObject);
    return newInputObject;
  };

  moveFaqItemUp = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentFaqArray = newInputObject.faq.content.items;
    let newFaqArray = [...currentFaqArray];
    moveArrayItem(newFaqArray, index, index - 1);
    newInputObject.faq.content.items = newFaqArray;
    return newInputObject;
  };
  moveFaqItemDown = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentFaqArray = newInputObject.faq.content.items;
    let newFaqArray = [...currentFaqArray];
    moveArrayItem(newFaqArray, index, index + 1);
    newInputObject.faq.content.items = newFaqArray;
    return newInputObject;
  };

  createPostsContentObject = ({
    title = "How to Be More Creative: 10 Actionable Tips",
    subtitle = "Read the story in our company blog.",
    text = "Donec facilisis tortor ut augue lacinia, at viverra est semper. Sed sapien metus, scelerisque nec pharetra id, tempor a tortor.",
    isHighlighted = false,
    href = "#", // if the posts object hasButton === false, then the whole item is clickable, so use href to determine target.
    isTargetBlank = true,
    pictogram = this.createPictogramContentObject(),
    button = this.createButtonContentObject({
      buttonType: linkOrButtonOptions.link,
      buttonTitle: "",
      buttonHref: "",
      buttonStyleType: buttonStyles.ghost.typeTitle,
      ghostColor: buttonStyles.ghost.accentOutline,
      buttonIsTargetBlank: true,
    }),
    thumb = this.createGraphicsContentObject({
      imageUrl:
        "https://ucarecdn.com/64ece1b9-e6f3-430e-8501-7f54a2b5581f/alexanderandrewsfsH1KjbdjE8unsplash.jpg",
    }),
    bgColor = "#dbfff5",
    logo = this.createLogoContentObject(),
  } = {}) => {
    return {
      title: title,
      subtitle: subtitle,
      text: text,
      isHighlighted: isHighlighted,
      pictogram: pictogram,
      button: button,
      href: href,
      isTargetBlank: isTargetBlank,
      thumb: thumb,
      logo: logo,
      bgColor: bgColor,
    };
  };

  setUpPosts = ({
    fieldTitle = { default: "Posts" },
    label = { default: "Edit the posts:" },
    labelTooltip = { default: "" },
    fieldInfo = { default: "" },
    priority = 500,

    maxItems = 999,
    hasThumb = true,
    hasTitle = true,
    hasSubtitle = true,

    hasText = false,
    hasPictogram = false,
    hasHighlight = false, //some Posts components offer highlighting an item (e.g. with a border).
    hasHighlightInfo = false,
    hasBgColor = false,
    hasButton = false, //if false then the whole item is clickable
    hasTags = false,
    hasLogo = false,

    buttonTypes = [buttonStyles.ghost.typeTitle],
    isDefaultEmpty = false,

    items = [
      this.createPostsContentObject({
        title: "How to Be More Creative: 10 Actionable Tips",
        subtitle: "Read the story in our company blog.",
        thumb: this.createGraphicsContentObject({
          imageUrl: DEFAULT_IMAGES_CDN.PHOTO_01,
          width: 500,
          height: 750,
        }),
      }),

      this.createPostsContentObject({
        title:
          "Design Trends of " +
          monthNames[new Date().getMonth()] +
          ", " +
          new Date().getFullYear(), // the title looks fresh everytime.
        subtitle: "Brutal design and pantone colors.",
        thumb: this.createGraphicsContentObject({
          imageUrl: DEFAULT_IMAGES_CDN.PHOTO_02,
          width: 500,
          height: 750,
        }),
      }),

      this.createPostsContentObject({
        title:
          "How I Learned to Stop Worrying and Love Figma: 5 Advices for Beginners    ",
        subtitle: "",
        thumb: this.createGraphicsContentObject({
          imageUrl: DEFAULT_IMAGES_CDN.PHOTO_03,
          width: 500,
          height: 750,
        }),
      }),
    ],
  } = {}) => {
    return {
      posts: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,

        maxItems: maxItems, //not used at this moment
        buttonTypes: buttonTypes,
        hasSubtitle: hasSubtitle,
        hasText: hasText,
        hasPictogram: hasPictogram,
        hasHighlight: hasHighlight,
        hasHighlightInfo: hasHighlightInfo,
        hasButton: hasButton,
        hasThumb: hasThumb,
        hasTitle: hasTitle,

        settings: {
          editor: defaultTextEditorType,
        },

        hasTags: hasTags,
        hasLogo: hasLogo,

        hasBgColor: hasBgColor,

        content: {
          items: isDefaultEmpty ? [] : items,
        },
      },
    };
  };

  addEmptyPostIfNeeded = (currentInputObject: any) => {
    let newInputObject = { ...currentInputObject };

    // let inputObjectArray = newInputObject.posts.content.items;
    // let inputObjectArrayLength = inputObjectArray.length;
    // let lastInput = inputObjectArray[inputObjectArrayLength - 1];
    let firstPostsItem = newInputObject.posts.content.items[0];

    if (firstPostsItem === undefined) {
      let newPostsItem = this.createPostsContentObject();

      newInputObject.posts.content.items.push(newPostsItem);

      return newInputObject;
    } else {
      let clonedPostsItem = this.createPostsContentObject({
        title: firstPostsItem.title,
        text: firstPostsItem.text,
        href: firstPostsItem.href,
        // Uncomment and test when have some posts with buttons to test.
        // button: this.createButtonContentObject({
        //     buttonType: linkOrButtonOptions.link,
        //     buttonTitle: firstPostsItem.button.title,
        //     buttonHref: '',
        //     ghostColor: buttonStyles.ghost.accentOutline,
        //     buttonStyleType: firstPostsItem.button.settings.appearance,
        //     buttonIsTargetBlank: firstPostsItem.button.isTargetBlank
        // }),
        // tags: {...firstPostsItem.tags},
        isHighlighted: false,
        isTargetBlank: firstPostsItem.isTargetBlank,
        pictogram: this.createPictogramContentObject({
          type: firstPostsItem.pictogram.type,
        }),
        thumb: this.createGraphicsContentObject(),
      });

      newInputObject.posts.content.items.push(clonedPostsItem);

      return newInputObject;
    }
  };

  changePostTitle = (
    currentInputObject: any,
    index: number,
    newTitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    // newInputObject = this.addEmptyPostIfNeeded(newInputObject);
    newInputObject.posts.content.items[index].title = newTitle;

    return newInputObject;
  };
  changePostHref = (
    currentInputObject: any,
    index: number,
    newHref: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].href = newHref;
    newInputObject.posts.content.items[index].button.href = newHref; // we keep them in sync. If a Post template doesn't offer a button, it's clickable itself.
    return newInputObject;
  };
  changePostBgColor = (
    currentInputObject: any,
    index: number,
    newColor: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].bgColor = newColor;
    return newInputObject;
  };
  changePostIsTargetBlank = (
    currentInputObject: any,
    index: number,
    isTargetBlank: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].isTargetBlank = isTargetBlank;

    return newInputObject;
  };
  changePostSubtitle = (
    currentInputObject: any,
    index: number,
    newSubtitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].subtitle = newSubtitle;

    return newInputObject;
  };
  changePostText = (
    currentInputObject: any,
    index: number,
    newText: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].text = newText;

    return newInputObject;
  };
  changePostButtonHref = (
    currentInputObject: any,
    index: number,
    newHref: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].button.href = newHref;
    newInputObject.posts.content.items[index].href = newHref; // we keep them in sync. If a Post template doesn't offer a button, it's clickable itself.

    return newInputObject;
  };
  changePostButtonIsTargetBlank = (
    currentInputObject: any,
    index: number,
    isTargetBlank: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[
      index
    ].button.isTargetBlank = isTargetBlank;

    return newInputObject;
  };
  changePostButtonTitle = (
    currentInputObject: any,
    index: number,
    title: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].button.title = title;

    return newInputObject;
  };

  changePostIsHighlighted = (
    currentInputObject: any,
    index: number,
    isHighlighted: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].isHighlighted = isHighlighted;

    return newInputObject;
  };

  // Note: index comes last here because it's an optional argument in the buttonGhostColorChangeHandler() (<ContentInput/>).
  changePostButtonGhostColor = (
    currentInputObject: any,
    newColor: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[
      index
    ].button.settings.appearance.ghostColor = newColor;

    return newInputObject;
  };
  changePostButtonColor = (
    currentInputObject: any,
    newColor: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[
      index
    ].button.settings.appearance.color = newColor;

    return newInputObject;
  };
  changePostButtonPillColor = (
    currentInputObject: any,
    newColor: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[
      index
    ].button.settings.appearance.pillColor = newColor;

    return newInputObject;
  };
  changePostButtonPillPrefix = (
    currentInputObject: any,
    newPrefix: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].button.pillPrefix = newPrefix;
    return newInputObject;
  };

  changePostButtonMobileAppStoreType = (
    currentInputObject: any,
    newMobileAppStoreType: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[
      index
    ].button.settings.appearance.mobileAppStoreType = newMobileAppStoreType;

    return newInputObject;
  };
  changePostButtonStyleType = (
    currentInputObject: any,
    newStyleType: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[
      index
    ].button.settings.appearance.styleType = newStyleType;

    return newInputObject;
  };

  changePostButtonIsRelayUTM = (
    currentInputObject: any,
    index: number,
    isRelayUTM: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].button.isRelayUTM = isRelayUTM;

    return newInputObject;
  };

  changePostButtonID = (
    currentInputObject: any,
    index: number,
    custom_id: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[
      index
    ].button.settings.custom_id = custom_id;

    return newInputObject;
  };
  changePostButtonClass = (
    currentInputObject: any,
    index: number,
    classes: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].button.settings.classes = classes;

    return newInputObject;
  };
  changePostButtonOnClick = (
    currentInputObject: any,
    index: number,
    onclick: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].button.settings.onclick = onclick;

    return newInputObject;
  };
  changePostButtonAttributes = (
    currentInputObject: any,
    index: number,
    attributes: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[
      index
    ].button.settings.attributes = attributes;

    return newInputObject;
  };

  changePostButtonStripeProductId = (
    currentInputObject: any,
    index: number,
    stripeProductId: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[
      index
    ].button.stripeProductId = stripeProductId;

    return newInputObject;
  };
  changePostButtonStripePaymentMode = (
    currentInputObject: any,
    index: number,
    stripePaymentMode: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[
      index
    ].button.stripePaymentMode = stripePaymentMode;

    return newInputObject;
  };
  changePostButtonSuccessfulPaymentUrl = (
    currentInputObject: any,
    index: number,
    successfulPaymentUrl: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[
      index
    ].button.successfulPaymentUrl = successfulPaymentUrl;

    return newInputObject;
  };
  changePostButtonCancelPaymentUrl = (
    currentInputObject: any,
    index: number,
    cancelPaymentUrl: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[
      index
    ].button.cancelPaymentUrl = cancelPaymentUrl;

    return newInputObject;
  };

  changePostPictogramType = (
    currentInputObject: any,
    pictogramType: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].pictogram.type = pictogramType;

    return newInputObject;
  };
  changePostPictogramEmojiSrc = (
    currentInputObject: any,
    emojiSrc: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].pictogram.emojiSrc = emojiSrc;

    return newInputObject;
  };
  changePostPictogramLineaIconSrc = (
    currentInputObject: any,
    lineaIconSrc: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[
      index
    ].pictogram.lineaIconSrc = lineaIconSrc;

    return newInputObject;
  };
  changePostPictogramAbstractIconId = (
    currentInputObject: any,
    abstractIconId: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[
      index
    ].pictogram.abstractIconId = abstractIconId;

    return newInputObject;
  };

  changePostPictogramUploadedSrc = (
    currentInputObject: any,
    uploadedSrc: string,
    index: number,
    width?: number,
    height?: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[
      index
    ].pictogram.uploadedSrc = uploadedSrc;
    newInputObject.posts.content.items[index].pictogram.width = width;
    newInputObject.posts.content.items[index].pictogram.height = height;

    return newInputObject;
  };
  changePostPictogramUploadedUUID = (
    currentInputObject: any,
    uploadedUUID: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[
      index
    ].pictogram.uploadedUUID = uploadedUUID;

    return newInputObject;
  };
  changePostPictogramUploadedAlt = (
    currentInputObject: any,
    alt: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].pictogram.alt = alt;

    return newInputObject;
  };
  changePostPictogramUploadedHeight = (
    currentInputObject: any,
    uploadedHeight: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[
      index
    ].pictogram.uploadedHeight = uploadedHeight;

    return newInputObject;
  };

  changePostImageUrl = (
    currentInputObject: any,
    newUrl: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    // newInputObject = this.addEmptyPostIfNeeded(newInputObject);
    newInputObject.posts.content.items[index].thumb.image.url = newUrl;

    return newInputObject;
  };
  changePostImageUUID = (
    currentInputObject: any,
    newUUID: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].thumb.image.uuid = newUUID;

    return newInputObject;
  };
  changePostImageWidth = (
    currentInputObject: any,
    newWidth: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].thumb.image.width = newWidth;

    return newInputObject;
  };
  changePostImageHeight = (
    currentInputObject: any,
    newHeight: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].thumb.image.height = newHeight;

    return newInputObject;
  };
  changePostImageAlt = (
    currentInputObject: any,
    newAlt: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].thumb.image.alt = newAlt;

    return newInputObject;
  };
  changePostLogoUploadedSrc = (
    currentInputObject: any,
    uploadedSrc: string,
    index: number,
    width: number,
    height: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].logo.uploadedSrc = uploadedSrc;
    newInputObject.posts.content.items[index].logo.source_width = width;
    newInputObject.posts.content.items[index].logo.source_height = height;

    return newInputObject;
  };
  changePostLogoUUID = (
    currentInputObject: any,
    UUID: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].logo.uuid = UUID;

    return newInputObject;
  };
  changePostLogoAlt = (currentInputObject: any, alt: string, index: number) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].logo.alt = alt;

    return newInputObject;
  };
  changePostLogoHeight = (
    currentInputObject: any,
    height: number,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].logo.height = height;

    return newInputObject;
  };
  changePostLogoType = (
    currentInputObject: any,
    logoType: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items[index].logo.type = logoType;

    return newInputObject;
  };

  deletePost = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.posts.content.items.splice(index, 1);
    // newInputObject = this.addEmptyPostIfNeeded(newInputObject);
    return newInputObject;
  };
  movePostItemUp = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentPostArray = newInputObject.posts.content.items;
    let newPostArray = [...currentPostArray];
    moveArrayItem(newPostArray, index, index - 1);
    newInputObject.posts.content.items = newPostArray;
    return newInputObject;
  };
  movePostItemDown = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentPostArray = newInputObject.posts.content.items;
    let newPostArray = [...currentPostArray];
    moveArrayItem(newPostArray, index, index + 1);
    newInputObject.posts.content.items = newPostArray;
    return newInputObject;
  };

  //Slider or Tabs
  createGalleryContentObject = ({
    tabTitle = "Authentic design",
    tabSubtitle = "Authentic design",
    tabPictogram = this.createPictogramContentObject(),

    contentTitle = "Authentic design",
    contentSubtitle = "Subtitle",
    contentText = "Unicorn Platform comes with dozens of hand-made templates.",

    activeCtaOption = callToActionOptions.buttons, //see availableOptions.ts to view available options for a particular category or component.

    //Todo: form
    // formButtonTitle = 'Submit',
    // formButtonStyleType = buttonStyles.regular.typeTitle,
    ctaButtons = [
      this.createButtonContentObject({
        buttonType: linkOrButtonOptions.link,
        buttonTitle: "Learn more",
        buttonHref: "/about-product",
        buttonStyleType: buttonStyles.ghost.typeTitle,
        buttonColor: buttonStyles.ghost.accentOutline,
        buttonIsTargetBlank: false,
        pillPrefix: "Whoa!",
      }),

      // ghost object
      this.createButtonContentObject({
        buttonType: linkOrButtonOptions.link,
        buttonTitle: "",
        buttonHref: "",
        buttonStyleType: buttonStyles.ghost.typeTitle,
        buttonColor: buttonStyles.ghost.accentOutline,
        buttonIsTargetBlank: false,
        pillPrefix: "View",
      }),
    ],
    // this.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "", buttonHref: '', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline, buttonIsTargetBlank: true}),
    contentGraphic = [
      this.createGraphicsContentObject({
        imageUrl: "",
      }),
    ],

    // tags = [createTagContentObject()]
    // text
  } = {}) => {
    return {
      tabTitle: tabTitle,
      tabSubtitle: tabSubtitle,
      tabPictogram: tabPictogram,

      activeCtaOption: activeCtaOption,

      contentTitle: contentTitle,
      contentSubtitle: contentSubtitle,
      contentText: contentText,
      contentGraphic: contentGraphic,
      settings: {
        editor: defaultTextEditorType,
      },
      contentCta: {
        [callToActionOptions.buttons]: ctaButtons,
        // Todo: form
        // [callToActionOptions.form]: this.createFormContentObject({
        //     "buttonTitle": formButtonTitle,
        //     "buttonStyleType": formButtonStyleType
        // }),
      },
    };
  };

  // Use the 'gallery' content element to display a 'slider' or a 'tabs' component.
  setUpGallery = ({
    fieldTitle = { default: "Image slider" },
    label = { default: "Edit the slides:" },
    labelTooltip = { default: "" },
    fieldInfo = { default: "" },
    priority = 400,
    isDefaultEmpty = false,

    maxItems = 7,

    maxCtaButtons = 3,

    tabHasPictogram = false,
    tabHasTitle = false,
    tabHasSubtitle = false,

    contentHasTitle = false,
    contentHasSubtitle = false,
    contentHasText = false,
    contentHasCta = false,
    contentHasGraphic = true, // All false but graphic by default - for an image slider component.

    buttonTypes = [
      buttonStyles.regular.typeTitle,
      buttonStyles.ghost.typeTitle,
      buttonStyles.mobileAppStore.typeTitle,
      buttonStyles.pill.typeTitle,
    ],

    userCanAddGraphicItems = false,
    maxGraphicItems = 1,

    items = [
      this.createGalleryContentObject({
        contentGraphic: [
          this.createGraphicsContentObject({
            imageUrl: DEFAULT_IMAGES_CDN.UI_DESKTOP_DASHBOARD_1,
            videoUrl: "https://www.youtube.com/watch?v=tw4jkyfY4HE",
            width: 1280,
            height: 800,
          }),
        ],
      }),
      this.createGalleryContentObject({
        contentGraphic: [
          this.createGraphicsContentObject({
            imageUrl: DEFAULT_IMAGES_CDN.UI_DESKTOP_CALENDAR,
            videoUrl: "https://www.youtube.com/watch?v=J2U9Hmmpqhc",
            width: 1280,
            height: 800,
          }),
        ],
      }),
      this.createGalleryContentObject({
        contentGraphic: [
          this.createGraphicsContentObject({
            imageUrl: DEFAULT_IMAGES_CDN.UI_DESKTOP_FINANCE,
            videoUrl: "https://www.youtube.com/watch?v=lfucQM07tGQ",
            width: 1280,
            height: 800,
          }),
        ],
      }),
      this.createGalleryContentObject({
        contentGraphic: [
          this.createGraphicsContentObject({
            imageUrl: DEFAULT_IMAGES_CDN.UI_DESKTOP_CRM,
            videoUrl: "https://www.youtube.com/watch?v=4_sLTe6-7SE",
            width: 1280,
            height: 800,
          }),
        ],
      }),
    ],
  } = {}) => {
    return {
      gallery: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,

        maxItems: maxItems,
        maxCtaButtons: maxCtaButtons,

        tabHasPictogram: tabHasPictogram,
        tabHasTitle: tabHasTitle,
        tabHasSubtitle: tabHasSubtitle,

        contentHasTitle: contentHasTitle,
        contentHasSubtitle: contentHasSubtitle,
        contentHasText: contentHasText,
        contentHasCta: contentHasCta,
        contentHasGraphic: contentHasGraphic,
        buttonTypes: buttonTypes,
        settings: {
          editor: defaultTextEditorType,
        },
        userCanAddGraphicItems: userCanAddGraphicItems,

        content: {
          items: isDefaultEmpty ? [] : items,
        },
      },
    };
  };

  addEmptyGalleryIfNeeded = (currentInputObject: any) => {
    let newInputObject = { ...currentInputObject };

    let inputObjectArray = newInputObject.gallery.content.items;
    let inputObjectArrayLength = inputObjectArray.length;

    let isCurrentItemsCountEqualsMax: boolean;
    if (inputObjectArrayLength === currentInputObject.maxItems) {
      isCurrentItemsCountEqualsMax = true;
    } else {
      isCurrentItemsCountEqualsMax = false;
    }

    if (newInputObject.gallery.content.items[0] === undefined) {
      let clonedGalleryItem = this.createGalleryContentObject({});

      newInputObject.gallery.content.items.push(clonedGalleryItem);

      return newInputObject;
    } else if (isCurrentItemsCountEqualsMax === false) {
      // We have an "add gallery item" button because in the Slider option an item doesn't have any attributes (no title, pictogram etc).
      let firstGalleryItem = { ...newInputObject.gallery.content.items[0] };
      let clonedItemButtons = [];

      let firstItemButtonsCount = firstGalleryItem.contentCta.buttons.length;
      for (let i = 0; i < firstItemButtonsCount; i++) {
        let currentButton = firstGalleryItem.contentCta.buttons[i];
        clonedItemButtons.push(
          this.createButtonContentObject({
            buttonType: currentButton.type,
            buttonTitle: currentButton.title,
            buttonHref: currentButton.href,
            buttonStyleType: currentButton.settings.appearance.styleType,
            buttonColor: currentButton.settings.appearance.color,
            buttonIsTargetBlank: currentButton.isTargetBlank,
            pillPrefix: currentButton.pillPrefix,
          })
        );
      }

      // if(firstGalleryItemButton && firstGalleryItemButton.href !== '' && firstGalleryItemButton.title !== ''){
      //     // If the first element has a first button, clone it to the new cloned gallery item
      //     clonedItemButtons.unshift(this.createButtonContentObject({
      //         buttonType: firstGalleryItemButton.type,
      //         buttonTitle: firstGalleryItemButton.title,
      //         buttonHref: firstGalleryItemButton.href,
      //         buttonStyleType: firstGalleryItemButton.settings.appearance.styleType,
      //         buttonColor: firstGalleryItemButton.settings.appearance.color,
      //         buttonIsTargetBlank: firstGalleryItemButton.isTargetBlank,
      //         pillPrefix: firstGalleryItemButton.pillPrefix
      //     }))
      // }

      let clonedGalleryItem = this.createGalleryContentObject({
        tabTitle: firstGalleryItem.tabTitle,
        tabSubtitle: firstGalleryItem.tabSubtitle,
        tabPictogram: this.createPictogramContentObject({
          type: firstGalleryItem.tabPictogram.type,
        }),

        activeCtaOption: firstGalleryItem.activeCtaOption,

        contentTitle: firstGalleryItem.contentTitle,
        contentSubtitle: firstGalleryItem.contentSubtitle,
        contentText: firstGalleryItem.contentText,
        contentGraphic: [
          this.createGraphicsContentObject({
            imageUrl: "",
          }),
        ],
        ctaButtons: clonedItemButtons,
      });

      newInputObject.gallery.content.items.push(clonedGalleryItem);

      return newInputObject;
    } else {
      // if no addition is needed, simply pass the original object.
      return currentInputObject;
    }
  };

  changeGalleryTabTitle = (
    currentInputObject: any,
    index: number,
    newTitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[index].tabTitle = newTitle;

    return newInputObject;
  };
  changeGalleryTabSubtitle = (
    currentInputObject: any,
    index: number,
    newSubtitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[index].tabSubtitle = newSubtitle;

    return newInputObject;
  };

  changeGalleryContentTitle = (
    currentInputObject: any,
    index: number,
    newTitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[index].contentTitle = newTitle;

    return newInputObject;
  };
  changeGalleryContentSubtitle = (
    currentInputObject: any,
    index: number,
    newSubtitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[index].contentSubtitle = newSubtitle;

    return newInputObject;
  };
  changeGalleryContentText = (
    currentInputObject: any,
    index: number,
    newText: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[index].contentText = newText;

    return newInputObject;
  };

  changeGalleryButtonHref = (
    currentInputObject: any,
    galleryItemIndex: number,
    newHref: string,
    buttonIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons[
      buttonIndex
    ].href = newHref;
    this.addEmptyGalleryButtonIfNeeded(currentInputObject, galleryItemIndex);

    return newInputObject;
  };
  changeGalleryButtonIsTargetBlank = (
    currentInputObject: any,
    galleryItemIndex: number,
    isTargetBlank: boolean,
    buttonIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons[
      buttonIndex
    ].isTargetBlank = isTargetBlank;

    return newInputObject;
  };
  changeGalleryButtonTitle = (
    currentInputObject: any,
    galleryItemIndex: number,
    title: string,
    buttonIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons[
      buttonIndex
    ].title = title;
    this.addEmptyGalleryButtonIfNeeded(currentInputObject, galleryItemIndex);

    return newInputObject;
  };

  // Note: index comes last here because it's an optional argument in the buttonGhostColorChangeHandler() (<ContentInput/>).
  addEmptyGalleryButtonIfNeeded = (
    currentInputObject: any,
    galleryItemIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };

    let inputObjectArray =
      newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons;
    let inputObjectArrayLength = inputObjectArray.length;

    let isCurrentItemsCountEqualsMax: boolean;
    if (inputObjectArrayLength === currentInputObject.gallery.maxCtaButtons) {
      isCurrentItemsCountEqualsMax = true;
    } else {
      isCurrentItemsCountEqualsMax = false;
    }

    let lastInput = inputObjectArray[inputObjectArrayLength - 1];
    if (
      lastInput.href !== "" &&
      lastInput.title !== "" &&
      isCurrentItemsCountEqualsMax === false
    ) {
      const newButtonItem = this.createButtonContentObject();
      newButtonItem.href = "";
      newButtonItem.title = "";

      newInputObject.gallery.content.items[
        galleryItemIndex
      ].contentCta.buttons.push(newButtonItem);

      return newInputObject;
    } else {
      // if no addition is needed, simply pass the original object.
      return currentInputObject;
    }
  };

  changeGalleryButtonGhostColor = (
    currentInputObject: any,
    newColor: string,
    galleryItemIndex: number,
    buttonIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons[
      buttonIndex
    ].settings.appearance.ghostColor = newColor;

    return newInputObject;
  };
  changeGalleryButtonColor = (
    currentInputObject: any,
    newColor: string,
    galleryItemIndex: number,
    buttonIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons[
      buttonIndex
    ].settings.appearance.color = newColor;

    return newInputObject;
  };
  changeGalleryButtonPillColor = (
    currentInputObject: any,
    newColor: string,
    galleryItemIndex: number,
    buttonIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons[
      buttonIndex
    ].settings.appearance.pillColor = newColor;

    return newInputObject;
  };
  changeGalleryButtonPillPrefix = (
    currentInputObject: any,
    newPrefix: string,
    galleryItemIndex: number,
    buttonIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons[
      buttonIndex
    ].pillPrefix = newPrefix;
    return newInputObject;
  };

  changeGalleryButtonIsRelayUTM = (
    currentInputObject: any,
    isRelayUTM: boolean,
    galleryItemIndex: number,
    buttonIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons[
      buttonIndex
    ].isRelayUTM = isRelayUTM;
    return newInputObject;
  };

  changeGalleryButtonID = (
    currentInputObject: any,
    custom_id: string,
    galleryItemIndex: number,
    buttonIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons[
      buttonIndex
    ].settings.custom_id = custom_id;

    return newInputObject;
  };
  changeGalleryButtonClass = (
    currentInputObject: any,
    classes: string,
    galleryItemIndex: number,
    buttonIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons[
      buttonIndex
    ].settings.classes = classes;

    return newInputObject;
  };
  changeGalleryButtonOnClick = (
    currentInputObject: any,
    onclick: string,
    galleryItemIndex: number,
    buttonIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons[
      buttonIndex
    ].settings.onclick = onclick;

    return newInputObject;
  };
  changeGalleryButtonAttributes = (
    currentInputObject: any,
    attributes: string,
    galleryItemIndex: number,
    buttonIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons[
      buttonIndex
    ].settings.attributes = attributes;

    return newInputObject;
  };

  changeGalleryButtonStripeProductId = (
    currentInputObject: any,
    stripeProductId: string,
    galleryItemIndex: number,
    buttonIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons[
      buttonIndex
    ].stripeProductId = stripeProductId;

    return newInputObject;
  };
  changeGalleryButtonStripePaymentMode = (
    currentInputObject: any,
    stripePaymentMode: string,
    galleryItemIndex: number,
    buttonIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons[
      buttonIndex
    ].stripePaymentMode = stripePaymentMode;

    return newInputObject;
  };
  changeGalleryButtonSuccessfulPaymentUrl = (
    currentInputObject: any,
    successfulPaymentUrl: string,
    galleryItemIndex: number,
    buttonIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons[
      buttonIndex
    ].successfulPaymentUrl = successfulPaymentUrl;

    return newInputObject;
  };
  changeGalleryButtonCancelPaymentUrl = (
    currentInputObject: any,
    cancelPaymentUrl: string,
    galleryItemIndex: number,
    buttonIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons[
      buttonIndex
    ].cancelPaymentUrl = cancelPaymentUrl;

    return newInputObject;
  };

  changeGalleryButtonMobileAppStoreType = (
    currentInputObject: any,
    newMobileAppStoreType: string,
    galleryItemIndex: number,
    buttonIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons[
      buttonIndex
    ].settings.appearance.mobileAppStoreType = newMobileAppStoreType;

    return newInputObject;
  };
  changeGalleryButtonStyleType = (
    currentInputObject: any,
    newStyleType: string,
    galleryItemIndex: number,
    buttonIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentCta.buttons[
      buttonIndex
    ].settings.appearance.styleType = newStyleType;

    return newInputObject;
  };

  changeGalleryPictogramType = (
    currentInputObject: any,
    pictogramType: string,
    galleryItemIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[
      galleryItemIndex
    ].tabPictogram.type = pictogramType;

    return newInputObject;
  };
  changeGalleryPictogramEmojiSrc = (
    currentInputObject: any,
    emojiSrc: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[
      index
    ].tabPictogram.emojiSrc = emojiSrc;

    return newInputObject;
  };
  changeGalleryPictogramLineaIconSrc = (
    currentInputObject: any,
    lineaIconSrc: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[
      index
    ].tabPictogram.lineaIconSrc = lineaIconSrc;

    return newInputObject;
  };
  changeGalleryPictogramAbstractIconId = (
    currentInputObject: any,
    abstractIconId: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[
      index
    ].tabPictogram.abstractIconId = abstractIconId;

    return newInputObject;
  };

  changeGalleryPictogramUploadedSrc = (
    currentInputObject: any,
    uploadedSrc: string,
    index: number,
    width?: number,
    height?: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[
      index
    ].tabPictogram.uploadedSrc = uploadedSrc;
    newInputObject.gallery.content.items[index].tabPictogram.width = width;
    newInputObject.gallery.content.items[index].tabPictogram.height = height;

    return newInputObject;
  };
  changeGalleryPictogramUploadedUUID = (
    currentInputObject: any,
    uploadedUUID: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[
      index
    ].tabPictogram.uploadedUUID = uploadedUUID;

    return newInputObject;
  };
  changeGalleryPictogramUploadedAlt = (
    currentInputObject: any,
    alt: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[index].tabPictogram.alt = alt;

    return newInputObject;
  };
  changeGalleryPictogramUploadedHeight = (
    currentInputObject: any,
    uploadedHeight: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[
      index
    ].tabPictogram.uploadedHeight = uploadedHeight;

    return newInputObject;
  };

  changeGalleryImageUrl = (
    currentInputObject: any,
    newUrl: string,
    galleryItemIndex: number,
    graphicItemIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentGraphic[
      graphicItemIndex
    ].image.url = newUrl;

    return newInputObject;
  };
  changeGalleryVideoUrl = (
    currentInputObject: any,
    newUrl: string,
    galleryItemIndex: number,
    graphicItemIndex: number
  ) => {
    const newInputObject = { ...currentInputObject };
    return _.set(
      newInputObject,
      [
        "gallery",
        "content",
        "items",
        galleryItemIndex,
        "contentGraphic",
        graphicItemIndex,
        GraphicsOptions.video,
        "youtube",
        "url",
      ],
      formatVideoSourceUrl(newUrl)
    );
  };
  changeGalleryCodeSnippet = (
    currentInputObject: any,
    newCodeSnippet: string,
    galleryItemIndex: number,
    graphicItemIndex: number
  ) => {
    const newInputObject = { ...currentInputObject };
    return _.set(
      newInputObject,
      [
        "gallery",
        "content",
        "items",
        galleryItemIndex,
        "contentGraphic",
        graphicItemIndex,
        GraphicsOptions.HTML,
        "codeSnippet",
      ],
      newCodeSnippet
    );
  };

  changeGalleryImageUUID = (
    currentInputObject: any,
    newUUID: string,
    galleryItemIndex: number,
    graphicItemIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentGraphic[
      graphicItemIndex
    ].image.uuid = newUUID;

    return newInputObject;
  };
  changeGalleryImageWidth = (
    currentInputObject: any,
    newWidth: string,
    galleryItemIndex: number,
    graphicItemIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentGraphic[
      graphicItemIndex
    ].image.width = newWidth;

    return newInputObject;
  };
  changeGalleryImageHeight = (
    currentInputObject: any,
    newHeight: string,
    galleryItemIndex: number,
    graphicItemIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentGraphic[
      graphicItemIndex
    ].image.height = newHeight;

    return newInputObject;
  };
  changeGalleryImageAlt = (
    currentInputObject: any,
    newAlt: string,
    galleryItemIndex: number,
    graphicItemIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items[galleryItemIndex].contentGraphic[
      graphicItemIndex
    ].image.alt = newAlt;

    return newInputObject;
  };

  deleteGalleryItem = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.gallery.content.items.splice(index, 1);
    return newInputObject;
  };

  moveGalleryItemUp = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentGalleryArray = newInputObject.gallery.content.items;
    let newGalleryArray = [...currentGalleryArray];
    moveArrayItem(newGalleryArray, index, index - 1);
    newInputObject.gallery.content.items = newGalleryArray;
    return newInputObject;
  };
  moveGalleryItemDown = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentGalleryArray = newInputObject.gallery.content.items;
    let newGalleryArray = [...currentGalleryArray];
    moveArrayItem(newGalleryArray, index, index + 1);
    newInputObject.gallery.content.items = newGalleryArray;
    return newInputObject;
  };

  setUpCode = ({
    fieldTitle = { default: "Code snippet" },
    label = { default: "Enter the code:" },
    labelTooltip = { default: "" },
    fieldInfo = { default: "" },
    priority = 200,
    codeSnippet = "const transaction = await uni.transactions.create({\n" +
      "  amount: 48,\n" +
      "  currency: 'ETH',\n" +
      "  email: 'john@doe.com'\n" +
      "});",
    gistUrl = "https://gist.github.com/alexanderisora/e5eee4d915e13fa53dc8779316e41bcf",

    hasGistUrl = false,
    hasCodeSnippet = false,
    hasTitle = false,
    hasSubtitle = false,
    title = "Simple Code",
    subtitle = "Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu.",
    isDefaultEmpty = false,
  } = {}) => {
    return {
      code: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,
        hasGistUrl: hasGistUrl,
        hasCodeSnippet: hasCodeSnippet,
        hasTitle: hasTitle,
        hasSubtitle: hasSubtitle,
        content: {
          codeSnippet: isDefaultEmpty ? "" : codeSnippet,
          gistUrl: isDefaultEmpty ? "" : gistUrl,
          title: isDefaultEmpty ? "" : title,
          subtitle: isDefaultEmpty ? "" : subtitle,
        },
      },
    };
  };

  changeCodeData = (
    currentInputObject: any,
    dataType: CodeInputData,
    newValue: string
  ) => {
    const newInputObject = { ...currentInputObject };
    _.set(newInputObject, `code.content.${dataType}`, newValue);
    return newInputObject;
  };

  createStepContentObject = ({
    title = "Create profile",
    subtitle = "",
    text = "Eheu, competition! Sunt apolloniateses vitare salvus, varius tuses. Bi-color boreas nunquam dignuss terror est. Sunt nomenes consumere flavum, barbatus lunaes.",
    button = this.createButtonContentObject({
      buttonType: linkOrButtonOptions.link,
      buttonTitle: "",
      buttonHref: "",
      buttonStyleType: buttonStyles.ghost.typeTitle,
      ghostColor: buttonStyles.ghost.accentOutline,
      buttonIsTargetBlank: false,
    }),
    graphics = this.createGraphicsContentObject({
      imageUrl:
        publicUrl +
        "/img/screenshots/dashboard-concept.png",
      width: "1440",
      height: "900",
    }),
  } = {}) => {
    return {
      title: title,
      subtitle: subtitle,
      text: text,
      button: button,
      graphics: graphics,
    };
  };

  setUpSteps = ({
    fieldTitle = { default: "Steps" },
    label = { default: "Edit the steps:" },
    labelTooltip = {
      default:
        "Please note the images will be enlarged on a mouse click only if they are wider than " +
        lightboxActivationWidth +
        "px.",
    },
    fieldInfo = { default: "" },
    priority = 500,

    maxItems = 24,

    hasTitle = false,
    hasSubtitle = false,
    hasText = false,
    hasButton = false,
    hasImage = false,
    isDefaultEmpty = false,

    buttonTypes = [buttonStyles.ghost.typeTitle],

    items = [
      this.createStepContentObject({
        title: "Create profile",
        text:
          "Eheu, competition! Sunt apolloniateses vitare salvus, varius tuses. Bi-color boreas nunquam dignuss terror est. Sunt nomenes consumere flavum, barbatus lunaes.",
        button: this.createButtonContentObject({
          buttonType: linkOrButtonOptions.link,
          buttonTitle: "",
          buttonHref: "",
          buttonStyleType: buttonStyles.ghost.typeTitle,
          ghostColor: buttonStyles.ghost.accentOutline,
        }),
        graphics: this.createGraphicsContentObject({
          imageUrl: DEFAULT_IMAGES_CDN.UI_DESKTOP_DASHBOARD_1,
          videoUrl: "https://www.youtube.com/watch?v=tw4jkyfY4HE",
          width: 1280,
          height: 800,
        }),
      }),
      this.createStepContentObject({
        title: "Find friends",
        text:
          "Noster exemplars ducunt ad finis. Heu, secundus pulchritudine! Est domesticus tumultumque, cesaris. Germanus, raptus rumors aegre captis de audax, castus usus.",
        button: this.createButtonContentObject({
          buttonType: linkOrButtonOptions.link,
          buttonTitle: "",
          buttonHref: "",
          buttonStyleType: buttonStyles.ghost.typeTitle,
          ghostColor: buttonStyles.ghost.accentOutline,
        }),
        graphics: this.createGraphicsContentObject({
          imageUrl: DEFAULT_IMAGES_CDN.UI_DESKTOP_CRM,
          videoUrl: "https://www.youtube.com/watch?v=J2U9Hmmpqhc",
          width: 1280,
          height: 800,
        }),
      }),
      this.createStepContentObject({
        title: "Connect with strangers",
        text:
          "Eheu, competition! Sunt apolloniateses vitare salvus, varius tuses. Bi-color boreas nunquam dignuss terror est. Sunt nomenes consumere flavum, barbatus lunaes.",
        button: this.createButtonContentObject({
          buttonType: linkOrButtonOptions.link,
          buttonTitle: "",
          buttonHref: "",
          buttonStyleType: buttonStyles.ghost.typeTitle,
          ghostColor: buttonStyles.ghost.accentOutline,
        }),
        graphics: this.createGraphicsContentObject({
          imageUrl: DEFAULT_IMAGES_CDN.UI_DESKTOP_CALENDAR,
          videoUrl: "https://www.youtube.com/watch?v=lfucQM07tGQ",
          width: 1280,
          height: 800,
        }),
      }),
      this.createStepContentObject({
        title: "Enjoy your app!",
        text:
          "Audax nuclear vexatum iaceres ducunt ad saga. Zelus de clemens apolloniates, anhelare guttus! Studere acceleratrix ducunt ad ferox fortis.",
        button: this.createButtonContentObject({
          buttonType: linkOrButtonOptions.link,
          buttonTitle: "",
          buttonHref: "",
          buttonStyleType: buttonStyles.ghost.typeTitle,
          ghostColor: buttonStyles.ghost.accentOutline,
        }),
        graphics: this.createGraphicsContentObject({
          imageUrl: DEFAULT_IMAGES_CDN.UI_DESKTOP_FINANCE,
          videoUrl: "https://www.youtube.com/watch?v=4_sLTe6-7SE",
          width: 1280,
          height: 800,
        }),
      }),
    ],
  } = {}) => {
    return {
      steps: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,

        maxItems: maxItems, //not used at this moment
        buttonTypes: buttonTypes,
        hasSubtitle: hasSubtitle,
        hasText: hasText,
        hasTitle: hasTitle,
        hasButton: hasButton,
        hasImage: hasImage,
        settings: {
          editor: defaultTextEditorType,
        },
        content: {
          items: isDefaultEmpty ? [] : items,
        },
      },
    };
  };

  addEmptyStepIfNeeded = (currentInputObject: any) => {
    let newInputObject = { ...currentInputObject };

    let inputObjectArray = newInputObject.steps.content.items;
    let inputObjectArrayLength = inputObjectArray.length;

    let isCurrentItemsCountEqualsMax: boolean;
    if (inputObjectArrayLength === currentInputObject.steps.maxItems) {
      isCurrentItemsCountEqualsMax = true;
    } else {
      isCurrentItemsCountEqualsMax = false;
    }

    if (isCurrentItemsCountEqualsMax) {
      // if no addition is needed, simply pass the original object.
      return currentInputObject;
    } else {
      let newStepsItem = this.createStepContentObject();

      newInputObject.steps.content.items.push(newStepsItem);

      return newInputObject;
    }
  };

  changeStepTitle = (
    currentInputObject: any,
    index: number,
    newTitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[index].title = newTitle;

    return newInputObject;
  };
  changeStepSubtitle = (
    currentInputObject: any,
    index: number,
    newSubtitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[index].subtitle = newSubtitle;

    return newInputObject;
  };
  changeStepText = (
    currentInputObject: any,
    index: number,
    newText: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[index].text = newText;

    return newInputObject;
  };
  changeStepButtonHref = (
    currentInputObject: any,
    index: number,
    newHref: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[index].button.href = newHref;

    return newInputObject;
  };
  changeStepButtonIsTargetBlank = (
    currentInputObject: any,
    index: number,
    isTargetBlank: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[
      index
    ].button.isTargetBlank = isTargetBlank;

    return newInputObject;
  };

  changeStepButtonTitle = (
    currentInputObject: any,
    index: number,
    title: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[index].button.title = title;

    return newInputObject;
  };

  // Note: index comes last here because it's an optional argument in the buttonGhostColorChangeHandler() (<ContentInput/>).
  changeStepButtonGhostColor = (
    currentInputObject: any,
    newColor: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[
      index
    ].button.settings.appearance.ghostColor = newColor;

    return newInputObject;
  };
  changeStepButtonStyleType = (
    currentInputObject: any,
    newStyleType: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[
      index
    ].button.settings.appearance.styleType = newStyleType;

    return newInputObject;
  };

  changeStepButtonColor = (
    currentInputObject: any,
    newColor: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[
      index
    ].button.settings.appearance.color = newColor;

    return newInputObject;
  };
  changeStepButtonPillColor = (
    currentInputObject: any,
    newColor: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[
      index
    ].button.settings.appearance.pillColor = newColor;

    return newInputObject;
  };
  changeStepButtonPillPrefix = (
    currentInputObject: any,
    newPrefix: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[index].button.pillPrefix = newPrefix;
    return newInputObject;
  };

  changeStepButtonIsRelayUTM = (
    currentInputObject: any,
    index: number,
    isRelayUTM: boolean
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[index].button.isRelayUTM = isRelayUTM;

    return newInputObject;
  };

  changeStepButtonID = (
    currentInputObject: any,
    index: number,
    custom_id: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[
      index
    ].button.settings.custom_id = custom_id;

    return newInputObject;
  };
  changeStepButtonClass = (
    currentInputObject: any,
    index: number,
    classes: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[index].button.settings.classes = classes;

    return newInputObject;
  };
  changeStepButtonOnClick = (
    currentInputObject: any,
    index: number,
    onclick: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[index].button.settings.onclick = onclick;

    return newInputObject;
  };
  changeStepButtonAttributes = (
    currentInputObject: any,
    index: number,
    attributes: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[
      index
    ].button.settings.attributes = attributes;

    return newInputObject;
  };

  changeStepButtonStripeProductId = (
    currentInputObject: any,
    index: number,
    stripeProductId: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[
      index
    ].button.stripeProductId = stripeProductId;

    return newInputObject;
  };
  changeStepButtonStripePaymentMode = (
    currentInputObject: any,
    index: number,
    stripePaymentMode: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[
      index
    ].button.stripePaymentMode = stripePaymentMode;

    return newInputObject;
  };
  changeStepButtonSuccessfulPaymentUrl = (
    currentInputObject: any,
    index: number,
    successfulPaymentUrl: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[
      index
    ].button.successfulPaymentUrl = successfulPaymentUrl;

    return newInputObject;
  };
  changeStepButtonCancelPaymentUrl = (
    currentInputObject: any,
    index: number,
    cancelPaymentUrl: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[
      index
    ].button.cancelPaymentUrl = cancelPaymentUrl;

    return newInputObject;
  };

  changeStepButtonMobileAppStoreType = (
    currentInputObject: any,
    newMobileAppStoreType: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[
      index
    ].button.settings.appearance.mobileAppStoreType = newMobileAppStoreType;

    return newInputObject;
  };

  changeStepImageUrl = (
    currentInputObject: any,
    newUrl: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[index].graphics.image.url = newUrl;

    return newInputObject;
  };
  changeStepImageUUID = (
    currentInputObject: any,
    newUUID: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[index].graphics.image.uuid = newUUID;

    return newInputObject;
  };
  changeStepImageWidth = (
    currentInputObject: any,
    newWidth: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[index].graphics.image.width = newWidth;

    return newInputObject;
  };
  changeStepImageHeight = (
    currentInputObject: any,
    newHeight: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[index].graphics.image.height = newHeight;

    return newInputObject;
  };
  changeStepImageAlt = (
    currentInputObject: any,
    newAlt: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items[index].graphics.image.alt = newAlt;

    return newInputObject;
  };
  changeStepVideoUrl = (
    currentInputObject: any,
    newVideoUrl: string,
    index: number
  ) => {
    const newInputObject = { ...currentInputObject };
    return _.set(
      newInputObject,
      [
        "steps",
        "content",
        "items",
        index,
        "graphics",
        "video",
        "youtube",
        "url",
      ],
      formatVideoSourceUrl(newVideoUrl)
    );
  };
  changeStepCodeSnippet = (
    currentInputObject: any,
    newCodeSnippet: string,
    index: number
  ) => {
    const newInputObject = { ...currentInputObject };
    return _.set(
      newInputObject,
      ["steps", "content", "items", index, "graphics", "HTML", "codeSnippet"],
      newCodeSnippet
    );
  };

  deleteStep = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.steps.content.items.splice(index, 1);
    return newInputObject;
  };
  moveStepUp = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentStepArray = newInputObject.steps.content.items;
    let newStepArray = [...currentStepArray];
    moveArrayItem(newStepArray, index, index - 1);
    newInputObject.steps.content.items = newStepArray;
    return newInputObject;
  };
  moveStepDown = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentStepArray = newInputObject.steps.content.items;
    let newStepArray = [...currentStepArray];
    moveArrayItem(newStepArray, index, index + 1);
    newInputObject.steps.content.items = newStepArray;
    return newInputObject;
  };

  createTeamMemberContentObject = ({
    name = "John Rush",
    jobTitle = "Owner & CEO",
    href = "https://twitter.com/johnrushx",
    username = "@johnrushx",
    socialNetworks = [
      {
        href: "https://twitter.com/johnrushx",
      },
      {
        href: "",
      },
    ],
    info = "Serial startup founder. Leading 20+ products.",
    graphics = this.createGraphicsContentObject({
      imageUrl: DEFAULT_IMAGES_CDN.PERSON_JOHN_RUSH,
      width: 400,
      height: 400,
    }),
  } = {}) => {
    return {
      jobTitle: jobTitle,
      name: name,
      href: href,
      username: username,
      socialNetworks: socialNetworks,
      graphics: graphics,
      info: info,
    };
  };

  setUpTeamMembers = ({
    fieldTitle = { default: "Team" },
    label = { default: "Edit the team members:" },
    labelTooltip = { default: "" },
    fieldInfo = { default: "Recommended photo shape: square" },
    priority = 500,
    isDefaultEmpty = false,

    maxItems = 66,

    hasPhoto = false,
    hasName = false,
    hasJobTitle = false,
    hasUsername = false,
    hasSocialNetworks = false,
    hasHref = false,
    hasInfo = false,

    items = [
      this.createTeamMemberContentObject(),
      this.createTeamMemberContentObject({
        jobTitle: "Founder",
        name: "Alexander Isora",
        username: "@alexanderisora",
        graphics: this.createGraphicsContentObject({
          imageUrl: DEFAULT_IMAGES_CDN.PERSON_1,
          width: 400,
          height: 400,
        }),
        info: "Alexander is passionate about web, tech and the startup industry.",
      }),
      this.createTeamMemberContentObject(),
      this.createTeamMemberContentObject({
        jobTitle: "Founder",
        name: "Alexander Isora",
        username: "@alexanderisora",
        graphics: this.createGraphicsContentObject({
          imageUrl: DEFAULT_IMAGES_CDN.PERSON_1,
          width: 400,
          height: 400,
        }),
        info: "Alexander is passionate about web, tech and the startup industry.",
      }),
    ],
  } = {}) => {
    return {
      team: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,

        maxItems: maxItems, //not used at this moment
        hasJobTitle: hasJobTitle,
        hasUsername: hasUsername,
        hasName: hasName,
        hasSocialNetworks: hasSocialNetworks,
        hasHref: hasHref,
        hasInfo: hasInfo,
        hasPhoto: hasPhoto,
        settings: {
          editor: defaultTextEditorType,
        },
        content: {
          items: isDefaultEmpty ? [] : items,
        },
      },
    };
  };

  addEmptyTeamMembersIfNeeded = (currentInputObject: any) => {
    let newInputObject = { ...currentInputObject };

    let inputObjectArray = newInputObject.team.content.items;
    let inputObjectArrayLength = inputObjectArray.length;

    let isCurrentItemsCountEqualsMax: boolean;
    if (inputObjectArrayLength === currentInputObject.team.maxItems) {
      isCurrentItemsCountEqualsMax = true;
    } else {
      isCurrentItemsCountEqualsMax = false;
    }

    if (isCurrentItemsCountEqualsMax) {
      // if no addition is needed, simply pass the original object.
      return currentInputObject;
    } else {
      let newTeamsItem = this.createTeamMemberContentObject();

      newInputObject.team.content.items.push(newTeamsItem);

      return newInputObject;
    }
  };

  changeTeamMemberJobTitle = (
    currentInputObject: any,
    index: number,
    newJobTitle: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.team.content.items[index].jobTitle = newJobTitle;

    return newInputObject;
  };
  changeTeamMemberName = (
    currentInputObject: any,
    index: number,
    newName: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.team.content.items[index].name = newName;

    return newInputObject;
  };

  changeTeamMemberSocialNetworkHref = (
    currentInputObject: any,
    memberIndex: number,
    socialNetworkItemIndex: number,
    newHref: string
  ) => {
    let newInputObject = { ...currentInputObject };

    newInputObject.team.content.items[memberIndex].socialNetworks[
      socialNetworkItemIndex
    ].href = newHref; // we need to add the href BEFORE checking if a new empty input is needed
    newInputObject = this.addEmptyTeamMemberSocialNetworkIfNeeded(
      newInputObject,
      memberIndex
    );

    return newInputObject;
  };
  addEmptyTeamMemberSocialNetworkIfNeeded = (
    currentInputObject: any,
    memberIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };

    let inputObjectArray =
      newInputObject.team.content.items[memberIndex].socialNetworks;
    let inputObjectArrayLength = inputObjectArray.length;
    let lastInput = inputObjectArray[inputObjectArrayLength - 1];
    if (lastInput === undefined || lastInput.href !== "") {
      // if last input was edited we need to create another last input for user to fill in.
      // we don't have an "add link" button - instead, we simply create an empty ghost link inputs group for user to fill in.
      let clonedLinkItem = { href: "" };

      newInputObject.team.content.items[memberIndex].socialNetworks.push(
        clonedLinkItem
      );

      return newInputObject;
    } else {
      // if no addition is needed, simply pass the original object.
      return currentInputObject;
    }
  };
  deleteTeamMemberSocialNetwork = (
    currentInputObject: any,
    memberIndex: number,
    socialNetworkItemIndex: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.team.content.items[memberIndex].socialNetworks.splice(
      socialNetworkItemIndex,
      1
    );
    newInputObject = this.addEmptyTeamMemberSocialNetworkIfNeeded(
      newInputObject,
      memberIndex
    );
    return newInputObject;
  };

  changeTeamMemberUsername = (
    currentInputObject: any,
    index: number,
    newUsername: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.team.content.items[index].username = newUsername;

    return newInputObject;
  };
  changeTeamMemberInfo = (
    currentInputObject: any,
    index: number,
    newInfo: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.team.content.items[index].info = newInfo;

    return newInputObject;
  };
  changeTeamMemberHref = (
    currentInputObject: any,
    index: number,
    newHref: string
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.team.content.items[index].href = newHref;

    return newInputObject;
  };

  changeTeamMemberImageUrl = (
    currentInputObject: any,
    newUrl: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.team.content.items[index].graphics.image.url = newUrl;

    return newInputObject;
  };
  changeTeamMemberImageUUID = (
    currentInputObject: any,
    newUUID: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.team.content.items[index].graphics.image.uuid = newUUID;

    return newInputObject;
  };
  changeTeamMemberImageWidth = (
    currentInputObject: any,
    newWidth: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.team.content.items[index].graphics.image.width = newWidth;

    return newInputObject;
  };
  changeTeamMemberImageAlt = (
    currentInputObject: any,
    newAlt: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.team.content.items[index].graphics.image.alt = newAlt;

    return newInputObject;
  };
  changeTeamMemberImageHeight = (
    currentInputObject: any,
    newHeight: string,
    index: number
  ) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.team.content.items[index].graphics.image.height = newHeight;

    return newInputObject;
  };

  deleteTeamMember = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.team.content.items.splice(index, 1);
    return newInputObject;
  };

  moveTeamMemberUp = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentTeamArray = newInputObject.team.content.items;
    let newTeamArray = [...currentTeamArray];
    moveArrayItem(newTeamArray, index, index - 1);
    newInputObject.team.content.items = newTeamArray;
    return newInputObject;
  };
  moveTeamMemberDown = (currentInputObject: any, index: number) => {
    let newInputObject = { ...currentInputObject };
    let currentTeamArray = newInputObject.team.content.items;
    let newTeamArray = [...currentTeamArray];
    moveArrayItem(newTeamArray, index, index + 1);
    newInputObject.team.content.items = newTeamArray;
    return newInputObject;
  };

  setUpDirectory = ({
    fieldTitle = { default: "Directory" },
    label = { default: "CMS URL:" },
    labelTooltip = { default: "The URL for the API endpoint, which returns data in CSV or JSON format." },
    fieldInfo = { default: "" },
    priority = 200,
    isDefaultEmpty = false,
    cmsUrl = DEFAULT_GOOGLE_SHEETS_URL,
    showMoreButtonText = "Show all",
    maxItems = 8,
    directoryItemHtml = DEFAULT_DIRECTORY_ITEM_HTML,
    sortingField = "rank",
    sortingOrder = "descending",
    searchPlaceholder = "Search among {{amount}} items",
    filterFields = "",
    filterFieldsMode = "all",
    tags = [],
    filteredTags = "",
    filteredTagsMode = "all",
    categoryColumn = undefined,
    availableColumns = [],
    isTagsHidden = false,
    versions = undefined, // Activate some of the new logic only after user's input so we don't break the existing content.
    dynamicUrl = "url",
    dynamicTarget = "new_tab",
    dynamicUrlPrefix = "no_prefix",
    currentRowsSample = [], // Store first 10 rows

    version = 2,
    filtersV2 = {
      items: []
    },
    updateItemsIndicator = 0,
    updateTopSectionIndicator = 0,
    updateFilterColumnsIndicator = 0,
    topSectionHtml = DEFAULT_DIRECTORY_TOP_SECTION_HTML,
    notFoundPlaceholder = "No results found",
  } = {}) => {
    return {
      directory: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,

        content: {
          cmsUrl: isDefaultEmpty ? "" : cmsUrl,
          showMoreButtonText,
          maxItems,
          directoryItemHtml,
          sortingField,
          sortingOrder,
          searchPlaceholder,
          filterFields,
          filterFieldsMode,
          tags,
          filteredTags,
          filteredTagsMode,
          categoryColumn,
          availableColumns,
          isTagsHidden,
          versions,
          dynamicUrl,
          dynamicTarget,
          dynamicUrlPrefix,
          currentRowsSample,
          filtersV2,
          updateItemsIndicator,
          updateTopSectionIndicator,
          updateFilterColumnsIndicator,
          topSectionHtml,
          notFoundPlaceholder,
          version,
        },
      },
    };
  };

  changeDirectoryCmsUrl = (currentInputObject: any, newUrl: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.cmsUrl = newUrl;
    newInputObject.directory.content.filtersV2.items = [];
    newInputObject.directory.content.filterFields = "";
    newInputObject.directory.content.sortingField = "no_sorting";
    return newInputObject;
  };
  changeDirectoryShowMoreButtonText = (currentInputObject: any, newText: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.showMoreButtonText = newText;
    _.set(newInputObject, "directory.content.versions.button", 2); // v2 - hide button if text is blank.
    newInputObject.directory.content.updateItemsIndicator = (newInputObject.directory.content.updateItemsIndicator || 0) + 1;
    newInputObject.directory.content.updateTopSectionIndicator = (newInputObject.directory.content.updateTopSectionIndicator || 0) + 1;
    return newInputObject;
  };
  changeDirectoryMaxItems = (currentInputObject: any, newMaxItems: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.maxItems = newMaxItems;
    newInputObject.directory.content.updateItemsIndicator = (newInputObject.directory.content.updateItemsIndicator || 0) + 1;
    newInputObject.directory.content.updateTopSectionIndicator = (newInputObject.directory.content.updateTopSectionIndicator || 0) + 1;
    return newInputObject;
  };
  changeDirectoryItemHtml = (currentInputObject: any, newHtml: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.directoryItemHtml = newHtml;
    newInputObject.directory.content.updateItemsIndicator = (newInputObject.directory.content.updateItemsIndicator || 0) + 1;
    newInputObject.directory.content.updateTopSectionIndicator = (newInputObject.directory.content.updateTopSectionIndicator || 0) + 1;
    return newInputObject;
  };
  changeDirectorySortingField = (currentInputObject: any, newField: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.sortingField = newField;
    newInputObject.directory.content.updateItemsIndicator = (newInputObject.directory.content.updateItemsIndicator || 0) + 1;
    newInputObject.directory.content.updateTopSectionIndicator = (newInputObject.directory.content.updateTopSectionIndicator || 0) + 1;
    return newInputObject;
  };
  changeDirectorySearchPlaceholder = (currentInputObject: any, newPlaceholder: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.searchPlaceholder = newPlaceholder;
    newInputObject.directory.content.updateItemsIndicator = (newInputObject.directory.content.updateItemsIndicator || 0) + 1;
    return newInputObject;
  };
  changeDirectoryFilterFields = (currentInputObject: any, newFields: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.filterFields = newFields;
    newInputObject.directory.content.updateItemsIndicator = (newInputObject.directory.content.updateItemsIndicator || 0) + 1;
    newInputObject.directory.content.updateTopSectionIndicator = (newInputObject.directory.content.updateTopSectionIndicator || 0) + 1;
    return newInputObject;
  };
  changeDirectoryFilterFieldsMode = (currentInputObject: any, newFilterFieldsMode: "all" | "any") => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.filterFieldsMode = newFilterFieldsMode;
    newInputObject.directory.content.updateItemsIndicator = (newInputObject.directory.content.updateItemsIndicator || 0) + 1;
    newInputObject.directory.content.updateTopSectionIndicator = (newInputObject.directory.content.updateTopSectionIndicator || 0) + 1;
    return newInputObject;
  }
  changeDirectoryTags = (currentInputObject: any, newTags: string[]) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.tags = newTags;
    return newInputObject;
  };
  changeDirectoryFilteredTags = (currentInputObject: any, newFilteredTags: string[]) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.filteredTags = newFilteredTags.join(";");
    return newInputObject;
  };
  changeDirectoryFilteredTagsMode = (currentInputObject: any, newFilteredTagsMode: "all" | "any") => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.filteredTagsMode = newFilteredTagsMode;
    return newInputObject;
  }
  changeDirectoryCategoryColumn = (currentInputObject: any, newCategoryColumn: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.categoryColumn = newCategoryColumn;
    newInputObject.directory.content.filteredTags = "";
    return newInputObject;
  }
  changeDirectoryAvailableColumns = (currentInputObject: any, newAvailableColumns: string[]) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.availableColumns = newAvailableColumns;
    return newInputObject;
  }
  changeDirectorySortingOrder = (currentInputObject: any, newOrder: "ascending" | "descending") => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.sortingOrder = newOrder;
    newInputObject.directory.content.updateItemsIndicator = (newInputObject.directory.content.updateItemsIndicator || 0) + 1;
    newInputObject.directory.content.updateTopSectionIndicator = (newInputObject.directory.content.updateTopSectionIndicator || 0) + 1;
    return newInputObject;
  }
  changeDirectoryIsTagsHidden = (currentInputObject: any, isTagsHidden: boolean) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.isTagsHidden = isTagsHidden;
    return newInputObject;
  }
  changeDirectoryDynamicUrl = (currentInputObject: any, newDynamicUrl: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.dynamicUrl = newDynamicUrl;
    return newInputObject;
  }
  changeDirectoryDynamicTarget = (currentInputObject: any, newDynamicTarget: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.dynamicTarget = newDynamicTarget;
    return newInputObject;
  }
  changeDirectoryDynamicUrlPrefix = (currentInputObject: any, newDynamicUrlPrefix: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.dynamicUrlPrefix = newDynamicUrlPrefix;
    return newInputObject;
  }
  changeDirectoryCurrentRowsSample = (currentInputObject: any, newCurrentRowsSample: any) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.currentRowsSample = newCurrentRowsSample;
    return newInputObject;
  }
  changeDirectoryNotfoundPlaceholder = (currentInputObject: any, newPlaceholder: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.notFoundPlaceholder = newPlaceholder;
    return newInputObject;
  }

  changeDirectoryFiltersV2 = (currentInputObject: any, data: FilterData) => {
    let newInputObject = { ...currentInputObject };
    const item = newInputObject.directory.content.filtersV2.items.find((item: any) => item.id === data.id);
    if (item) {
      Object.assign(item, data);
    }
    if (data.column) {
      item.showByFilters.items = "";
      item.showByFilters.mode = "any";
      newInputObject.directory.content.updateFilterColumnsIndicator = (newInputObject.directory.content.updateFilterColumnsIndicator || 0) + 1;
    }
    newInputObject.directory.content.updateItemsIndicator = (newInputObject.directory.content.updateItemsIndicator || 0) + 1;
    return newInputObject;
  }
  addDirectoryFilterV2 = (currentInputObject: any, defaultColumn: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.filtersV2.items.push({
      id: uuidv4(),
      column: defaultColumn,
      label: "",
      sorting: "no_sorting",
      multiselect: "off",
      filters: [],
      showByFilters: {
        items: "",
        mode: "any"
      },
      isHidden: "visible",
    });
    newInputObject.directory.content.updateItemsIndicator = (newInputObject.directory.content.updateItemsIndicator || 0) + 1;
    return newInputObject;
  }
  deleteDirectoryFilterV2 = (currentInputObject: any, id: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.directory.content.filtersV2.items = newInputObject.directory.content.filtersV2.items.filter((item: any) => item.id !== id);
    newInputObject.directory.content.updateItemsIndicator = (newInputObject.directory.content.updateItemsIndicator || 0) + 1;
    return newInputObject;
  }
  moveDirectoryFilterV2Up = (currentInputObject: any, id: string) => {
    let newInputObject = { ...currentInputObject };
    const index = newInputObject.directory.content.filtersV2.items.findIndex((item: any) => item.id === id);
    if (index > 0) {
      moveArrayItem(newInputObject.directory.content.filtersV2.items, index, index - 1);
    }
    newInputObject.directory.content.updateItemsIndicator = (newInputObject.directory.content.updateItemsIndicator || 0) + 1;
    return newInputObject;
  }
  moveDirectoryFilterV2Down = (currentInputObject: any, id: string) => {
    let newInputObject = { ...currentInputObject };
    const index = newInputObject.directory.content.filtersV2.items.findIndex((item: any) => item.id === id);
    if (index < newInputObject.directory.content.filtersV2.items.length - 1) {
      moveArrayItem(newInputObject.directory.content.filtersV2.items, index, index + 1);
    }
    newInputObject.directory.content.updateItemsIndicator = (newInputObject.directory.content.updateItemsIndicator || 0) + 1;
    return newInputObject;
  }
  convertLegacyDirectoryToV2 = (currentInputObject) => {
    let newInputObject = { ...currentInputObject };
    const { content } = newInputObject.directory;

    if (content.version && content.version >= 2) return newInputObject;

    content.version = 2;
    content.topSectionHtml = DEFAULT_DIRECTORY_TOP_SECTION_HTML;
    content.filtersV2 = {
      items: []
    }
    if (content.categoryColumn !== "no_tags") {
      content.filtersV2.items.push({
        id: "legacy-tag",
        column: content.categoryColumn || "category",
        label: "",
        sorting: "a-z",
        multiselect: "off",
        filters: content.tags,
        showByFilters: {
          items: content.filteredTags || "",
          mode: content.filteredTagsMode || "any",
        },
        isHidden: content.isTagsHidden ? "hidden" : "visible",
      });
    }
    return newInputObject;
  };

  setUpWidget = ({
    fieldTitle = { default: "Widget" },
    label = { default: "Set up widget:" },
    labelTooltip = { default: "wip" },
    fieldInfo = { default: "" },
    priority = 2000,
    isDefaultEmpty = false,
    code = `<div class="senja-embed" data-id="d41c0ec5-3338-4600-ab57-d127bac1bafb" data-lazyload="false"></div>`,
  } = {}) => {
    return {
      widget: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,
  
        content: {
          code: isDefaultEmpty ? "" : code,
        },
      },
    };
  };

  changeWidgetCode = (currentInputObject: any, newCode: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.widget.content.code = newCode;
    return newInputObject;
  }

  setUpRapidForms = ({
    fieldTitle = { default: "Form" },
    label = { default: "Form widget by Rapidforms:" },
    labelTooltip = { default: "Add testimonials widget by rapidforms.co." },
    fieldInfo = { default: "" },
    priority = 2000,
    isDefaultEmpty = false,
    src = "https://app.rapidforms.co/embed/5bc5b6",
    isDemo = true,
  } = {}) => {
    return {
      rapidForms: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,
  
        content: {
          src: isDefaultEmpty ? "" : src,
          isDemo,
        },
      },
    };
  };

  changeRapidFormsSrc = (currentInputObject: any, newSrc: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.rapidForms.content.src = newSrc;
    newInputObject.rapidForms.content.isDemo = false;
    return newInputObject;
  }

  setUpMarketsy = ({
    fieldTitle = { default: "Marketplace" },
    label = { default: "Marketplace by Marketsy:" },
    labelTooltip = { default: "Add marketplace widget by marketsy.ai." },
    fieldInfo = { default: "" },
    priority = 2000,
    isDefaultEmpty = false,
    src = null,
    id = null,
    seed = 0,
  } = {}) => {
    return {
      marketsy: {
        fieldTitle: fieldTitle,
        label: label,
        fieldInfo: fieldInfo,
        labelTooltip: labelTooltip,
        priority: priority,
        content: {
          src: src || `https://marketsy.ai/widgets/66719aca521e3978dce864c1?storeId=664b84f918554a99f3133006`,
          id: id || `66719aca521e3978dce864c1`,
        },
      },
    };
  };

  changeMarketsyData = (currentInputObject: any, newSrc: string, newId: string) => {
    let newInputObject = { ...currentInputObject };
    newInputObject.marketsy.content.src = newSrc;
    newInputObject.marketsy.content.id = newId;
    return newInputObject;
  }
}


export default generateContentDataObject;
