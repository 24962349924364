import React, {Component} from "react";
import {BrowserRouter as Router, Route, Link, Switch, Redirect} from "react-router-dom";
import {Button, Form, Input, message} from "antd";
import setInputStatus from "../../helpers/setInputStatus";
import {api} from "../../data/urls";
import {getCurrentWebsiteSubdomain} from "../../helpers/getCurrentWebsiteSubdomain";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {defaultBlogUrl, indexWebsitePageDashboardUrl, projectUrl} from "../../data/constants";
import {openNotification} from "../../helpers/openNotification";
import {getCurrentBlogUrl} from "../../helpers/blog/getCurrentBlogUrl";
import {messages} from "../../data/messages";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import { getActiveDomain } from "../../helpers/websites/getActiveDomain";
import { formatToURLPath } from "../../helpers/strings/formatToURLPath";

interface Props {
    websites: any,
    blogs: any,
    blogPosts: any,
    clearUpdatePostUrl: any,
    auth: any,
    history: any,

    currentWebsite: any,
    currentBlog: any,
    currentBlogPost: any,

    saveBlogPostDataInStore: any,

    postUrlUpdateWaitingForServer: any,
    postUrlUpdateErrorMessage: any,
    postUrlUpdateToggleInvalidAttempt: any,
    url: string,
    setUrl: (url: string) => void,
}

type MyState = {};

class UpdatePostUrlForm extends Component<Props, MyState>{
    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        // We set the new state only when we change a settings active website or page.
        // We want to set up an initial value of a new picked website or page.
        if((this.props.currentWebsite.subdomain !== nextProps.currentWebsite.subdomain) || (this.props.currentBlogPost.url !== nextProps.currentBlogPost.url)){
            this.props.setUrl(nextProps.currentBlogPost.url);
            // We also clear errors when switch a website or a page.
            this.props.clearUpdatePostUrl();
        }
    }

    handleUrlUpdate = (e:any):void => {
        e.preventDefault();

        this.props.postUrlUpdateWaitingForServer(true);

        //We want to remove all errors from inputs on a new request.
        this.props.clearUpdatePostUrl();


        // Our server API expects the 'indexWebsitePageDashboardUrl' as an URL kwarg because a URL of a home page ('') is in appropriate for API and the dashboard.
        let newUrl = formatToURLPath(this.props.url);
        this.props.setUrl(newUrl);

        let blogPostUrlToServer = this.props.currentBlogPost.url;

        let accessToken = this.props.auth.accessToken;
        let apiUrl = api.blogPosts.updateBlogPost.prefix + this.props.currentWebsite.subdomain + '/' + blogPostUrlToServer + api.blogPosts.updateBlogPost.postfix;
        axios.patch(apiUrl, {url: newUrl, subdomain: this.props.currentWebsite.subdomain}, {...returnAuthHeaderForAJAX(accessToken)})
            .then(() => {
                let currentBlogUrl = this.props.currentBlog.url;
                let newPostUrlLink;

                this.props.saveBlogPostDataInStore({
                  data: {
                    ...this.props.currentBlogPost,
                    url: newUrl,
                  }
                }, this.props.currentBlog, this.props.blogPosts.items, this.props.currentBlogPost.url);

                fireAnalyticsEvent.fireCrisp(CrispEvents.changePostUrl, {
                  subdomain: this.props.currentWebsite.subdomain,
                  post_url: newUrl,
                });

                // Server gives the object of the saved blog post.
                let newPostUrlFromServer = newUrl;
                if(newPostUrlFromServer === ''){
                    newPostUrlFromServer = indexWebsitePageDashboardUrl;
                }
                


                // Tell a user that the operation is successful.
                let currentWebsiteUrl = 'https://';
                if(this.props.currentWebsite.is_custom_domain_active){
                    currentWebsiteUrl = currentWebsiteUrl + this.props.currentWebsite.custom_domain;
                }else{
                    currentWebsiteUrl = currentWebsiteUrl + this.props.currentWebsite.subdomain + '.' + projectUrl;
                }
                
                if (currentBlogUrl === ''){
                    currentBlogUrl = defaultBlogUrl;
                    newPostUrlLink = currentWebsiteUrl + '/' + newUrl;
                } else {
                    currentBlogUrl = this.props.currentBlog.url;
                    newPostUrlLink = currentWebsiteUrl + '/' + currentBlogUrl + '/' + newUrl;
                }
                
                let newUrlMessage = (<span>Successfully changed the post url. The post can be accessed here:&nbsp;<a target="_blank" href={newPostUrlLink}>{newPostUrlLink}</a></span>);

                if(newUrl === '' || newUrl === ' '){
                    newUrlMessage = (<span>Successfully changed the page url. This page is now a home page and it can be accessed here:&nbsp;<a target="_blank" href={newPostUrlLink}>{newPostUrlLink}</a></span>);
                }
            })
            .catch(error => {
                if(error.response){
                    let errorData = error.response.data;
                    if (errorData.detail !== undefined){
                        let message = errorData.detail;

                        let errorObjectKey = getErrorKey(errorData);
                        let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                        fireAnalyticsEvent.fireCrisp(CrispEvents.changePostUrlError, {
                          error_type: errorObjectKey,
                          error_message: errorObjectValue,
                          subdomain: this.props.currentWebsite.subdomain,
                          post_url: newUrl,
                        }, true);

                        // This happens when user tries to change a website which doesn't exist or doesn't belog to the user.
                        openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                    }

                    if (errorData.locked !== undefined){
                        let message = errorData.locked;
                        openNotification('Denied', message, 'OK', 'warn');
                    }

                    if (errorData.url !== undefined){
                        let message = errorData.url.join(' ');
                        this.props.postUrlUpdateErrorMessage(message);
                        this.props.postUrlUpdateToggleInvalidAttempt(true);
                    }
                }
            })
            .then(response => {
                // always executed
                this.props.postUrlUpdateWaitingForServer(false);
            });
    };

    handleInputChange = (e:any) => {
        this.props.setUrl(e.target.value);
    };

    render(){
        let currentWebsiteUrl = 'https://';
        let currentBlogUrl = this.props.currentBlog.url;
        if(this.props.currentWebsite.is_custom_domain_active){
            currentWebsiteUrl = currentWebsiteUrl + this.props.currentWebsite.custom_domain;
        }else{
            currentWebsiteUrl = currentWebsiteUrl + this.props.currentWebsite.subdomain + '.' + projectUrl;
        }

        if(currentBlogUrl !== ''){
            currentBlogUrl = '/' + currentBlogUrl
        }

        const displayedDomain = getActiveDomain(this.props.currentWebsite) + currentBlogUrl;

        return (
            <form onSubmit={this.handleUrlUpdate} className="settings-box__form">
                <div className="settings-box__input_box full-width">
                    <Input name="url"
                          id="url"
                          required={false}
                          placeholder="post-url"
                          type="text"
                          value={this.props.url}
                          onChange={e => this.handleInputChange(e)}
                          addonBefore={<div style={{
                            maxWidth: "150px",
                            direction: "rtl",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                           }}>{displayedDomain}/&lrm;</div>}
                    />
                    <div className="settings-box__error_message" style={{opacity: this.props.blogPosts.postUrlUpdateErrorMessage === undefined ? 0 : 1}}>
                        {this.props.blogPosts.postUrlUpdateErrorMessage}
                    </div>
                </div>
                <Button
                    className="settings-box__form_save"
                    icon='save'
                    type="primary"
                    loading={this.props.blogPosts.isWaitingForPostUrlUpdateResponse}
                    disabled={this.props.blogPosts.isWaitingForPostUrlUpdateResponse}
                    htmlType="submit"
                    hidden={(this.props.currentBlogPost.url === this.props.url || this.props.url === '')}
                />
            </form>
        )
    }
}

export default UpdatePostUrlForm;
