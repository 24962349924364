import { Tooltip, Icon } from "antd";
import Search from "antd/lib/input/Search";
import React from "react";
import { urls } from "../../../../data/urls";
import generateFormIntegrationDataObject from "../../../../helpers/editor/generateFormIntegrationDataObject";
import { toggleNewWebsitePageEditionsDetected } from "../../../../store/websitePages/actions";
import { saveWebsiteNewFormIntegrationInState } from "../../../../store/websites/actions";
import { connect } from "react-redux";
import { SaveConnectedIntegrationsInServer } from "../../../../helpers/types/globalTypes";
import ContentInputLabel from "../../../ui_components/ContentInputLabel";

interface Props {
  currentFormIntegrationTitle: string;
  currentWebsite: any;
  websites: any;
  saveConnectedIntegrationsInServer: SaveConnectedIntegrationsInServer;

  toggleNewWebsitePageEditionsDetected: (payload: boolean) => void;
  saveWebsiteNewFormIntegrationInState: (payload: any) => void;
}

const MailerLiteInputs = (props: Props): JSX.Element => {
  const {
    currentFormIntegrationTitle,
    currentWebsite,
    saveConnectedIntegrationsInServer,
    websites,
  } = props;

  return (
    <li className="content-input">
      <ContentInputLabel
        title={
          <div>
            Please copy the <code>action=""</code> attribute contents:
            <br />
            <br />
            <a
              href={urls.assets.mailerliteIntegrationAction}
              style={{ display: "block" }}
              target="_blank"
            >
              <img
                style={{ cursor: "pointer", width: "100%" }}
                src={urls.assets.mailerliteIntegrationAction}
                alt="Mailerlite integraion guide image."
              />
            </a>
            <br />
            Read this{" "}
            <a
              style={{
                color: "white",
                textDecoration: "underline",
              }}
              href={urls.guides.mailerliteIntegration}
              target="_blank"
            >
              simple tutorial <Icon type="link" />
            </a>{" "}
            if you need help.
          </div>
        }
      >
        Enter the Mailerlite action
      </ContentInputLabel>
      <div className="content-input__input_box">
        <Search
          onSearch={(value: any) => {
            props.toggleNewWebsitePageEditionsDetected(true);

            const newConnectedIntegrationObject =
              generateFormIntegrationDataObject.mailerlite(
                currentFormIntegrationTitle,
                value
              );

            props.saveWebsiteNewFormIntegrationInState({
              newConnectedIntegrationObject,
              currentWebsiteSubdomain: currentWebsite.subdomain,
            });
            saveConnectedIntegrationsInServer(newConnectedIntegrationObject);
          }}
          enterButton={
            <span style={{ verticalAlign: "middle" }}>
              Save{" "}
              <Icon
                type={
                  websites.isWaitingForFormIntegrationsServerResponse
                    ? "loading"
                    : "save"
                }
              />
            </span>
          }
          placeholder="https://assets.mailerlite.com/jsonp/<some_id>/forms/<some_id>/subscribe"
          id={currentFormIntegrationTitle}
        />
      </div>
    </li>
  );
};

const mapActionsToProps = {
  toggleNewWebsitePageEditionsDetected,
  saveWebsiteNewFormIntegrationInState,
};
export default connect(null, mapActionsToProps)(MailerLiteInputs);
