import React from "react";
import { Icon, Tooltip } from "antd";
import classNames from "classnames";

interface Props {
  title?: string | JSX.Element;
  noMargin?: boolean;
}

class ContentInputLabel extends React.Component<Props> {
  render() {
    const { noMargin } = this.props;
    return (
      <div className={classNames("content-input__label_box", {
        "no-margin": noMargin,
      })}>
        <span className="content-input__label">{this.props.children}</span>
        {!!this.props.title && <Tooltip placement="top" title={this.props.title}>
          <Icon className="content-input__tooltip_icon" type="info-circle" />
        </Tooltip>}
      </div>
    );
  }
}

export default ContentInputLabel;
