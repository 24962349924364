import { ComponentTypes } from "../../../../enums/ComponentTypes";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import { UITextTypes } from "../../../../enums/UITextTypes";
import { GraphicsOptions } from "./componentGraphicsData";

const graphicsUIText = {
  [UITextTypes.title]: {
    [GraphicsOptions.image]: "Image",
    [GraphicsOptions.video]: "Video",
    [GraphicsOptions.HTML]: "HTML code",
  },
  [UITextTypes.label]: {
    [GraphicsOptions.image]: "Upload an image:",
    [GraphicsOptions.video]: "Enter video URL (YouTube, Vimeo, .mp4):",
    [GraphicsOptions.HTML]: "Enter HTML code:",
  },
  [UITextTypes.labelTooltip]: {
    [GraphicsOptions.image]:
      "Upload an image from your computer, your cloud, or a direct URL.",
    [GraphicsOptions.video]:
      "Enter URL of a YouTube or Vimeo video. You can also paste a direct URL to a video file (must end on '.mp4') or a Wistia video embed code.",
    [GraphicsOptions.HTML]:
      "You can paste HTML code. If you need JS or CSS, use the <script> and <style> tags. Use '.container' class to wrap your embed in a container. Use '.text-center' to align horizontaly.",
  },
  [UITextTypes.fieldInfo]: {
    [GraphicsOptions.image]: "",
    [GraphicsOptions.video]: "",
    [GraphicsOptions.HTML]: "",
  },
  [UITextTypes.titleTooltip]: {
    [GraphicsOptions.image]: "",
    [GraphicsOptions.video]: "Enter URL of a YouTube or Vimeo video. You can also paste a direct URL to a video file (must end on '.mp4') or a Wistia video embed code.",
    [GraphicsOptions.HTML]: "",
  },
};

const stepsUIText = {
  [UITextTypes.title]: {
    [GraphicsOptions.image]: "Steps with images",
    [GraphicsOptions.video]: "Steps with video",
    [GraphicsOptions.HTML]: "Steps with HTML",
  },
  [UITextTypes.label]: {
    [GraphicsOptions.image]: "Edit the steps:",
    [GraphicsOptions.video]: "Edit the steps:",
    [GraphicsOptions.HTML]: "Edit the steps:",
  },
  [UITextTypes.labelTooltip]: {
    [GraphicsOptions.image]:
      "Please note the images will be enlarged on a mouse click only if they are wider than 300px.",
    [GraphicsOptions.video]:
      "Enter URL of a YouTube or Vimeo video. You can also paste a direct URL to a video file (must end on '.mp4') or a Wistia video embed code.",
    [GraphicsOptions.HTML]:
      "You can paste HTML code. If you need JS or CSS, use the <script> and <style> tags. Use '.container' class to wrap your embed in a container. Use '.text-center' to align horizontaly.",
  },
  [UITextTypes.fieldInfo]: {
    [GraphicsOptions.image]: "",
    [GraphicsOptions.video]: "",
    [GraphicsOptions.HTML]: "",
  },
  [UITextTypes.titleTooltip]: {
    [GraphicsOptions.image]: "",
    [GraphicsOptions.video]: "Enter URL of a YouTube or Vimeo video. You can also paste a direct URL to a video file (must end on '.mp4') or a Wistia video embed code.",
    [GraphicsOptions.HTML]: "",
  },
};

const galleryUIText = {
  [UITextTypes.title]: {
    [GraphicsOptions.image]: {
      [ComponentTypes.slider]: "Image slider",
      [ComponentTypes.tabs]: "Image tabs",
    },
    [GraphicsOptions.video]: {
      [ComponentTypes.slider]: "Video slider",
      [ComponentTypes.tabs]: "Video tabs",
    },
    [GraphicsOptions.HTML]: {
      [ComponentTypes.slider]: "HTML slider",
      [ComponentTypes.tabs]: "HTML tabs",
    },
  },
  [UITextTypes.label]: {
    [GraphicsOptions.image]: {
      [ComponentTypes.slider]: "Edit the slides:",
      [ComponentTypes.tabs]: "Edit the tabs:",
    },
    [GraphicsOptions.video]: {
      [ComponentTypes.slider]: "Edit the slides:",
      [ComponentTypes.tabs]: "Edit the tabs:",
    },
    [GraphicsOptions.HTML]: {
      [ComponentTypes.slider]: "Edit the slides:",
      [ComponentTypes.tabs]: "Edit the tabs:",
    },
  },
  [UITextTypes.labelTooltip]: {
    [GraphicsOptions.image]: {
      [ComponentTypes.slider]:
        "Upload images from your computer, your cloud, or a direct URL.",
      [ComponentTypes.tabs]:
        "Upload images from your computer, your cloud, or a direct URL.",
    },
    [GraphicsOptions.video]: {
      [ComponentTypes.slider]:
        "Enter URL of a YouTube or Vimeo video. You can also paste a direct URL to a video file (must end on '.mp4') or a Wistia video embed code.",
      [ComponentTypes.tabs]:
        "Enter URL of a YouTube or Vimeo video. You can also paste a direct URL to a video file (must end on '.mp4') or a Wistia video embed code.",
    },
    [GraphicsOptions.HTML]: {
      [ComponentTypes.slider]:
        "You can paste HTML code. If you need JS or CSS, use the <script> and <style> tags. Use '.container' class to wrap your embed in a container. Use '.text-center' to align horizontaly.",
      [ComponentTypes.tabs]:
        "You can paste HTML code. If you need JS or CSS, use the <script> and <style> tags. Use '.container' class to wrap your embed in a container. Use '.text-center' to align horizontaly.",
    },
  },
  [UITextTypes.fieldInfo]: {
    [GraphicsOptions.image]: {
      [ComponentTypes.slider]: "",
      [ComponentTypes.tabs]: "",
    },
    [GraphicsOptions.video]: {
      [ComponentTypes.slider]: "",
      [ComponentTypes.tabs]: "",
    },
    [GraphicsOptions.HTML]: {
      [ComponentTypes.slider]: "",
      [ComponentTypes.tabs]: "",
    },
  },
  [UITextTypes.titleTooltip]: {
    [GraphicsOptions.image]: {
      [ComponentTypes.slider]: "",
      [ComponentTypes.tabs]: "",
    },
    [GraphicsOptions.video]: {
      [ComponentTypes.slider]: "Enter URL of a YouTube or Vimeo video. You can also paste a direct URL to a video file (must end on '.mp4') or a Wistia video embed code.",
      [ComponentTypes.tabs]: "Enter URL of a YouTube or Vimeo video. You can also paste a direct URL to a video file (must end on '.mp4') or a Wistia video embed code.",
    },
    [GraphicsOptions.HTML]: {
      [ComponentTypes.slider]: "",
      [ComponentTypes.tabs]: "",
    },
  },
};

export const mapTextObjectsToContentTypes = {
  [ContentInputsTypesEnum.graphics]: graphicsUIText,
  [ContentInputsTypesEnum.steps]: stepsUIText,
  [ContentInputsTypesEnum.gallery]: galleryUIText,
};
