import React, { useState } from "react";
import { Button, Icon, Input, Tooltip } from "antd";
import { urls } from "../../../../data/urls";
import { toggleNewWebsitePageEditionsDetected } from "../../../../store/websitePages/actions";
import { saveWebsiteNewFormIntegrationInState } from "../../../../store/websites/actions";
import { connect } from "react-redux";
import UpgradeToUnlockMessage from "../../UpgradeToUnlockMessage";
import generateFormIntegrationDataObject from "../../../../helpers/editor/generateFormIntegrationDataObject";
import { SaveConnectedIntegrationsInServer } from "../../../../helpers/types/globalTypes";
import { UserEvents } from "../../../../enums/UserEvents";
import ContentInputLabel from "../../../ui_components/ContentInputLabel";

interface Props {
  currentFormIntegrationTitle: string;
  currentFormIntegrationHumanTitle: string;
  currentWebsite: any;
  user: any;
  websites: any;

  saveConnectedIntegrationsInServer: SaveConnectedIntegrationsInServer;

  toggleNewWebsitePageEditionsDetected: (payload: boolean) => void;
  saveWebsiteNewFormIntegrationInState: (payload: any) => void;
}

const EmailInputs = (props: Props): JSX.Element => {
  const {
    currentFormIntegrationTitle,
    currentWebsite,
    saveConnectedIntegrationsInServer,
    user,
    currentFormIntegrationHumanTitle,
    websites,
  } = props;
  const currentUserEmail = user.data.email;

  const [email, setEmail] = useState(currentUserEmail);

  const displayInput = () => {
    if (!user.isPro) {
      return (
        <UpgradeToUnlockMessage
          title="Hold on, cowboy"
          description={
            <span>
              The <b>"{currentFormIntegrationHumanTitle}"</b> integration is
              available on all paid plans. Upgrade your account to get it.
            </span>
          }
          userEventOnMount={UserEvents.SendToEmailNotAvailable}
        />
      );
    }

    const handleOnSubmit = (e) => {
      e.preventDefault();
      if (!email) return;
      // Detect component changes because when a new connection is added, it is automatically applied to the form which was being edited
      props.toggleNewWebsitePageEditionsDetected(true);

      // Save the new integration of the website in the Store:
      let emails = [email]; // the list of emails where the form data will be sent to
      const newConnectedIntegrationObject =
        generateFormIntegrationDataObject.email(
          currentFormIntegrationTitle,
          currentWebsite.id,
          emails
        );
      props.saveWebsiteNewFormIntegrationInState({
        newConnectedIntegrationObject,
        currentWebsiteSubdomain: currentWebsite.subdomain,
      });

      // Also save the new integration of the website in the server:
      saveConnectedIntegrationsInServer(newConnectedIntegrationObject);

      // Apply the newly added integration to the target form:
    };

    return (
      <form onSubmit={handleOnSubmit}>
        <div className="content-input__input_box">
          <Input
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="hi@acme.com"
            type="email"
          />
        </div>
        <div className="content-input__input_box" style={{ marginTop: 10 }}>
          <Button
            type={"primary"}
            icon="plus"
            loading={websites.isWaitingForFormIntegrationsServerResponse}
            disabled={websites.isWaitingForFormIntegrationsServerResponse}
            htmlType="submit"
          >
            Add integration
          </Button>
        </div>
      </form>
    );
  };

  return (
    // The "content-input" className is taken from the <ContentInput> JSX component. We can't use it for the integration connection goals so we copied the markup (label, tooltip, inputs).
    <li className="content-input">
      <ContentInputLabel
        title={
          <div>
            This integration will make the form sending submitted data to a
            given email address.
            <br />
            <br />
            Read the{" "}
            <a
              style={{
                color: "white",
                textDecoration: "underline",
              }}
              href={urls.guides.emailIntegration}
              target="_blank"
            >
              guide <Icon type="link" />
            </a>{" "}
            if you need help or learn more.
          </div>
        }
      >
        Email
      </ContentInputLabel>
      {displayInput()}
    </li>
  );
};

const mapActionsToProps = {
  toggleNewWebsitePageEditionsDetected,
  saveWebsiteNewFormIntegrationInState,
};
export default connect(null, mapActionsToProps)(EmailInputs);
