import { publicUrl } from "../../data/urls";

function getSocialNetworkIcon(socialNetworkTitle:string, colorType:string):string {
    // colorType may be: 'white', 'black' or 'color'
    let pathToIconFolder = publicUrl + "/img/icons/social/" + colorType + "/";
    let resultingUrlToIcon = pathToIconFolder;
    switch (socialNetworkTitle) {
        case 'facebook':
            resultingUrlToIcon += 'facebook.svg';
            break;
        case 'fb':
            resultingUrlToIcon += 'facebook-only-letter.svg';
            break;
        case '500px':
            resultingUrlToIcon += '500px.svg';
            break;
        case 'angel':
            resultingUrlToIcon += 'angel.svg';
            break;
        case 'tiktok':
            resultingUrlToIcon += 'tiktok.svg';
            break;
        case 'behance':
            resultingUrlToIcon += 'behance.svg';
            break;
        case 'bitcointalk':
            resultingUrlToIcon += 'bitcointalk.svg';
            break;
        case 'blogger':
            resultingUrlToIcon += 'blogger.svg';
            break;
        case 'buffer':
            resultingUrlToIcon += 'buffer.svg';
            break;
        case 'buysellads':
            resultingUrlToIcon += 'buysellads.svg';
            break;
        case 'codepen':
            resultingUrlToIcon += 'codepen.svg';
            break;
        case 'creativemarket':
            resultingUrlToIcon += 'creative-market.svg';
            break;
        case 'deviantart':
            resultingUrlToIcon += 'deviantart.svg';
            break;
        case 'dribbble':
            resultingUrlToIcon += 'dribbble.svg';
            break;
        case 'dropbox':
            resultingUrlToIcon += 'dropbox.svg';
            break;
        case 'email':
            resultingUrlToIcon += 'email.svg';
            break;
        case 'spotify':
            resultingUrlToIcon += 'spotify.svg';
            break;
        case 'vsco':
            resultingUrlToIcon += 'vsco.svg';
            break;
        case 'bandcamp':
            resultingUrlToIcon += 'bandcamp.svg';
            break;
        case 'envato':
            resultingUrlToIcon += 'envato.svg';
            break;
        case 'evernote':
            resultingUrlToIcon += 'evernote.svg';
            break;
        case 'flickr':
            resultingUrlToIcon += 'flickr.svg';
            break;
        case 'github':
            resultingUrlToIcon += 'github.svg';
            break;
        case 'google':
            resultingUrlToIcon += 'google-plus.svg';
            break;
        case 'instagram':
            resultingUrlToIcon += 'instagram.svg';
            break;
        case 'lastfm':
            resultingUrlToIcon += 'lastfm.svg';
            break;
        case 'linkedin':
            resultingUrlToIcon += 'linkedin.svg';
            break;
        case 'medium':
            resultingUrlToIcon += 'medium.svg';
            break;
        case 'apple-music':
            resultingUrlToIcon += 'apple-music.svg';
            break;
        case 'myspace':
            resultingUrlToIcon += 'myspace.svg';
            break;
        case 'paypal':
            resultingUrlToIcon += 'paypal.svg';
            break;
        case 'pinterest':
            resultingUrlToIcon += 'pinterest.svg';
            break;
        case 'producthunt':
            resultingUrlToIcon += 'product-hunt.svg';
            break;
        case 'reddit':
            resultingUrlToIcon += 'reddit.svg';
            break;
        case 'rss':
            resultingUrlToIcon += 'rss.svg';
            break;
        case 'shopify':
            resultingUrlToIcon += 'shopify.svg';
            break;
        case 'skype':
            resultingUrlToIcon += 'skype.svg';
            break;
        case 'slack':
            resultingUrlToIcon += 'slack.svg';
            break;
        case 'soundcloud':
            resultingUrlToIcon += 'soundcloud.svg';
            break;
        case 'spotify':
            resultingUrlToIcon += 'spotify.svg';
            break;
        case 'steemit':
            resultingUrlToIcon += 'steemit.svg';
            break;
        case 'telegram':
            resultingUrlToIcon += 'telegram.svg';
            break;
        case 't':
            resultingUrlToIcon += 'telegram.svg';
            break;
        case 'tidal':
            resultingUrlToIcon += 'tidal.svg';
            break;
        case 'trello':
            resultingUrlToIcon += 'trello.svg';
            break;
        case 'tumblr':
            resultingUrlToIcon += 'tumblr.svg';
            break;
        case 'twitter':
            resultingUrlToIcon += 'x.svg';
            break;
        case 'x':
            resultingUrlToIcon += 'x.svg';
            break;
        case 'vimeo':
            resultingUrlToIcon += 'vimeo.svg';
            break;
        case 'vine':
            resultingUrlToIcon += 'vine.svg';
            break;
        case 'whatsapp':
            resultingUrlToIcon += 'whatsapp.svg';
            break;
        case 'wa':
            resultingUrlToIcon += 'whatsapp.svg';
            break;
        case 'wordpress':
            resultingUrlToIcon += 'wordpress.svg';
            break;
        case 'yelp':
            resultingUrlToIcon += 'yelp.svg';
            break;
        case 'youtube':
            resultingUrlToIcon += 'youtube.svg';
            break;
        case 'youtu':
            resultingUrlToIcon += 'youtube.svg';
            break;
        case 'vk':
            resultingUrlToIcon += 'vk.svg';
            break;
        case 'indiehackers':
            resultingUrlToIcon += 'ih.svg';
            break;
        case 'vkontakte':
            resultingUrlToIcon += 'vk.svg';
            break;
        case 'discord':
            resultingUrlToIcon += 'discord.svg';
            break;
        case 'quora':
            resultingUrlToIcon += 'quora.svg';
            break;
        case 'crunchbase':
            resultingUrlToIcon += 'crunchbase.svg';
        case 'mastodon':
            resultingUrlToIcon += 'mastodon.svg';
            break;
        case 'threads':
            resultingUrlToIcon += 'threads.svg';
            break;
        default:
            resultingUrlToIcon += 'link.svg'
    }

    return resultingUrlToIcon;
}

export default getSocialNetworkIcon;
