import React, { Component } from "react";
import "./ButtonPluginsDrawer.css";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {
  Alert,
  Badge,
  Button,
  Drawer,
  Icon,
  Input,
  InputNumber,
  Popconfirm,
  Popover,
  Select,
  Switch,
  Tabs,
  Tooltip,
} from "antd";
import { Radio } from "antd";
import StripePaymentsLogo from "../../img/logos/stripe-payments.svg";
import Relay from "../../img/other/relay.png";
import { api, urls } from "../../data/urls";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import { openNotification } from "../../helpers/openNotification";
import { getErrorKey } from "../../helpers/editor/getErrorKey";
import { getErrorValue } from "../../helpers/editor/getErrorValue";
import generateRandomNumerousId from "../../helpers/generateRandomNumerousId";
import { any, number, string } from "prop-types";
import { openChatWidget } from "../../helpers/editor/openChatWidget";
import MyButton from "../MyButton";
import { buttonStyles } from "../../data/buttonStyles";
import ButtonSettings from "./ButtonSettings";
import PopupMenu from "./popups/PopupMenu";
import { generatePopupDrawerClass } from "../../helpers/generatePopupDrawerClass";
import { ComponentDataPaths } from "../../helpers/types/popupTypes";
import DrawerTitle from "./DrawerTitle";
// const addressDetect = require('cryptocurrency-address-detector'); //via https://davidwalsh.name/detect-cryptocurrency-by-wallet

interface Props {
  websites: any;
  currentWebsite: any;
  auth: any;

  toggleWaitingForConfigurePaymentIntegration: any;

  currentButtonObject: any;
  changeStripePublicKey: any;
  saveWebsiteDataInStore: any;

  setStripeProductIndex: any; //sets string or undefined by default
  setSuccessfulPaymentUrl: any;
  setCancelPaymentUrl: any;
  setStripePaymentButtonMode: any;

  changeButtonIsRelayUTM: any;

  // added 11 august 2021
  changeButtonAttributes: any;
  changeButtonClass: any;
  changeButtonButtonID: any;
  changeButtonOnClick: any;

  contentType: any;
  currentInputData: any;
  buttonIndex: number;
  componentType: string;

  saveContentInStore: (payload: object) => void;
  toggleNewEditionsDetected: (payload: boolean) => void;

  componentDataPath: ComponentDataPaths;
}

interface MyState {
  showSaveStripeAPIButtonSuccess: boolean;
  current_button_payment_stripe_product_id: string;
  current_button_payment_success_url: string;
  current_button_payment_cancel_url: string;
  stripe_public_key: string;
  stripeConfigDrawerVisible: boolean;
  learnMoreStripeVisible: boolean;
  learnMoreUTMrelayVisible: boolean;
}
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

class ButtonPluginsDrawer extends Component<Props, MyState> {
  componentWillMount(): void {
    this.setState({
      showSaveStripeAPIButtonSuccess: false,
      current_button_payment_stripe_product_id:
        this.props.currentButtonObject.stripeProductId, //stripeProductId and other currentButtonObject properties is from frontend/src/helpers/editor/generateContentDataObject.ts
      current_button_payment_success_url:
        this.props.currentButtonObject.successfulPaymentUrl,
      current_button_payment_cancel_url:
        this.props.currentButtonObject.cancelPaymentUrl,

      stripe_public_key: this.props.currentWebsite
        .connected_payment_integrations.stripe_public_key
        ? this.props.currentWebsite.connected_payment_integrations
            .stripe_public_key
        : "",
    });
  }

  // state = {
  //     stripeConfigDrawerVisible: false,
  //     stripe_public_key: '', // Default value is empty.
  //     learnMoreStripeVisible: false,
  //     learnMoreUTMrelayVisible: false,
  //
  //     current_button_payment_stripe_product_id: undefined,
  //     current_button_payment_success_url: '',
  //     current_button_payment_cancel_url: '',
  // };

  showStripeConfigDrawer = () => {
    this.setState({
      stripeConfigDrawerVisible: true,
    });
  };

  onStripeConfigDrawerClose = () => {
    this.setState({
      stripeConfigDrawerVisible: false,
    });
  };

  saveStripeIntegrationObjectOnServer = (
    newPaymentIntegrationsObject: any,
    toggleWaiting: any,
    callback?: any
  ) => {
    toggleWaiting(true);

    let accessToken = this.props.auth.accessToken;
    let apiUrl =
      api.websites.updateWebsite.prefix +
      this.props.currentWebsite.subdomain +
      api.websites.updateWebsite.postfix;

    axios
      .patch(
        apiUrl,
        { connected_payment_integrations: { ...newPaymentIntegrationsObject } },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then(() => {
        // set the Stripe object and demo product to the currentWebsite "connected_payment_integrations"
        // let payload = {
        //     currentWebsiteSubdomain: this.props.currentWebsite.subdomain,
        //     newPaymentObject: response.data.connected_payment_integrations
        // };

        this.props.saveWebsiteDataInStore(
          {
            data: {
              ...this.props.currentWebsite,
              connected_payment_integrations: {
                ...newPaymentIntegrationsObject,
              },
            },
          },
          this.props.currentWebsite.subdomain
        );

        if (callback) {
          // Sometimes we want to show a notification
          callback();
        }
      })
      .catch((error) => {
        if (error.response) {
          let errorData = error.response.data;
          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            // let errorObjectKey = getErrorKey(errorData);
            // let errorObjectValue = getErrorValue(errorData, errorObjectKey);
            //
            // fireAnalyticsEvent.changePageMetaTitleError(this.props.currentWebsite.subdomain, websitePageUrlToServer, newMetaTitle, errorObjectKey, errorObjectValue);

            // This happens when user tries to change a website which doesn't exist or doesn't belog to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }

          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }
        }
      })
      .then((response) => {
        // always executed
        toggleWaiting(false);
      });
  };

  handleUpdateStripePublicKey = (e: any) => {
    e.preventDefault();
    let newPaymentObject = {
      ...this.props.currentWebsite.connected_payment_integrations,
    };
    newPaymentObject.stripe_public_key = this.state.stripe_public_key;

    // save on the server.
    this.saveStripeIntegrationObjectOnServer(
      newPaymentObject,
      this.props.toggleWaitingForConfigurePaymentIntegration,
      this.launchSuccessfulSaveAnimation()
    );
  };
  handleStripePublicKeyInputChange = (e: any) => {
    this.setState({ stripe_public_key: e.target.value });
  };

  handleLearnMoreStripeVisibleChange = (learnMoreStripeVisible) => {
    this.setState({ learnMoreStripeVisible });
  };
  handleLearnMoreUTMrelayVisibleChange = (learnMoreUTMrelayVisible) => {
    this.setState({ learnMoreUTMrelayVisible });
  };

  handleRemoveStripeProduct = () => {
    // When we want to remove a Stripe integration from a button we set the button's stripeProductId === undefined
    this.setState({
      current_button_payment_stripe_product_id: undefined,
    });
    this.props.setStripeProductIndex(undefined);
  };

  handleUpdateButtonProductId = (e: any) => {
    e.preventDefault();

    let newId = e.target.value;

    this.props.setStripeProductIndex(newId);
  };
  handleButtonProductIdInputChange = (e: any) => {
    this.setState({
      current_button_payment_stripe_product_id: e.target.value,
    });
  };

  handleUpdateButtonSuccessUrl = (e: any) => {
    e.preventDefault();

    let newSuccessfulPaymentUrl = e.target.value;

    this.props.setSuccessfulPaymentUrl(newSuccessfulPaymentUrl);
  };
  handleButtonSuccessUrlInputChange = (e: any) => {
    this.setState({
      current_button_payment_success_url: e.target.value,
    });
  };

  handleUpdateButtonMode = (e: any) => {
    e.preventDefault();

    let newMode = e.target.value;

    this.props.setStripePaymentButtonMode(newMode);
  };

  handleUpdateButtonCancelUrl = (e: any) => {
    e.preventDefault();

    let newCancelPaymentUrl = e.target.value;

    this.props.setCancelPaymentUrl(newCancelPaymentUrl);
  };
  handleButtonCancelUrlInputChange = (e: any) => {
    this.setState({
      current_button_payment_cancel_url: e.target.value,
    });
  };

  displayUpdatePublicKey = () => {
    let isSaveButtonHidden = false;
    try {
      isSaveButtonHidden =
        this.props.currentWebsite.connected_payment_integrations
          .stripe_public_key === this.state.stripe_public_key;
    } catch (e) {}

    return (
      <div className="settings-box__input" style={{marginTop: 20}}>
        <div className="content-input__label_box">
          <span className="content-input__label">Publishable key</span>
          <div>
            <Popover
              placement="bottomRight"
              content={
                <div style={{ width: "500px" }}>
                  Kindly go to your{" "}
                  <a href={urls.stripeApiKeys.dashboard} target="_blank">
                    Stripe dashboard
                  </a>
                  , copy the Publishable key and paste here.
                  <br />
                  <br />
                  <a href={urls.assets.stripePublicApiKey} target="_blank">
                    <img
                      style={{ width: "100%" }}
                      src={urls.assets.stripePublicApiKey}
                      alt="Public key in the Stripe dashboard."
                    />
                  </a>
                </div>
              }
            >
              <Icon className="content-input__tooltip_icon" type="info-circle" />
            </Popover>
          </div>
        </div>
        <form
          onSubmit={this.handleUpdateStripePublicKey}
          className="settings-box__form"
        >
          <div style={{ marginRight: 8 }}>
            <Input
              name="stripe_public_key"
              id="stripe_public_key"
              required={false}
              placeholder="pk_poGiWG19FPolklsGjWJ4mta1"
              type="text"
              value={this.state.stripe_public_key}
              onChange={this.handleStripePublicKeyInputChange}
              style={{width: 235}}
            />
          </div>
          <MyButton
            className="settings-box__form_save"
            icon="save"
            type="primary"
            showButtonSuccess={this.state.showSaveStripeAPIButtonSuccess}
            loading={
              this.props.websites
                .isWaitingForConfigurePaymentIntegrationServerResponse
            }
            htmlType="submit"
            hidden={isSaveButtonHidden}
          />
        </form>
      </div>
    );
  };

  openChat = (e: any) => {
    e.preventDefault();
    openChatWidget();
  };

  displayUTMRelay = () => {
    let learnMoreJSX = (
      <div style={{ width: "500px" }}>
        <span>
          Activate the plugin to convey all UTMs parameters from the URL of the
          landing page to the "href" attribute of the button. Use it if you want
          to transfer UTMs to your app or another website. <br /> Works with any
          other '?get' params such as 'ref'. <br />
          <br />
          Learn more{" "}
          <a href={urls.guides.utmRelay} target="_blank">
            here <Icon type="link" />
          </a>
          .
        </span>
      </div>
    );

    return (
      <div className={"plugins_drawer__option plugins_drawer__option--utm "}>
        <div className="plugins_drawer__option_header">
          <div className="plugins_drawer__option_left">
            <div className="plugins_drawer__option_info">
              <h3 className="plugins_drawer__option_title">
                <span className="plugins_drawer__option_title_word">
                  <img src={Relay} height="40" />
                  <span className="plugins_drawer__option_title_word_text">
                    UTM Relay
                  </span>
                </span>
              </h3>
            </div>
          </div>

          <div className="plugins_drawer__option_right">
            <Switch
              style={{ marginRight: 15 }}
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="close" />}
              defaultChecked={this.props.currentButtonObject.isRelayUTM}
              onChange={(checked: boolean) => {
                this.props.changeButtonIsRelayUTM(checked);
              }}
            />

            <Popover
              placement="leftTop"
              content={learnMoreJSX}
              title="Learn more about the UTM Relay plugin."
              trigger="click"
              visible={this.state.learnMoreUTMrelayVisible}
              onVisibleChange={this.handleLearnMoreUTMrelayVisibleChange}
            >
              <Button
                style={{ color: "white", borderColor: "white" }}
                className=""
                type="ghost"
                icon="question"
              />
            </Popover>
          </div>
        </div>
      </div>
    );
  };

  displayStripeCheckout = () => {
    let isWaitingForConfigurePaymentIntegrationServerResponse =
      this.props.websites.isWaitingForConfigurePaymentIntegrationServerResponse;

    let learnMoreJSX = (
      <div style={{ width: "500px" }}>
        Stripe Checkout is a secure Stripe-hosted payment page that allows you
        collecting payments. Unicorn Platform supports both one-time and
        recurring payments. <br />
        <br />
        You can read more, get self-support and see examples in our
        human-friendly{" "}
        <a href={urls.guides.stripeCheckoutGuide} target="_blank">
          guide <Icon type="link" />
        </a>
        . <br />
        <br />
        Setting up difficulty: <em>easy</em>.<br />
        Requirements: Stripe Business account, HTTPS-protected website, Stripe
        Checkout activated).
        <br />
        <br />
        Need help? Click{" "}
        <Button
          size="small"
          onClick={(e) => {
            this.openChat(e);
          }}
        >
          here <Icon type="message" />
        </Button>{" "}
        to talk to a human and get quick support.
      </div>
    );

    let customDomain = this.props.currentWebsite.custom_domain;

    let isStripeApiKeyEmpty =
      this.props.currentWebsite.connected_payment_integrations
        .stripe_public_key === undefined ||
      this.props.currentWebsite.connected_payment_integrations
        .stripe_public_key === "";

    let isProductIDEmpty =
      this.state.current_button_payment_stripe_product_id === undefined ||
      this.state.current_button_payment_stripe_product_id === "";

    let infoText = "Enter ID and purchase flow URLs.";

    if (isProductIDEmpty) {
      infoText = "Enter Stripe product Pricing ID.";
    }
    return (
      <div className={"plugins_drawer__option plugins_drawer__option--stripe "}>
        <div className="plugins_drawer__option_header">
          <div className="plugins_drawer__option_left">
            <div className="plugins_drawer__option_info">
              <h3 className="plugins_drawer__option_title">
                <svg
                  className="plugins_drawer__option_title_logo"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 468 222.5"
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      className="stripe_logo_fill"
                      d="M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3
                                                            c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z
                                                             M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z"
                    />
                    <path
                      className="stripe_logo_fill"
                      d="M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3
            c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1
            c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z"
                    />
                    <polygon
                      className="stripe_logo_fill"
                      points="223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 	"
                    />
                    <rect
                      x="223.8"
                      y="69.3"
                      className="stripe_logo_fill"
                      width="25.1"
                      height="87.5"
                    />
                    <path
                      className="stripe_logo_fill"
                      d="M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z"
                    />
                    <path
                      className="stripe_logo_fill"
                      d="M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135
            c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z"
                    />
                    <path
                      className="stripe_logo_fill"
                      d="M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6
            C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7
            c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z"
                    />
                  </g>
                </svg>

                <span className="plugins_drawer__option_title_word">
                  <span className="plugins_drawer__option_title_word_text">
                    Checkout
                  </span>{" "}
                  <img
                    src={StripePaymentsLogo}
                    className="plugins_drawer__option_title_word_logo"
                    alt="Stripe payments logo"
                  />
                </span>
              </h3>
            </div>
          </div>

          <div className="plugins_drawer__option_right">
            <Popover
              placement="leftTop"
              content={learnMoreJSX}
              title="Stripe integration details"
              trigger="click"
              visible={this.state.learnMoreStripeVisible}
              onVisibleChange={this.handleLearnMoreStripeVisibleChange}
            >
              <Button
                style={{ marginRight: 15, color: "white" }}
                className=""
                type="ghost"
                icon="question"
              />
            </Popover>

            {/* At first a user must enter API key, only then enter Product ID and purchase success/cancel URL */}
            {isStripeApiKeyEmpty && (
              <Button
                onClick={() => {
                  this.showStripeConfigDrawer();
                }}
                loading={isWaitingForConfigurePaymentIntegrationServerResponse}
                type="primary"
                style={{ color: "white" }}
                icon="api"
              >
                Set up Stripe
              </Button>
            )}

            {/* After API key is entered, it can be edited in the future by clicking this button */}
            {isStripeApiKeyEmpty === false && (
              <Button
                onClick={() => {
                  this.showStripeConfigDrawer();
                }}
                loading={isWaitingForConfigurePaymentIntegrationServerResponse}
                type="ghost"
                style={{ color: "white" }}
                icon="setting"
              />
            )}
          </div>
        </div>
        {isStripeApiKeyEmpty === false && (
          <div className="plugins_drawer__option_body">
            <div className="plugins_drawer__option_body_products">
              <h3>
                Stripe Checkout details
                <span className="content-input__title_info">{infoText}</span>
              </h3>
              <div className="plugins_drawer__option_body_products_items">
                <div>
                  <div className="settings-box__input">
                    <div className="settings-box__input_label">
                      <div>
                        <Popover
                          placement={"left"}
                          content={
                            <div style={{ width: 500 }}>
                              Enter a product Price ID.
                              <h3>For one-time purchase product:</h3>
                              <ol style={{ paddingLeft: 40 }}>
                                <li>
                                  Go to the{" "}
                                  <a
                                    href={urls.stripe.products}
                                    target="_blank"
                                  >
                                    products list <Icon type={"link"} />
                                  </a>
                                  .
                                </li>
                                <li>Click on a product.</li>
                                <li>
                                  Copy Price ID (
                                  <a
                                    href={urls.assets.copyStripePricingId}
                                    target="_blank"
                                  >
                                    see screenshot <Icon type={"link"} />
                                  </a>
                                  ).
                                </li>
                                <li>Paste the copied Price ID here.</li>
                              </ol>
                              <br />
                              <h3>For recurring products (Price ID):</h3>
                              <ol style={{ paddingLeft: 40 }}>
                                <li>
                                  Go to the{" "}
                                  <a
                                    href={urls.stripe.products}
                                    target="_blank"
                                  >
                                    products list <Icon type={"link"} />
                                  </a>
                                  .
                                </li>
                                <li>Click on a product.</li>
                                <li>
                                  Copy Price ID (
                                  <a
                                    href={urls.assets.copyStripePricingId}
                                    target="_blank"
                                  >
                                    see screenshot <Icon type={"link"} />
                                  </a>
                                  ).
                                </li>
                                <li>Paste the copied Price ID here.</li>
                              </ol>
                              <br />
                              <span>
                                If you do not have a product or a plan, navigate
                                to you{" "}
                                <a href={urls.stripe.products} target="_blank">
                                  Stripe products list <Icon type={"link"} />
                                </a>{" "}
                                screen and create a product or a plan (
                                <a
                                  href={urls.assets.createStripeProduct}
                                  target="_blank"
                                >
                                  see screenshot <Icon type={"link"} />
                                </a>
                                ).
                              </span>
                              <br />
                              <br />
                              <span>
                                This looks overwhelming, huh? 😓 <br /> We
                                created a minimalistic step-by-step{" "}
                                <a
                                  target="_blank"
                                  href={urls.guides.stripeCheckoutGuide}
                                >
                                  guide <Icon type={"link"} />
                                </a>{" "}
                                for you.
                              </span>
                            </div>
                          }
                        >
                          <Icon type="info-circle" />
                        </Popover>
                      </div>
                      <div className="settings-box__input_info_text">
                        Price ID:
                      </div>
                    </div>

                    <form className="settings-box__form">
                      <div className="plugins_drawer__fields">
                        <Input
                          id="stripe_product_id"
                          name="stripe_product_id"
                          placeholder="price_GdYmBZB"
                          value={
                            this.state.current_button_payment_stripe_product_id
                              ? this.state
                                  .current_button_payment_stripe_product_id
                              : ""
                          }
                          type="text"
                          required={false}
                          onChange={this.handleButtonProductIdInputChange}
                          onBlur={this.handleUpdateButtonProductId}
                        />

                        {/*previously added buttons do not have the stripePaymentMode property.*/}
                        {this.props.currentButtonObject.stripePaymentMode &&
                          isProductIDEmpty === false && (
                            <Radio.Group
                              style={{ marginTop: 10 }}
                              onChange={this.handleUpdateButtonMode}
                              defaultValue={
                                this.props.currentButtonObject.stripePaymentMode
                              }
                            >
                              <Radio value={"payment"}>One-time</Radio>
                              <Radio value={"subscription"}>Recurring</Radio>
                            </Radio.Group>
                          )}
                      </div>
                    </form>
                  </div>

                  {isProductIDEmpty === false && (
                    <div className="settings-box__input">
                      <div className="settings-box__input_label">
                        <div>
                          <Popover
                            content={
                              <div style={{ width: 500 }}>
                                Please enter a page URL where your visitors will
                                be redirected after a <b>successful payment</b>.
                                The default behavior is redirecting a visitor to
                                the same page and opening a minimalistic
                                "successful payment" popup. <br />
                                <br />
                                Stripe requires a <b>full URL</b> to be entered.
                                <br />
                                <br />
                                <Icon
                                  type="smile"
                                  style={{ color: "var(--grass)" }}
                                />{" "}
                                Good example:{" "}
                                <code>https://my-domain.com/congrats</code>
                                <br />
                                <br />
                                <Icon
                                  type="close"
                                  style={{ color: "var(--blood)" }}
                                />{" "}
                                Bad examples: <code>/congrats</code>,{" "}
                                <code>my-domain.com/congrats</code>
                              </div>
                            }
                          >
                            <Icon type="info-circle" />
                          </Popover>
                        </div>
                        <div className="settings-box__input_info_text">
                          Successful payment URL:
                        </div>
                      </div>

                      <form className="settings-box__form">
                        <div className="plugins_drawer__fields">
                          <Input
                            id="product_success_url"
                            name="product_success_url"
                            placeholder={`https://${
                              customDomain ? customDomain : "your-domain.com"
                            }/success`}
                            value={
                              this.state.current_button_payment_success_url
                            }
                            type="text"
                            required={false}
                            onChange={this.handleButtonSuccessUrlInputChange}
                            onBlur={this.handleUpdateButtonSuccessUrl}
                          />
                          {/*<a title="Open the Successful payment URL in a new tab." target="_blank" href={this.state.current_button_payment_success_url} className="plugins_drawer__test_url"><Icon type="link"/></a>*/}
                        </div>
                      </form>
                    </div>
                  )}

                  {isProductIDEmpty === false && (
                    <div className="settings-box__input">
                      <div className="settings-box__input_label">
                        <div>
                          <Popover
                            content={
                              <div style={{ width: 500 }}>
                                Please enter a page URL where your visitors will
                                be redirected if they <b>cancel payment</b>. The
                                default behavior is redirecting a visitor to the
                                same page and opening a minimalistic "canceled
                                payment" popup. <br />
                                <br />
                                Stripe requires a <b>full URL</b> to be entered.
                                <br />
                                <br />
                                <Icon
                                  type="smile"
                                  style={{ color: "var(--grass)" }}
                                />{" "}
                                Good example:{" "}
                                <code>https://my-domain.com/canceled</code>
                                <br />
                                <br />
                                <Icon
                                  type="close"
                                  style={{ color: "var(--blood)" }}
                                />{" "}
                                Bad examples: <code>/canceled</code>,{" "}
                                <code>my-domain.com/canceled</code>
                              </div>
                            }
                          >
                            <Icon type="info-circle" />
                          </Popover>
                        </div>
                        <div className="settings-box__input_info_text">
                          Cancel payment URL:
                        </div>
                      </div>

                      <form className="settings-box__form">
                        <div className="plugins_drawer__fields">
                          <Input
                            id="product_cancel_url"
                            name="product_cancel_url"
                            placeholder={`https://${
                              customDomain ? customDomain : "your-domain.com"
                            }/cancelled`}
                            value={this.state.current_button_payment_cancel_url}
                            type="text"
                            required={false}
                            onChange={this.handleButtonCancelUrlInputChange}
                            onBlur={this.handleUpdateButtonCancelUrl}
                          />
                          {/*<a title="Open the Cancelled payment URL in a new tab." target="_blank" href={this.state.current_button_payment_cancel_url} className="plugins_drawer__test_url"><Icon type="link"/></a>*/}
                        </div>
                      </form>
                    </div>
                  )}

                  {isProductIDEmpty === false && (
                    <Tooltip
                      title={
                        <span>
                          Remove Stripe Checkout plugin from this button and
                          make it act as a normal link.
                        </span>
                      }
                    >
                      <Button
                        onClick={this.handleRemoveStripeProduct}
                        icon="poweroff"
                        type="danger"
                        size="small"
                        ghost
                      >
                        Disconnect Stripe Checkout
                      </Button>
                    </Tooltip>
                  )}
                </div>

                {/* Add 'test <ICON>' link */}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  launchSuccessfulSaveAnimation = () => {
    this.setState({
      showSaveStripeAPIButtonSuccess: true,
    });

    setTimeout(() => {
      this.setState({
        showSaveStripeAPIButtonSuccess: false,
      });
    }, 1500);
  };
  render() {
    const isPopupConfigVisible =
      this.props.componentType && this.props.componentType !== "popups";
    return (
      <div className="plugins_drawer">
        <Tabs defaultActiveKey={"payment"} size="small">
          <TabPane tab="Payment" key={"payment"}>
            {this.displayStripeCheckout()}
          </TabPane>
          {isPopupConfigVisible && (
            <TabPane tab="Popup" key={"popup"}>
              <PopupMenu
                popupType="button"
                contentType={this.props.contentType}
                currentInputData={this.props.currentInputData}
                saveContentInStore={this.props.saveContentInStore}
                toggleNewEditionsDetected={this.props.toggleNewEditionsDetected}
                buttonIndex={this.props.buttonIndex}
                componentDataPath={this.props.componentDataPath}
              />
            </TabPane>
          )}
          <TabPane tab="Marketing" key={"tools"}>
            {this.displayUTMRelay()}
          </TabPane>
          <TabPane tab="Settings" key={"settings"}>
            <ButtonSettings
              buttonObject={this.props.currentButtonObject}
              changeButtonAttributes={this.props.changeButtonAttributes}
              changeButtonClass={this.props.changeButtonClass}
              changeButtonButtonID={this.props.changeButtonButtonID}
              changeButtonOnClick={this.props.changeButtonOnClick}
            />
          </TabPane>
        </Tabs>

        <Drawer
          width={300}
          title={
            <DrawerTitle
              title="Stripe public API key"
              onClose={this.onStripeConfigDrawerClose}
            />
          }
          placement="right"
          closable={false}
          onClose={this.onStripeConfigDrawerClose}
          visible={this.state.stripeConfigDrawerVisible}
          className={"edit-component-drawer " + generatePopupDrawerClass(this.props.componentType)}
        >
          {this.displayUpdatePublicKey()}
        </Drawer>
      </div>
    );
  }
}

export default ButtonPluginsDrawer;
