import React from "react";
import { Switch } from "antd";
import { connect } from "react-redux";
import {
  toggleWebsiteCaptchaEditionsDetected,
  saveWebsiteCaptchaInState,
} from "../../../store/websites/actions";
import ContentInputLabel from "../../ui_components/ContentInputLabel";
import _ from "lodash";

interface Props {
  currentWebsite: any;
  toggleWebsiteCaptchaEditionsDetected?: (payload: boolean) => void;
  saveWebsiteCaptchaInState?: (payload: object) => void;
}

class CaptchaSwitch extends React.Component<Props> {
  info = {
    title: "Enable reCAPTCHA",
    popover: (
      <div className="content-input__tooltip-content">
        <p>Enable invisible reCAPTCHA for every form on your website.</p>
      </div>
    ),
  };

  handleChange = (checked: boolean) => {
    const captchaObject = {
      ...this.props.currentWebsite.recaptcha,
      is_enabled: checked,
    };
    const currentWebsiteSubdomain = this.props.currentWebsite.subdomain;
    this.props.toggleWebsiteCaptchaEditionsDetected(true);
    this.props.saveWebsiteCaptchaInState({
      currentWebsiteSubdomain,
      captchaObject,
    });
  };

  render() {
    const isCaptchaEnabled = _.get(
      this.props.currentWebsite,
      "recaptcha.is_enabled",
      false
    );
    return (
      <div className="captcha-drawer__section" style={{marginTop: 20}}>
        <ContentInputLabel title={this.info.popover}>
          {this.info.title}
        </ContentInputLabel>
        <Switch
          defaultChecked={isCaptchaEnabled}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export default connect(null, {
  toggleWebsiteCaptchaEditionsDetected,
  saveWebsiteCaptchaInState,
})(CaptchaSwitch);
