import React, { Component } from 'react';
import DebouncedInput from '../../DebouncedInput';
import { defaultBgImageOverlayOpacity } from "../../../data/constants";
import './BackgroundImagePicker.css'
import BackgroundTransparencySlider from './BackgroundTransparencySlider';

interface Props {
    currentWebsite: any;
    componentItem: any;
    toggleNewEditionsDetected: any;
    saveComponentBackgroundImageOverlayOpacityInStore: any;

    placeholder?: string;
    debounceDelay?: number;
    onChange?: (value: string) => void;
    currentBackgroundVideoSrc?: string;
}

class BackgroundVideoPicker extends Component<Props> {

    handleChangeBgImageTransparency(value: number) {
        this.props.toggleNewEditionsDetected(true);
        this.props.saveComponentBackgroundImageOverlayOpacityInStore(value);
    };

    setCurrentBgImageTransparencyValue() {
        const props = this.props;
        if (props.componentItem && props.componentItem.settings && props.componentItem.settings.background) {
            if (props.componentItem.settings.background.overlayOpacity !== undefined) {
                return props.componentItem.settings.background.overlayOpacity;
            }
        }
        return defaultBgImageOverlayOpacity;
    }

    displayOpacitySlider() {
        if (this.props.currentBackgroundVideoSrc !== '') {
            return (
                <BackgroundTransparencySlider
                    label="Transparency"
                    step={0.01}
                    value={this.setCurrentBgImageTransparencyValue()}
                    tipFormatter={(value: number) => `${Math.round(value * 100)}%`}
                    onChange={(value: any) => {
                        this.handleChangeBgImageTransparency(value);
                    }}
                    defaultValue={30}
                    min={0}
                    max={1}
                />
            );
        }
        return null;
    }

    render() {
        const props = this.props;
        return (
            <div>
                <DebouncedInput
                    placeholder={props.placeholder}
                    debounceDelay={props.debounceDelay}
                    onChange={props.onChange}
                    defaultValue={props.currentBackgroundVideoSrc}
                />
                {this.displayOpacitySlider()}
            </div>
        );
    }
}

export default BackgroundVideoPicker;
