import React from "react";
import { Button, Checkbox, Tooltip, Input } from "antd";
import { InputProps } from "./interfaces";
import { draftJsEditorTitle } from "../../../data/constants";
import { getFullWebsiteUrl } from "../../../helpers/editor/getFullWebsiteUrl";
import { buttonStyles } from "../../../data/buttonStyles";
import DebouncedInput from "../../DebouncedInput";
import DebouncedTextarea from "../../DebouncedTextarea";
import checkIsButtonHrefDisabled from "../../../helpers/editor/checkIsButtonHrefDisabled";
import MyRichTextEditor from "../../MyRichTextEditor";
import ImagePicker from "../ImagePicker";
import { GraphicsOptions } from "../component_graphics/data/componentGraphicsData";
import { useCurrentInputData } from "./useCurrentInputData";
import { Components } from "../../../enums/Components";
import { postsWithOneItem } from "../../../data/componentExceptions";
import HorInputGroup from "../../ui_components/HorInputGroup";

const Posts = (props: InputProps): JSX.Element => {
  const {
    content,
    currentInputData,
    saveContentInStore,
    generator,
    contentType,
    toggleNewEditionsDetected,
    displayMoveUpDownButtons,
    currentWebsite,
    displayPictogramEditorOpener,
    displayButtonStyleEditorOpener,
    setState,
    state,
    displayButtonPluginsDrawerOpener,
    textEditorType,
    revertToPlainHTML,
    displayLogoEditor,
    saveImageInfoInDB,
    removeImageFromDB,
    componentType,
    componentTitle,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  const componentName = `${componentType}-${componentTitle}` as Components;
  const isSingleItem = postsWithOneItem.includes(componentName);

  let maxItemsReached = currentInputDataRef.current.maxItems === content.items.length;

  let postsInputs = content.items.map((item: any, key: number) => {
    if (isSingleItem && key > 0) {
      return null;
    }
    let doesButtonHavePluginActionInsteadOfHref = false;
    if (currentInputDataRef.current.hasButton) {
      doesButtonHavePluginActionInsteadOfHref = checkIsButtonHrefDisabled(
        item.button,
        currentWebsite
      );
    }

    let onChangePostTitleHandler = (value: any) => {
      saveContentInStore(
        generator.changePostTitle(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangePostSubtitleHandler = (value: any) => {
      saveContentInStore(
        generator.changePostSubtitle(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangePostTextHandler = (value: any) => {
      saveContentInStore(
        generator.changePostText(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangePostHrefHandler = (value: any) => {
      saveContentInStore(
        generator.changePostHref(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangePostButtonPillPrefixHandler = (value: any) => {
      saveContentInStore(
        generator.changePostButtonPillPrefix(
          { [contentType]: currentInputDataRef.current },
          value,
          key
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangePostButtonTitleHandler = (value: any) => {
      saveContentInStore(
        generator.changePostButtonTitle(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangePostButtonHrefHandler = (value: any) => {
      saveContentInStore(
        generator.changePostButtonHref(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };

    let returnPostTextField = () => {
      if (textEditorType && textEditorType === draftJsEditorTitle) {
        return (
          <div className="content-input__input">
            <MyRichTextEditor
              revertToPlainHTML={revertToPlainHTML}
              placeholder="Enter post description"
              defaultValue={item.text}
              onChange={onChangePostTextHandler}
            />
          </div>
        );
      } else {
        return (
          <DebouncedTextarea
            className="content-input__input"
            onChange={onChangePostTextHandler}
            defaultValue={item.text}
            autosize={{ minRows: 1, maxRows: 6 }}
            placeholder="Enter post description"
          />
        );
      }
    };

    return (
      <div className={"content-input__cluster "} key={"posts-" + key}>
        {!isSingleItem && <Button
          onClick={() => {
            toggleNewEditionsDetected(true);
            saveContentInStore(
              generator.deletePost({ [contentType]: currentInputDataRef.current }, key)
            );
          }}
          htmlType="button"
          className="content-input__control_cluster"
          shape="circle"
          title="Delete this post"
          icon="cross"
          size="small"
        />}

        {!isSingleItem && displayMoveUpDownButtons(
          key,
          content.items.length,
          () => {
            saveContentInStore(
              generator.movePostItemUp({ [contentType]: currentInputDataRef.current }, key)
            );
          },
          () => {
            saveContentInStore(
              generator.movePostItemDown(
                { [contentType]: currentInputDataRef.current },
                key
              )
            );
          }
        )}

        {currentInputDataRef.current.hasHighlight && (
          <Checkbox
            className="content-input__input"
            checked={item.isHighlighted}
            onChange={(event: any) => {
              saveContentInStore(
                generator.changePostIsHighlighted(
                  {
                    [contentType]: currentInputDataRef.current,
                  },
                  key,
                  event.target.checked
                )
              );
              toggleNewEditionsDetected(true);
            }}
          >
            Highlighted item
          </Checkbox>
        )}

        {currentInputDataRef.current.hasPictogram &&
          displayPictogramEditorOpener(item.pictogram, key)}

        {currentInputDataRef.current.hasLogo && (
          <div className="content-input__group">
            {displayLogoEditor(item.logo, key)}
          </div>
        )}

        {currentInputDataRef.current.hasThumb && (
          <div className="content-input__input">
            <ImagePicker
              currentImgAlt={item.thumb[GraphicsOptions.image].alt}
              saveImageAltInReduxStore={(newAlt: string) => {
                saveContentInStore(
                  generator.changePostImageAlt(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    newAlt,
                    key
                  )
                );

                toggleNewEditionsDetected(true);
              }}
              currentImgSrc={item.thumb[GraphicsOptions.image].url}
              currentImgUUID={item.thumb[GraphicsOptions.image].uuid}
              inputId={contentType}
              saveImageInfoInDB={saveImageInfoInDB}
              removeImageFromDB={removeImageFromDB}
              saveImageInReduxStore={(
                newSrc: string,
                newUUID: any,
                width: any,
                height: any
              ) => {
                saveContentInStore(
                  generator.changePostImageUrl(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    newSrc,
                    key
                  )
                );
                saveContentInStore(
                  generator.changePostImageUUID(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    newUUID,
                    key
                  )
                ); //'' for no image

                saveContentInStore(
                  generator.changePostImageWidth(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    width,
                    key
                  )
                );
                saveContentInStore(
                  generator.changePostImageHeight(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    height,
                    key
                  )
                );

                toggleNewEditionsDetected(true);
              }}
            />
          </div>
        )}

        {currentInputDataRef.current.hasBgColor && (
          <Tooltip title={<span>Change background color of the item.</span>}>
            <input
              className="ant-input content-input__input"
              style={{ width: "60px", cursor: "pointer" }}
              type="color"
              onChange={(event: any) => {
                saveContentInStore(
                  generator.changePostBgColor(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    key,
                    event.target.value
                  )
                );
                toggleNewEditionsDetected(true);
              }}
              value={item.bgColor}
              placeholder="#ffffff"
            />
          </Tooltip>
        )}

        <div className="content-input__group">
          {currentInputDataRef.current.hasTitle && (
            <DebouncedInput
              className="content-input__input"
              onChange={onChangePostTitleHandler}
              defaultValue={item.title}
              placeholder="Enter post title"
            />
          )}

          {/*If a Post template doesn't offer a button, it's clickable itself.*/}

          {currentInputDataRef.current.hasSubtitle && (
            <DebouncedInput
              className="content-input__input"
              onChange={onChangePostSubtitleHandler}
              defaultValue={item.subtitle}
              placeholder="Add subtitle"
            />
          )}

          {currentInputDataRef.current.hasText && returnPostTextField()}

          {/*If a Post template doesn't offer a button, it's clickable itself.*/}
          {currentInputDataRef.current.hasButton === false && (
            <DebouncedInput
              className="content-input__input"
              onChange={onChangePostHrefHandler}
              defaultValue={item.href}
              placeholder={
                getFullWebsiteUrl(currentWebsite) +
                "/post-" +
                Math.floor(Math.random() * 20 + 10)
              } //e.g. https://stripe.com/case-12
            />
          )}
          {currentInputDataRef.current.hasButton === false && item.href !== "" && (
            <Checkbox
              className="content-input__input"
              checked={item.isTargetBlank}
              onChange={(event: any) => {
                saveContentInStore(
                  generator.changePostIsTargetBlank(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    key,
                    event.target.checked
                  )
                );
                toggleNewEditionsDetected(true);
              }}
            >
              Open in a new tab
            </Checkbox>
          )}
        </div>
        {currentInputDataRef.current.hasButton && (
          <div className="content-input__group">
            {item.button.title !== "" &&
              displayButtonStyleEditorOpener(item.button, () => {
                setState({
                  ...state,
                  currentPostIndex: key, //we need to know the index of a post to edit it's button. Same for any other countable elements with buttons or pictograms.
                  buttonStylesOptions: currentInputDataRef.current.buttonTypes,
                  currentButtonObject: item.button,
                });
              })}

            {/*If this button has an active styleType === 'pill' we show additional field - for prefix.*/}
            <HorInputGroup gap="small">
              <Input.Group compact style={{display: "flex"}}>
                {item.button.title !== "" &&
                  item.button.settings.appearance.styleType ===
                    buttonStyles.pill.typeTitle && (
                    <DebouncedInput
                      onChange={onChangePostButtonPillPrefixHandler}
                      defaultValue={item.button.pillPrefix}
                      placeholder="New post!"
                      className="content-input__input"
                      style={{width: 64}}
                    />
                  )}

                {
                  <DebouncedInput
                    className="content-input__input"
                    onChange={onChangePostButtonTitleHandler}
                    defaultValue={item.button.title}
                    placeholder="Read full story"
                  />
                }
              </Input.Group>
              {
                <div className="content-input__input">
                  <DebouncedInput
                    className=""
                    onChange={onChangePostButtonHrefHandler}
                    defaultValue={item.button.href}
                    disabled={doesButtonHavePluginActionInsteadOfHref}
                    addonAfter={
                      // Adds plugins (payment, UTM relay etc) to a button.
                      displayButtonPluginsDrawerOpener(item.button, key)
                    }
                    placeholder={
                      getFullWebsiteUrl(currentWebsite) +
                      "/post-" +
                      Math.floor(Math.random() * 20 + 10)
                    } //e.g. https://stripe.com/case-12
                  />
                </div>
              }
            </HorInputGroup>
            {doesButtonHavePluginActionInsteadOfHref === false &&
              item.button.href !== "" &&
              item.button.title !== "" && (
                <Checkbox
                  className="content-input__input"
                  checked={item.button.isTargetBlank}
                  onChange={(event: any) => {
                    saveContentInStore(
                      generator.changePostButtonIsTargetBlank(
                        {
                          [contentType]: currentInputDataRef.current,
                        },
                        key,
                        event.target.checked
                      )
                    );
                    toggleNewEditionsDetected(true);
                  }}
                  style={{marginTop: 10}}
                >
                  Open in a new tab
                </Checkbox>
              )}
          </div>
        )}
      </div>
    );
  });

  return (
    <div>
      {postsInputs}

      {maxItemsReached === false && !isSingleItem && (
        <Button
          size="small"
          onClick={() => {
            toggleNewEditionsDetected(true);
            saveContentInStore(
              generator.addEmptyPostIfNeeded({
                [contentType]: currentInputDataRef.current,
              })
            );
          }}
          icon="plus"
        >
          Add new item
        </Button>
      )}
    </div>
  );
};

export default Posts;
