import React, {Component} from "react";
import {BrowserRouter as Router, Route, Link, Switch, Redirect} from "react-router-dom";
import {Button, Form, Input, message} from "antd";
import setInputStatus from "../../helpers/setInputStatus";
import {api} from "../../data/urls";
import {getCurrentWebsiteSubdomain} from "../../helpers/getCurrentWebsiteSubdomain";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {indexWebsitePageDashboardUrl, projectUrl} from "../../data/constants";
import {openNotification} from "../../helpers/openNotification";
import {messages} from "../../data/messages";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import {compareEditedValueWithCurrent} from "../../helpers/editor/compareEditedValueWithCurrent";
import trimStringTo from "../../helpers/strings/trimStringTo";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import _ from "lodash";
const { TextArea } = Input;

interface Props {
    websites: any,
    websitesPages: any
    user: any,
    auth: any,
    waitingForServer: any,
    currentWebsite: any,
    currentWebsitePage:any,
    saveWebsitePageDataInStore: any,
    isWaitingForServer: boolean,
    saveWebsiteBackup: any;
}
type MyState = { current_custom_html_code: string };
class AddPageHeadCustomCodeForm extends Component<Props, MyState>{
    componentWillMount(): void {
        // We want to set up an initial value of a website custom_head_html_code.
        this.setState({current_custom_html_code: this.props.currentWebsitePage.custom_head_html_code});
    }

    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        // We set the new state only when we change a settings active website or page.
        // We want to set up an initial value of a new picked website.
        if(
        (this.props.currentWebsite.subdomain !== nextProps.currentWebsite.subdomain)
        ||
        (this.props.currentWebsitePage.url !== nextProps.currentWebsitePage.url)
        ){
            this.setState({current_custom_html_code: nextProps.currentWebsitePage.custom_head_html_code});
        }
    }

    handlePageHeadCustomCodeUpdate = async (e:any) => {
        e.preventDefault();

        this.props.waitingForServer(true);
        
        // try {
        //   await this.props.saveWebsiteBackup({ page_id: this.props.currentWebsitePage.id, has_page_head_code: true});
        // } catch (e) {
        //   console.error(_.get(e, "response.data"));
        // }


        // Our server API expects the 'indexWebsitePageDashboardUrl' as an URL kwarg because a URL of a home page ('') is in appropriate for API and the dashboard.
        let newPageCustomCode = this.state.current_custom_html_code;

        let websitePageUrlToServer = this.props.currentWebsitePage.url;
        if(websitePageUrlToServer === ''){
            websitePageUrlToServer = indexWebsitePageDashboardUrl;
        }

        let accessToken = this.props.auth.accessToken;
        let apiUrl = api.websitePages.updateWebsitePage.prefix + this.props.currentWebsite.subdomain + '/' + websitePageUrlToServer + api.websitePages.updateWebsitePage.postfix;
        axios.patch(apiUrl, {custom_head_html_code: newPageCustomCode, subdomain: this.props.currentWebsite.subdomain}, {...returnAuthHeaderForAJAX(accessToken)})
            .then(() => {
                // Server gives the object of the saved website page. Throw it in the store.
                const dataToStore = {
                  data: {
                    ...this.props.currentWebsitePage,
                    custom_head_html_code: newPageCustomCode
                  }
                }
                this.props.saveWebsitePageDataInStore(dataToStore, this.props.currentWebsite, this.props.websitesPages.items, this.props.currentWebsitePage.url);

                let successMessage = <span> <code>{trimStringTo(newPageCustomCode, 50)}</code> added to the {websitePageUrlToServer} page.</span>;
                if(newPageCustomCode === ''){
                    successMessage = <span>Successfully removed the code.</span>;
                }
                
                fireAnalyticsEvent.fireCrisp(CrispEvents.changePageHeadCustomCode, {
                  subdomain: this.props.currentWebsite.subdomain,
                  page_url: this.props.currentWebsitePage.url,
                  code: trimStringTo(newPageCustomCode, 100),
                });
            })
            .catch(error => {
                if(error.response){
                    let errorData = error.response.data;
                    handleBadWebsiteError(errorData);
                    if (errorData.detail !== undefined){
                        let message = errorData.detail;

                        let errorObjectKey = getErrorKey(errorData);
                        let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                        fireAnalyticsEvent.fireCrisp(CrispEvents.changePageHeadCustomCodeError, {
                          error_type: errorObjectKey,
                          error_message: errorObjectValue,
                          subdomain: this.props.currentWebsite.subdomain,
                          page_url: websitePageUrlToServer,
                          code: trimStringTo(newPageCustomCode, 100),
                        }, true);

                        // This happens when user tries to change a website which doesn't exist or doesn't belog to the user.
                        openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                    }

                    if (errorData.locked !== undefined){
                        let message = errorData.locked;
                        openNotification('Denied', message, 'OK', 'warn');
                    }

                    if (errorData.current_custom_html_code !== undefined){
                        let message = errorData.current_custom_html_code.join(' ');
                        // this.props.pageMetaTitleUpdateErrorMessage(message);
                        // this.props.pageMetaTitleUpdateToggleInvalidAttempt(true);
                    }
                }
            })
            .then(response => {
                // always executed
                this.props.waitingForServer(false);
            });
    };

    handleInputChange = (e:any) => {
        this.setState({current_custom_html_code: e.target.value});
    };

    render(){
        let returnCustomCodeField = () => {
            // Only pro users can upgrade to custom domains
            // if(this.props.user.isPro){
            return (
                <div className="settings-box__input_box settings-box__input_box--large">
                        <TextArea
                            name="custom_head_html_code"
                            id="custom_head_html_code"
                            required={false}
                            value={this.state.current_custom_html_code}
                            placeholder='<!-- Event snippet for Submit lead form conversion page --> <script> gtag("event", "conversion", {"send_to": "AW-996845408/few_CA6e1w2BENAgrLAz"}); </script>'
                            onChange={this.handleInputChange}
                            autosize={{ minRows: 4, maxRows: 30 }}
                            style={{fontFamily: 'monospace'}}
                        />
                    <div className="settings-box__error_message" style={{opacity: this.props.websites.errorMessage === undefined ? 0 : 1}}>
                        {this.props.websites.errorMessage}
                    </div>
                </div>
            );
            // }
            // else{
            //     return (
            {/*<Button onClick={this.handleUpgradeToUnlock} icon='star' type="primary" size="large" htmlType="button">Upgrade to unlock</Button>*/}
            // )
            // }
        };

        // We hide the save button in 2 cases:
        // 1) when the form has not yet received its initial value (and the initial value === undefined)
        // 2) when the initial value is the same as the current value (e.g. nothing to save)
        let isSaveButtonVisible = this.state.current_custom_html_code !== undefined && (compareEditedValueWithCurrent(this.props.currentWebsitePage.custom_head_html_code, this.state.current_custom_html_code) === false);

        return (
            <form onSubmit={this.handlePageHeadCustomCodeUpdate} className="settings-box__form">
                {returnCustomCodeField()}
                <Button
                    className="settings-box__form_save"
                    icon='save'
                    type="primary"
                    loading={this.props.isWaitingForServer}
                    htmlType="submit"
                    hidden={!isSaveButtonVisible}
                />
            </form>
        )
    }
}

export default AddPageHeadCustomCodeForm;
