import { Icon, Tooltip } from "antd";
import classNames from "classnames";
import React from "react";

interface Props {
  children: React.ReactNode;
  tooltip?: string;
  style?: React.CSSProperties;
}

const InputTitle = (props: Props): JSX.Element => {
  const { children, tooltip, style } = props;

  return (
    <h2
      className={classNames("content-input__title", {
        "has-tooltip": !!tooltip,
      })}
      style={style}
    >
      <span>{children}</span>
      {!!tooltip && (
        <Tooltip placement="top" title={tooltip}>
          <Icon className="content-input__tooltip_icon" type="info-circle" />
        </Tooltip>
      )}
    </h2>
  );
};

export default InputTitle;
