import React from "react";
import { Button } from "antd";
import { InputProps } from "./interfaces";
import DebouncedInput from "../../DebouncedInput";
import classNames from "classnames";
import { useCurrentInputData } from "./useCurrentInputData";
import HorInputGroup from "../../ui_components/HorInputGroup";

const Contacts = (props: InputProps): JSX.Element => {
  const {
    content,
    currentInputData,
    saveContentInStore,
    generator,
    contentType,
    toggleNewEditionsDetected,
    displayMoveUpDownButtons,
    currentWebsite,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  let contactsInputs = content.items.map((item: any, key: number) => {
    let lastItem = content.items[content.items.length - 1];
    let maxItemsReached = lastItem.body !== "";
    let contactsCount = maxItemsReached
      ? content.items.length
      : content.items.length - 1; //if max not reached the last item is ghost

    let onChangeContactItemTitleHandler = (value: any) => {
      saveContentInStore(
        generator.changeContactItemTitle(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangeContactItemBodyHandler = (value: any) => {
      saveContentInStore(
        generator.changeContactItemBody(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );

      toggleNewEditionsDetected(true);
    };

    return (
      <div
        className={classNames("content-input__cluster", {
          "content-input__cluster--with-ghost": !maxItemsReached,
        })}
        data-add-new="Add new contact ⤵"
        key={"contact-item-" + key}
      >
        <Button
          onClick={() => {
            toggleNewEditionsDetected(true);
            saveContentInStore(
              generator.deleteContact(
                { [contentType]: currentInputDataRef.current },
                key
              )
            );
          }}
          htmlType="button"
          className="content-input__control_cluster"
          shape="circle"
          title="Delete this contact"
          icon="cross"
          size="small"
        />

        {displayMoveUpDownButtons(
          key,
          contactsCount,
          () => {
            saveContentInStore(
              generator.moveContactUp(
                { [contentType]: currentInputDataRef.current },
                key
              )
            );
          },
          () => {
            saveContentInStore(
              generator.moveContactDown(
                { [contentType]: currentInputDataRef.current },
                key
              )
            );
          }
        )}

        <HorInputGroup gap="small">
          {currentInputDataRef.current.contactsItemHasTitle &&
            !!item &&
            !!item.body && (
              <DebouncedInput
                className="content-input__input"
                onChange={onChangeContactItemTitleHandler}
                defaultValue={item.title}
                placeholder={"Email us:"}
              />
            )}

          <DebouncedInput
            className="content-input__input"
            onChange={onChangeContactItemBodyHandler}
            defaultValue={item.body}
            placeholder={
              !!currentWebsite.custom_domain
                ? `hello@${currentWebsite.custom_domain}`
                : "hi@acme.com"
            }
          />
        </HorInputGroup>
      </div>
    );
  });

  let onChangeContactsMapTitleHandler = (value: any) => {
    saveContentInStore(
      generator.changeContactsMapTitle(
        { [contentType]: currentInputDataRef.current },
        value
      )
    );
    toggleNewEditionsDetected(true);
  };
  let onChangeContactsMapURLHandler = (value: any) => {
    saveContentInStore(
      generator.changeContactsMapURL(
        { [contentType]: currentInputDataRef.current },
        value
      )
    );
    toggleNewEditionsDetected(true);
  };

  let mapInputs = (
    <div>
      {currentInputDataRef.current.mapHasTitle && (
        <DebouncedInput
          className="content-input__input"
          onChange={onChangeContactsMapTitleHandler}
          defaultValue={content.map.mapTitle}
          placeholder={"Enter map title"}
        />
      )}

      {currentInputDataRef.current.isMapEmbedded === false && (
        <DebouncedInput
          className="content-input__input"
          onChange={onChangeContactsMapURLHandler}
          defaultValue={content.map.url}
          placeholder={"Link to the place on a map"}
        />
      )}
    </div>
  );

  return (
    <div>
      {currentInputDataRef.current.hasContactsItems && contactsInputs}
      {currentInputDataRef.current.hasMap && mapInputs}
    </div>
  );
};

export default Contacts;
