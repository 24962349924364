import React from "react";
import getIntegrationLogoSourceFromTitle from "../../../helpers/editor/getIntegrationLogoSourceFromTitle";
import InputTitle from "../../ui_components/InputTitle";

interface Props {
  currentFormIntegrationTitle: string;
  currentFormIntegrationHumanTitle: string;
}

const IntegrationTitle = (props: Props): JSX.Element => {
  const {
    currentFormIntegrationTitle,
    currentFormIntegrationHumanTitle,
  } = props;

  return (
    <InputTitle style={{
      display: "flex",
      gap: 7,
      alignItems: "center",
      marginTop: 16
    }}>
      Fill in the fields to connect{" "}
      <img
        title={currentFormIntegrationHumanTitle}
        height="14"
        src={getIntegrationLogoSourceFromTitle(currentFormIntegrationTitle)}
        alt={currentFormIntegrationHumanTitle + " logo"}
      />
    </InputTitle>
  );
};

export default IntegrationTitle;
