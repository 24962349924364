import React, { Component } from "react";
import "./OpenGraphImageUploader.css";
import { Alert, Button, Icon, Popconfirm } from "antd";
import Uploader from "../editor/Uploader";
import { openNotification } from "../../helpers/openNotification";
import { api } from "../../data/urls";
import { getCurrentWebsiteSubdomain } from "../../helpers/getCurrentWebsiteSubdomain";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import { projectUrl } from "../../data/constants";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { getErrorKey } from "../../helpers/editor/getErrorKey";
import { getErrorValue } from "../../helpers/editor/getErrorValue";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import { getCurrentWebsite } from "../../helpers/getCurrentWebsite";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";
import _ from "lodash";
import SocialPreview from "./SocialPreview";

interface Props {
  auth: any;
  currentWebsite?: any;
  currentBlog?: any;
  currentBlogPost?: any;
  currentWebsitePage?: any;
  websitesPages?: any;
  saveWebsiteDataInStore?: any;
  saveBlogDataInStore?: any;
  saveBlogPostDataInStore?: any;
  saveWebsitePageDataInStore?: any;

  saveImageInfoInDB: any;
  removeImageFromDB: any;

  settingsType: "website" | "blog" | "blogPost" | "page";
  displayedSettingsType?: "website" | "blog" | "blogPost" | "page";
  apiUrl: string;

  blogPosts?: any;

  currentMetaTitle?: string;
  currentMetaDescription?: string;
  disableLiveUpdate?: boolean;
}

class OpenGraphImageUploader extends Component<Props> {
  state = {
    isWaitingForOpenGraphImage: false,
    isImageLoaded: false,
  };

  setIsImageLoaded = (isImageLoaded: boolean) => {
    this.setState({ isImageLoaded });
  };

  saveImageInServer = (
    src: any,
    uuid: any,
    width: number,
    height: number,
    fileName: string
  ) => {
    let accessToken = this.props.auth.accessToken;
    let apiUrl = this.props.apiUrl;
    axios
      .patch(
        apiUrl,
        {
          og_image_url: src + fileName,
          og_image_uuid: uuid,
          og_image_width: width,
          og_image_height: height,
        },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then(() => {
        if (this.props.settingsType === "website") {
          this.props.saveWebsiteDataInStore(
            {
              data: {
                ...this.props.currentWebsite,
                og_image_url: src + fileName,
                og_image_uuid: uuid,
              },
            },
            this.props.currentWebsite.subdomain
          );
        } else if (this.props.settingsType === "blog") {
          this.props.saveBlogDataInStore({
            data: {
              ...this.props.currentBlog,
              og_image_url: src + fileName,
              og_image_uuid: uuid,
            },
          }, this.props.currentBlog.id);
        } else if (this.props.settingsType === "blogPost") {
          this.props.saveBlogPostDataInStore(
            {
              data: {
                ...this.props.currentBlogPost,
                og_image_url: src + fileName,
                og_image_uuid: uuid,
              }
            },
            this.props.currentBlog,
            this.props.blogPosts.items,
            this.props.currentBlogPost.url
          );
        } else if (this.props.settingsType === "page") {
          this.props.saveWebsitePageDataInStore(
            {
              data: {
                ...this.props.currentWebsitePage,
                og_image_url: src + fileName,
                og_image_uuid: uuid,
              },
            },
            this.props.currentWebsite,
            this.props.websitesPages.items,
            this.props.currentWebsitePage.url
          );
        }

        // console.log('response', response);

        let message = <span>The social networks image has been deleted.</span>;
        if (src && src !== "") {
          message = (
            <span>
              The new social networks image have been successfully saved.
            </span>
          );
        }

        fireAnalyticsEvent.fireCrisp(CrispEvents.setOpengraphImage, {
          subdomain: this.props.currentWebsite.subdomain,
          og_image_url: src,
        });
      })
      .catch((error) => {
        // handle error
        if (error.response) {
          let errorData = error.response.data;

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);

          fireAnalyticsEvent.fireCrisp(
            CrispEvents.setOpengraphImageError,
            {
              error_type: errorObjectKey,
              error_message: errorObjectValue,
              subdomain: this.props.currentWebsite.subdomain,
            },
            true
          );

          if (errorData.not_pro !== undefined) {
            let message = errorData.not_pro;
            openNotification("Denied", message, "OK", "warn");
          }
          handleBadWebsiteError(errorData);

          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }
        }
      })
      .then((response) => {
        // always executed
        this.setState({ isWaitingForOpenGraphImage: false });
      });
  };

  render() {
    let ogImageUrl;
    let ogImageUuid;

    if (this.props.settingsType === "website") {
      ogImageUrl = this.props.currentWebsite.og_image_url;
      ogImageUuid = this.props.currentWebsite.og_image_uuid;
    } else if (this.props.settingsType === "blog") {
      ogImageUrl = this.props.currentBlog.og_image_url;
      ogImageUuid = this.props.currentBlog.og_image_uuid;
    } else if (this.props.settingsType === "blogPost") {
      ogImageUrl = this.props.currentBlogPost.og_image_url;
      ogImageUuid = this.props.currentBlogPost.og_image_uuid;
    } else if (this.props.settingsType === "page") {
      ogImageUrl = this.props.currentWebsitePage.og_image_url;
      ogImageUuid = this.props.currentWebsitePage.og_image_uuid;
    }

    ogImageUrl = formatImageUrl(ogImageUrl);
    return (
      <div style={{ display: "flex", gap: "20px", flexDirection: "column" }}>
        {!ogImageUrl && (
          <Uploader
            id={"og_image"}
            name={"og_image"}
            data-tabs="file url gdrive gphotos dropbox onedrive box"
            data-preview-step={true}
            data-crop="1200:630"
            data-image-shrink="1200x630"
            data-system-dialog={true}
            data-images-only={true}
            onChange={(file: any) => {
              // console.log('File changed: ', file);
              // if (file) {
              // file.progress((info:any) => console.log('File progress: ', info.progress));
              // file.done((info:any) => console.log('File uploaded: ', info));
              // }
            }}
            onUploadComplete={(info: any) => {
              this.setIsImageLoaded(false);
              let fileName = info.name;
              if (!fileName || typeof fileName !== "string") {
                fileName = "";
              }
              fileName = fileName.replace(/ /g, "-");
              let uploadedImgSrc = info.cdnUrl;
              const width = _.get(info, ["originalImageInfo", "width"]);
              const height = _.get(info, ["originalImageInfo", "height"]);

              if (uploadedImgSrc) {
                this.saveImageInServer(
                  uploadedImgSrc,
                  info.uuid,
                  width,
                  height,
                  fileName
                );
                this.props.saveImageInfoInDB(info);

                fireAnalyticsEvent.fireCrisp(CrispEvents.uploadOpengraphImage, {
                  subdomain: this.props.currentWebsite.subdomain,
                  image_url: uploadedImgSrc,
                  uuid: info.uuid,
                  size_bytes: info.size,
                });
              } else {
                fireAnalyticsEvent.fireCrisp(
                  CrispEvents.uploadOpengraphImageError,
                  {
                    subdomain: this.props.currentWebsite.subdomain,
                  },
                  true
                );
                openNotification(
                  "Error :(",
                  "There was an unknown error during uploading the Open Graph image. Please refresh the page and try again.",
                  "OK",
                  "warn"
                );
              }
            }}
          />
        )}
        <SocialPreview
          setIsImageLoaded={this.setIsImageLoaded}
          isImageLoaded={this.state.isImageLoaded}
          settingsType={this.props.settingsType}
          overrideTitle={this.props.currentMetaTitle}
          overrideDescription={this.props.currentMetaDescription}
          displayedSettingsType={this.props.displayedSettingsType}
          deleteButtonJsx={
            <Popconfirm
              placement="top"
              title="Are you sure to delete the image?"
              onConfirm={() => {
                this.setState({ isWaitingForOpenGraphImage: true });

                this.saveImageInServer("", "", null, null, "");

                if (ogImageUuid) {
                  this.props.removeImageFromDB(ogImageUuid);
                }
              }}
              okText="Yes, delete"
              cancelText="No"
            >
              <Button
                type="danger"
                loading={this.state.isWaitingForOpenGraphImage}
                className={
                  "opengraph__delete_button social-preview__delete-button "
                }
              >
                Delete image
              </Button>
            </Popconfirm>
          }
          disableLiveUpdate={this.props.disableLiveUpdate}
        />
      </div>
    );
  }
}

export default OpenGraphImageUploader;
