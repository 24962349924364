import React from "react";
import { Checkbox, Input } from "antd";
import { buttonStyles } from "../../../../data/buttonStyles";
import { getFullWebsiteUrl } from "../../../../helpers/editor/getFullWebsiteUrl";
import checkIsButtonHrefDisabled from "../../../../helpers/editor/checkIsButtonHrefDisabled";
import DebouncedInput from "../../../DebouncedInput";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import { useCurrentInputData } from "../useCurrentInputData";
import HorInputGroup from "../../../ui_components/HorInputGroup";

interface Props {
  galleryItem: any;
  currentWebsite: any;
  saveContentInStore: any;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
  currentGalleryItemIndex: number;
  toggleNewEditionsDetected: (value: boolean) => void;
  displayButtonStyleEditorOpener: (
    currentButtonObject: any,
    handler: any,
    shouldDisplayForcedWhiteButtonsWarning?: boolean
  ) => JSX.Element;
  setState: any;
  state: any;
  displayButtonPluginsDrawerOpener: (
    item: any,
    itemIndex: number,
    galleryItemIndex?: number,
    isYearlyPricingButtonPassed?: boolean
  ) => JSX.Element;
}

const GalleryButtonField = (props: Props): JSX.Element => {
  const {
    galleryItem,
    currentWebsite,
    saveContentInStore,
    contentType,
    currentInputData,
    currentGalleryItemIndex,
    toggleNewEditionsDetected,
    displayButtonStyleEditorOpener,
    setState,
    state,
    displayButtonPluginsDrawerOpener,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  const generator = new generateContentDataObject();

  let lastButton =
    galleryItem.contentCta.buttons[galleryItem.contentCta.buttons.length - 1];
  let doesLastButtonHavePluginActionInsteadOfHref = checkIsButtonHrefDisabled(
    lastButton,
    currentWebsite
  );

  if (!currentInputDataRef.current.contentHasCta) return null;

  let buttonsInputs = galleryItem.contentCta.buttons.map(
    (button: any, key: number) => {
      let doesButtonHavePluginActionInsteadOfHref = checkIsButtonHrefDisabled(
        button,
        currentWebsite
      );

      let onChangeGalleryButtonPillPrefixHandler = (value: any) => {
        saveContentInStore(
          generator.changeGalleryButtonPillPrefix(
            { [contentType]: currentInputDataRef.current },
            value,
            currentGalleryItemIndex,
            key
          )
        );
        toggleNewEditionsDetected(true);
      };
      let onChangeGalleryButtonTitleHandler = (value: any) => {
        saveContentInStore(
          generator.changeGalleryButtonTitle(
            { [contentType]: currentInputDataRef.current },
            currentGalleryItemIndex,
            value,
            key
          )
        );
        toggleNewEditionsDetected(true);
      };
      let onChangeGalleryButtonHrefHandler = (value: any) => {
        saveContentInStore(
          generator.changeGalleryButtonHref(
            { [contentType]: currentInputDataRef.current },
            currentGalleryItemIndex,
            value,
            key
          )
        );
        toggleNewEditionsDetected(true);
      };

      return (
        <div
          className={"content-input__cluster "}
          key={"gallery-buttons-fields-" + key}
        >
          {/* if href is not empty OR if there is a special action enabled (doesButtonHavePluginActionInsteadOfHref)*/}
          {button.title !== "" &&
            displayButtonStyleEditorOpener(button, () => {
              setState({
                ...state,
                currentGalleryIndex: currentGalleryItemIndex, //we need to know the index of a post to edit it's button. Same for any other countable elements with buttons or pictograms.
                buttonStylesOptions: currentInputDataRef.current.buttonTypes,
                currentButtonObject: button,
                currentGalleryButtonIndex: key,
              });
            })}

          {/*If this button has an active styleType === 'pill' we show additional field - for prefix.*/}
          <HorInputGroup gap="small">
            {button.settings.appearance.styleType !==
              buttonStyles.mobileAppStore.typeTitle && <Input.Group compact style={{display: "flex"}}>
              {button.title !== "" &&
                button.settings.appearance.styleType ===
                  buttonStyles.pill.typeTitle && (
                  <DebouncedInput
                    onChange={onChangeGalleryButtonPillPrefixHandler}
                    defaultValue={button.pillPrefix}
                    placeholder="Wow"
                    className="content-input__input"
                    style={{width: 64}}
                  />
                )}

              {button.settings.appearance.styleType !==
                buttonStyles.mobileAppStore.typeTitle && (
                <DebouncedInput
                  className="content-input__input"
                  onChange={onChangeGalleryButtonTitleHandler}
                  defaultValue={button.title}
                  placeholder="Learn more"
                />
              )}
            </Input.Group>}
            <div className="content-input__input">
              <DebouncedInput
                className=""
                onChange={onChangeGalleryButtonHrefHandler}
                defaultValue={button.href}
                disabled={doesButtonHavePluginActionInsteadOfHref}
                addonAfter={
                  // Adds plugins (payment, UTM relay etc) to a button.
                  displayButtonPluginsDrawerOpener(
                    button,
                    key,
                    currentGalleryItemIndex
                  )
                }
                placeholder={
                  getFullWebsiteUrl(currentWebsite) +
                  "/post-" +
                  Math.floor(Math.random() * 20 + 10)
                } //e.g. https://stripe.com/case-12
              />
            </div>
          </HorInputGroup>
          {doesButtonHavePluginActionInsteadOfHref === false &&
            button.href &&
            button.title !== "" && (
              <Checkbox
                className="content-input__input"
                checked={button.isTargetBlank}
                onChange={(event: any) => {
                  saveContentInStore(
                    generator.changeGalleryButtonIsTargetBlank(
                      {
                        [contentType]: currentInputDataRef.current,
                      },
                      currentGalleryItemIndex,
                      event.target.checked,
                      key
                    )
                  );
                  toggleNewEditionsDetected(true);
                }}
                style={{marginTop: 10}}
              >
                Open in a new tab
              </Checkbox>
            )}
        </div>
      );
    }
  );

  return <div className="content-input__group">{buttonsInputs}</div>;
};

export default GalleryButtonField;
