import React, { FormEventHandler, useEffect, useState } from "react";
import { Input, Button, Icon, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { connect } from "react-redux";
import { WebsitePagesState } from "../../store/websitePages/types";
import {
  pageUrlUpdateWaitingForServer,
  pageMetaTitleUpdateWaitingForServer,
  pageMetaDescriptionUpdateWaitingForServer,
  publishPageToggleWaitingForServer,
} from "../../store/websitePages/actions";
import { getCurrentStoreData } from "../../store/websites/thunks";
import { GetCurrentStoreData } from "../../store/websites/types";
import { updatePageAsync } from "../../helpers/async/updatePageAsync";
import { AuthState } from "../../store/auth/types";
import { RouteComponentProps, withRouter } from "react-router-dom";
import _ from "lodash";
import {
  DEFAULT_ERROR_TITLE,
  DEFAULT_ERROR_MESSAGE,
  notificationDuration,
} from "../../data/constants";
import { openNotification } from "../../helpers/openNotification";
import { getFullPageUrl } from "../../helpers/editor/getFullPageUrl";
import trimStringTo from "../../helpers/strings/trimStringTo";
import { getActiveDomain } from "../../helpers/websites/getActiveDomain";
import GooglePreview from "./GooglePreview";
import "./PublishPageMenu.scss";
import { checkIfGoogleSheetUrl } from "../../helpers/checkIfGoogleSheetUrl";
import { PosthogEvents } from "../../enums/AnalyticsEventsEnums";
import { formatToURLPath } from "../../helpers/strings/formatToURLPath";
import addNoCacheParamToURL from "../../helpers/editor/addNoCacheParamToURL";

interface Props extends RouteComponentProps {
  websitesPages: WebsitePagesState;
  auth: AuthState;
  pageUrlUpdateWaitingForServer: (payload: boolean) => void;
  pageMetaTitleUpdateWaitingForServer: (payload: boolean) => void;
  pageMetaDescriptionUpdateWaitingForServer: (payload: boolean) => void;
  publishPageToggleWaitingForServer: (payload: boolean) => void;
  getCurrentStoreData: GetCurrentStoreData;

  saveWebsitePageDataInStore: any;
}

const PublishPageMenu = (props: Props): JSX.Element => {
  const {
    websitesPages,
    auth,
    history,
    pageUrlUpdateWaitingForServer,
    pageMetaTitleUpdateWaitingForServer,
    pageMetaDescriptionUpdateWaitingForServer,
    getCurrentStoreData,
    saveWebsitePageDataInStore,
    publishPageToggleWaitingForServer,
  } = props;

  const {
    isWaitingForPageMetaTitleUpdateResponse,
    isWaitingForPageUrlUpdateResponse,
    isWaitingForPageMetaDescriptionUpdateResponse,
    isWaitingForPublishPageResponse,
  } = websitesPages;

  const isLoading =
    isWaitingForPageMetaTitleUpdateResponse ||
    isWaitingForPageUrlUpdateResponse ||
    isWaitingForPageMetaDescriptionUpdateResponse ||
    isWaitingForPublishPageResponse;

  const [pageUrl, setPageUrl] = useState(
    getCurrentStoreData().currentWebsitePage.url || ""
  );
  const [metaTitle, setMetaTitle] = useState(
    getCurrentStoreData().currentWebsitePage.meta_title
  );
  const [metaDescription, setMetaDescription] = useState(
    getCurrentStoreData().currentWebsitePage.meta_description
  );

  const [pageUrlErrorMessage, setPageUrlErrorMessage] = useState("");
  const [metaTitleErrorMessage, setMetaTitleErrorMessage] = useState("");
  const [metaDescriptionErrorMessage, setMetaDescriptionErrorMessage] =
    useState("");

  useEffect(() => {
    if (metaTitle.length > 200) {
      setMetaTitleErrorMessage(
        `Meta title is too long: ${metaTitle.length}/200`
      );
    } else {
      setMetaTitleErrorMessage("");
    }
  }, [metaTitle]);

  useEffect(() => {
    if (metaDescription.length > 350) {
      setMetaDescriptionErrorMessage(
        `Meta description is too long: ${metaDescription.length}/350`
      );
    } else {
      setMetaDescriptionErrorMessage("");
    }
  }, [metaDescription]);

  const handleOnSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (isLoading) return;

    setPageUrlErrorMessage("");
    setMetaTitleErrorMessage("");
    setMetaDescriptionErrorMessage("");

    pageUrlUpdateWaitingForServer(true);
    pageMetaTitleUpdateWaitingForServer(true);
    pageMetaDescriptionUpdateWaitingForServer(true);
    publishPageToggleWaitingForServer(true);

    const { currentWebsite, currentWebsitePage } = getCurrentStoreData();

    const data = {
      meta_title: metaTitle,
      meta_description: metaDescription,
      is_published: true,
      first_page_title: currentWebsitePage.first_page_title,
      first_page_subtitle: currentWebsitePage.first_page_subtitle,
      hero_image_url: currentWebsitePage.hero_image_url,
      hero_image_uuid: currentWebsitePage.hero_image_uuid,
    };

    if (pageUrl !== currentWebsitePage.url) {
      data["url"] = formatToURLPath(pageUrl);
    }

    updatePageAsync({
      accessToken: auth.accessToken,
      subdomain: currentWebsite.subdomain,
      pageUrl: currentWebsitePage.url,
      data,
    })
      .then(() => {
        if ("url" in data) {
          window.posthog.capture(PosthogEvents.SET_PAGE_URL, {
            page_url: currentWebsitePage.url,
          });
        }
        saveWebsitePageDataInStore(
          {
            data: {
              ...currentWebsitePage,
              ...data,
            },
          },
          currentWebsite,
          websitesPages.items,
          currentWebsitePage.url
        );
        const fullPageUrl = getFullPageUrl(currentWebsite, formatToURLPath(pageUrl));
        openNotification(
          "Done",
          <span>
            The page has been published. It can be accessed here:
            <a target="_blank" href={addNoCacheParamToURL(fullPageUrl)}>
              {trimStringTo(fullPageUrl, 70)}
            </a>
          </span>,
          "Close",
          "success",
          notificationDuration.medium
        );
      })
      .catch((error) => {
        const urlErrorMessage = _.get(error, "response.data.url.0");
        if (urlErrorMessage) {
          setPageUrlErrorMessage(urlErrorMessage);
          setPageUrl(formatToURLPath(pageUrl));
          return;
        }
        console.error(error.response);
        const limitError = _.get(error, "response.data.limit");
        if (limitError) {
          openNotification(
            "Limit reached",
            limitError,
            "OK",
            "warning",
            notificationDuration.medium
          );
          return;
        }
        openNotification(
          DEFAULT_ERROR_TITLE,
          DEFAULT_ERROR_MESSAGE,
          "OK",
          "error",
          notificationDuration.medium
        );
      })
      .finally(() => {
        pageUrlUpdateWaitingForServer(false);
        pageMetaTitleUpdateWaitingForServer(false);
        pageMetaDescriptionUpdateWaitingForServer(false);
        publishPageToggleWaitingForServer(false);
      });
  };

  return (
    <form onSubmit={handleOnSubmit} className="publish-page-form">
      <div className="publish-page-form__inputs">
        <div className="publish-page-form__input-box">
          <div className="publish-page-form__label-box">
            <Tooltip
              title={
                <div>
                  How your website page will be accessible for visitors. Set it
                  blank and the page will become a homepage.
                </div>
              }
              placement="left"
            >
              <Icon type="info-circle" />
            </Tooltip>
            <div>Page url:</div>
          </div>
          <div className="publish-page-form__input-container">
            <Input
              placeholder="about-us"
              value={pageUrl}
              onChange={(e) => setPageUrl(e.target.value)}
              addonBefore={
                <div
                  style={{
                    maxWidth: "100px",
                    direction: "rtl",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {getActiveDomain(getCurrentStoreData().currentWebsite)}/&lrm;
                </div>
              }
            />
            <div
              className="settings-box__error_message"
              style={{
                opacity: !pageUrlErrorMessage ? 0 : 1,
                marginTop: !pageUrlErrorMessage ? 0 : "3px",
              }}
            >
              {pageUrlErrorMessage}
            </div>
          </div>
        </div>

        <div className="publish-page-form__input-box">
          <div className="publish-page-form__label-box">
            <Tooltip
              overlayStyle={{ maxWidth: "500px" }}
              title={
                <div>
                  Meta title of this page.
                  <br />
                  Optimal length is 50-60 characters.
                  <br />
                  {metaTitle ? ` Yours is ${metaTitle.length} characters.` : ""}
                </div>
              }
              placement="left"
            >
              <Icon type="info-circle" />
            </Tooltip>
            <div>Title:</div>
          </div>

          <div className="publish-page-form__input-container">
            <Input
              placeholder="Enter meta title"
              value={metaTitle}
              onChange={(e) => setMetaTitle(e.target.value)}
            />
            <div
              className="settings-box__error_message"
              style={{
                opacity: !metaTitleErrorMessage ? 0 : 1,
                marginTop: !metaTitleErrorMessage ? 0 : "3px",
              }}
            >
              {metaTitleErrorMessage}
            </div>
          </div>
        </div>

        <div className="publish-page-form__input-box">
          <div className="publish-page-form__label-box">
            <Tooltip
              overlayStyle={{ maxWidth: "500px" }}
              title={
                <div>
                  Meta description of this page.
                  <br />
                  Optimal length is 120-158 characters.
                  <br />
                  {metaDescription
                    ? ` Yours is ${metaDescription.length} characters.`
                    : ""}
                </div>
              }
              placement="left"
            >
              <Icon type="info-circle" />
            </Tooltip>
            <div>Description:</div>
          </div>
          <div className="publish-page-form__input-container">
            <TextArea
              placeholder="Enter meta description"
              autosize={{ minRows: 2, maxRows: 10 }}
              value={metaDescription}
              onChange={(e) => setMetaDescription(e.target.value)}
              style={{ marginBottom: 0 }}
            />
            <div
              className="settings-box__error_message"
              style={{
                opacity: !metaDescriptionErrorMessage ? 0 : 1,
                marginTop: !metaDescriptionErrorMessage ? 0 : "3px",
              }}
            >
              {metaDescriptionErrorMessage}
            </div>
          </div>
        </div>
      </div>

      <div>
        <GooglePreview
          overrideTitle={metaTitle}
          overrideDescription={metaDescription}
          urlsArray={[pageUrl]}
          type="page"
          disableLiveUpdate
        />
      </div>

      <div>
        <Button
          type="primary"
          block
          disabled={!!metaTitleErrorMessage || !!metaDescriptionErrorMessage}
          htmlType="submit"
          loading={isLoading}
          className="is-black-button"
        >
          Publish to web
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    websitesPages: state.websitesPages,
    auth: state.auth,
  };
};
export default connect(mapStateToProps, {
  pageUrlUpdateWaitingForServer,
  pageMetaTitleUpdateWaitingForServer,
  pageMetaDescriptionUpdateWaitingForServer,
  getCurrentStoreData,
  publishPageToggleWaitingForServer,
})(withRouter(PublishPageMenu));
