import React, {Component} from "react";
import './FaviconUploader.css'
import {Alert, Button, Icon, Popconfirm} from "antd";
import Uploader from "../editor/Uploader";
import {openNotification} from "../../helpers/openNotification";
import {api} from "../../data/urls";
import {getCurrentWebsiteSubdomain} from "../../helpers/getCurrentWebsiteSubdomain";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {projectUrl} from "../../data/constants";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";
import _ from "lodash";

interface Props {
    auth: any,
    saveDataInStore: any,

    currentWebsite: any,
    saveImageInfoInDB: any,
    removeImageFromDB: any,

    apiUrl: any,
    settingsType: "website" | "blog",
    currentBlog?: any,
}

class FaviconUploader extends Component<Props>{
    state = {
        isWaitingForDeleteFavicon: false
    };

    saveFaviconInServer = (src:any, uuid:any) => {
        let accessToken = this.props.auth.accessToken;
        let apiUrl = this.props.apiUrl;
        axios.patch(apiUrl, {favicon_16x16_url: src, favicon_16x16_uuid: uuid}, {...returnAuthHeaderForAJAX(accessToken)})
            .then(() => {
              if(this.props.settingsType === 'blog'){
                    const dataToStore = {
                      data: {
                        ...this.props.currentBlog,
                        favicon_16x16_url: src,
                        favicon_16x16_uuid: uuid
                      }
                    }
                    this.props.saveDataInStore(dataToStore, this.props.currentBlog.id);
                  }else if (this.props.settingsType === 'website'){
                    const dataToStore = {
                      data: {
                        ...this.props.currentWebsite,
                        favicon_16x16_url: src,
                        favicon_16x16_uuid: uuid
                      }
                    }
                    this.props.saveDataInStore(dataToStore, this.props.currentWebsite.subdomain);
                }

                // console.log('response', response);

                let message = <span>The favicon has been deleted.</span>;
                if(src && src !== ''){
                    message = <span>The new favicon have been successfully saved: <img width="16" src={formatImageUrl(src)} alt=""/></span>
                }

                fireAnalyticsEvent.fireCrisp(CrispEvents.setFavicon, {
                  subdomain: this.props.currentWebsite.subdomain + (this.props.settingsType === 'blog' ? `/${this.props.currentBlog.url}` : ''),
                  favicon_url: src,
                })
            })
            .catch(error => {
                // handle error
                if(error.response){
                    let errorData = error.response.data;

                    let errorObjectKey = getErrorKey(errorData);
                    let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                    fireAnalyticsEvent.fireCrisp(CrispEvents.setFaviconError, {
                      subdomain: this.props.currentWebsite.subdomain + (this.props.settingsType === 'blog' ? `/${this.props.currentBlog.url}` : ''),
                      error_type: errorObjectKey,
                      error_message: errorObjectValue,
                    }, true);

                    if (errorData.not_pro !== undefined){
                        let message = errorData.not_pro;
                        openNotification('Denied', message, 'OK', 'warn');
                    }
                    handleBadWebsiteError(errorData);

                    if (errorData.locked !== undefined){
                        let message = errorData.locked;
                        openNotification('Denied', message, 'OK', 'warn');
                    }

                    if (errorData.detail !== undefined){
                        let message = errorData.detail;

                        // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
                        openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                    }
                }
            })
            .then(response => {
                // always executed
                this.setState({isWaitingForDeleteFavicon: false})
            });
    };

    render(){
        let faviconOwner: any;
        if(this.props.settingsType === 'blog'){
            faviconOwner = this.props.currentBlog;
        }else if(this.props.settingsType === 'website'){
            faviconOwner = this.props.currentWebsite;
        }


        if(faviconOwner.favicon_16x16_url && faviconOwner.favicon_16x16_url !== ''){
            return(
                <div className="favicon-updated__existing_icon">
                    <img className="favicon-updated__current_icon" src={formatImageUrl(faviconOwner.favicon_16x16_url)} alt=""/>
                    <Popconfirm
                        placement="right"
                        title="Delete the favicon?"
                        onConfirm={() => {
                            this.setState({isWaitingForDeleteFavicon: true});

                            this.saveFaviconInServer('', '');

                            if(faviconOwner.favicon_16x16_uuid){
                                if(this.props.settingsType === 'blog'){
                                    this.props.removeImageFromDB(faviconOwner.favicon_16x16_uuid);
                                }else if(this.props.settingsType === 'website'){
                                    this.props.removeImageFromDB(faviconOwner.favicon_16x16_uuid);
                                }
                            }
                        }}
                        // onCancel={cancel}
                        okText="Yes, delete"
                        cancelText="No"
                    >
                        {this.state.isWaitingForDeleteFavicon === false && <a aria-disabled={this.state.isWaitingForDeleteFavicon} className={"favicon-updated__delete_button "} href="#"><Icon type="delete" style={{"color": "red"}} /></a>}

                        {this.state.isWaitingForDeleteFavicon && <span className="favicon-updated__deleting_text">Deleting...</span>}
                        {this.state.isWaitingForDeleteFavicon && <Icon type="loading" style={{color: 'var(--ocean)', marginLeft: '10px', verticalAlign: 'middle'}}/>}
                    </Popconfirm>
                </div>
            )
        }else{
            return (
                <div>
                    <Uploader
                        id={'favicon_16x16'}
                        name={'favicon_16x16'}
                        data-tabs='file url gdrive gphotos dropbox onedrive box'
                        data-preview-step={true}
                        data-crop='1:1'
                        data-image-shrink="1600x1600"
                        data-system-dialog={true}
                        data-images-only={true}
                        onChange={(file:any) => {
                            // console.log('File changed: ', file);

                            // if (file) {
                            // file.progress((info:any) => console.log('File progress: ', info.progress));
                            // file.done((info:any) => console.log('File uploaded: ', info));
                            // }
                        }}
                        onUploadComplete={(info:any) => {
                            // console.log('Upload completed:', info);
                            let uploadedImgSrc = info.cdnUrl;
                            if(uploadedImgSrc){
                                this.saveFaviconInServer(uploadedImgSrc, info.uuid);
                                this.props.saveImageInfoInDB(info);

                                fireAnalyticsEvent.fireCrisp(CrispEvents.uploadFavicon, {
                                  subdomain: this.props.currentWebsite.subdomain + (this.props.settingsType === 'blog' ? `/${this.props.currentBlog.url}` : ''),
                                  image_url: uploadedImgSrc,
                                  uuid: info.uuid,
                                  size_bytes: info.size,
                                });
                                
                            }else{
                                fireAnalyticsEvent.fireCrisp(CrispEvents.uploadFaviconError, {
                                  subdomain: this.props.currentWebsite.subdomain,
                                }, true);
                                openNotification('Error :(', "There was an unknown error during uploading the favicon. Please refresh the page and try again.", 'OK', 'warn');
                            }
                        }
                        } />
                </div>
            )
        }
    }
}

export default FaviconUploader;
