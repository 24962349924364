import React, {Component} from "react";
import {BrowserRouter as Router, Route, Link, Switch, Redirect} from "react-router-dom";
import { Button, Form, Input, message, Tooltip } from 'antd';
import setInputStatus from "../../helpers/setInputStatus";
import {api} from "../../data/urls";
import {getCurrentWebsiteSubdomain} from "../../helpers/getCurrentWebsiteSubdomain";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {indexWebsitePageDashboardUrl, projectUrl} from "../../data/constants";
import {openNotification} from "../../helpers/openNotification";
import {messages} from "../../data/messages";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import {compareEditedValueWithCurrent} from "../../helpers/editor/compareEditedValueWithCurrent";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";

interface Props {
    websites: any,
    blogs: any,
    blogPosts: any,
    clearUpdatePostMetaTitle: any,
    auth: any,

    currentWebsite: any,
    currentBlog: any,
    currentBlogPost: any,

    saveBlogPostDataInStore: any,

    postMetaTitleUpdateWaitingForServer: any,
    postMetaTitleUpdateToggleInvalidAttempt: any,
    postMetaTitleUpdateErrorMessage: any,
    meta_title: string,
    setMetaTitle: (meta_title: string) => void,
}
type MyState = { };
class UpdatePostMetaTitleForm extends Component<Props, MyState>{
    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        // We set the new state only when we change a settings active website or page.
        // We want to set up an initial value of a new picked website or page.
        if((this.props.currentWebsite.subdomain !== nextProps.currentWebsite.subdomain) || (this.props.currentBlogPost.url !== nextProps.currentBlogPost.url)){
            this.props.setMetaTitle(nextProps.currentBlogPost.meta_title);
            // We also clear errors when switch a website or a page.
            this.props.clearUpdatePostMetaTitle();
        }
    }

    handleMetaTitleUpdate = (e:any):void => {
        e.preventDefault();

        this.props.postMetaTitleUpdateWaitingForServer(true);

        //We want to remove all errors from inputs on a new request.
        this.props.clearUpdatePostMetaTitle();


        let newMetaTitle = this.props.meta_title;

        let blogPostUrlToServer = this.props.currentBlogPost.url;

        let accessToken = this.props.auth.accessToken;
        let apiUrl = api.blogPosts.updateBlogPost.prefix + this.props.currentWebsite.subdomain + '/' + blogPostUrlToServer + api.blogPosts.updateBlogPost.postfix;
        axios.patch(apiUrl, {meta_title: newMetaTitle, subdomain: this.props.currentWebsite.subdomain}, {...returnAuthHeaderForAJAX(accessToken)})
            .then(() => {
                // Server gives the object of the saved website page. Throw it in the store.
                this.props.saveBlogPostDataInStore({
                  data: {
                    ...this.props.currentBlogPost,
                    meta_title: newMetaTitle,
                  }
                }, this.props.currentBlog, this.props.blogPosts.items, this.props.currentBlogPost.url);

                let successMessage = <span>Successfully changed the post title to <b>{newMetaTitle}</b>.</span>;
                if(newMetaTitle === ''){
                    successMessage = <span>Successfully removed the post title. Now it's blank.</span>;
                }
                
                fireAnalyticsEvent.fireCrisp(CrispEvents.changePostMetaTitle, {
                  subdomain: this.props.currentWebsite.subdomain,
                  post_url: this.props.currentBlogPost.url,
                  meta_title: newMetaTitle,
                });
            })
            .catch(error => {
                if(error.response){
                    let errorData = error.response.data;
                    if (errorData.detail !== undefined){
                        let message = errorData.detail;

                        let errorObjectKey = getErrorKey(errorData);
                        let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                        fireAnalyticsEvent.fireCrisp(CrispEvents.changePostMetaTitleError, {
                          error_type: errorObjectKey,
                          error_message: errorObjectValue,
                          subdomain: this.props.currentWebsite.subdomain,
                          post_url: blogPostUrlToServer,
                          meta_title: newMetaTitle,
                        }, true);

                        // This happens when user tries to change a website which doesn't exist or doesn't belog to the user.
                        openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                    }

                    if (errorData.locked !== undefined){
                        let message = errorData.locked;
                        openNotification('Denied', message, 'OK', 'warn');
                    }

                    if (errorData.meta_title !== undefined){
                        let message = errorData.meta_title.join(' ');
                        this.props.postMetaTitleUpdateErrorMessage(message);
                        this.props.postMetaTitleUpdateToggleInvalidAttempt(true);
                    }
                }
            })
            .then(response => {
                // always executed
                this.props.postMetaTitleUpdateWaitingForServer(false);
            });
    };

    handleInputChange = (e:any) => {
        this.props.setMetaTitle(e.target.value);
    };

    render(){
        return (
            <form onSubmit={this.handleMetaTitleUpdate} className="settings-box__form" style={{display: "flex", gap: "10px", width: "300px", alignItems: "flex-start"}}>
                <div className="settings-box__input_box" style={{margin: 0, flexGrow: 1}}>
                    
                      <Input name="meta_title"
                            id="meta_title"
                            required={false}
                            placeholder="Enter meta title"
                            type="text"
                            value={this.props.meta_title}
                            onChange={this.handleInputChange}
                            style={{width: "100%"}}
                      />                  
                    
                    <div className="settings-box__error_message" style={{opacity: this.props.blogPosts.postMetaTitleUpdateErrorMessage === undefined ? 0 : 1, marginTop: !this.props.blogPosts.postMetaTitleUpdateErrorMessage ? 0 : "3px"}}>
                        {this.props.blogPosts.postMetaTitleUpdateErrorMessage}
                    </div>
                </div>
                <Button
                    className="settings-box__form_save"
                    icon='save'
                    type="primary"
                    loading={this.props.blogPosts.isWaitingForPostMetaTitleUpdateResponse}
                    disabled={this.props.blogPosts.isWaitingForPostMetaTitleUpdateResponse}
                    htmlType="submit"
                    hidden={compareEditedValueWithCurrent(this.props.currentBlogPost.meta_title, this.props.meta_title)}
                    style={{bottom: 0}}
                />
            </form>
        )
    }
}

export default UpdatePostMetaTitleForm;
