import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import { companyLogos, pressLogos, publicUrl } from "../../data/urls";
import getUrlWithoutProtocol from "../../helpers/editor/getUrlWithoutProtocol";
import { checkIfUrlStartsWithProtocol } from "../../helpers/editor/checkIfUrlStartsWithProtocol";
import { checkIfUrlStartsWithMailto } from "../../helpers/editor/checkIfUrlStartsWithMailto";
import { checkIfUrlStartsWithTel } from "../../helpers/editor/checkIfUrlStartsWithTel";
import { getUrlWithoutMailto } from "../../helpers/editor/getUrlWithoutMailto";
import { getUrlWithoutTel } from "../../helpers/editor/getUrlWithoutTel";
import { getPhoneNumberHref } from "../../helpers/getPhoneNumberHref";
import { returnHref } from "../../helpers/content_displayers/returnHref";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Contact01 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpTitle({ titleText: "Say Hi", isDefaultEmpty})
      );
      this.props.saveContentInStore(
        this.generator.setUpSubtitleText({
          subtitleText:
            "We are super happy to talk to each interested lady or man and discuss relevant or off-topic stuff.",
            isDefaultEmpty
        })
      );

      this.props.saveContentInStore(
        this.generator.setSocialLinks({
          isDefaultEmpty,
          items: [
            {
              href: "https://twitter.com/johnrushx",
            },
            {
              href: "mailto:hi@unicornplatform.com",
            },
            {
              href: "tel:+1 (555) 123 4567",
            },
            {
              href: "",
            },
          ],
          fieldTitle: { default: "Contacts" },
          label: { default: "Paste social network full links or emails:" },
          labelTooltip: {
            default:
              "Add emails or paste social networks. Or add other links and text.",
          },
          fieldInfo: {
            default: "Email, Twitter, Facebook, Product Hunt, etc.",
          },
          hasTitle: false,
        })
      );
      this.props.saveContentInStore(
        this.generator.setUpContacts({
          isDefaultEmpty,
          hasMap: true,
          mapHasTitle: true,
          isMapEmbedded: false,
          hasContactsItems: false,

          fieldTitle: { default: "Map" },
          label: { default: "Enter a link to your office location:" },
          labelTooltip: {
            default:
              "Find your place on Google Maps (or other maps service) and get a link of the place. Copy and paste the link here.",
          },
          fieldInfo: { default: "" },

          map: {
            mapTitle: isDefaultEmpty ? "" : "Visit Our Place!",
            url: isDefaultEmpty ? "" : "https://goo.gl/maps/F2pQZPq4tYXSoBX38",
          },
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpCta({
          isDefaultEmpty,
          activeOption: callToActionOptions.buttons,
          fieldTitle: {
            [callToActionOptions.buttons]: "Bottom buttons",
            default: "Bottom buttons",
          },
          label: {
            [callToActionOptions.buttons]: "Edit the buttons:",
            default: "Edit the buttons:",
          },
          labelTooltip: {
            [callToActionOptions.buttons]:
              "Add a CTA (call to action) to the bottom of the contacts component to offer more ways to contact you.",
            default:
              "Add a CTA (call to action) to the bottom of the contacts component to offer more ways to contact you.",
          },

          buttons: [
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "",
              buttonHref: "",
              buttonStyleType: buttonStyles.pill.typeTitle,
              pillPrefix: "See also",
            }),
          ],

          priority: 10,
        })
      );
    }
  }

  render() {
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );

    let map = this.props.componentItem.componentData.contacts.content.map;
    let socialLinks = this.props.componentItem.componentData.socialLinks.content
      .items;

    return (
      <section>
        <div className="contact-01">
          <TitleBox 
            title={title}
            subtitleText={subtitleText}
            isDarkBg={this.props.isDarkBg}
          />
          <div className="container container--xsmall">
            {/* > 1 because there is always one ghost item with body === '' */}
            {socialLinks && socialLinks.length > 1 && (
              <ul className="contact-01__list">
                {socialLinks.map((item: any, key: number) => {
                  let itemBodyStartsWithProtocol = checkIfUrlStartsWithProtocol(
                    item.href
                  );
                  const itemBodyStartsWithMailto = checkIfUrlStartsWithMailto(
                    item.href
                  );
                  const itemBodyStartsWithTel = checkIfUrlStartsWithTel(
                    item.href
                  );
                  let cleanLookingUrl = getUrlWithoutProtocol(item.href);
                  let href = item.href.toLowerCase();
                  if (itemBodyStartsWithMailto) {
                    cleanLookingUrl = getUrlWithoutMailto(item.href);
                  }
                  if (itemBodyStartsWithTel) {
                    cleanLookingUrl = getUrlWithoutTel(item.href);
                    href = getPhoneNumberHref(item.href);
                  }

                  let socialLinkItemContent;
                  if (
                    itemBodyStartsWithProtocol ||
                    itemBodyStartsWithMailto ||
                    itemBodyStartsWithTel
                  ) {
                    socialLinkItemContent = (
                      <a
                        className={
                          "content-text contact-01__link " +
                          (this.props.isDarkBg
                            ? "text-white"
                            : "link  color-main")
                        }
                        href={href}
                      >
                        {cleanLookingUrl}
                      </a>
                    );
                  } else {
                    socialLinkItemContent = (
                      <span
                        className={
                          this.props.isDarkBg ? "text-white" : "color-main"
                        }
                        dangerouslySetInnerHTML={{ __html: item.href }}
                      />
                    );
                  }

                  if (item.href && item.href !== "") {
                    return (
                      <li
                        key={"contact-01-" + key}
                        className="contact-01__list_item"
                      >
                        {socialLinkItemContent}
                      </li>
                    );
                  } else {
                    return null;
                  }
                })}
              </ul>
            )}
            {map && map.url && map.url !== "" && (
              <div className="contact-01__map_box">
                {map.mapTitle && map.mapTitle !== "" && (
                  <h2
                    className={
                      "title-text--inner heading def-32 " + (this.props.isDarkBg ? "text-white" : "")
                    }
                  >
                    {map.mapTitle}
                  </h2>
                )}
                <a
                  className="card-container contact-01__map sh-1"
                  href={returnHref(map.url)}
                  style={{
                    backgroundImage:
                      "url(" +
                      publicUrl +
                      "/img/other/map.png)",
                  }}
                />
              </div>
            )}
            <div className="bottom_cta">
              {this.props.componentItem.componentData.cta &&
                <WebsiteCTA componentItem={this.props.componentItem} />}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact01;
