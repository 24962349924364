import React from "react";
import { Button, Icon, Tooltip } from "antd";
import generateFormIntegrationDataObject from "../../../../helpers/editor/generateFormIntegrationDataObject";
import { toggleNewWebsitePageEditionsDetected } from "../../../../store/websitePages/actions";
import { saveWebsiteNewFormIntegrationInState } from "../../../../store/websites/actions";
import { connect } from "react-redux";
import TooltipLink from "../../TooltipLink";
import { urls } from "../../../../data/urls";
import { SaveConnectedIntegrationsInServer } from "../../../../helpers/types/globalTypes";
import ContentInputLabel from "../../../ui_components/ContentInputLabel";

interface Props {
  currentFormIntegrationTitle: string;
  currentWebsite: any;
  websites: any;
  saveConnectedIntegrationsInServer: SaveConnectedIntegrationsInServer;

  toggleNewWebsitePageEditionsDetected: (payload: boolean) => void;
  saveWebsiteNewFormIntegrationInState: (payload: any) => void;
}

const ZapierInputs = (props: Props): JSX.Element => {
  const {
    currentFormIntegrationTitle,
    currentWebsite,
    saveConnectedIntegrationsInServer,
    websites,
  } = props;

  const handleOnClick = () => {
    // Detect component changes because when a new connection is added, it is automatically applied to the form which was being edited
    props.toggleNewWebsitePageEditionsDetected(true);

    const newConnectedIntegrationObject =
      generateFormIntegrationDataObject.zapier(
        currentFormIntegrationTitle,
        currentWebsite.id
      );

    // Save the new integration of the website in the Store:
    props.saveWebsiteNewFormIntegrationInState({
      newConnectedIntegrationObject,
      currentWebsiteSubdomain: currentWebsite.subdomain,
    });

    // Also save the new integration of the website in the server:
    saveConnectedIntegrationsInServer(newConnectedIntegrationObject);

    // Apply the newly added integration to the target form:
  };

  return (
    // The "content-input" className is taken from the <ContentInput> JSX component. We can't use it for the integration connection goals so we copied the markup (label, tooltip, inputs).
    <li className="content-input">
      <ContentInputLabel
        title={
          <div>
            Create a Zapier integration item that you can connect to a Zap.{" "}
            <TooltipLink hasIcon href={urls.guides.zapierIntegration}>
              Learn more
            </TooltipLink>
          </div>
        }
      >
        Create Zapier integration
      </ContentInputLabel>
      <div className="content-input__input_box">
        <Button
          icon="plus"
          type="primary"
          onClick={handleOnClick}
          loading={websites.isWaitingForFormIntegrationsServerResponse}
        >
          Add integration
        </Button>
      </div>
    </li>
  );
};

const mapActionsToProps = {
  toggleNewWebsitePageEditionsDetected,
  saveWebsiteNewFormIntegrationInState,
};
export default connect(null, mapActionsToProps)(ZapierInputs);
