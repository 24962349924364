import React, { Component } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Empty, Icon, Popover, Tabs, Select, Divider } from "antd";
import SpinnerBox from "./SpinnerBox";
import { defaultBlogUrl } from "../../data/constants";
import { getCurrentBlogPostUrl } from "../../helpers/blog/getCurrentBlogPostUrl";
import { getCurrentBlogPost } from "../../helpers/blog/getCurrentBlogPost";
import SettingsBox from "./SettingsBox";
import { getCurrentWebsite } from "../../helpers/getCurrentWebsite";
import { getCurrentBlog } from "../../helpers/blog/getCurrentBlog";
import trimStringTo from "../../helpers/strings/trimStringTo";
import UpdatePostUrlForm from "../blog/UpdatePostUrlForm";
import UpdatePostMetaTitleForm from "../blog/UpdatePostMetaTitleForm";
import UpdatePostMetaDescriptionForm from "../blog/UpdatePostMetaDescriptionForm";
import OpenGraphImageUploader from "./OpenGraphImageUploader";
import { api, urls } from "../../data/urls";
import AddPostHeadCustomCodeForm from "../blog/AddPostHeadCustomCodeForm";
import UpdatePostThumbnailAlt from "../blog/UpdatePostThumbnailAlt";
import { PosthogEvents } from "../../enums/AnalyticsEventsEnums";
import { connect } from "react-redux";
import {
  postUrlUpdateWaitingForServer,
  postUrlUpdateToggleInvalidAttempt,
  postUrlUpdateErrorMessage,
  postMetaTitleUpdateWaitingForServer,
  postMetaTitleUpdateToggleInvalidAttempt,
  postMetaTitleUpdateErrorMessage,
  postMetaDescriptionUpdateWaitingForServer,
  postMetaDescriptionUpdateToggleInvalidAttempt,
  postMetaDescriptionUpdateErrorMessage,
  publishPostToggleWaitingForServer,
  toggleWaitingForCommonBlogPostUpdate,
  changePostStateGlobalData,
  changeBlogPostData,
} from "../../store/blogPosts/actions";
import { ActiveMenu } from "./SettingsDrawerContent";
import GooglePreview from "./GooglePreview";
import {
  getCurrentStoreData,
  saveWebsiteBackup,
} from "../../store/websites/thunks";
import { GetCurrentStoreData } from "../../store/websites/types";
import { DatePicker } from "antd";
import moment from "moment";
import { updatePostAsync } from "../../helpers/async/updatePostAsync";
import { ChangeBlogPostData } from "../../store/blogPosts/types";
import BlogPostFiltersInput from "./BlogPostFiltersInput";
import _ from "lodash";

const TabPane = Tabs.TabPane;
const { Option } = Select;

interface Props extends RouteComponentProps {
  websites: any;
  blogs: any;
  blogPosts: any;
  auth: any;
  user: any;
  history: any;
  postUrlUpdateWaitingForServer: any;
  postUrlUpdateToggleInvalidAttempt: any;
  postUrlUpdateErrorMessage: any;
  postMetaTitleUpdateWaitingForServer: any;
  postMetaTitleUpdateToggleInvalidAttempt: any;
  postMetaTitleUpdateErrorMessage: any;
  postMetaDescriptionUpdateWaitingForServer: any;
  postMetaDescriptionUpdateToggleInvalidAttempt: any;
  postMetaDescriptionUpdateErrorMessage: any;
  publishPostToggleWaitingForServer: any;
  toggleWaitingForCommonBlogPostUpdate: any;
  getCurrentStoreData: GetCurrentStoreData;
  changePostStateGlobalData: any;
  changeBlogPostData: ChangeBlogPostData;
  saveWebsiteBackup: any;

  saveBlogPostDataInStore: any;
  saveImageInfoInDB: any;
  removeImageFromDB: any;
  publishPost: any;
  currentMenuTitle: ActiveMenu;
}
interface State {
  meta_title: string;
  meta_description: string;
  url: string;
  published_on: moment.Moment;
}

class BlogPostSettings extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { currentBlogPost } = this.props.getCurrentStoreData();

    this.state = {
      meta_title: currentBlogPost.meta_title,
      meta_description: currentBlogPost.meta_description,
      url: currentBlogPost.url,
      published_on: moment(currentBlogPost.published_on),
    };
  }

  setMetaTitle = (meta_title: string) => {
    this.setState({ meta_title });
  };
  setMetaDescription = (meta_description: string) => {
    this.setState({ meta_description });
  };
  setUrl = (url: string) => {
    this.setState({ url });
  };

  componentDidMount(): void {
    //We want to remove all errors from inputs on render.
    this.clearUpdatePostUrl();
    this.clearUpdatePostMetaTitle();
    this.clearUpdatePostMetaDescription();
  }

  clearUpdatePostUrl = (): void => {
    //Remove all errors and make inputs normal colour (not red).
    this.props.postUrlUpdateToggleInvalidAttempt(false);
    this.props.postUrlUpdateErrorMessage(undefined);
  };

  clearUpdatePostMetaTitle = (): void => {
    //Remove all errors and make inputs normal colour (not red).
    this.props.postMetaTitleUpdateToggleInvalidAttempt(false);
    this.props.postMetaTitleUpdateErrorMessage(undefined);
  };

  clearUpdatePostMetaDescription = (): void => {
    //Remove all errors and make inputs normal colour (not red).
    this.props.postMetaDescriptionUpdateToggleInvalidAttempt(false);
    this.props.postMetaDescriptionUpdateErrorMessage(undefined);
  };

  handleChangePostStatus = (
    status,
    currentWebsite,
    currentBlog,
    currentBlogPost
  ) => {
    this.props.publishPost(
      status,
      currentWebsite,
      currentBlog,
      currentBlogPost
    );
  };

  render() {
    if (this.props.websites.dataFetched && this.props.blogs.dataFetched) {
      // After the form is rendered, it fills in default values for the inputs. That means if user entered something and don't save that, his changes may disappear.
      let currentWebsite = getCurrentWebsite(this.props.websites.items);
      let currentBlog = getCurrentBlog(this.props.blogs.items, currentWebsite);

      if (currentBlog === undefined) {
        // If no such blog, offer to go to the main dashboard screen.
        return (
          <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
            <Empty description={<span>Blog not found</span>}>
              <Link to="/">
                <Button type="primary">
                  Back to your websites to create a blog
                </Button>
              </Link>
            </Empty>
          </div>
        );
      }

      // After we check the blog, check the post:
      let blogsPostsArray = this.props.blogPosts.items;
      let currentBlogPostUrl = getCurrentBlogPostUrl(
        currentBlog,
        blogsPostsArray
      );
      let currentBlogPostUrlToDisplay = currentBlogPostUrl;
      currentBlogPostUrlToDisplay =
        "/" + trimStringTo(currentBlogPostUrlToDisplay, 30);

      let currentBlogPost = getCurrentBlogPost(
        currentBlogPostUrl,
        blogsPostsArray,
        currentBlog
      );

      if (currentBlogPost === undefined || currentBlogPost.is_seobot_post) {
        // If no such blog post, offer to go to the blog posts list.
        return (
          <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
            <Empty description={<span>Blog posts not found</span>}>
              <Link to={"/" + currentWebsite.subdomain}>
                <Button type="primary">
                  {'View all posts of "' + currentWebsite.subdomain + '"'}
                </Button>
              </Link>
            </Empty>
          </div>
        );
      }

      const { currentMenuTitle, blogPosts } = this.props;

      const saveDate = () => {
        if (!this.state.published_on) {
          this.setState({ published_on: moment(currentBlogPost.published_on) });
          return;
        }
        const date = new Date(moment(this.state.published_on).format());
        this.props.changePostStateGlobalData({
          data: { isWaitingPublishDateUpdateResponse: true },
        });
        updatePostAsync({
          accessToken: this.props.auth.accessToken,
          subdomain: currentWebsite.subdomain,
          postUrl: currentBlogPostUrl,
          data: { published_on: date },
        })
          .then(() => {
            this.props.changeBlogPostData({
              postId: currentBlogPost.id,
              data: { published_on: date },
            });
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.props.changePostStateGlobalData({
              data: { isWaitingPublishDateUpdateResponse: false },
            });
          });
      };

      const hasFilters = _.get(
        this.props.user,
        "data.feature_flags.filters",
        false
      );

      if (currentMenuTitle === "postSettings") {
        return (
          <div className="settings__container">
            <SettingsBox titleText="Blog post settings">
              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          How your blog post will be <br />
                          accessible for visitors.
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">Post url:</div>
                </div>

                <UpdatePostUrlForm
                  websites={this.props.websites}
                  blogs={this.props.blogs}
                  currentWebsite={currentWebsite}
                  currentBlog={currentBlog}
                  currentBlogPost={currentBlogPost}
                  clearUpdatePostUrl={this.clearUpdatePostUrl}
                  auth={this.props.auth}
                  blogPosts={this.props.blogPosts}
                  history={this.props.history}
                  saveBlogPostDataInStore={this.props.saveBlogPostDataInStore}
                  postUrlUpdateWaitingForServer={
                    this.props.postUrlUpdateWaitingForServer
                  }
                  postUrlUpdateErrorMessage={
                    this.props.postUrlUpdateErrorMessage
                  }
                  postUrlUpdateToggleInvalidAttempt={
                    this.props.postUrlUpdateToggleInvalidAttempt
                  }
                  url={this.state.url}
                  setUrl={this.setUrl}
                />
              </div>

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          Publish your post to make it accessible for visitors.{" "}
                          <span style={{ opacity: 0.7 }}>
                            Set it "Direct URL" to
                            <br />
                            delist the post from your blog home page.
                          </span>
                          <br />
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Post visibility:
                  </div>
                </div>
                <div>
                  <Select
                    value={currentBlogPost.status}
                    style={{ width: 120 }}
                    onChange={(value) =>
                      this.handleChangePostStatus(
                        value,
                        currentWebsite,
                        currentBlog,
                        currentBlogPost
                      )
                    }
                  >
                    <Option value="public">Public</Option>
                    <Option value="draft">Draft</Option>
                    <Option value="direct_url">Direct URL</Option>
                  </Select>
                </div>
              </div>

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          Change the publish date of this blog post.
                          <br />
                          More recent posts are displayed first.
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Publish date:
                  </div>
                </div>
                <div>
                  <DatePicker
                    showTime
                    placeholder="Select date"
                    onChange={(date) => {
                      this.setState({ published_on: date });
                    }}
                    value={this.state.published_on}
                    onOk={saveDate}
                    disabled={blogPosts.isWaitingPublishDateUpdateResponse}
                    onOpenChange={(status) => {
                      if (!status) {
                        saveDate();
                      }
                    }}
                    allowClear={false}
                  />
                </div>
              </div>

              {hasFilters && (
                <>
                <Divider className="settings__divider" />
                  <div className="settings-box__input">
                    <div className="settings-box__input_label">
                      <div>
                        <Popover
                          content={
                            'Enter filters for this blog post. Separate by a semicolon. Example: "SEO;Marketing;Design"'
                          }
                        >
                          <Icon type="info-circle" />
                        </Popover>
                      </div>
                      <div className="settings-box__input_info_text">
                        Filters:
                      </div>
                    </div>
                    <div>
                      <BlogPostFiltersInput />
                    </div>
                  </div>
                </>
              )}

              <Divider className="settings__divider" />

              <div className="settings-box__input publish-page-form__input-box">
                <div
                  className="settings-box__input_label"
                  style={{ marginBottom: 0, marginTop: "4px" }}
                >
                  <div>
                    <Popover
                      content={
                        <div>
                          Meta title of this blog post.
                          <br />
                          Optimal length is 50-60 characters.
                          <br />
                          {this.state.meta_title
                            ? ` Yours is ${this.state.meta_title.length} characters.`
                            : ""}
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">Title:</div>
                </div>

                <UpdatePostMetaTitleForm
                  currentWebsite={currentWebsite}
                  blogs={this.props.blogPosts}
                  currentBlog={currentBlog}
                  currentBlogPost={currentBlogPost}
                  websites={this.props.websites}
                  clearUpdatePostMetaTitle={this.clearUpdatePostMetaTitle}
                  auth={this.props.auth}
                  blogPosts={this.props.blogPosts}
                  saveBlogPostDataInStore={this.props.saveBlogPostDataInStore}
                  postMetaTitleUpdateWaitingForServer={
                    this.props.postMetaTitleUpdateWaitingForServer
                  }
                  postMetaTitleUpdateToggleInvalidAttempt={
                    this.props.postMetaTitleUpdateToggleInvalidAttempt
                  }
                  postMetaTitleUpdateErrorMessage={
                    this.props.postMetaTitleUpdateErrorMessage
                  }
                  meta_title={this.state.meta_title}
                  setMetaTitle={this.setMetaTitle}
                />
              </div>

              <div className="settings-box__input publish-page-form__input-box">
                <div
                  className="settings-box__input_label"
                  style={{ marginBottom: 0, marginTop: "4px" }}
                >
                  <div>
                    <Popover
                      content={
                        <div>
                          Meta description of this blog post.
                          <br />
                          Optimal length is 120-158 characters.
                          <br />
                          {this.state.meta_description
                            ? ` Yours is ${this.state.meta_description.length} characters.`
                            : ""}
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Description:
                  </div>
                </div>

                <UpdatePostMetaDescriptionForm
                  currentWebsite={currentWebsite}
                  currentBlog={currentBlog}
                  currentBlogPost={currentBlogPost}
                  websites={this.props.websites}
                  blogs={this.props.blogs}
                  clearUpdatePostMetaDescription={
                    this.clearUpdatePostMetaDescription
                  }
                  auth={this.props.auth}
                  blogPosts={this.props.blogPosts}
                  saveBlogPostDataInStore={this.props.saveBlogPostDataInStore}
                  postMetaDescriptionUpdateWaitingForServer={
                    this.props.postMetaDescriptionUpdateWaitingForServer
                  }
                  postMetaDescriptionUpdateToggleInvalidAttempt={
                    this.props.postMetaDescriptionUpdateToggleInvalidAttempt
                  }
                  postMetaDescriptionUpdateErrorMessage={
                    this.props.postMetaDescriptionUpdateErrorMessage
                  }
                  meta_description={this.state.meta_description}
                  setMetaDescription={this.setMetaDescription}
                />
              </div>

              <GooglePreview
                urlsArray={[currentBlog.url, this.state.url]}
                overrideTitle={this.state.meta_title}
                overrideDescription={this.state.meta_description}
                type="post"
                disableLiveUpdate
              />

              <Divider className="settings__divider" />

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div style={{ width: 400 }}>
                          Displayed as a thumbnail and when the blog post is
                          shared on social media. If not set, we'll use the
                          image from Blog settings or General settings.
                          Recommended size: 1200x630px.
                          <br />
                          <br />
                          If you don't specify any image, we'll look for one in
                          your site's content.
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Blog post social image:{" "}
                    <span className="settings-box__input_info_subtext">
                      a.k.a. Open Graph image
                    </span>
                  </div>
                </div>

                <OpenGraphImageUploader
                  settingsType={"blogPost"}
                  saveBlogPostDataInStore={this.props.saveBlogPostDataInStore}
                  auth={this.props.auth}
                  currentWebsite={currentWebsite}
                  currentBlog={currentBlog}
                  currentBlogPost={currentBlogPost}
                  saveImageInfoInDB={this.props.saveImageInfoInDB}
                  removeImageFromDB={this.props.removeImageFromDB}
                  blogPosts={this.props.blogPosts}
                  apiUrl={
                    api.blogPosts.updateBlogPost.prefix +
                    currentWebsite.subdomain +
                    "/" +
                    currentBlogPostUrl +
                    api.blogPosts.updateBlogPost.postfix
                  }
                  currentMetaTitle={this.state.meta_title}
                  currentMetaDescription={this.state.meta_description}
                  disableLiveUpdate
                />
              </div>

              {currentBlogPost.og_image_url && (
                <div className="settings-box__input">
                  <div className="settings-box__input_label">
                    <div>
                      <Popover
                        content={
                          <div style={{ width: 300 }}>
                            Use the alt (alternative) text attribute to describe
                            your blog post thumbnails. It will be used by screen
                            readers and search engine crawlers.
                          </div>
                        }
                      >
                        <Icon type="info-circle" />
                      </Popover>
                    </div>
                    <div className="settings-box__input_info_text">
                      Thumbnail alt attribute:
                    </div>
                  </div>
                  <UpdatePostThumbnailAlt
                    currentBlogPost={currentBlogPost}
                    currentWebsite={currentWebsite}
                    currentBlog={currentBlog}
                    saveBlogPostDataInStore={this.props.saveBlogPostDataInStore}
                  />
                </div>
              )}

              <Divider className="settings__divider" />

              <div className="settings__container">
                <div className="settings-box__input">
                  <div className="settings-box__input_label">
                    <div className="settings-box__input_info_text">
                      Custom{" "}
                      <code>
                        <span style={{ opacity: 0.4 }}>&lt;</span>head
                        <span style={{ opacity: 0.4 }}>&gt;</span>
                      </code>{" "}
                      code:
                    </div>
                  </div>
                  <AddPostHeadCustomCodeForm
                    currentWebsite={currentWebsite}
                    currentBlog={currentBlog}
                    currentBlogPost={currentBlogPost}
                    websites={this.props.websites}
                    blogs={this.props.blogs}
                    auth={this.props.auth}
                    user={this.props.user}
                    blogPosts={this.props.blogPosts}
                    toggleWaitingForCommonBlogPostUpdate={
                      this.props.toggleWaitingForCommonBlogPostUpdate
                    }
                    saveBlogPostDataInStore={this.props.saveBlogPostDataInStore}
                    saveWebsiteBackup={this.props.saveWebsiteBackup}
                  />
                </div>
              </div>
            </SettingsBox>
          </div>
        );
      }

      return null;
    } else {
      return <SpinnerBox text="Post settings are loading..." />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    websites: state.websites,
    blogs: state.blogs,
    blogPosts: state.blogPosts,
    auth: state.auth,
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  postUrlUpdateWaitingForServer,
  postUrlUpdateToggleInvalidAttempt,
  postUrlUpdateErrorMessage,
  postMetaTitleUpdateWaitingForServer,
  postMetaTitleUpdateToggleInvalidAttempt,
  postMetaTitleUpdateErrorMessage,
  postMetaDescriptionUpdateWaitingForServer,
  postMetaDescriptionUpdateToggleInvalidAttempt,
  postMetaDescriptionUpdateErrorMessage,
  publishPostToggleWaitingForServer,
  toggleWaitingForCommonBlogPostUpdate,
  getCurrentStoreData,
  changePostStateGlobalData,
  changeBlogPostData,
  saveWebsiteBackup,
})(withRouter(BlogPostSettings));
