import { Button } from "antd";
import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  ChangeWebsiteItemGlobalData,
  ChangeWebsitesStateGlobalData,
  EditCustomStyles,
  GetCurrentStoreData,
  SavePartialWebsiteData,
  SaveWebsiteBackup,
  WebsitesState,
} from "../../store/websites/types";
import {
  getCurrentStoreData,
  savePartialWebsiteData,
  saveWebsiteBackup,
} from "../../store/websites/thunks";
import {
  changeWebsiteItemGlobalData,
  changeWebsitesStateGlobalData,
  editCustomStyles,
} from "../../store/websites/actions";
import { updateWebsiteAsync } from "../../helpers/async/updateWebsiteAsync";
import _ from "lodash";
import { AuthState } from "../../store/auth/types";

interface Props {
  websites: WebsitesState;
  auth: AuthState;
  getCurrentStoreData: GetCurrentStoreData;
  changeWebsitesStateGlobalData: ChangeWebsitesStateGlobalData;
  savePartialWebsiteData: SavePartialWebsiteData;
  changeWebsiteItemGlobalData: ChangeWebsiteItemGlobalData;
  editCustomStyles: EditCustomStyles;
  saveWebsiteBackup: SaveWebsiteBackup;
}

const Name = (props: Props): JSX.Element => {
  const {
    websites,
    auth,
    getCurrentStoreData,
    changeWebsitesStateGlobalData,
    savePartialWebsiteData,
    changeWebsiteItemGlobalData,
    editCustomStyles,
    saveWebsiteBackup
  } = props;

  const { currentWebsite } = getCurrentStoreData();

  const getFullStylesObject = (stylesObj) => {
    const keys = [
      "buttonRadius",
      "buttonBorderColor",
      "borderSize",
      "buttonHorizontalPadding",
      "buttonVerticalPadding",
      "fontSizeParagraphs",
      "fontSizeH1",
      "fontSizeH2",
      "fontSizeH3",
      "inputRadius",
      "inputBorderSize",
      "inputVerticalPadding",
      "inputHorizontalPadding",
      "buttonFontSize",
      "inputFontSize",
      "buttonFontWeight",
      "inputFontWeight",
      "buttonShadow",
      "fontSizeH3",
      "inputLabelFontSize",
      "inputLabelFontWeight",
      "searchRadius",
      "searchBorderSize",
      "searchHorizontalPadding",
      "searchVerticalPadding",
      "searchFontSize",
      "searchFontWeight",
      "cardRadius",
      "cardShadow",
      "fontSizeContent",
      "additionalVerticalSpace",
      "inputLabelMarginBottom",
    ];
    const res = {};
    keys.forEach((key) => {
      res[key] = _.get(stylesObj, key, null);
    });
    return res;
  };

  const initialCustomStylesRef = useRef(
    getFullStylesObject(_.get(currentWebsite, "custom_styles", {}))
  );

  const saveChanges = async () => {
    changeWebsitesStateGlobalData({
      isCustomStylesWaitingForServer: true,
    });
    // try {
    //   await saveWebsiteBackup({has_styles: true});
    // } catch(e) {
    //   console.error(_.get(e, "response.data"));
    // }
    const custom_styles = _.get(currentWebsite, "custom_styles", {});
    updateWebsiteAsync({
      accessToken: auth.accessToken,
      subdomain: currentWebsite.subdomain,
      data: { custom_styles },
    })
      .then(() => {
        initialCustomStylesRef.current = getFullStylesObject(
          custom_styles
        );
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
      })
      .finally(() => {
        changeWebsiteItemGlobalData({
          websiteId: currentWebsite.id,
          data: {
            hasUnsavedChanges: false,
          },
        });
        changeWebsitesStateGlobalData({
          isCustomStylesWaitingForServer: false,
        });
      });
  };

  const resetStyles = () => {
    editCustomStyles({
      websiteId: currentWebsite.id,
      newData: initialCustomStylesRef.current,
    });
    changeWebsiteItemGlobalData({
      websiteId: currentWebsite.id,
      data: {
        hasUnsavedChanges: false,
      },
    });
  };

  useEffect(() => {
    return () => {
      const { currentWebsite } = getCurrentStoreData();
      if (
        currentWebsite.hasUnsavedChanges &&
        !websites.isCustomStylesWaitingForServer
      ) {
        resetStyles();
      }
    };
  }, []);

  if (!currentWebsite.hasUnsavedChanges) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        padding: "20px",
        gap: 10,
        zIndex: 100,
        borderTop: "1px solid #E8E8E8",
        position: "absolute",
        bottom: 0,
        right: 0,
        width: 466,
      }}
    >
      <Button
        type="primary"
        onClick={saveChanges}
        loading={websites.isCustomStylesWaitingForServer}
      >
        Save changes
      </Button>
      <Button
        disabled={websites.isCustomStylesWaitingForServer}
        onClick={resetStyles}
      >
        Cancel
      </Button>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  websites: state.websites,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  getCurrentStoreData,
  changeWebsitesStateGlobalData,
  savePartialWebsiteData,
  changeWebsiteItemGlobalData,
  editCustomStyles,
  saveWebsiteBackup
})(Name);
