import React, { useState } from "react";
import { Button, Input } from "antd";
import { connect } from "react-redux";
import { getCurrentStoreData } from "../../store/websites/thunks";
import { GetCurrentStoreData } from "../../store/websites/types";
import _ from "lodash";
import { ChangeBlogPostData } from "../../store/blogPosts/types";
import { changeBlogPostData } from "../../store/blogPosts/actions";
import { updatePostAsync } from "../../helpers/async/updatePostAsync";
import { AuthState } from "../../store/auth/types";

interface Props {
  auth: AuthState;
  getCurrentStoreData: GetCurrentStoreData;
  changeBlogPostData: ChangeBlogPostData;
}

const BlogPostFiltersInput = (props: Props): JSX.Element => {
  const { getCurrentStoreData, changeBlogPostData, auth } = props;
  const { currentBlogPost, currentWebsite } = getCurrentStoreData();
  const filterGroups = _.get(currentBlogPost, "filters.groups", []);

  const [hasChanges, setHasChanges] = useState(false);
  const [currentFilterGroups, setCurrentFilterGroups] = useState(filterGroups);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnChange = (e, index) => {
    setHasChanges(true);
    const newFilterGroups = _.cloneDeep(currentFilterGroups);
    newFilterGroups[index].filters = e.target.value;
    setCurrentFilterGroups(newFilterGroups);
  };

  const handleOnAddFilterGroup = () => { 
    const newFilterGroups = _.cloneDeep(currentFilterGroups);
    newFilterGroups.push({ filters: "" });
    setCurrentFilterGroups(newFilterGroups);
   }

  const handleSaveFilters = () => {
    setIsLoading(true);

    updatePostAsync({
      accessToken: auth.accessToken,
      subdomain: currentWebsite.subdomain,
      postUrl: currentBlogPost.url,
      data: {
        filters: {
          groups: currentFilterGroups,
        },
      },
    })
      .then(() => {
        changeBlogPostData({
          postId: currentBlogPost.id,
          data: {
            filters: {
              groups: currentFilterGroups,
            },
          },
        });
        setHasChanges(false);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
      {currentFilterGroups.map((filterGroup, index) => {
        return (
          <Input
            key={index}
            placeholder="filter1;filter2"
            defaultValue={filterGroup.filters}
            onChange={(e) => {
              handleOnChange(e, index);
            }}
          />
        );
      })}
      <div>
        <Button size="small" icon="plus" onClick={handleOnAddFilterGroup}>
          Add filter group
        </Button>
      </div>
      <div style={{ display: "flex", gap: 10 }}>
        <Button
          type="primary"
          hidden={!hasChanges}
          onClick={handleSaveFilters}
          loading={isLoading}
        >
          Save filters
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps, {
  getCurrentStoreData,
  changeBlogPostData,
})(BlogPostFiltersInput);
