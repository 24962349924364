import React, { Component } from "react";
import "./ContentInput.css";
import _ from "lodash";
import {
  Alert,
  Button,
  Collapse,
  Drawer,
  Icon,
  Input,
  Radio,
  Slider,
  Tabs,
  Tooltip,
} from "antd";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import capitalizeString from "../../helpers/strings/capitalizeString";
import EmptyImage from "../../img/other/empty.svg";
import { callToActionOptions } from "../../data/callToActionOptions";
import { availableOptions } from "../../data/availableOptions";
import { checkIfObjectEmpty } from "../../helpers/checkIfObjectEmpty";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import { checkIfWebsiteHasBlackPalette } from "../../helpers/editor/checkIfWebsiteHasBlackPalette";
import { pictogramOptions } from "../../data/pictogramOptions";
import { abstractIconsCount } from "../../data/abstractIconsCount";
import { emoji } from "../../data/emoji";
import { emojiPath, publicUrl } from "../../data/urls";
const RadioGroup = Radio.Group;
import ImagePicker from "./ImagePicker";
import { logoOptions } from "../../data/logoOptions";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import ButtonPluginsDrawer from "./ButtonPluginsDrawer";
import checkIsButtonHrefDisabled from "../../helpers/editor/checkIsButtonHrefDisabled";
import {
  defaultUploadedPictogramHeight,
  projectTitle,
} from "../../data/constants";
import { applyCustomColorsClassnames } from "../../helpers/editor/applyCustomColorsClassnames";
import { returnTextEditorType } from "../../helpers/editor/returnTextEditorType";
import { Link } from "react-router-dom";
import { generatePopupDrawerClass } from "../../helpers/generatePopupDrawerClass";
import { checkIfPopupExists } from "../../helpers/checkIfPopupExists";
import { ContentInputsMap } from "./contentInputs";
import ContentInputsTypesEnum from "../../enums/ContentInputsTypesEnum";
import {
  ComponentDataPaths,
  ComponentDataPathsInputState,
} from "../../helpers/types/popupTypes";
import { generateComponentDataPath } from "../../helpers/editor/generateComponentDataPath";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import { getAvailableGraphicsOptions } from "./component_graphics/helpers/getAvailableGraphicsOptions";
import classNames from "classnames";
import {
  contentTypesWithGraphicsOptions,
  GraphicsOptions,
} from "./component_graphics/data/componentGraphicsData";
import GraphicsPicker from "./component_graphics/components/GraphicsPicker";
import { Components } from "../../enums/Components";
import { forceNewUITextIfHasMultipleGraphicsOptions } from "./component_graphics/helpers/forceNewUITextIfHasMultipleGraphicsOptions";
import { ComponentTypes } from "../../enums/ComponentTypes";
import { UITextTypes } from "../../enums/UITextTypes";
import AvailableIntegrationsList from "./formIntegration/available_integrations/AvailableIntegrationsList";
import Pictogram from "../../pageComponents/component_molecules/Pictogram";
import WebsiteButton from "../../pageComponents/component_molecules/WebsiteButton";
import DrawerTitle from "./DrawerTitle";
import { contentEditionDrawerSmallWidth, contentEditionDrawerWidth } from "../../data/settings";

//This component is used in <PageComponent> and in <Editor> only.

interface Props {
  saveContentInStore: any;
  toggleNewEditionsDetected: any;
  contentType: ContentInputsTypesEnum;
  user: any;
  currentInputData: any;
  currentWebsite: any; //needed for nav and footer changes, for email forms

  closeFormIntegrationsDrawer?: any; //needed for formIntegrations configurations
  showFormIntegrationsDrawer?: any; //needed for formIntegrations configurations
  showButtonStylesDrawer?: any;
  closeButtonStylesDrawer?: any;

  componentType: string; //e.g. header, nav, footer, cta_form
  componentTitle: string; //e.g. 02, 12, 25

  removeImageFromDB: any;
  saveImageInfoInDB: any;
  isFirstComponentBgDark?: any;

  websites: any;
  auth: any;
  toggleWaitingForConnectPaymentIntegration: any;
  toggleWaitingForChangePaymentIntegrationProductsArray: any;
  toggleWaitingForEditPaymentIntegrationProductDetails: any;
  toggleWaitingForConfigurePaymentIntegration: any;

  toggleWaitingForCommonWebsiteUpdate: any;

  changeStripeSecretKey: any;
  changeStripePublicKey: any;
  changeStripeCheckoutProductsArray: any;

  saveWebsiteDataInStore: any;
  removeWebsiteFormIntegration: any;
  isBlogObjectEdited?: any;
  currentBlog?: any;
}
const Panel = Collapse.Panel;
const InputGroup = Input.Group;
const { TabPane } = Tabs;

class ContentInput extends Component<Props> {
  componentWillMount(): void {
    let activeOption = this.props.currentInputData.activeOption;

    if (activeOption === undefined) {
      this.setState({
        activeOption: "default",
      });
    } else {
      this.setState({
        activeOption: activeOption,
      });
    }
  }

  componentDidMount(): void {
    // startOnboardingTour('edit_component_content', this.props.user);
  }
  componentWillUnmount(): void {
    // startOnboardingTour('save', this.props.user);
  }
  componentDidUpdate(prevProps: Readonly<Props>) {
    // We need to update the UI manually state when the active option is changed by AI.
    if (this.props.contentType !== ContentInputsTypesEnum.cta) return;
    const prevActiveOption = _.get(prevProps.currentInputData, "activeOption");
    const currentActiveOption = _.get(
      this.props.currentInputData,
      "activeOption"
    );
    if (prevActiveOption !== currentActiveOption) {
      this.setState({
        activeOption: currentActiveOption,
      });
    }
  }

  state = {
    activeOption: "default",

    buttonStylesVisible: false,
    buttonPluginsVisible: false,
    buttonStylesOptions: [buttonStyles.regular.typeTitle], // different components may have different button style types. E.g. a form button can not have an iOS mobileAppStore button as a submit button.
    currentButtonObject: undefined as any, //we need to pass the currentButtonObject to styles editor because styles editor is placed in another component - a modal window (or Drawer).
    isYearlyPricingButtonPassed: false, //for Pricing components, we need to detect whether a default button was passed or yearly-only button was passed because we have different actions for editing buttons (e.g. changePricingButtonIsRelayUTM and changePricingYearlyButtonIsRelayUTM). If yearly-only (yearlyButton) was passed, then it is true, else - undefined or false

    pictogramDrawerVisible: false,
    currentPictogramType: pictogramOptions.abstractIcon.title,

    shouldDisplayForcedWhiteButtonsWarning: false,

    currentFeatureIndex: 0, //updated when we click on a feature to edit its button or pictogram. Same for other indexes.
    currentPricingIndex: 0,
    currentGalleryIndex: 0,
    currentGalleryButtonIndex: 0,
    currentCtaButtonIndex: 0,
    currentPostIndex: 0,
    currentGroupIndex: 0,
    currentPictogramIndex: 0,
    currentStepsItemIndex: 0,
    currentPictogramObject: undefined,
  };

  generator = new generateContentDataObject() as any;

  showPictogramDrawer = () => {
    this.setState({
      pictogramDrawerVisible: true,
    });
  };
  closePictogramDrawer = () => {
    this.setState({
      pictogramDrawerVisible: false,
    });
  };

  showButtonStylesDrawer = () => {
    this.setState({
      buttonStylesVisible: true,
    });
  };
  closeButtonStylesDrawer = () => {
    this.setState({
      buttonStylesVisible: false,
    });
  };
  showButtonPluginsDrawer = (
    buttonObject: any,
    isYearlyPricingButtonPassed: boolean
  ) => {
    this.setState({
      buttonPluginsVisible: true,
      currentButtonObject: buttonObject,
      isYearlyPricingButtonPassed,
    });
  };
  closeButtonPluginsDrawer = () => {
    this.setState({
      buttonPluginsVisible: false,
    });
  };

  removeUploadedIcon = (
    currentElementHasPictogramInGroups: boolean,
    currentInputObject: any
  ) => {
    // We need to remove the uploaded icon (this happens when a user picks another icon type - e.g. emoji or abstract icon). This is done to let user set the pictogramOptions.uploaded.title type back again (by uploading an icon).
    // We don't erase the UUID because it will be re-written when a new icon is uploaded, no need to perform this action right now.

    if (currentElementHasPictogramInGroups) {
      this.props.saveContentInStore(
        this.generator.changeGroupPictogramUploadedSrc(
          currentInputObject,
          "",
          this.state.currentGroupIndex,
          this.props.contentType
        )
      );
      // this.props.saveContentInStore(this.generator.changeGroupPictogramUploadedUUID(currentInputObject, '', this.state.currentGroupIndex, this.props.contentType));
    } else {
      if (this.props.contentType === "features") {
        this.props.saveContentInStore(
          this.generator.changeFeaturePictogramUploadedSrc(
            currentInputObject,
            "",
            this.state.currentFeatureIndex
          )
        );
        // this.props.saveContentInStore(this.generator.changeFeaturePictogramUploadedUUID(currentInputObject, '', this.state.currentFeatureIndex));
      } else if (this.props.contentType === "pricing") {
        this.props.saveContentInStore(
          this.generator.changePricingPictogramUploadedSrc(
            currentInputObject,
            "",
            this.state.currentPricingIndex
          )
        );
        // this.props.saveContentInStore(this.generator.changePricingPictogramUploadedUUID(currentInputObject, '', this.state.currentPricingIndex));
      } else if (this.props.contentType === "posts") {
        this.props.saveContentInStore(
          this.generator.changePostPictogramUploadedSrc(
            currentInputObject,
            "",
            this.state.currentPostIndex
          )
        );
        // this.props.saveContentInStore(this.generator.changePostPictogramUploadedUUID(currentInputObject, '', this.state.currentPostIndex));
      } else if (this.props.contentType === "gallery") {
        this.props.saveContentInStore(
          this.generator.changeGalleryPictogramUploadedSrc(
            currentInputObject,
            "",
            this.state.currentGalleryIndex
          )
        );
        // this.props.saveContentInStore(this.generator.changeGalleryPictogramUploadedUUID(currentInputObject, '', this.state.currentGalleryIndex));
      } else if (this.props.contentType === "pictograms") {
        this.props.saveContentInStore(
          this.generator.changePictogramsItemUploadedSrc(
            currentInputObject,
            "",
            this.state.currentPictogramIndex
          )
        );
        // this.props.saveContentInStore(this.generator.changePictogramsItemUploadedUUID(currentInputObject, '', this.state.currentPictogramIndex));
      }
    }
  };

  displayPictogramEditor = (currentElementHasPictogramInGroups: boolean) => {
    let defaultTab = this.state.currentPictogramType;
    if (defaultTab === "") {
      defaultTab = pictogramOptions.abstractIcon.title;
    }

    let currentInputObject = {
      [this.props.contentType]: this.props.currentInputData, // looks like this:
      /*
            {
            "cta": {
                "fieldTitle": "Bottom buttons",
                "label": "Edit bottom buttons",

                ...

                "maxButtons": 2,
                ctaBottomInfo: {
                    markup: 'Unsubscribe anytime.'
                },

                ...

                "content": {
                    buttons: [{}, {}, ... ],
                    form: {}
                }
            */
    };

    let displayEmojiPicker = () => {
      let emojiGroupsJSX = [];

      let displayEmojiList = (emojiList: any) => {
        let emojiListJSX = [];

        let emojiListCount = emojiList.length;
        for (let i = 0; i < emojiListCount; i++) {
          emojiListJSX.push(
            <Button
              className="content-input__emoji_button"
              onClick={() => {
                this.props.toggleNewEditionsDetected(true);

                if (currentElementHasPictogramInGroups) {
                  this.props.saveContentInStore(
                    this.generator.changeGroupPictogramType(
                      currentInputObject,
                      pictogramOptions.emoji.title,
                      this.state.currentGroupIndex,
                      this.props.contentType
                    )
                  );
                  this.props.saveContentInStore(
                    this.generator.changeGroupPictogramEmojiSrc(
                      currentInputObject,
                      emojiList[i].src,
                      this.state.currentGroupIndex,
                      this.props.contentType
                    )
                  );
                } else {
                  if (this.props.contentType === "features") {
                    this.props.saveContentInStore(
                      this.generator.changeFeaturePictogramType(
                        currentInputObject,
                        pictogramOptions.emoji.title,
                        this.state.currentFeatureIndex
                      )
                    );
                    this.props.saveContentInStore(
                      this.generator.changeFeaturePictogramEmojiSrc(
                        currentInputObject,
                        emojiList[i].src,
                        this.state.currentFeatureIndex
                      )
                    );
                  } else if (this.props.contentType === "pricing") {
                    this.props.saveContentInStore(
                      this.generator.changePricingPictogramType(
                        currentInputObject,
                        pictogramOptions.emoji.title,
                        this.state.currentPricingIndex
                      )
                    );
                    this.props.saveContentInStore(
                      this.generator.changePricingPictogramEmojiSrc(
                        currentInputObject,
                        emojiList[i].src,
                        this.state.currentPricingIndex
                      )
                    );
                  } else if (this.props.contentType === "posts") {
                    this.props.saveContentInStore(
                      this.generator.changePostPictogramType(
                        currentInputObject,
                        pictogramOptions.emoji.title,
                        this.state.currentPostIndex
                      )
                    );
                    this.props.saveContentInStore(
                      this.generator.changePostPictogramEmojiSrc(
                        currentInputObject,
                        emojiList[i].src,
                        this.state.currentPostIndex
                      )
                    );
                  } else if (this.props.contentType === "gallery") {
                    this.props.saveContentInStore(
                      this.generator.changeGalleryPictogramType(
                        currentInputObject,
                        pictogramOptions.emoji.title,
                        this.state.currentGalleryIndex
                      )
                    );
                    this.props.saveContentInStore(
                      this.generator.changeGalleryPictogramEmojiSrc(
                        currentInputObject,
                        emojiList[i].src,
                        this.state.currentGalleryIndex
                      )
                    );
                  } else if (this.props.contentType === "pictograms") {
                    this.props.saveContentInStore(
                      this.generator.changePictogramsItemType(
                        currentInputObject,
                        pictogramOptions.emoji.title,
                        this.state.currentPictogramIndex
                      )
                    );
                    this.props.saveContentInStore(
                      this.generator.changePictogramsItemEmojiSrc(
                        currentInputObject,
                        emojiList[i].src,
                        this.state.currentPictogramIndex
                      )
                    );
                  }
                }

                this.removeUploadedIcon(
                  currentElementHasPictogramInGroups,
                  currentInputObject
                );
              }}
              key={i}
            >
              <img
                className="content-input__emoji_image"
                src={publicUrl + emojiPath.prefix + emojiList[i].src}
                alt={emojiList[i].keywords.join(", ")}
              />
            </Button>
          );
        }
        return <div>{emojiListJSX}</div>;
      };

      let emojiCategoriesCount = emoji.categories.length;
      for (let i = 0; i < emojiCategoriesCount; i++) {
        let emojiGroup = (
          <div className="content-input__emoji_group" key={i}>
            <h3 className="content-input__emoji_title">
              {capitalizeString(emoji.categories[i].title)}
            </h3>
            {displayEmojiList(emoji.categories[i].items)}
          </div>
        );

        emojiGroupsJSX.push(emojiGroup);
      }

      return (
        <div>
          {emojiGroupsJSX}
          <div style={{ opacity: 0.6, margin: "20px 0" }}>
            Graphics by{" "}
            <a href="https://twemoji.twitter.com/" target="_blank">
              Twitter
            </a>
          </div>
        </div>
      );
    };

    let displayAbstractIcons = () => {
      let abstractIconsJSX = [];

      let currentInputObject = {
        [this.props.contentType]: this.props.currentInputData, // looks like this:
        /*
                {
                "cta": {
                    "fieldTitle": "Bottom buttons",
                    "label": "Edit bottom buttons",

                    ...

                    "maxButtons": 2,
                    ctaBottomInfo: {
                        markup: 'Unsubscribe anytime.'
                    },

                    ...

                    "content": {
                        buttons: [{}, {}, ... ],
                        form: {}
                    }
                */
      };

      for (let i = 0; i < abstractIconsCount; i++) {
        let iconFileId = (i + 1).toString(); //titles of the SVG files start from 1

        abstractIconsJSX.push(
          <Button
            className="content-input__abstract_icon_item"
            key={i}
            onClick={() => {
              this.props.toggleNewEditionsDetected(true);

              if (currentElementHasPictogramInGroups) {
                this.props.saveContentInStore(
                  this.generator.changeGroupPictogramType(
                    currentInputObject,
                    pictogramOptions.abstractIcon.title,
                    this.state.currentGroupIndex,
                    this.props.contentType
                  )
                );
                this.props.saveContentInStore(
                  this.generator.changeGroupPictogramAbstractIconId(
                    currentInputObject,
                    iconFileId,
                    this.state.currentGroupIndex,
                    this.props.contentType
                  )
                );
              } else {
                if (this.props.contentType === "features") {
                  this.props.saveContentInStore(
                    this.generator.changeFeaturePictogramType(
                      currentInputObject,
                      pictogramOptions.abstractIcon.title,
                      this.state.currentFeatureIndex
                    )
                  );
                  this.props.saveContentInStore(
                    this.generator.changeFeaturePictogramAbstractIconId(
                      currentInputObject,
                      iconFileId,
                      this.state.currentFeatureIndex
                    )
                  );
                } else if (this.props.contentType === "pricing") {
                  this.props.saveContentInStore(
                    this.generator.changePricingPictogramType(
                      currentInputObject,
                      pictogramOptions.abstractIcon.title,
                      this.state.currentPricingIndex
                    )
                  );
                  this.props.saveContentInStore(
                    this.generator.changePricingPictogramAbstractIconId(
                      currentInputObject,
                      iconFileId,
                      this.state.currentPricingIndex
                    )
                  );
                } else if (this.props.contentType === "posts") {
                  this.props.saveContentInStore(
                    this.generator.changePostPictogramType(
                      currentInputObject,
                      pictogramOptions.abstractIcon.title,
                      this.state.currentPostIndex
                    )
                  );
                  this.props.saveContentInStore(
                    this.generator.changePostPictogramAbstractIconId(
                      currentInputObject,
                      iconFileId,
                      this.state.currentPostIndex
                    )
                  );
                } else if (this.props.contentType === "gallery") {
                  this.props.saveContentInStore(
                    this.generator.changeGalleryPictogramType(
                      currentInputObject,
                      pictogramOptions.abstractIcon.title,
                      this.state.currentGalleryIndex
                    )
                  );
                  this.props.saveContentInStore(
                    this.generator.changeGalleryPictogramAbstractIconId(
                      currentInputObject,
                      iconFileId,
                      this.state.currentGalleryIndex
                    )
                  );
                } else if (this.props.contentType === "pictograms") {
                  this.props.saveContentInStore(
                    this.generator.changePictogramsItemType(
                      currentInputObject,
                      pictogramOptions.abstractIcon.title,
                      this.state.currentPictogramIndex
                    )
                  );
                  this.props.saveContentInStore(
                    this.generator.changePictogramsItemAbstractIconId(
                      currentInputObject,
                      iconFileId,
                      this.state.currentPictogramIndex
                    )
                  );
                }
              }

              this.removeUploadedIcon(
                currentElementHasPictogramInGroups,
                currentInputObject
              );
            }}
          >
            <Pictogram
              pictogramObject={{
                type: pictogramOptions.abstractIcon.title,
                abstractIconId: iconFileId,
              }}
            />
          </Button>
        );
      }

      return (
        <div className="content-input__abstract_icon_items">
          {abstractIconsJSX}
        </div>
      );
    };

    let displayUploadIcon = () => {
      let handleChangeIconAlt = (newAlt: string) => {
        this.props.toggleNewEditionsDetected(true);

        if (currentElementHasPictogramInGroups) {
          this.props.saveContentInStore(
            this.generator.changeGroupPictogramUploadedAlt(
              currentInputObject,
              newAlt,
              this.state.currentGroupIndex,
              this.props.contentType
            )
          );
        } else {
          if (this.props.contentType === "features") {
            this.props.saveContentInStore(
              this.generator.changeFeaturePictogramUploadedAlt(
                currentInputObject,
                newAlt,
                this.state.currentFeatureIndex
              )
            );
          } else if (this.props.contentType === "pricing") {
            this.props.saveContentInStore(
              this.generator.changePricingPictogramUploadedAlt(
                currentInputObject,
                newAlt,
                this.state.currentPricingIndex
              )
            );
          } else if (this.props.contentType === "posts") {
            this.props.saveContentInStore(
              this.generator.changePostPictogramUploadedAlt(
                currentInputObject,
                newAlt,
                this.state.currentPostIndex
              )
            );
          } else if (this.props.contentType === "gallery") {
            this.props.saveContentInStore(
              this.generator.changeGalleryPictogramUploadedAlt(
                currentInputObject,
                newAlt,
                this.state.currentGalleryIndex
              )
            );
          } else if (this.props.contentType === "pictograms") {
            this.props.saveContentInStore(
              this.generator.changePictogramsItemUploadedAlt(
                currentInputObject,
                newAlt,
                this.state.currentPictogramIndex
              )
            );
          }
        }
      };
      let handleUpload = (newSrc: string, newUUID: any, width, height) => {
        this.props.toggleNewEditionsDetected(true);

        let isNewSrcEmpty = newSrc === ""; //if we set the new icon src to '' (means deleted it) we set the type of the icon to '' (no type)

        if (currentElementHasPictogramInGroups) {
          this.props.saveContentInStore(
            this.generator.changeGroupPictogramType(
              currentInputObject,
              isNewSrcEmpty ? "" : pictogramOptions.uploaded.title,
              this.state.currentGroupIndex,
              this.props.contentType
            )
          );
          this.props.saveContentInStore(
            this.generator.changeGroupPictogramUploadedSrc(
              currentInputObject,
              newSrc,
              this.state.currentGroupIndex,
              this.props.contentType,
              width,
              height
            )
          );
          this.props.saveContentInStore(
            this.generator.changeGroupPictogramUploadedUUID(
              currentInputObject,
              newUUID,
              this.state.currentGroupIndex,
              this.props.contentType
            )
          );
        } else {
          if (this.props.contentType === "features") {
            this.props.saveContentInStore(
              this.generator.changeFeaturePictogramType(
                currentInputObject,
                isNewSrcEmpty ? "" : pictogramOptions.uploaded.title,
                this.state.currentFeatureIndex
              )
            );
            this.props.saveContentInStore(
              this.generator.changeFeaturePictogramUploadedSrc(
                currentInputObject,
                newSrc,
                this.state.currentFeatureIndex,
                width,
                height
              )
            );
            this.props.saveContentInStore(
              this.generator.changeFeaturePictogramUploadedUUID(
                currentInputObject,
                newUUID,
                this.state.currentFeatureIndex
              )
            );
          } else if (this.props.contentType === "pricing") {
            this.props.saveContentInStore(
              this.generator.changePricingPictogramType(
                currentInputObject,
                isNewSrcEmpty ? "" : pictogramOptions.uploaded.title,
                this.state.currentPricingIndex
              )
            );
            this.props.saveContentInStore(
              this.generator.changePricingPictogramUploadedSrc(
                currentInputObject,
                newSrc,
                this.state.currentPricingIndex,
                width,
                height
              )
            );
            this.props.saveContentInStore(
              this.generator.changePricingPictogramUploadedUUID(
                currentInputObject,
                newUUID,
                this.state.currentPricingIndex
              )
            );
          } else if (this.props.contentType === "posts") {
            this.props.saveContentInStore(
              this.generator.changePostPictogramType(
                currentInputObject,
                isNewSrcEmpty ? "" : pictogramOptions.uploaded.title,
                this.state.currentPostIndex
              )
            );
            this.props.saveContentInStore(
              this.generator.changePostPictogramUploadedSrc(
                currentInputObject,
                newSrc,
                this.state.currentPostIndex,
                width,
                height
              )
            );
            this.props.saveContentInStore(
              this.generator.changePostPictogramUploadedUUID(
                currentInputObject,
                newUUID,
                this.state.currentPostIndex
              )
            );
          } else if (this.props.contentType === "gallery") {
            this.props.saveContentInStore(
              this.generator.changeGalleryPictogramType(
                currentInputObject,
                isNewSrcEmpty ? "" : pictogramOptions.uploaded.title,
                this.state.currentGalleryIndex
              )
            );
            this.props.saveContentInStore(
              this.generator.changeGalleryPictogramUploadedSrc(
                currentInputObject,
                newSrc,
                this.state.currentGalleryIndex,
                width,
                height
              )
            );
            this.props.saveContentInStore(
              this.generator.changeGalleryPictogramUploadedUUID(
                currentInputObject,
                newUUID,
                this.state.currentGalleryIndex
              )
            );
          } else if (this.props.contentType === "pictograms") {
            this.props.saveContentInStore(
              this.generator.changePictogramsItemType(
                currentInputObject,
                isNewSrcEmpty ? "" : pictogramOptions.uploaded.title,
                this.state.currentPictogramIndex
              )
            );
            this.props.saveContentInStore(
              this.generator.changePictogramsItemUploadedSrc(
                currentInputObject,
                newSrc,
                this.state.currentPictogramIndex,
                width,
                height
              )
            );
            this.props.saveContentInStore(
              this.generator.changePictogramsItemUploadedUUID(
                currentInputObject,
                newUUID,
                this.state.currentPictogramIndex
              )
            );
          }
        }
      };

      let handleChangeHeight = (value: number) => {
        this.props.toggleNewEditionsDetected(true);

        if (currentElementHasPictogramInGroups) {
          this.props.saveContentInStore(
            this.generator.changeGroupPictogramUploadedHeight(
              currentInputObject,
              value,
              this.state.currentGroupIndex,
              this.props.contentType
            )
          );
        } else {
          if (this.props.contentType === "features") {
            this.props.saveContentInStore(
              this.generator.changeFeaturePictogramUploadedHeight(
                currentInputObject,
                value,
                this.state.currentFeatureIndex
              )
            );
          } else if (this.props.contentType === "pricing") {
            this.props.saveContentInStore(
              this.generator.changePricingPictogramUploadedHeight(
                currentInputObject,
                value,
                this.state.currentPricingIndex
              )
            );
          } else if (this.props.contentType === "posts") {
            this.props.saveContentInStore(
              this.generator.changePostPictogramUploadedHeight(
                currentInputObject,
                value,
                this.state.currentPostIndex
              )
            );
          } else if (this.props.contentType === "gallery") {
            this.props.saveContentInStore(
              this.generator.changeGalleryPictogramUploadedHeight(
                currentInputObject,
                value,
                this.state.currentGalleryIndex
              )
            );
          } else if (this.props.contentType === "pictograms") {
            this.props.saveContentInStore(
              this.generator.changePictogramsItemUploadedHeight(
                currentInputObject,
                value,
                this.state.currentPictogramIndex
              )
            );
          }
        }
      };

      let currentSrc = "";
      let currentUUID = undefined;
      let currentHeight = defaultUploadedPictogramHeight;
      let alt = "";

      let content = currentInputObject[this.props.contentType].content;

      let currentPictogramType = "";

      // We
      if (
        this.state.currentPictogramObject &&
        this.state.currentPictogramObject.type !== undefined
      ) {
        currentPictogramType = this.state.currentPictogramObject.type;
      }

      if (currentPictogramType !== "") {
        // Because each piece of content has it's own data structure (some stores it's Pictogram data in "pictogram", some in "tabPictogram", groups require also a group ID to find a pictogram)
        if (currentElementHasPictogramInGroups) {
          currentSrc =
            content.groups[this.state.currentGroupIndex].pictogram.uploadedSrc;
          currentUUID =
            content.groups[this.state.currentGroupIndex].pictogram.uploadedUUID;
          currentHeight =
            content.groups[this.state.currentGroupIndex].pictogram
              .uploadedHeight;
          alt = content.groups[this.state.currentGroupIndex].pictogram.alt;
        } else {
          if (this.props.contentType === "features") {
            currentSrc =
              content.items[this.state.currentFeatureIndex].pictogram
                .uploadedSrc;
            currentUUID =
              content.items[this.state.currentFeatureIndex].pictogram
                .uploadedUUID;
            currentHeight =
              content.items[this.state.currentFeatureIndex].pictogram
                .uploadedHeight;
            alt = content.items[this.state.currentFeatureIndex].pictogram.alt;
          } else if (this.props.contentType === "pricing") {
            currentSrc =
              content.items[this.state.currentPricingIndex].pictogram
                .uploadedSrc;
            currentUUID =
              content.items[this.state.currentPricingIndex].pictogram
                .uploadedUUID;
            currentHeight =
              content.items[this.state.currentPricingIndex].pictogram
                .uploadedHeight;
            alt = content.items[this.state.currentPricingIndex].pictogram.alt;
          } else if (this.props.contentType === "posts") {
            currentSrc =
              content.items[this.state.currentPostIndex].pictogram.uploadedSrc;
            currentUUID =
              content.items[this.state.currentPostIndex].pictogram.uploadedUUID;
            currentHeight =
              content.items[this.state.currentPostIndex].pictogram
                .uploadedHeight;
            alt = content.items[this.state.currentPostIndex].pictogram.alt;
          } else if (this.props.contentType === "gallery") {
            currentSrc =
              content.items[this.state.currentGalleryIndex].tabPictogram
                .uploadedSrc;
            currentUUID =
              content.items[this.state.currentGalleryIndex].tabPictogram
                .uploadedUUID;
            currentHeight =
              content.items[this.state.currentGalleryIndex].tabPictogram
                .uploadedHeight;
            alt =
              content.items[this.state.currentGalleryIndex].tabPictogram.alt;
          } else if (this.props.contentType === "pictograms") {
            currentSrc =
              content.items[this.state.currentPictogramIndex].pictogram
                .uploadedSrc;
            currentUUID =
              content.items[this.state.currentPictogramIndex].pictogram
                .uploadedUUID;
            currentHeight =
              content.items[this.state.currentPictogramIndex].pictogram
                .uploadedHeight;
            alt = content.items[this.state.currentPictogramIndex].pictogram.alt;
          }
        }
      }

      return (
        <div className="">
          <div className="content-input__input" style={{ marginBottom: 40 }}>
            <div className="content-input__label_box">
              <label className="content-input__label">
                Upload an icon
              </label>
            </div>
            <div style={{ marginTop: 10 }}>
              <ImagePicker
                currentImgAlt={alt}
                saveImageAltInReduxStore={(newAlt: string) => {
                  handleChangeIconAlt(newAlt);
                }}
                currentImgSrc={currentSrc}
                currentImgUUID={currentUUID}
                inputId={"pictogram-uploader-" + this.props.contentType}
                saveImageInfoInDB={this.props.saveImageInfoInDB}
                removeImageFromDB={this.props.removeImageFromDB}
                saveImageInReduxStore={(
                  newSrc: string,
                  newUUID: any,
                  width: any,
                  height: any
                ) => {
                  handleUpload(newSrc, newUUID, width, height);
                }}
              />
            </div>
          </div>

          <div className="content-input__input" style={{ maxWidth: 400 }}>
            {/*value = 30 is the default logo height. used in ContentInput (changeNavLogoHeight), in the Editor (displayNav) and in the render nav.html*/}
            {currentSrc !== "" && (
              <div>
                <div className="content-input__label_box">
                  <label className="content-input__label">
                    Set the icon's height:
                  </label>
                </div>
                <Slider
                  min={5}
                  max={100}
                  step={1}
                  value={currentHeight || defaultUploadedPictogramHeight}
                  tipFormatter={(value: number) => `Icon height: ${value}px`}
                  onChange={(value: any) => {
                    handleChangeHeight(value);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      );
    };

    let customColorsActiveClassnames = applyCustomColorsClassnames(
      this.props.currentWebsite
    );

    return (
      <div className="comps">
        <div
          className={
            this.props.currentWebsite.color_classname +
            " " +
            customColorsActiveClassnames
          }
        >
          <Tabs
            defaultActiveKey={defaultTab}
            onChange={(e: any) => {
              // console.log(e);
            }}
          >
            <TabPane
              tab={<span>Emoji</span>}
              key={pictogramOptions.emoji.title}
            >
              {displayEmojiPicker()}
            </TabPane>
            <TabPane tab="Upload" key={pictogramOptions.uploaded.title}>
              {displayUploadIcon()}
            </TabPane>
            <TabPane
              tab="Abstract Icons"
              key={pictogramOptions.abstractIcon.title}
            >
              {displayAbstractIcons()}
            </TabPane>
            {/*<TabPane tab="Linea.io " key={pictogramOptions.lineaIcon.title}>*/}
            {/*todo*/}
            {/*</TabPane>*/}
          </Tabs>
        </div>
      </div>
    );
  };

  displayButtonStylesEditor = () => {
    let index: number;
    // Index is needed to target the exact button from a set of features, pricing tables etc.
    // We keep index for each type of content in state (e.g. this.state.currentPricingIndex for 'pricing').
    const indexMap = {
      [ContentInputsTypesEnum.features]: this.state.currentFeatureIndex,
      [ContentInputsTypesEnum.pricing]: this.state.currentPricingIndex,
      [ContentInputsTypesEnum.cta]: this.state.currentCtaButtonIndex,
      [ContentInputsTypesEnum.posts]: this.state.currentPostIndex,
      [ContentInputsTypesEnum.gallery]: this.state.currentGalleryIndex,
      [ContentInputsTypesEnum.pictograms]: this.state.currentPictogramIndex,
      [ContentInputsTypesEnum.steps]: this.state.currentStepsItemIndex,
    };
    index = indexMap[this.props.contentType];

    let currentInputObject = {
      [this.props.contentType]: this.props.currentInputData, // looks like this:
      /*
            {
            "cta": {
                "fieldTitle": "Bottom buttons",
                "label": "Edit bottom buttons",

                ...

                "maxButtons": 2,
                ctaBottomInfo: {
                    markup: 'Unsubscribe anytime.'
                },

                ...

                "content": {
                    buttons: [{}, {}, ... ],
                    form: {}
                }
            */
    };

    const saveObjectInStore = (...args) => {
      const generatorFunction = args.shift();
      this.props.saveContentInStore(
        generatorFunction(currentInputObject, ...args)
      );
    };

    let buttonColorChangeHandler = (value: any) => {
      const getCtaArgs = () => {
        if (this.state.activeOption === callToActionOptions.form) {
          return [this.generator.changeCtaEmailFormColor, value];
        }
        if (this.state.activeOption === callToActionOptions.buttons) {
          return [this.generator.changeButtonColor, value, index];
        }
        return [];
      };

      const argsMap = {
        [ContentInputsTypesEnum.features]: [
          this.generator.changeFeatureButtonColor,
          value,
          index,
        ],
        [ContentInputsTypesEnum.pricing]: [
          this.generator.changePricingButtonColor,
          value,
          index,
        ],
        [ContentInputsTypesEnum.cta]: getCtaArgs(),
        [ContentInputsTypesEnum.posts]: [
          this.generator.changePostButtonColor,
          value,
          index,
        ],
        [ContentInputsTypesEnum.gallery]: [
          this.generator.changeGalleryButtonColor,
          value,
          index,
          this.state.currentGalleryButtonIndex,
        ],
        [ContentInputsTypesEnum.steps]: [
          this.generator.changeStepButtonColor,
          value,
          index,
        ],
      };

      const saveInStoreArgsArr = argsMap[this.props.contentType] || [];
      if (saveInStoreArgsArr.length) {
        saveObjectInStore(...saveInStoreArgsArr);
      }
    };

    let buttonPillColorChangeHandler = (value: any) => {
      const getCtaArgs = () => {
        if (this.state.activeOption === callToActionOptions.buttons) {
          return [this.generator.changeButtonPillColor, value, index];
        }
        return [];
      };

      const argsMap = {
        [ContentInputsTypesEnum.features]: [
          this.generator.changeFeatureButtonPillColor,
          value,
          index,
        ],
        [ContentInputsTypesEnum.pricing]: [
          this.generator.changePricingButtonPillColor,
          value,
          index,
        ],
        [ContentInputsTypesEnum.posts]: [
          this.generator.changePostButtonPillColor,
          value,
          index,
        ],
        [ContentInputsTypesEnum.gallery]: [
          this.generator.changeGalleryButtonPillColor,
          value,
          index,
          this.state.currentGalleryButtonIndex,
        ],
        [ContentInputsTypesEnum.cta]: getCtaArgs(),
        [ContentInputsTypesEnum.steps]: [
          this.generator.changeStepButtonPillColor,
          value,
          index,
        ],
      };

      const saveInStoreArgsArr = argsMap[this.props.contentType] || [];
      if (saveInStoreArgsArr.length) {
        saveObjectInStore(...saveInStoreArgsArr);
      }
    };

    let buttonGhostColorChangeHandler = (value: any) => {
      const getCtaArgs = () => {
        if (this.state.activeOption === callToActionOptions.buttons) {
          return [this.generator.changeButtonGhostColor, value, index];
        }
        return [];
      };

      const argsMap = {
        [ContentInputsTypesEnum.features]: [
          this.generator.changeFeatureButtonGhostColor,
          value,
          index,
        ],
        [ContentInputsTypesEnum.pricing]: [
          this.generator.changePricingButtonGhostColor,
          value,
          index,
        ],
        [ContentInputsTypesEnum.posts]: [
          this.generator.changePostButtonGhostColor,
          value,
          index,
        ],
        [ContentInputsTypesEnum.gallery]: [
          this.generator.changeGalleryButtonGhostColor,
          value,
          index,
          this.state.currentGalleryButtonIndex,
        ],
        [ContentInputsTypesEnum.cta]: getCtaArgs(),
        [ContentInputsTypesEnum.steps]: [
          this.generator.changeStepButtonGhostColor,
          value,
          index,
        ],
      };

      const saveInStoreArgsArr = argsMap[this.props.contentType] || [];
      if (saveInStoreArgsArr.length) {
        saveObjectInStore(...saveInStoreArgsArr);
      }
    };

    let buttonStyleTypeChangeHandler = (value: any) => {
      const getCtaArgs = () => {
        if (this.state.activeOption === callToActionOptions.form) {
          return [this.generator.changeCtaEmailFormStyleType, value];
        }
        if (this.state.activeOption === callToActionOptions.buttons) {
          return [this.generator.changeButtonStyleType, value, index];
        }
        return [];
      };

      const argsMap = {
        [ContentInputsTypesEnum.features]: [
          this.generator.changeFeatureButtonStyleType,
          value,
          index,
        ],
        [ContentInputsTypesEnum.pricing]: [
          this.generator.changePricingButtonStyleType,
          value,
          index,
        ],
        [ContentInputsTypesEnum.posts]: [
          this.generator.changePostButtonStyleType,
          value,
          index,
        ],
        [ContentInputsTypesEnum.gallery]: [
          this.generator.changeGalleryButtonStyleType,
          value,
          index,
          this.state.currentGalleryButtonIndex,
        ],
        [ContentInputsTypesEnum.cta]: getCtaArgs(),
        [ContentInputsTypesEnum.steps]: [
          this.generator.changeStepButtonStyleType,
          value,
          index,
        ],
      };

      const saveInStoreArgsArr = argsMap[this.props.contentType] || [];
      if (saveInStoreArgsArr.length) {
        saveObjectInStore(...saveInStoreArgsArr);
      }
    };

    let buttonMobileAppStoreTypeChangeHandler = (value: any) => {
      const getCtaArgs = () => {
        if (this.state.activeOption === callToActionOptions.buttons) {
          return [this.generator.changeButtonMobileAppStoreType, value, index];
        }
        return [];
      };

      const argsMap = {
        [ContentInputsTypesEnum.features]: [
          this.generator.changeFeatureButtonMobileAppStoreType,
          value,
          index,
        ],
        [ContentInputsTypesEnum.pricing]: [
          this.generator.changePricingButtonMobileAppStoreType,
          value,
          index,
        ],
        [ContentInputsTypesEnum.posts]: [
          this.generator.changePostButtonMobileAppStoreType,
          value,
          index,
        ],
        [ContentInputsTypesEnum.gallery]: [
          this.generator.changeGalleryButtonMobileAppStoreType,
          value,
          index,
          this.state.currentGalleryButtonIndex,
        ],
        [ContentInputsTypesEnum.cta]: getCtaArgs(),
        [ContentInputsTypesEnum.steps]: [
          this.generator.changeStepButtonMobileAppStoreType,
          value,
          index,
        ],
      };

      const saveInStoreArgsArr = argsMap[this.props.contentType] || [];
      if (saveInStoreArgsArr.length) {
        saveObjectInStore(...saveInStoreArgsArr);
      }
    };

    let currentEditableButtonTitle = "Get it";
    if (
      this.state.currentButtonObject &&
      this.state.currentButtonObject.title !== ""
    ) {
      currentEditableButtonTitle = this.state.currentButtonObject.title;
    }

    const isButtonVisible = (buttonType) =>
      this.state.buttonStylesOptions.indexOf(buttonType) !== -1;

    let shouldDisplayRegularButtons = isButtonVisible(
      buttonStyles.regular.typeTitle
    );
    let shouldDisplayMobileAppStoreButtons = isButtonVisible(
      buttonStyles.mobileAppStore.typeTitle
    );
    let shouldDisplayPillButtons = isButtonVisible(buttonStyles.pill.typeTitle);
    let shouldDisplayGhostButtons = isButtonVisible(
      buttonStyles.ghost.typeTitle
    );

    let shouldDisplayBlackButtons =
      checkIfWebsiteHasBlackPalette(this.props.currentWebsite) === false;

    let customColorsActiveClassnames = applyCustomColorsClassnames(
      this.props.currentWebsite
    );
    return (
      <div className="comps" style={{ marginBottom: 100 }}>
        <div
          className={
            this.props.currentWebsite.color_classname +
            " " +
            customColorsActiveClassnames
          }
        >
          {/*regular buttons: for links and forms*/}
          {shouldDisplayRegularButtons && (
            <div className="content-input__group">
              {this.state.shouldDisplayForcedWhiteButtonsWarning && (
                <Alert
                  message="Warning"
                  description="You can change the buttons colors but they will be forced to white on this particular page because the first component on this page is dark."
                  type="warning"
                  showIcon
                />
              )}

              <div className="content-input__button_style_options checkered-bg custom_fonts">
                <div
                  className={this.props.currentWebsite.active_font_classname}
                >
                  {/*accent*/}
                  <button
                    onClick={() => {
                      buttonStyleTypeChangeHandler(
                        buttonStyles.regular.typeTitle
                      );
                      buttonColorChangeHandler(buttonStyles.regular.accentBg);
                      this.props.toggleNewEditionsDetected(true);
                    }}
                    className="content-input__button_style_option"
                  >
                    <WebsiteButton
                      config={{
                        buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                        buttonTitle: currentEditableButtonTitle,
                        buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                        buttonIsTargetBlank: false,

                        disableMessageOnClick: true,

                        buttonStyleType: buttonStyles.regular.typeTitle,
                        buttonColor: buttonStyles.regular.accentBg,
                      }}
                    />
                  </button>
                  <button
                    onClick={() => {
                      buttonStyleTypeChangeHandler(
                        buttonStyles.regular.typeTitle
                      );
                      buttonColorChangeHandler(
                        buttonStyles.regular.accentOutline
                      );
                      this.props.toggleNewEditionsDetected(true);
                    }}
                    className="content-input__button_style_option"
                  >
                    <WebsiteButton
                      config={{
                        buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                        buttonTitle: currentEditableButtonTitle,
                        buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                        buttonIsTargetBlank: false,

                        disableMessageOnClick: true,

                        buttonStyleType: buttonStyles.regular.typeTitle,
                        buttonColor: buttonStyles.regular.accentOutline,
                      }}
                    />
                  </button>

                  {/*alt accent*/}
                  <button
                    onClick={() => {
                      buttonStyleTypeChangeHandler(
                        buttonStyles.regular.typeTitle
                      );
                      buttonColorChangeHandler(
                        buttonStyles.regular.altAccentBg
                      );
                      this.props.toggleNewEditionsDetected(true);
                    }}
                    className="content-input__button_style_option"
                  >
                    <WebsiteButton
                      config={{
                        buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                        buttonTitle: currentEditableButtonTitle,
                        buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                        buttonIsTargetBlank: false,

                        disableMessageOnClick: true,

                        buttonStyleType: buttonStyles.regular.typeTitle,
                        buttonColor: buttonStyles.regular.altAccentBg,
                      }}
                    />
                  </button>

                  <button
                    onClick={() => {
                      buttonStyleTypeChangeHandler(
                        buttonStyles.regular.typeTitle
                      );
                      buttonColorChangeHandler(
                        buttonStyles.regular.altAccentOutline
                      );
                      this.props.toggleNewEditionsDetected(true);
                    }}
                    className="content-input__button_style_option"
                  >
                    <WebsiteButton
                      config={{
                        buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                        buttonTitle: currentEditableButtonTitle,
                        buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                        buttonIsTargetBlank: false,

                        disableMessageOnClick: true,

                        buttonStyleType: buttonStyles.regular.typeTitle,
                        buttonColor: buttonStyles.regular.altAccentOutline,
                      }}
                    />
                  </button>

                  {/*black*/}
                  {shouldDisplayBlackButtons && (
                    <button
                      onClick={() => {
                        buttonStyleTypeChangeHandler(
                          buttonStyles.regular.typeTitle
                        );
                        buttonColorChangeHandler(buttonStyles.regular.blackBg);
                        this.props.toggleNewEditionsDetected(true);
                      }}
                      className="content-input__button_style_option"
                    >
                      <WebsiteButton
                        config={{
                          buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                          buttonTitle: currentEditableButtonTitle,
                          buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                          buttonIsTargetBlank: false,

                          disableMessageOnClick: true,

                          buttonStyleType: buttonStyles.regular.typeTitle,
                          buttonColor: buttonStyles.regular.blackBg,
                        }}
                      />
                    </button>
                  )}

                  {shouldDisplayBlackButtons && (
                    <button
                      onClick={() => {
                        buttonStyleTypeChangeHandler(
                          buttonStyles.regular.typeTitle
                        );
                        buttonColorChangeHandler(
                          buttonStyles.regular.blackOutline
                        );
                        this.props.toggleNewEditionsDetected(true);
                      }}
                      className="content-input__button_style_option"
                    >
                      <WebsiteButton
                        config={{
                          buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                          buttonTitle: currentEditableButtonTitle,
                          buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                          buttonIsTargetBlank: false,

                          disableMessageOnClick: true,

                          buttonStyleType: buttonStyles.regular.typeTitle,
                          buttonColor: buttonStyles.regular.blackOutline,
                        }}
                      />
                    </button>
                  )}

                  {/*white*/}
                  <button
                    onClick={() => {
                      buttonStyleTypeChangeHandler(
                        buttonStyles.regular.typeTitle
                      );
                      buttonColorChangeHandler(buttonStyles.regular.whiteBg);
                      this.props.toggleNewEditionsDetected(true);
                    }}
                    className="content-input__button_style_option"
                  >
                    <WebsiteButton
                      config={{
                        buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                        buttonTitle: currentEditableButtonTitle,
                        buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                        buttonIsTargetBlank: false,

                        disableMessageOnClick: true,

                        buttonStyleType: buttonStyles.regular.typeTitle,
                        buttonColor: buttonStyles.regular.whiteBg,
                      }}
                    />
                  </button>

                  <button
                    onClick={() => {
                      buttonStyleTypeChangeHandler(
                        buttonStyles.regular.typeTitle
                      );
                      buttonColorChangeHandler(
                        buttonStyles.regular.whiteOutline
                      );
                      this.props.toggleNewEditionsDetected(true);
                    }}
                    className="content-input__button_style_option content-input__button_style_option--gray"
                  >
                    <WebsiteButton
                      config={{
                        buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                        buttonTitle: currentEditableButtonTitle,
                        buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                        buttonIsTargetBlank: false,

                        disableMessageOnClick: true,

                        buttonStyleType: buttonStyles.regular.typeTitle,
                        buttonColor: buttonStyles.regular.whiteOutline,
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          )}

          {/*Mobile app store buttons: for links only*/}
          {shouldDisplayMobileAppStoreButtons && (
            <div className="content-input__group">
              <div className="content-input__button_style_options custom_fonts checkered-bg">
                <div
                  className={this.props.currentWebsite.active_font_classname}
                >
                  <button
                    onClick={() => {
                      buttonStyleTypeChangeHandler(
                        buttonStyles.mobileAppStore.typeTitle
                      );
                      buttonMobileAppStoreTypeChangeHandler(
                        buttonStyles.mobileAppStore.ios
                      );
                      this.props.toggleNewEditionsDetected(true);
                    }}
                    className="content-input__button_style_option"
                  >
                    <WebsiteButton
                      config={{
                        buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                        buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                        buttonIsTargetBlank: false,

                        disableMessageOnClick: true,

                        buttonStyleType: buttonStyles.mobileAppStore.typeTitle,
                        buttonMobileAppStoreType:
                          buttonStyles.mobileAppStore.ios,
                      }}
                    />
                  </button>

                  <button
                    onClick={() => {
                      buttonStyleTypeChangeHandler(
                        buttonStyles.mobileAppStore.typeTitle
                      );
                      buttonMobileAppStoreTypeChangeHandler(
                        buttonStyles.mobileAppStore.android
                      );
                      this.props.toggleNewEditionsDetected(true);
                    }}
                    className="content-input__button_style_option"
                  >
                    <WebsiteButton
                      config={{
                        buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                        buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                        buttonIsTargetBlank: false,

                        disableMessageOnClick: true,

                        buttonStyleType: buttonStyles.mobileAppStore.typeTitle,
                        buttonMobileAppStoreType:
                          buttonStyles.mobileAppStore.android,
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          )}

          {/*Pill buttons: for links only*/}
          {shouldDisplayPillButtons && (
            <div className="content-input__group">
              <div className="content-input__button_style_options checkered-bg custom_fonts">
                <div
                  className={this.props.currentWebsite.active_font_classname}
                >
                  <button
                    onClick={() => {
                      buttonStyleTypeChangeHandler(buttonStyles.pill.typeTitle);
                      buttonPillColorChangeHandler(buttonStyles.pill.blue);
                      this.props.toggleNewEditionsDetected(true);
                    }}
                    className="content-input__button_style_option"
                  >
                    <WebsiteButton
                      config={{
                        buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                        buttonTitle: currentEditableButtonTitle,
                        buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                        buttonIsTargetBlank: false,

                        disableMessageOnClick: true,

                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillColor: buttonStyles.pill.blue,
                        pillPrefix: this.state.currentButtonObject.pillPrefix,
                      }}
                    />
                  </button>

                  <button
                    onClick={() => {
                      buttonStyleTypeChangeHandler(buttonStyles.pill.typeTitle);
                      buttonPillColorChangeHandler(buttonStyles.pill.green);
                      this.props.toggleNewEditionsDetected(true);
                    }}
                    className="content-input__button_style_option"
                  >
                    <WebsiteButton
                      config={{
                        buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                        buttonTitle: currentEditableButtonTitle,
                        buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                        buttonIsTargetBlank: false,

                        disableMessageOnClick: true,

                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillColor: buttonStyles.pill.green,
                        pillPrefix: this.state.currentButtonObject.pillPrefix,
                      }}
                    />
                  </button>

                  <button
                    onClick={() => {
                      buttonStyleTypeChangeHandler(buttonStyles.pill.typeTitle);
                      buttonPillColorChangeHandler(buttonStyles.pill.orange);
                      this.props.toggleNewEditionsDetected(true);
                    }}
                    className="content-input__button_style_option"
                  >
                    <WebsiteButton
                      config={{
                        buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                        buttonTitle: currentEditableButtonTitle,
                        buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                        buttonIsTargetBlank: false,

                        disableMessageOnClick: true,

                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillColor: buttonStyles.pill.orange,
                        pillPrefix: this.state.currentButtonObject.pillPrefix,
                      }}
                    />
                  </button>

                  <button
                    onClick={() => {
                      buttonStyleTypeChangeHandler(buttonStyles.pill.typeTitle);
                      buttonPillColorChangeHandler(buttonStyles.pill.purple);
                      this.props.toggleNewEditionsDetected(true);
                    }}
                    className="content-input__button_style_option"
                  >
                    <WebsiteButton
                      config={{
                        buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                        buttonTitle: currentEditableButtonTitle,
                        buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                        buttonIsTargetBlank: false,

                        disableMessageOnClick: true,

                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillColor: buttonStyles.pill.purple,
                        pillPrefix: this.state.currentButtonObject.pillPrefix,
                      }}
                    />
                  </button>

                  {shouldDisplayBlackButtons && (
                    <button
                      onClick={() => {
                        buttonStyleTypeChangeHandler(
                          buttonStyles.pill.typeTitle
                        );
                        buttonPillColorChangeHandler(buttonStyles.pill.black);
                        this.props.toggleNewEditionsDetected(true);
                      }}
                      className="content-input__button_style_option"
                    >
                      <WebsiteButton
                        config={{
                          buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                          buttonTitle: currentEditableButtonTitle,
                          buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                          buttonIsTargetBlank: false,

                          disableMessageOnClick: true,

                          buttonStyleType: buttonStyles.pill.typeTitle,
                          pillColor: buttonStyles.pill.black,
                          pillPrefix: this.state.currentButtonObject.pillPrefix,
                        }}
                      />
                    </button>
                  )}

                  <button
                    onClick={() => {
                      buttonStyleTypeChangeHandler(buttonStyles.pill.typeTitle);
                      buttonPillColorChangeHandler(buttonStyles.pill.white);
                      this.props.toggleNewEditionsDetected(true);
                    }}
                    className="content-input__button_style_option"
                  >
                    <WebsiteButton
                      config={{
                        buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                        buttonTitle: currentEditableButtonTitle,
                        buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                        buttonIsTargetBlank: false,

                        disableMessageOnClick: true,

                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillColor: buttonStyles.pill.white,
                        pillPrefix: this.state.currentButtonObject.pillPrefix,
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          )}

          {/*Ghost buttons*/}
          {shouldDisplayGhostButtons && (
            <div className="content-input__group">
              <div className="content-input__button_style_options checkered-bg custom_fonts">
                <div
                  className={this.props.currentWebsite.active_font_classname}
                >
                  <button
                    onClick={() => {
                      buttonStyleTypeChangeHandler(
                        buttonStyles.ghost.typeTitle
                      );
                      buttonGhostColorChangeHandler(
                        buttonStyles.ghost.accentOutline
                      );
                      this.props.toggleNewEditionsDetected(true);
                    }}
                    className="content-input__button_style_option"
                  >
                    <WebsiteButton
                      config={{
                        buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                        buttonTitle: currentEditableButtonTitle,
                        buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                        buttonIsTargetBlank: false,

                        disableMessageOnClick: true,

                        buttonStyleType: buttonStyles.ghost.typeTitle,
                        ghostColor: buttonStyles.ghost.accentOutline,
                      }}
                    />
                  </button>

                  <button
                    onClick={() => {
                      buttonStyleTypeChangeHandler(
                        buttonStyles.ghost.typeTitle
                      );
                      buttonGhostColorChangeHandler(
                        buttonStyles.ghost.altAccentOutline
                      );
                      this.props.toggleNewEditionsDetected(true);
                    }}
                    className="content-input__button_style_option"
                  >
                    <WebsiteButton
                      config={{
                        buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                        buttonTitle: currentEditableButtonTitle,
                        buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                        buttonIsTargetBlank: false,

                        disableMessageOnClick: true,

                        buttonStyleType: buttonStyles.ghost.typeTitle,
                        ghostColor: buttonStyles.ghost.altAccentOutline,
                      }}
                    />
                  </button>

                  <button
                    onClick={() => {
                      buttonStyleTypeChangeHandler(
                        buttonStyles.ghost.typeTitle
                      );
                      buttonGhostColorChangeHandler(
                        buttonStyles.ghost.blackOutline
                      );
                      this.props.toggleNewEditionsDetected(true);
                    }}
                    className="content-input__button_style_option"
                  >
                    <WebsiteButton
                      config={{
                        buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                        buttonTitle: currentEditableButtonTitle,
                        buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                        buttonIsTargetBlank: false,

                        disableMessageOnClick: true,

                        buttonStyleType: buttonStyles.ghost.typeTitle,
                        ghostColor: buttonStyles.ghost.blackOutline,
                      }}
                    />
                  </button>

                  <button
                    onClick={() => {
                      buttonStyleTypeChangeHandler(
                        buttonStyles.ghost.typeTitle
                      );
                      buttonGhostColorChangeHandler(
                        buttonStyles.ghost.whiteOutline
                      );
                      this.props.toggleNewEditionsDetected(true);
                    }}
                    className="content-input__button_style_option content-input__button_style_option--gray"
                  >
                    <WebsiteButton
                      config={{
                        buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                        buttonTitle: currentEditableButtonTitle,
                        buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                        buttonIsTargetBlank: false,

                        disableMessageOnClick: true,

                        buttonStyleType: buttonStyles.ghost.typeTitle,
                        ghostColor: buttonStyles.ghost.whiteOutline,
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  displayButtonPluginsPicker = (
    generateComponentDataPath: (
      inputState: ComponentDataPathsInputState
    ) => ComponentDataPaths
  ) => {
    // let index: number;
    // Index is needed to target the exact button from a set of features, pricing tables etc.
    // We keep index for each type of content in state (e.g. this.state.currentPricingIndex for 'pricing').
    // if(this.props.contentType === 'features'){
    //     index = this.state.currentFeatureIndex;
    // }else if(this.props.contentType === 'pricing'){
    //     index = this.state.currentPricingIndex;
    // }else if(this.props.contentType === 'cta'){
    //     index = this.state.currentCtaButtonIndex;
    // }else if(this.props.contentType === 'posts'){
    //     index = this.state.currentPostIndex;
    // }else if(this.props.contentType === 'gallery'){
    //     index = this.state.currentGalleryIndex;
    // }else if(this.props.contentType === 'pictograms'){
    //     index = this.state.currentPictogramIndex;
    // }else if(this.props.contentType === 'steps'){
    //     index = this.state.currentStepsItemIndex;
    // }

    let currentInputObject = {
      [this.props.contentType]: this.props.currentInputData, // looks like this:
      /*
            {
            "cta": {
                "fieldTitle": "Bottom buttons",
                "label": "Edit bottom buttons",

                ...

                "maxButtons": 2,
                ctaBottomInfo: {
                    markup: 'Unsubscribe anytime.'
                },

                ...

                "content": {
                    buttons: [{}, {}, ... ],
                    form: {}
                }
            */
    };

    return (
      <div>
        {
          <ButtonPluginsDrawer
            websites={this.props.websites}
            currentWebsite={this.props.currentWebsite}
            auth={this.props.auth}
            currentButtonObject={this.state.currentButtonObject} //We need to pass stripeProductId and other plugins' props.
            // toggleWaitingForConnectPaymentIntegration={this.props.toggleWaitingForConnectPaymentIntegration}
            // toggleWaitingForChangePaymentIntegrationProductsArray={this.props.toggleWaitingForChangePaymentIntegrationProductsArray}
            // toggleWaitingForEditPaymentIntegrationProductDetails={this.props.toggleWaitingForEditPaymentIntegrationProductDetails}
            toggleWaitingForConfigurePaymentIntegration={
              this.props.toggleWaitingForConfigurePaymentIntegration
            }
            // saveImageInfoInDB={this.props.saveImageInfoInDB}
            // removeImageFromDB={this.props.removeImageFromDB}

            // changeStripeSecretKey={this.props.changeStripeSecretKey}
            changeStripePublicKey={this.props.changeStripePublicKey}
            // changeStripeCheckoutProductsArray={this.props.changeStripeCheckoutProductsArray}
            saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
            setStripeProductIndex={this.setStripeProductIndex}
            setSuccessfulPaymentUrl={this.setSuccessfulPaymentUrl}
            setCancelPaymentUrl={this.setCancelPaymentUrl}
            setStripePaymentButtonMode={this.setStripePaymentButtonMode}
            changeButtonIsRelayUTM={this.setUTMrelay}
            changeButtonAttributes={this.setButtonAttributes}
            changeButtonClass={this.setButtonClass}
            changeButtonButtonID={this.setButtonID}
            changeButtonOnClick={this.setOnClick}
            contentType={this.props.contentType}
            currentInputData={this.props.currentInputData}
            saveContentInStore={this.props.saveContentInStore}
            toggleNewEditionsDetected={this.props.toggleNewEditionsDetected}
            buttonIndex={this.state.currentCtaButtonIndex}
            componentType={this.props.componentType}
            componentDataPath={generateComponentDataPath(this.state)}
          />
        }
      </div>
    );
  };

  displayActiveOptionsPicker = (dataGenerator: any) => {
    // let availableOptions = this.props.currentInputData.availableOptions;
    let currentComponentAvailableOptions =
      availableOptions[this.props.componentType]; //get options for all content types of this component category (header, nav, cta_form etc)

    let optionsChooser: any;
    if (
      currentComponentAvailableOptions &&
      checkIfObjectEmpty(currentComponentAvailableOptions) === false
    ) {
      let currentComponentAvailableOptionsForThisContent =
        currentComponentAvailableOptions[this.props.contentType]; //get options for the current ContentInput type (cta, graphics etc)

      if (currentComponentAvailableOptionsForThisContent === undefined) {
        return null;
      }

      // Some specific components of a category (e.g. header-23) may has specific available options. Check the list and try to find such specific components.
      if (
        currentComponentAvailableOptionsForThisContent[
          this.props.componentTitle
        ] !== undefined
      ) {
        currentComponentAvailableOptionsForThisContent =
          currentComponentAvailableOptionsForThisContent[
            this.props.componentTitle
          ];
      } else {
        // We don't have a specific "available options" rule for the component of this title, so this component just uses the default options set.
        currentComponentAvailableOptionsForThisContent =
          currentComponentAvailableOptionsForThisContent["default"];
      }

      // currentComponentAvailableOptionsForThisContent now equals to e.g. ['form', 'buttons'].
      if (currentComponentAvailableOptionsForThisContent.length === 1) {
        // If the current component content has options but there is only one of them (a single option), we do not offer to switch between options (otherwise a single meaningless radio button will appear)
        return null;
      }

      optionsChooser = (
        <Radio.Group
          className="content-input__active_type"
          value={this.state.activeOption}
          size="small"
          onChange={(event) => {
            let newActiveOption = event.target.value;

            this.props.saveContentInStore(
              dataGenerator(
                { [this.props.contentType]: this.props.currentInputData },
                newActiveOption
              )
            );

            this.props.toggleNewEditionsDetected(true);
            this.setState({
              activeOption: newActiveOption,
            });

            if (newActiveOption === callToActionOptions.form) {
              fireAnalyticsEvent.fireCrisp(CrispEvents.changeCTAtoForm, {
                subdomain: this.props.currentWebsite.subdomain,
                component_content_type: this.props.componentType,
              });
            }
          }}
        >
          {currentComponentAvailableOptionsForThisContent.map(
            (item: any, key: number) => {
              return (
                <Radio.Button value={item} key={key}>
                  {capitalizeString(item)}
                </Radio.Button>
              );
            }
          )}
        </Radio.Group>
      );

      return optionsChooser;
    } else {
      return null;
    }
  };
  setButtonAttributes = (newAttributes: string) => {
    this.props.toggleNewEditionsDetected(true); //changes detected because we change the page JSON - by changing the button's attribute

    if (this.props.contentType === "cta") {
      //index = this.state.currentFeatureIndex;
      this.props.saveContentInStore(
        this.generator.changeCtaButtonAttributes(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentCtaButtonIndex,
          newAttributes
        )
      );
    } else if (this.props.contentType === "pricing") {
      if (this.state.isYearlyPricingButtonPassed) {
        this.props.saveContentInStore(
          this.generator.changePricingYearlyButtonAttributes(
            { [this.props.contentType]: this.props.currentInputData },
            this.state.currentPricingIndex,
            newAttributes
          )
        );
      } else {
        this.props.saveContentInStore(
          this.generator.changePricingButtonAttributes(
            { [this.props.contentType]: this.props.currentInputData },
            this.state.currentPricingIndex,
            newAttributes
          )
        );
      }
    } else if (this.props.contentType === "features") {
      //index = this.state.currentCtaButtonIndex;
    } else if (this.props.contentType === "posts") {
      // Be careful as Post can be a clickable item wit ha HREF or contain a button with button.href.
      this.props.saveContentInStore(
        this.generator.changePostButtonAttributes(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentPostIndex,
          newAttributes
        )
      );
    } else if (this.props.contentType === "gallery") {
      this.props.saveContentInStore(
        this.generator.changeGalleryButtonAttributes(
          { [this.props.contentType]: this.props.currentInputData },
          newAttributes,
          this.state.currentGalleryIndex,
          this.state.currentGalleryButtonIndex
        )
      );
    } else if (this.props.contentType === "pictograms") {
      //index = this.state.currentPictogramIndex;
    } else if (this.props.contentType === "steps") {
      this.props.saveContentInStore(
        this.generator.changeStepButtonAttributes(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentStepsItemIndex,
          newAttributes
        )
      );
    }
  };
  setButtonClass = (newClass: string) => {
    this.props.toggleNewEditionsDetected(true); //changes detected because we change the page JSON - by changing the button's attribute

    if (this.props.contentType === "cta") {
      //index = this.state.currentFeatureIndex;
      this.props.saveContentInStore(
        this.generator.changeCtaButtonClass(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentCtaButtonIndex,
          newClass
        )
      );
    } else if (this.props.contentType === "pricing") {
      if (this.state.isYearlyPricingButtonPassed) {
        this.props.saveContentInStore(
          this.generator.changePricingYearlyButtonClass(
            { [this.props.contentType]: this.props.currentInputData },
            this.state.currentPricingIndex,
            newClass
          )
        );
      } else {
        this.props.saveContentInStore(
          this.generator.changePricingButtonClass(
            { [this.props.contentType]: this.props.currentInputData },
            this.state.currentPricingIndex,
            newClass
          )
        );
      }
    } else if (this.props.contentType === "features") {
      //index = this.state.currentCtaButtonIndex;
    } else if (this.props.contentType === "posts") {
      // Be careful as Post can be a clickable item wit ha HREF or contain a button with button.href.
      this.props.saveContentInStore(
        this.generator.changePostButtonClass(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentPostIndex,
          newClass
        )
      );
    } else if (this.props.contentType === "gallery") {
      this.props.saveContentInStore(
        this.generator.changeGalleryButtonClass(
          { [this.props.contentType]: this.props.currentInputData },
          newClass,
          this.state.currentGalleryIndex,
          this.state.currentGalleryButtonIndex
        )
      );
    } else if (this.props.contentType === "pictograms") {
      //index = this.state.currentPictogramIndex;
    } else if (this.props.contentType === "steps") {
      this.props.saveContentInStore(
        this.generator.changeStepButtonClass(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentStepsItemIndex,
          newClass
        )
      );
    }
  };
  setButtonID = (newID: string) => {
    this.props.toggleNewEditionsDetected(true); //changes detected because we change the page JSON - by changing the button's attribute

    if (this.props.contentType === "cta") {
      //index = this.state.currentFeatureIndex;
      this.props.saveContentInStore(
        this.generator.changeCtaButtonID(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentCtaButtonIndex,
          newID
        )
      );
    } else if (this.props.contentType === "pricing") {
      if (this.state.isYearlyPricingButtonPassed) {
        this.props.saveContentInStore(
          this.generator.changePricingYearlyButtonID(
            { [this.props.contentType]: this.props.currentInputData },
            this.state.currentPricingIndex,
            newID
          )
        );
      } else {
        this.props.saveContentInStore(
          this.generator.changePricingButtonID(
            { [this.props.contentType]: this.props.currentInputData },
            this.state.currentPricingIndex,
            newID
          )
        );
      }
    } else if (this.props.contentType === "features") {
      //index = this.state.currentCtaButtonIndex;
    } else if (this.props.contentType === "posts") {
      // Be careful as Post can be a clickable item wit ha HREF or contain a button with button.href.
      this.props.saveContentInStore(
        this.generator.changePostButtonID(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentPostIndex,
          newID
        )
      );
    } else if (this.props.contentType === "gallery") {
      this.props.saveContentInStore(
        this.generator.changeGalleryButtonID(
          { [this.props.contentType]: this.props.currentInputData },
          newID,
          this.state.currentGalleryIndex,
          this.state.currentGalleryButtonIndex
        )
      );
    } else if (this.props.contentType === "pictograms") {
      //index = this.state.currentPictogramIndex;
    } else if (this.props.contentType === "steps") {
      this.props.saveContentInStore(
        this.generator.changeStepButtonID(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentStepsItemIndex,
          newID
        )
      );
    }
  };
  setOnClick = (newButtonOnClickCode: string) => {
    this.props.toggleNewEditionsDetected(true); //changes detected because we change the page JSON - by changing the button's attribute

    if (this.props.contentType === "cta") {
      //index = this.state.currentFeatureIndex;
      this.props.saveContentInStore(
        this.generator.changeCtaButtonOnClick(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentCtaButtonIndex,
          newButtonOnClickCode
        )
      );
    } else if (this.props.contentType === "pricing") {
      if (this.state.isYearlyPricingButtonPassed) {
        this.props.saveContentInStore(
          this.generator.changePricingYearlyButtonOnClick(
            { [this.props.contentType]: this.props.currentInputData },
            this.state.currentPricingIndex,
            newButtonOnClickCode
          )
        );
      } else {
        this.props.saveContentInStore(
          this.generator.changePricingButtonOnClick(
            { [this.props.contentType]: this.props.currentInputData },
            this.state.currentPricingIndex,
            newButtonOnClickCode
          )
        );
      }
    } else if (this.props.contentType === "features") {
      //index = this.state.currentCtaButtonIndex;
    } else if (this.props.contentType === "posts") {
      // Be careful as Post can be a clickable item wit ha HREF or contain a button with button.href.
      this.props.saveContentInStore(
        this.generator.changePostButtonOnClick(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentPostIndex,
          newButtonOnClickCode
        )
      );
    } else if (this.props.contentType === "gallery") {
      this.props.saveContentInStore(
        this.generator.changeGalleryButtonOnClick(
          { [this.props.contentType]: this.props.currentInputData },
          newButtonOnClickCode,
          this.state.currentGalleryIndex,
          this.state.currentGalleryButtonIndex
        )
      );
    } else if (this.props.contentType === "pictograms") {
      //index = this.state.currentPictogramIndex;
    } else if (this.props.contentType === "steps") {
      this.props.saveContentInStore(
        this.generator.changeStepButtonOnClick(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentStepsItemIndex,
          newButtonOnClickCode
        )
      );
    }
  };
  setUTMrelay = (newNewUTMRelayState: boolean) => {
    this.props.toggleNewEditionsDetected(true); //changes detected because we change the page JSON - by changing the button's attribute - IsRelayUTM

    if (this.props.contentType === "cta") {
      //index = this.state.currentFeatureIndex;
      this.props.saveContentInStore(
        this.generator.changeCtaButtonIsRelayUTM(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentCtaButtonIndex,
          newNewUTMRelayState
        )
      );
    } else if (this.props.contentType === "pricing") {
      if (this.state.isYearlyPricingButtonPassed) {
        this.props.saveContentInStore(
          this.generator.changePricingYearlyButtonIsRelayUTM(
            { [this.props.contentType]: this.props.currentInputData },
            this.state.currentPricingIndex,
            newNewUTMRelayState
          )
        );
      } else {
        this.props.saveContentInStore(
          this.generator.changePricingButtonIsRelayUTM(
            { [this.props.contentType]: this.props.currentInputData },
            this.state.currentPricingIndex,
            newNewUTMRelayState
          )
        );
      }
    } else if (this.props.contentType === "features") {
      //index = this.state.currentCtaButtonIndex;
    } else if (this.props.contentType === "posts") {
      // Be careful as Post can be a clickable item wit ha HREF or contain a button with button.href.
      this.props.saveContentInStore(
        this.generator.changePostButtonIsRelayUTM(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentPostIndex,
          newNewUTMRelayState
        )
      );
    } else if (this.props.contentType === "gallery") {
      this.props.saveContentInStore(
        this.generator.changeGalleryButtonIsRelayUTM(
          { [this.props.contentType]: this.props.currentInputData },
          newNewUTMRelayState,
          this.state.currentGalleryIndex,
          this.state.currentGalleryButtonIndex
        )
      );
    } else if (this.props.contentType === "pictograms") {
      //index = this.state.currentPictogramIndex;
    } else if (this.props.contentType === "steps") {
      this.props.saveContentInStore(
        this.generator.changeStepButtonIsRelayUTM(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentStepsItemIndex,
          newNewUTMRelayState
        )
      );
    }
  };
  setSuccessfulPaymentUrl = (newSuccessfulPaymentUrl: string) => {
    this.props.toggleNewEditionsDetected(true); //changes detected because we change the page JSON - by changing the button's attribute - successfulPaymentUrl

    if (this.props.contentType === "cta") {
      //index = this.state.currentFeatureIndex;
      this.props.saveContentInStore(
        this.generator.changeCtaButtonSuccessfulPaymentUrl(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentCtaButtonIndex,
          newSuccessfulPaymentUrl
        )
      );
    } else if (this.props.contentType === "pricing") {
      if (this.state.isYearlyPricingButtonPassed) {
        this.props.saveContentInStore(
          this.generator.changePricingYearlyButtonSuccessfulPaymentUrl(
            { [this.props.contentType]: this.props.currentInputData },
            this.state.currentPricingIndex,
            newSuccessfulPaymentUrl
          )
        );
      } else {
        this.props.saveContentInStore(
          this.generator.changePricingButtonSuccessfulPaymentUrl(
            { [this.props.contentType]: this.props.currentInputData },
            this.state.currentPricingIndex,
            newSuccessfulPaymentUrl
          )
        );
      }
    } else if (this.props.contentType === "features") {
      //index = this.state.currentCtaButtonIndex;
    } else if (this.props.contentType === "posts") {
      // Be careful as Post can be a clickable item wit ha HREF or contain a button with button.href.
      this.props.saveContentInStore(
        this.generator.changePostButtonSuccessfulPaymentUrl(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentPostIndex,
          newSuccessfulPaymentUrl
        )
      );
    } else if (this.props.contentType === "gallery") {
      this.props.saveContentInStore(
        this.generator.changeGalleryButtonSuccessfulPaymentUrl(
          { [this.props.contentType]: this.props.currentInputData },
          newSuccessfulPaymentUrl,
          this.state.currentGalleryIndex,
          this.state.currentGalleryButtonIndex
        )
      );
    } else if (this.props.contentType === "pictograms") {
      //index = this.state.currentPictogramIndex;
    } else if (this.props.contentType === "steps") {
      this.props.saveContentInStore(
        this.generator.changeStepButtonSuccessfulPaymentUrl(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentStepsItemIndex,
          newSuccessfulPaymentUrl
        )
      );
    }
  };
  setStripePaymentButtonMode = (newStripePaymentMode: string) => {
    this.props.toggleNewEditionsDetected(true); //changes detected because we change the page JSON - by changing the button's attribute - successfulPaymentUrl

    if (this.props.contentType === "cta") {
      //index = this.state.currentFeatureIndex;
      this.props.saveContentInStore(
        this.generator.changeCtaButtonStripePaymentMode(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentCtaButtonIndex,
          newStripePaymentMode
        )
      );
    } else if (this.props.contentType === "pricing") {
      if (this.state.isYearlyPricingButtonPassed) {
        this.props.saveContentInStore(
          this.generator.changePricingYearlyButtonStripePaymentMode(
            { [this.props.contentType]: this.props.currentInputData },
            this.state.currentPricingIndex,
            newStripePaymentMode
          )
        );
      } else {
        this.props.saveContentInStore(
          this.generator.changePricingButtonStripePaymentMode(
            { [this.props.contentType]: this.props.currentInputData },
            this.state.currentPricingIndex,
            newStripePaymentMode
          )
        );
      }
    } else if (this.props.contentType === "features") {
      //index = this.state.currentCtaButtonIndex;
    } else if (this.props.contentType === "posts") {
      // Be careful as Post can be a clickable item wit ha HREF or contain a button with button.href.
      this.props.saveContentInStore(
        this.generator.changePostButtonStripePaymentMode(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentPostIndex,
          newStripePaymentMode
        )
      );
    } else if (this.props.contentType === "gallery") {
      this.props.saveContentInStore(
        this.generator.changeGalleryButtonStripePaymentMode(
          { [this.props.contentType]: this.props.currentInputData },
          newStripePaymentMode,
          this.state.currentGalleryIndex,
          this.state.currentGalleryButtonIndex
        )
      );
    } else if (this.props.contentType === "pictograms") {
      //index = this.state.currentPictogramIndex;
    } else if (this.props.contentType === "steps") {
      this.props.saveContentInStore(
        this.generator.changeStepButtonStripePaymentMode(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentStepsItemIndex,
          newStripePaymentMode
        )
      );
    }
  };
  setCancelPaymentUrl = (newCancelPaymentUrl: string) => {
    this.props.toggleNewEditionsDetected(true); //changes detected because we change the page JSON - by changing the button's attribute - cancelPaymentUrl

    if (this.props.contentType === "cta") {
      //index = this.state.currentFeatureIndex;
      this.props.saveContentInStore(
        this.generator.changeCtaButtonCancelPaymentUrl(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentCtaButtonIndex,
          newCancelPaymentUrl
        )
      );
    } else if (this.props.contentType === "pricing") {
      if (this.state.isYearlyPricingButtonPassed) {
        this.props.saveContentInStore(
          this.generator.changePricingYearlyButtonCancelPaymentUrl(
            { [this.props.contentType]: this.props.currentInputData },
            this.state.currentPricingIndex,
            newCancelPaymentUrl
          )
        );
      } else {
        this.props.saveContentInStore(
          this.generator.changePricingButtonCancelPaymentUrl(
            { [this.props.contentType]: this.props.currentInputData },
            this.state.currentPricingIndex,
            newCancelPaymentUrl
          )
        );
      }
    } else if (this.props.contentType === "features") {
      //index = this.state.currentCtaButtonIndex;
    } else if (this.props.contentType === "posts") {
      // Be careful as Post can be a clickable item wit ha HREF or contain a button with button.href.
      this.props.saveContentInStore(
        this.generator.changePostButtonCancelPaymentUrl(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentPostIndex,
          newCancelPaymentUrl
        )
      );
    } else if (this.props.contentType === "gallery") {
      this.props.saveContentInStore(
        this.generator.changeGalleryButtonCancelPaymentUrl(
          { [this.props.contentType]: this.props.currentInputData },
          newCancelPaymentUrl,
          this.state.currentGalleryIndex,
          this.state.currentGalleryButtonIndex
        )
      );
    } else if (this.props.contentType === "pictograms") {
      //index = this.state.currentPictogramIndex;
    } else if (this.props.contentType === "steps") {
      this.props.saveContentInStore(
        this.generator.changeStepButtonCancelPaymentUrl(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentStepsItemIndex,
          newCancelPaymentUrl
        )
      );
    }
  };
  setStripeProductIndex = (stripeProductId: string) => {
    // stripeProductId can be a number or undefined (undefined if no Stripe product connected and by default).

    this.props.toggleNewEditionsDetected(true); //changes detected because we change the page JSON - by changing the button's attribute - stripeProductId

    if (this.props.contentType === "cta") {
      //index = this.state.currentFeatureIndex;
      this.props.saveContentInStore(
        this.generator.changeCtaButtonStripeProductId(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentCtaButtonIndex,
          stripeProductId
        )
      );
    } else if (this.props.contentType === "pricing") {
      if (this.state.isYearlyPricingButtonPassed) {
        this.props.saveContentInStore(
          this.generator.changePricingYearlyButtonStripeProductId(
            { [this.props.contentType]: this.props.currentInputData },
            this.state.currentPricingIndex,
            stripeProductId
          )
        );
      } else {
        this.props.saveContentInStore(
          this.generator.changePricingButtonStripeProductId(
            { [this.props.contentType]: this.props.currentInputData },
            this.state.currentPricingIndex,
            stripeProductId
          )
        );
      }
    } else if (this.props.contentType === "features") {
      //index = this.state.currentCtaButtonIndex;
    } else if (this.props.contentType === "posts") {
      // Be careful as Post can be a clickable item wit ha HREF or contain a button with button.href.
      this.props.saveContentInStore(
        this.generator.changePostButtonStripeProductId(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentPostIndex,
          stripeProductId
        )
      );
    } else if (this.props.contentType === "gallery") {
      this.props.saveContentInStore(
        this.generator.changeGalleryButtonStripeProductId(
          { [this.props.contentType]: this.props.currentInputData },
          stripeProductId,
          this.state.currentGalleryIndex,
          this.state.currentGalleryButtonIndex
        )
      );
    } else if (this.props.contentType === "pictograms") {
      //index = this.state.currentPictogramIndex;
    } else if (this.props.contentType === "steps") {
      this.props.saveContentInStore(
        this.generator.changeStepButtonStripeProductId(
          { [this.props.contentType]: this.props.currentInputData },
          this.state.currentStepsItemIndex,
          stripeProductId
        )
      );
    }
  };

  setActiveOptionInState = (activeOption: GraphicsOptions) => {
    this.setState({
      activeOption,
    });
  };

  render() {
    const { TextArea } = Input;
    let currentElementHasPictogramInGroups = false;
    // This is a little tricky stuff, let me explain.
    // We open the pictogram edition Drawer (modal window) which contains buttons to change pictograms.
    // To detect what pictogram we change, we pass this.props.contentType as an argument. It can be "features", "pricing" etc.
    // Sometimes one element can have groups. The groups may use pictograms in their titles.
    // In this case (if the element has groups (hasGroups === true) and the element's titles have pictogram in design (we mark it as groupTitleHasPictogram === true), the handleButtonClick() (this function) changes the group title pictogram.
    // Please note that a template element (e.g., "features", "pricing", "links" etc) can have pictogram only in one place (either in content OR in a group's title).
    if (
      this.props.currentInputData.hasGroups !== undefined &&
      this.props.currentInputData.hasGroups === true &&
      this.props.currentInputData.groupTitleHasPictogram !== undefined &&
      this.props.currentInputData.groupTitleHasPictogram === true
    ) {
      currentElementHasPictogramInGroups = true;
    }

    let displayPictogramEditorOpener = (
      currentPictogramObject: any,
      key: any
    ) => {
      let setCurrentItemIndexInState = () => {
        if (currentElementHasPictogramInGroups) {
          this.setState({ currentGroupIndex: key });
        } else {
          if (this.props.contentType === "features") {
            this.setState({ currentFeatureIndex: key });
          } else if (this.props.contentType === "pricing") {
            this.setState({ currentPricingIndex: key });
          } else if (this.props.contentType === "posts") {
            this.setState({ currentPostIndex: key });
          } else if (this.props.contentType === "gallery") {
            this.setState({ currentGalleryIndex: key });
          } else if (this.props.contentType === "pictograms") {
            this.setState({ currentPictogramIndex: key });
          }
        }

        this.setState({ currentPictogramObject: currentPictogramObject });
      };

      let handleChangeIconButtonClick = () => {
        this.showPictogramDrawer();

        let activePictogramTypeTab = currentPictogramObject.type;
        if (activePictogramTypeTab === "") {
          activePictogramTypeTab = pictogramOptions.abstractIcon.title;
        }
        this.setState({ currentPictogramType: activePictogramTypeTab });

        setCurrentItemIndexInState();
      };

      if (currentPictogramObject.type === "") {
        return (
          <div
            className="content-input__edit_img_opener_box"
            key={"img-opener-" + key}
          >
            <Button
              onClick={() => {
                handleChangeIconButtonClick();
              }}
              icon="plus"
              size="small"
            >
              Add an icon
            </Button>
          </div>
        );
      } else {
        return (
          <div
            className="content-input__edit_img_opener_box"
            key={"img-opened-box-" + key}
          >
            <Button
              className="content-input__edit_img_opener"
              onClick={() => {
                handleChangeIconButtonClick();
              }}
            >
              <Pictogram pictogramObject={currentPictogramObject} />
            </Button>

            <Button
              title="Remove icon"
              icon="cross"
              shape="circle"
              className="content-input__img_remove_button"
              size="small"
              onClick={() => {
                this.props.toggleNewEditionsDetected(true);

                if (currentElementHasPictogramInGroups) {
                  this.props.saveContentInStore(
                    this.generator.changeGroupPictogramType(
                      { [this.props.contentType]: this.props.currentInputData },
                      "",
                      key,
                      this.props.contentType
                    )
                  );
                } else {
                  if (this.props.contentType === "features") {
                    this.props.saveContentInStore(
                      this.generator.changeFeaturePictogramType(
                        {
                          [this.props.contentType]: this.props.currentInputData,
                        },
                        "",
                        key
                      )
                    );
                  } else if (this.props.contentType === "pricing") {
                    this.props.saveContentInStore(
                      this.generator.changePricingPictogramType(
                        {
                          [this.props.contentType]: this.props.currentInputData,
                        },
                        "",
                        key
                      )
                    );
                  } else if (this.props.contentType === "posts") {
                    this.props.saveContentInStore(
                      this.generator.changePostPictogramType(
                        {
                          [this.props.contentType]: this.props.currentInputData,
                        },
                        "",
                        key
                      )
                    );
                  } else if (this.props.contentType === "gallery") {
                    this.props.saveContentInStore(
                      this.generator.changeGalleryPictogramType(
                        {
                          [this.props.contentType]: this.props.currentInputData,
                        },
                        "",
                        key
                      )
                    );
                  } else if (this.props.contentType === "pictograms") {
                    this.props.saveContentInStore(
                      this.generator.changePictogramsItemType(
                        {
                          [this.props.contentType]: this.props.currentInputData,
                        },
                        "",
                        key
                      )
                    );
                  }
                }
              }}
            ></Button>
          </div>
        );
      }
    };

    let displayLogoEditor = (currentLogoObject: any, key: any) => {
      let uniqueKey = this.props.contentType + "-" + key;
      return (
        <div className="content-input__edit_img_opener_wrapper">
          <div className="content-input__image_picker_box">
            <ImagePicker
              currentImgAlt={currentLogoObject.alt}
              saveImageAltInReduxStore={(newAlt: string) => {
                if (this.props.contentType === "logos") {
                  this.props.saveContentInStore(
                    this.generator.changeLogosItemAlt(
                      { [this.props.contentType]: this.props.currentInputData },
                      newAlt,
                      key
                    )
                  );
                } else if (this.props.contentType === "posts") {
                  this.props.saveContentInStore(
                    this.generator.changePostLogoAlt(
                      { [this.props.contentType]: this.props.currentInputData },
                      newAlt,
                      key
                    )
                  );
                }

                this.props.toggleNewEditionsDetected(true);
              }}
              currentImgSrc={currentLogoObject.uploadedSrc}
              currentImgUUID={currentLogoObject.uuid}
              key={uniqueKey}
              height={currentLogoObject.height}
              inputId={uniqueKey}
              saveImageInfoInDB={this.props.saveImageInfoInDB}
              removeImageFromDB={this.props.removeImageFromDB}
              saveImageInReduxStore={(
                newSrc: string,
                newUUID: any,
                width,
                height
              ) => {
                if (this.props.contentType === "logos") {
                  this.props.saveContentInStore(
                    this.generator.changeLogosItemUploadedSrc(
                      { [this.props.contentType]: this.props.currentInputData },
                      newSrc,
                      key,
                      width,
                      height
                    )
                  );
                  this.props.saveContentInStore(
                    this.generator.changeLogosItemUUID(
                      { [this.props.contentType]: this.props.currentInputData },
                      newUUID,
                      key
                    )
                  );
                  this.props.saveContentInStore(
                    this.generator.changeLogosItemType(
                      { [this.props.contentType]: this.props.currentInputData },
                      logoOptions.uploaded.title,
                      key
                    )
                  );
                } else if (this.props.contentType === "posts") {
                  this.props.saveContentInStore(
                    this.generator.changePostLogoUploadedSrc(
                      { [this.props.contentType]: this.props.currentInputData },
                      newSrc,
                      key,
                      width,
                      height
                    )
                  );
                  this.props.saveContentInStore(
                    this.generator.changePostLogoUUID(
                      { [this.props.contentType]: this.props.currentInputData },
                      newUUID,
                      key
                    )
                  );
                  this.props.saveContentInStore(
                    this.generator.changePostLogoType(
                      { [this.props.contentType]: this.props.currentInputData },
                      logoOptions.uploaded.title,
                      key
                    )
                  );
                }

                this.props.toggleNewEditionsDetected(true);
              }}
            />
          </div>

          {currentLogoObject.type !== "" &&
            currentLogoObject.uploadedSrc !== "" && (
              <Slider
                min={5}
                max={100}
                step={1}
                value={currentLogoObject.height}
                tipFormatter={(value: number) => `Logo height: ${value}px`}
                onChange={(value: any) => {
                  if (this.props.contentType === "logos") {
                    this.props.saveContentInStore(
                      this.generator.changeLogosItemHeight(
                        {
                          [this.props.contentType]: this.props.currentInputData,
                        },
                        value,
                        key
                      )
                    );
                  } else if (this.props.contentType === "posts") {
                    this.props.saveContentInStore(
                      this.generator.changePostLogoHeight(
                        {
                          [this.props.contentType]: this.props.currentInputData,
                        },
                        value,
                        key
                      )
                    );
                  }
                  this.props.toggleNewEditionsDetected(true);
                }}
              />
            )}

        </div>
      );
    };

    let displayButtonStyleEditorOpener = (
      currentButtonObject: any,
      handler: any,
      shouldDisplayForcedWhiteButtonsWarning?: boolean
    ) => {
      // we have currentButtonObject in a state, yes, but it's only updated when we click on the  "edit style" button. But we need this data (currentButtonObject) before that: we want to display a miniature of the current button inside the "edit style" button, so we pass currentButtonObject and display the miniature.

      // handler() is what executed when the "change button style" button is pressed by a user.
      // handler() passes some state changes so functions inside a styles edition Drawer know indexes of buttons to edit.
      let displayCurrentButton = () => {
        if (currentButtonObject !== undefined) {
          let buttonTitle = currentButtonObject.title;
          if (currentButtonObject.title === "") {
            buttonTitle = "?";
          }

          return (
            <div className="custom_fonts">
              <div className={this.props.currentWebsite.active_font_classname}>
                <WebsiteButton
                  config={{
                    buttonType: linkOrButtonOptions.link, //always link because <button> can not be placed inside <button>
                    buttonTitle: buttonTitle,
                    buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration
                    buttonIsTargetBlank: currentButtonObject.isTargetBlank,

                    buttonStyleType:
                      currentButtonObject.settings.appearance.styleType,
                    buttonColor: currentButtonObject.settings.appearance.color,

                    pillColor:
                      currentButtonObject.settings.appearance.pillColor,
                    pillPrefix: currentButtonObject.pillPrefix,

                    buttonMobileAppStoreType:
                      currentButtonObject.settings.appearance
                        .mobileAppStoreType,
                    buttonDownloadType:
                      currentButtonObject.settings.appearance.downloadType,
                    ghostColor:
                      currentButtonObject.settings.appearance.ghostColor,
                  }}
                />
              </div>
            </div>
          );
        } else {
          return null;
        }
      };

      let additionalButtonClassname = "";
      if (
        (currentButtonObject.settings.appearance.styleType ===
          buttonStyles.regular.typeTitle &&
          currentButtonObject.settings.appearance.color ===
            buttonStyles.regular.whiteOutline) ||
        (currentButtonObject.settings.appearance.styleType ===
          buttonStyles.ghost.typeTitle &&
          currentButtonObject.settings.appearance.ghostColor ===
            buttonStyles.ghost.whiteOutline)
      ) {
        additionalButtonClassname =
          " content-input__edit_button_example--gray ";
      }

      if (currentButtonObject.title === "" && currentButtonObject.href === "") {
        return null;
      } else {
        return (
          <Button
            type="ghost"
            icon="bg-colors"
            className="content-input__edit_button_opener"
            onClick={() => {
              handler();
              this.showButtonStylesDrawer();

              // TODO: PASS THIS MESSAGE TO STATE This button was forced to white color because the current page has dark background. It will be displayed AND DISPLAY A NOTIF
              //PS DO NOT BLOCK CHANGING OF COLOR, JUST DISPLAY A NOTIF

              if (
                this.props.componentType === "nav" &&
                shouldDisplayForcedWhiteButtonsWarning !== undefined &&
                shouldDisplayForcedWhiteButtonsWarning
              ) {
                this.setState({
                  shouldDisplayForcedWhiteButtonsWarning: true,
                });
              } else {
                this.setState({
                  shouldDisplayForcedWhiteButtonsWarning: false,
                });
              }
            }}
          >
            Button style
            <span
              className={
                "content-input__edit_button_example " +
                additionalButtonClassname
              }
            >
              {displayCurrentButton()}
            </span>
          </Button>
        );
      }
    };

    let displayFormIntegrationsChooser = (content: any) => {
      let displayAddNewIntegration = () => {
        return (
          <Button
            type="default"
            onClick={(e: any) => {
              e.preventDefault();
              this.props.showFormIntegrationsDrawer();
            }}
            htmlType="button"
            size="small"
            icon="plus"
          >
            Connect new integration
          </Button>
        );
      };

      let selectIntegration = (e: any) => {
        let selectedIntegrationId = e.target.value;

        this.props.saveContentInStore(
          this.generator.activateCtaEmailFormIntegration(
            { [this.props.contentType]: this.props.currentInputData },
            selectedIntegrationId
          )
        );
        this.props.toggleNewEditionsDetected(true);
      };

      let availableIntegrations =
        this.props.currentWebsite.connected_form_integrations.connected;

      let visibleIntegrationsContent;

      if (availableIntegrations && availableIntegrations.length > 0) {
        visibleIntegrationsContent = (
          <div>
            {/*// We choose the first one from the list because currently each form can only have ONE active integration but in future there will be a list of connected integrations.*/}
            <RadioGroup
              className="content-input__integrations_radio_group"
              onChange={selectIntegration}
              value={
                content[callToActionOptions.form].connectedIntegrationsIds[0]
              }
            >
              <AvailableIntegrationsList
                currentWebsite={this.props.currentWebsite}
                accessToken={this.props.auth.accessToken}
                websites={this.props.websites}
                toggleWaitingForCommonWebsiteUpdate={
                  this.props.toggleWaitingForCommonWebsiteUpdate
                }
                removeWebsiteFormIntegration={
                  this.props.removeWebsiteFormIntegration
                }
              />
            </RadioGroup>
            {displayAddNewIntegration()}
          </div>
        );
      } else {
        visibleIntegrationsContent = (
          <div className="content-input__integrations_empty">
            <img
              src={EmptyImage}
              alt="Empty bin image"
              style={{ marginBottom: "10px" }}
            />
            <br />
            <div style={{}}>No form integrations connected.</div>
            <br />
            {displayAddNewIntegration()}
          </div>
        );
      }

      return (
        <div style={{ marginTop: "30px" }}>
          <div className="content-input__label_box">
            <div className="content-input__label">Form integrations</div>
          </div>

          {visibleIntegrationsContent}
        </div>
      );
    };

    let displayMoveUpDownButtons = (
      index: number,
      itemsCount: number,
      moveUp: any,
      moveDown: any
    ) => {
      let displayMoveUpButton = () => {
        if (index === 0) {
          // If the current itm is placed on the top
          return null;
        } else {
          return (
            <Button
              onClick={(event: any) => {
                this.props.toggleNewEditionsDetected(true);
                moveUp();
              }}
              htmlType="button"
              className="content-input__control_cluster content-input__control_cluster--top"
              shape="circle"
              title="Move this item up"
              icon="up"
              size="small"
            />
          );
        }
      };

      let displayMoveDownButton = () => {
        if (index === itemsCount - 1) {
          // If the current itm is placed on the bottom
          return null;
        } else {
          return (
            <Button
              onClick={(event: any) => {
                this.props.toggleNewEditionsDetected(true);
                moveDown();
              }}
              htmlType="button"
              className="content-input__control_cluster content-input__control_cluster--bottom"
              shape="circle"
              title="Move this item down"
              icon="down"
              size="small"
            />
          );
        }
      };

      return (
        <div>
          {/*If the total amount of items is 1 or zero, do not display the moving buttons.*/}
          {itemsCount > 1 && displayMoveUpButton()}
          {itemsCount > 1 && displayMoveDownButton()}
        </div>
      );
    };

    let displayButtonPluginsDrawerOpener = (
      item: any,
      itemIndex: number,
      galleryItemIndex?: number,
      isYearlyPricingButtonPassed?: boolean
    ) => {
      // item - a Post, a Step, A pricing plan or a button in a buttons set (e.g. CTA)
      // itemIndex - pricing plan, cta button, post, step index. We need to know which element's button is affected.

      //  we need to get a button object. The button object is placed differently depending on contentType. To see objects schemas go to frontend/src/helpers/editor/generateContentDataObject.ts

      if (
        this.props.contentType === "cta" ||
        this.props.contentType === "gallery" ||
        this.props.contentType === "posts"
      ) {
        // 'posts' can send a button obj or a post obj (because sometimes a post doesnt have a button but it is a clicakble object itself)

        var buttonObject = { ...item };
      } else if (this.props.contentType === "pricing") {
        // we added an ability to create a separate action for yearly tab, so we need this ad hoc isYearlyPricingButtonPassed to detect whether we edit a default pricing button or a yearly-only yearlyButton.
        if (isYearlyPricingButtonPassed && item.yearlyButton) {
          var buttonObject = {
            ...item.button,
            href: item.yearlyButton.href,
            isRelayUTM: item.yearlyButton.isRelayUTM,
            stripeProductId: item.yearlyButton.stripeProductId,
            stripePaymentMode: item.yearlyButton.stripePaymentMode,
            successfulPaymentUrl: item.yearlyButton.successfulPaymentUrl,
            cancelPaymentUrl: item.yearlyButton.cancelPaymentUrl,
            settings: {
              ...item.yearlyButton.settings,
            },
          };
        } else {
          var buttonObject = { ...item.button };
        }
      } else if (this.props.contentType === "steps") {
        var buttonObject = { ...item.button };
      } else {
        var buttonObject = undefined;
      }

      let pluginsCount = 0;
      let tooltipMessage = (
        <span>
          Turn the button into a Stripe payment action button, open popups,
          track marketing events, and change attributes.
        </span>
      );

      let isStripeCheckoutConnected = false;
      let isPopupConnected = false;

      if (buttonObject !== undefined) {
        let isRelayUTMConnected = false;
        if (buttonObject.isRelayUTM) {
          isRelayUTMConnected = buttonObject.isRelayUTM;
        }
        if (
          buttonObject.stripeProductId &&
          buttonObject.stripeProductId !== ""
        ) {
          if (
            this.props.currentWebsite.connected_payment_integrations &&
            this.props.currentWebsite.connected_payment_integrations
              .stripe_public_key &&
            this.props.currentWebsite.connected_payment_integrations
              .stripe_public_key !== ""
          ) {
            isStripeCheckoutConnected = true;
          }
        }
        if (
          buttonObject.settings &&
          buttonObject.settings.popup &&
          buttonObject.settings.popup.isActive &&
          buttonObject.settings.popup.popupId
        ) {
          const popupId = buttonObject.settings.popup.popupId;
          if (checkIfPopupExists(popupId, this.props.currentWebsite)) {
            isPopupConnected = true;
          }
        }

        // pluginsCount starts from 0, but if UTM Relay connected, add +1
        if (isRelayUTMConnected !== false) {
          pluginsCount += 1;
        }

        // let stripeCheckoutProductId = buttonObject.stripeProductId;
        // pluginsCount starts from 0, but if stripeCheckoutProductId connected, add +1
        // if(stripeCheckoutProductId !== undefined && stripeCheckoutProductId !== ''){
        // if (checkIsButtonHrefDisabled(buttonObject, this.props.currentWebsite)) {
        //     isStripeCheckoutConnected = true;
        // }

        if (isStripeCheckoutConnected) {
          pluginsCount += 1; // Success - the button has an existing Stripe product connected.
        }

        if (isPopupConnected) {
          pluginsCount += 1;
        }

        // if (isStripeCheckoutConnected && isRelayUTMConnected) {
        //     tooltipMessage = (<span>The UTM Relay plugin is connected but useless because Stripe Checkout is activated.</span>);
        // } else if (isStripeCheckoutConnected === true && isRelayUTMConnected === false) {
        //     tooltipMessage = (<span>This button acts as a Stripe Checkout invoker. Deactivate the plugin to convert the button back to a regular link.</span>);
        // } else if (isStripeCheckoutConnected === false && isRelayUTMConnected === true) {
        //     tooltipMessage = (<span>This button is a regular link with the UTM Relay plugin activated.</span>);
        // }

        if (isRelayUTMConnected) {
          tooltipMessage = (
            <span>
              This button is a regular link with the UTM Relay plugin activated.
            </span>
          );
        }
        if (isPopupConnected) {
          tooltipMessage = (
            <span>
              This button opens a popup on click. Deactivate the plugin to
              convert the button back to a regular link.
            </span>
          );
        }
        if (isStripeCheckoutConnected) {
          tooltipMessage = (
            <span>
              This button acts as a Stripe Checkout invoker. Deactivate the
              plugin to convert the button back to a regular link.
            </span>
          );
        }
      }

      return (
        <div>
          <Tooltip placement={"left"} title={tooltipMessage}>
            <Button
              size="small"
              onClick={() => {
                // We need to pass stripeProductId to check if current button already has Stripe product assigned to it.
                this.setState({
                  currentButtonStripeProductId: buttonObject
                    ? buttonObject.stripeProductId
                    : undefined,
                });
                if (this.props.contentType === "cta") {
                  this.setState({ currentCtaButtonIndex: itemIndex });
                } else if (this.props.contentType === "pricing") {
                  this.setState({ currentPricingIndex: itemIndex });
                } else if (this.props.contentType === "posts") {
                  this.setState({ currentPostIndex: itemIndex });
                } else if (this.props.contentType === "gallery") {
                  this.setState({
                    currentGalleryIndex: galleryItemIndex,
                    currentGalleryButtonIndex: itemIndex,
                  });
                } else if (this.props.contentType === "steps") {
                  this.setState({ currentStepsItemIndex: itemIndex });
                }
                this.showButtonPluginsDrawer(
                  buttonObject,
                  isYearlyPricingButtonPassed
                );
              }}
            >
              <Icon
                type="setting"
                theme={pluginsCount > 0 ? "twoTone" : "outlined"}
              />
              {pluginsCount > 0 ? "(" + pluginsCount + ")" : null}
            </Button>
          </Tooltip>
        </div>
      );
    };

    let displayInputs = () => {
      let content = this.props.currentInputData.content;
      let textEditorType = returnTextEditorType(this.props.currentInputData); //'draftjs' or undefined

      let revertToPlainHTML = () => {
        this.generator.changeItemTextEditorType(
          { [this.props.contentType]: this.props.currentInputData },
          this.props.contentType,
          undefined
        );
      };

      const handleSetState = (newState: any) => {
        this.setState(newState);
      };

      // REGULAR FIELDS (ANY COMPONENT MAY HAVE THESE FIELDS)

      const Input = ContentInputsMap[this.props.contentType];

      return (
        <Input
          saveContentInStore={this.props.saveContentInStore}
          generator={this.generator}
          contentType={this.props.contentType}
          currentInputData={this.props.currentInputData}
          toggleNewEditionsDetected={this.props.toggleNewEditionsDetected}
          content={content}
          revertToPlainHTML={revertToPlainHTML}
          textEditorType={textEditorType}
          displayPictogramEditorOpener={displayPictogramEditorOpener}
          checkIsButtonHrefDisabled={checkIsButtonHrefDisabled}
          currentWebsite={this.props.currentWebsite}
          displayMoveUpDownButtons={displayMoveUpDownButtons}
          saveImageInfoInDB={this.props.saveImageInfoInDB}
          removeImageFromDB={this.props.removeImageFromDB}
          displayButtonStyleEditorOpener={displayButtonStyleEditorOpener}
          state={this.state}
          displayButtonPluginsDrawerOpener={displayButtonPluginsDrawerOpener}
          setState={handleSetState}
          displayLogoEditor={displayLogoEditor}
          displayActiveOptionsPicker={this.displayActiveOptionsPicker}
          componentTitle={this.props.componentTitle}
          componentType={this.props.componentType}
          isFirstComponentBgDark={this.props.isFirstComponentBgDark}
          auth={this.props.auth}
          displayFormIntegrationsChooser={displayFormIntegrationsChooser}
          isBlogObjectEdited={this.props.isBlogObjectEdited}
          currentBlog={this.props.currentBlog}
        />
      );
    };

    const currentComponent =
      `${this.props.componentType}-${this.props.componentTitle}` as Components;

    let displayTitle = () => {
      // Explaining [this.state.activeOption]:
      // Some <ContentInput/>s (cta, graphics, pictogram) may have multiple content options. E.g., cta may be 'buttons' or 'form'.
      // Users can switch the type of currently active cta type.
      // Depending on the choice, we display corresponding fieldInfo, fieldTitle, labelTooltip etc.
      // E.g. 'fieldTitle' may be: "Buttons"/"Form"

      let fieldInfoJSX;

      let fieldInfo =
        this.props.currentInputData.fieldInfo[this.state.activeOption];
      fieldInfo = forceNewUITextIfHasMultipleGraphicsOptions(
        UITextTypes.fieldInfo,
        fieldInfo,
        this.props.contentType,
        currentComponent,
        this.state.activeOption as GraphicsOptions,
        this.props.componentType as ComponentTypes
      );
      
      const titleTooltip = forceNewUITextIfHasMultipleGraphicsOptions(
        UITextTypes.titleTooltip,
        "",
        this.props.contentType,
        currentComponent,
        this.state.activeOption as GraphicsOptions,
        this.props.componentType as ComponentTypes,
        this.props.componentType === "video"
      );

      const hasGraphicsOptions = !!getAvailableGraphicsOptions(
        this.props.componentType,
        this.props.componentTitle
      );

      let fieldTitle =
        this.props.currentInputData.fieldTitle[this.state.activeOption];
      fieldTitle = forceNewUITextIfHasMultipleGraphicsOptions(
        UITextTypes.title,
        fieldTitle,
        this.props.contentType,
        currentComponent,
        this.state.activeOption as GraphicsOptions,
        this.props.componentType as ComponentTypes
      );

      if (fieldTitle && fieldTitle !== "" && this.props.contentType !== "directory") {
        return (
          <h2 className="content-input__title">
            {fieldTitle}
            {!!titleTooltip && <Tooltip placement="top" title={titleTooltip}>
              <Icon className="content-input__tooltip_icon" type="info-circle" />
            </Tooltip>}
          </h2>
        );
      }
    };

    let label = this.props.currentInputData.label[this.state.activeOption];
    label = forceNewUITextIfHasMultipleGraphicsOptions(
      UITextTypes.label,
      label,
      this.props.contentType,
      currentComponent,
      this.state.activeOption as GraphicsOptions,
      this.props.componentType as ComponentTypes
    );

    let displayTooltip = () => {
      let labelTooltip =
        this.props.currentInputData.labelTooltip[this.state.activeOption];
      labelTooltip = forceNewUITextIfHasMultipleGraphicsOptions(
        UITextTypes.labelTooltip,
        labelTooltip,
        this.props.contentType,
        currentComponent,
        this.state.activeOption as GraphicsOptions,
        this.props.componentType as ComponentTypes
      );

      if (labelTooltip && labelTooltip !== "") {
        return (
          <div className="content-input__tooltip_box">
            <Tooltip placement="top" title={labelTooltip}>
              <Icon
                className="content-input__tooltip_icon"
                type="info-circle"
              />
            </Tooltip>
          </div>
        );
      }
    };

    return (
      <li className="content-input">
        {displayTitle()}
        <div>
          <div className="content-input__input_box">{displayInputs()}</div>
        </div>
        <GraphicsPicker
          componentTitle={this.props.componentTitle}
          componentType={this.props.componentType}
          contentType={this.props.contentType}
          currentInputData={this.props.currentInputData}
          saveContentInStore={this.props.saveContentInStore}
          toggleNewEditionsDetected={this.props.toggleNewEditionsDetected}
          setActiveOptionInState={this.setActiveOptionInState}
        />

        <Drawer
          title={
            <DrawerTitle
              title="Pick a button style"
              onClose={this.closeButtonStylesDrawer}
            />
          }
          width={contentEditionDrawerSmallWidth}
          closable={false}
          onClose={this.closeButtonStylesDrawer}
          visible={this.state.buttonStylesVisible}
          className={"edit-component-drawer " + generatePopupDrawerClass(this.props.componentType)}
        >
          {this.displayButtonStylesEditor()}
        </Drawer>

        <Drawer
          title={
            <DrawerTitle
              title="Button actions, integrations & settings"
              onClose={this.closeButtonPluginsDrawer}
            />
          }
          width={contentEditionDrawerWidth}
          closable={false}
          destroyOnClose={true} // we need to unMount this component on close because we set some state values on componentWillMount event
          onClose={this.closeButtonPluginsDrawer}
          visible={this.state.buttonPluginsVisible}
          className={"edit-component-drawer " + generatePopupDrawerClass(this.props.componentType)}
        >
          {this.displayButtonPluginsPicker(generateComponentDataPath)}
        </Drawer>

        <Drawer
          title={
            <DrawerTitle
            title="Select an icon"
            onClose={this.closePictogramDrawer}
          />
          }
          width={contentEditionDrawerWidth}
          closable={false}
          onClose={this.closePictogramDrawer}
          visible={this.state.pictogramDrawerVisible}
          className={"edit-component-drawer " + generatePopupDrawerClass(this.props.componentType)}
        >
          {this.displayPictogramEditor(currentElementHasPictogramInGroups)}
        </Drawer>
      </li>
    );
  }
}

export default ContentInput;
