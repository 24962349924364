import React from "react";
import ConditionalDiv from "../components/ConditionalDiv";
import { connect } from "react-redux";
import { getCurrentStoreData } from "../store/websites/thunks";
import { GetCurrentStoreData } from "../store/websites/types";

interface Props {
  className?: string;
  isDarkBg: boolean;
  codeSnippet: string;
  isBackgroundAlwaysLight?: boolean;
  containerClassName?: string;
  getCurrentStoreData: GetCurrentStoreData;
}

const EditorCodeSnippet = (props: Props): JSX.Element => {
  const {
    isDarkBg,
    codeSnippet,
    className,
    isBackgroundAlwaysLight,
    containerClassName,
    getCurrentStoreData,
  } = props;
  const { currentWebsite, currentWebsitePage } = getCurrentStoreData();

  return (
    <ConditionalDiv
      condition={!!containerClassName}
      className={containerClassName}
    >
      <div className={`other-01 ${className || ""}`}>
        <div className="other-01__logo_box">
          <span className="other-01__logo-new">{"</>"}</span>
        </div>
        <div
          className={
            "other-01__wrapper " +
            (codeSnippet ? " other-01__wrapper-has_content " : " ")
          }
        >
          <div className="other-01__text">
            <div
              className={
                "other-01__title " +
                (isDarkBg && !isBackgroundAlwaysLight ? "text-white" : "")
              }
            >
              To see rendered custom HTML, save changes and open the page
            </div>
            <div
              className={
                "other-01__info " +
                (isDarkBg && !isBackgroundAlwaysLight ? "text-white" : "")
              }
            >
              We don't render it here for security reasons
            </div>
          </div>
          {codeSnippet && (
            <div
              className={
                "other-01__code " +
                (isDarkBg && !isBackgroundAlwaysLight ? "text-white" : "")
              }
            >
              {codeSnippet}
            </div>
          )}
        </div>
      </div>
    </ConditionalDiv>
  );
};

export default connect(null, {
  getCurrentStoreData,
})(EditorCodeSnippet);
