import React from "react";
import "./ui_components.scss";
import classNames from "classnames";

interface Props {
  children: React.ReactNode | React.ReactNode[];
  gap?: "small" | "medium";
  equalSpace?: boolean;
}

const HorInputGroup = (props: Props): JSX.Element => {
  const { children, gap, equalSpace } = props;
  const gaps = {
    small: 4,
    medium: 10,
  };

  return (
    <div
      className={classNames("hor-input-group", {
        "equal-space": equalSpace === undefined ? true : equalSpace,
      })}
      style={{
        gap: gaps[gap || "medium"],
      }}
    >
      {children}
    </div>
  );
};

export default HorInputGroup;
