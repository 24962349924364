import {
  Alert,
  Button,
  Divider,
  Icon,
  Input,
  Popover,
  Radio,
  Switch,
} from "antd";
import React, { Component } from "react";
import "./WebsiteWidgets.css";
import { api, emojiPath, urls } from "../../data/urls";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import { openNotification } from "../../helpers/openNotification";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { getErrorKey } from "../../helpers/editor/getErrorKey";
import { getErrorValue } from "../../helpers/editor/getErrorValue";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import { getCurrentWebsiteLiveUrl } from "../../helpers/getCurrentWebsiteLiveUrl";
import { checkIfObjectEmpty } from "../../helpers/checkIfObjectEmpty";
import DebouncedTextarea from "../DebouncedTextarea";
import DebouncedInput from "../DebouncedInput";
import generateRandomNumerousId from "../../helpers/generateRandomNumerousId";
import CustomFontsStyles from "../editor/CustomFontsStyles";
import LanguagesSwitch from "../editor/siteWidgets/LanguagesSwitch";
import WidgetsPreview from "../editor/siteWidgets/WidgetsPreview";
import { checkIfThisWebsiteLanguageOptionOfAnotherWebsite } from "../../helpers/editor/checkIfThisWebsiteLanguageOptionOfAnotherWebsite";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import _ from "lodash";

interface Props {
  auth: any;
  user: any;
  currentWebsite: any;
  saveWebsiteDataInStore: any;
  fullWebsiteUrl: any;
  websites: any; // need to check if this website is a language option of another website.
}

interface MyState {
  isSimpleCookieWidgetVisible: boolean;
  waitingForServer: boolean;
  cookieWidgetChangesDetected: boolean;
  langWidgetChangesDetected: boolean;
  currentWebsiteWidgetsObject: any;
  isCookieWidgetActive: boolean;
  isLanguagesWidgetActive: boolean;
}

class WebsiteWidgets extends Component<Props, MyState> {
  // componentDidUpdate(): void {
  //   this.setState({
  //     currentWebsiteWidgetsObject: this.props.currentWebsite.widgets
  //   })
  // }
  // componentWillMount(): void {
  //   this.setState({
  //     isSimpleCookieWidgetVisible: true,
  //     waitingForServer: false,
  //     cookieWidgetChangesDetected: false,
  //   })
  // }
  constructor(props: Props) {
    super(props);
    this.state = {
      isSimpleCookieWidgetVisible: true,
      waitingForServer: false,
      cookieWidgetChangesDetected: false,
      langWidgetChangesDetected: false,
      currentWebsiteWidgetsObject: this.props.currentWebsite.widgets,
      isCookieWidgetActive: this.checkIfSimpleCookieWidgetActive(
        this.props.currentWebsite.widgets
      ),
      isLanguagesWidgetActive: this.checkIfLangWidgetActive(
        this.props.currentWebsite.widgets
      ),
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      cookieWidgetChangesDetected: false,
      langWidgetChangesDetected: false,
      currentWebsiteWidgetsObject: nextProps.currentWebsite.widgets,
      isCookieWidgetActive: this.checkIfSimpleCookieWidgetActive(
        nextProps.currentWebsite.widgets
      ),
      isLanguagesWidgetActive: this.checkIfLangWidgetActive(
        nextProps.currentWebsite.widgets
      ),
    });
  }

  toggleWaitingForServer = (value) => {
    // value == true || false
    this.setState({
      waitingForServer: value,
    });
  };
  displayWidgets = () => {
    let returnFields = () => {
      if (checkIfObjectEmpty(this.state.currentWebsiteWidgetsObject)) {
        return null;
      } else {
        // another check to make sure the object is formed (forms when the widget is activated)
        if (
          this.state.currentWebsiteWidgetsObject["items"] === undefined ||
          this.state.currentWebsiteWidgetsObject["items"]["simple_cookie"] ===
            undefined
        ) {
          return null;
        } else {
          let simpleCookieContentObject = this.state
            .currentWebsiteWidgetsObject["items"]["simple_cookie"];
          return (
            this.state.isCookieWidgetActive && (
              <div className="website_widgets__inputs">
                <div className="settings-box">
                  <div className="settings-box__input_label">
                    <div>
                      <Popover
                        content={
                          <div>
                            Tell the visitors about your website cookie policy.
                            Plain text or HTML.
                          </div>
                        }
                      >
                        <Icon type="info-circle" />
                      </Popover>
                    </div>
                    <div className="settings-box__input_info_text">
                      Message:
                    </div>
                  </div>
                  <div
                    className="settings-box__input_box"
                    style={{ marginBottom: "20px" }}
                  >
                    <DebouncedTextarea
                      value={
                        simpleCookieContentObject["content"]["messageText"][
                          "default"
                        ]
                      }
                      defaultValue={
                        simpleCookieContentObject["content"]["messageText"][
                          "default"
                        ]
                      }
                      placeholder="We use cookies to improve your experience on our site. By using our site you consent the cookies policy."
                      onChange={this.changeSimpleCookieMessageText}
                      autosize={{ minRows: 2, maxRows: 30 }}
                      style={{}}
                    />
                    {/* <div className="settings-box__error_message" style={{opacity: this.props.blogs.errorMessage === undefined ? 0 : 1}}>
                        {this.props.blogs.errorMessage}
                    </div> */}
                  </div>
                </div>

                <div className="settings-box">
                  <div className="settings-box__input_label">
                    <div>
                      <Popover
                        content={
                          <div>
                            The button text. 'OK', 'Alright', 'Understood'.
                          </div>
                        }
                      >
                        <Icon type="info-circle" />
                      </Popover>
                    </div>
                    <div className="settings-box__input_info_text">
                      Button title:
                    </div>
                  </div>
                  <div
                    className="settings-box__input_box"
                    style={{ marginBottom: "20px" }}
                  >
                    <DebouncedInput
                      // required={true}
                      value={
                        simpleCookieContentObject["content"]["buttonText"][
                          "default"
                        ]
                      }
                      defaultValue={
                        simpleCookieContentObject["content"]["buttonText"][
                          "default"
                        ]
                      }
                      placeholder="Understand"
                      onChange={this.changeSimpleCookieButtonText}
                      style={{}}
                    />
                    {/* <div className="settings-box__error_message" style={{opacity: this.props.blogs.errorMessage === undefined ? 0 : 1}}>
                        {this.props.blogs.errorMessage}
                    </div> */}
                  </div>
                </div>

                <div className="settings-box">
                  <div className="settings-box__input_label">
                    <div>
                      <Popover
                        content={
                          <div>The position of the widget on the screen.</div>
                        }
                      >
                        <Icon type="info-circle" />
                      </Popover>
                    </div>
                    <div className="settings-box__input_info_text">
                      Placement:
                    </div>
                  </div>
                  <div
                    className="settings-box__input_box"
                    style={{ marginBottom: "20px" }}
                  >
                    <Radio.Group
                      size="small"
                      value={simpleCookieContentObject["placement"]}
                      onChange={this.changeSimpleCookiePlacement}
                    >
                      <Radio.Button value="bottom_left">
                        <Icon type="radius-bottomleft" /> Bottom left
                      </Radio.Button>
                      <Radio.Button value="bottom_right">
                        <Icon type="radius-bottomright" /> Bottom right
                      </Radio.Button>
                      <Radio.Button value="top_left">
                        <Icon type="radius-upleft" /> Top left
                      </Radio.Button>
                      <Radio.Button value="top_right">
                        <Icon type="radius-upright" /> Top right
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                </div>

                <div className="settings-box" style={{ marginBottom: "20px" }}>
                  <div className="settings-box__input_label">
                    <div>
                      <Popover content={<div>The widget color scheme.</div>}>
                        <Icon type="info-circle" />
                      </Popover>
                    </div>
                    <div className="settings-box__input_info_text">Style:</div>
                  </div>
                  <div className="settings-box__input_box">
                    <div
                      className={
                        "checkered-bg background-color-picker " +
                        this.props.currentWebsite.color_classname
                      }
                    >
                      <button
                        onClick={() => this.changeSimpleCookieStyle("white")}
                        className={
                          "bg-white-color background-color-picker__item  background-color-picker__item--border " +
                          (simpleCookieContentObject["style"] === "white"
                            ? " background-color-picker__item--small "
                            : " ")
                        }
                      >
                        <span className="background-color-picker__circle"></span>
                      </button>
                      <button
                        onClick={() => this.changeSimpleCookieStyle("black")}
                        className={
                          "bg-black-color background-color-picker__item " +
                          (simpleCookieContentObject["style"] === "black"
                            ? " background-color-picker__item--small "
                            : " ")
                        }
                      >
                        <span className="background-color-picker__circle"></span>
                      </button>
                      <button
                        onClick={() => this.changeSimpleCookieStyle("gray")}
                        className={
                          "bg-light_gray-color background-color-picker__item " +
                          (simpleCookieContentObject["style"] === "gray"
                            ? " background-color-picker__item--small "
                            : " ")
                        }
                      >
                        <span className="background-color-picker__circle"></span>
                      </button>
                      <button
                        onClick={() => this.changeSimpleCookieStyle("midnight")}
                        className={
                          "background-color-picker__item " +
                          (simpleCookieContentObject["style"] === "midnight"
                            ? " background-color-picker__item--small "
                            : " ")
                        }
                      >
                        <span
                          className="background-color-picker__circle"
                          style={{ backgroundColor: "#031a4e" }}
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="settings-box">
                  <div className="settings-box__input_label">
                    <div>
                      <Popover
                        content={<div>Show or hide the cookie icon.</div>}
                      >
                        <Icon type="info-circle" />
                      </Popover>
                    </div>
                    <div className="settings-box__input_info_text">
                      Display cookie icon:
                    </div>
                  </div>
                  <div className="settings-box__input_box">
                    <Switch
                      checkedChildren={<Icon type="eye" />}
                      unCheckedChildren={<Icon type="eye-invisible" />}
                      defaultChecked={
                        simpleCookieContentObject["content"]["has_icon"]
                      }
                      onChange={this.changeSimpleCookieIconVisibility}
                    />
                  </div>
                </div>

                {
                  <Button
                    style={{ marginTop: 20 }}
                    onClick={() =>
                      this.saveChanges(this.props.currentWebsite.widgets)
                    }
                    disabled={!this.state.cookieWidgetChangesDetected}
                    type="primary"
                  >
                    Save cookie widget
                    <Icon
                      type={this.state.waitingForServer ? "loading" : "save"}
                    />
                  </Button>
                }
              </div>
            )
          );
        }
      }
    };

    return (
      <div className="website_widgets__wrapper">
        <div className="settings-box__input">
          <div className="website_widgets__preview_wrapper">
            <WidgetsPreview
              currentWebsiteWidgetsObject={
                this.state.currentWebsiteWidgetsObject
              }
              isSimpleCookieWidgetVisible={
                this.state.isSimpleCookieWidgetVisible
              }
              formInitialWidgetsObjectIfEmpty={
                this.formInitialWidgetsObjectIfEmpty
              }
              isCookieWidgetActive={this.state.isCookieWidgetActive}
              isLanguagesWidgetActive={this.state.isLanguagesWidgetActive}
              currentWebsite={this.props.currentWebsite}
            />
          </div>
        </div>

        <Divider className="settings__divider" />

        <div className="settings-box__input">
          <div className="website_widgets__item">
            <LanguagesSwitch
              toggleWaitingForServer={this.toggleWaitingForServer}
              isLanguagesWidgetActive={this.state.isLanguagesWidgetActive}
              waitingForServer={this.state.waitingForServer}
              currentWebsite={this.props.currentWebsite}
              saveChanges={this.saveChanges}
              activateLangWidget={this.activateLangWidget}
              currentWebsiteWidgetsObject={
                this.state.currentWebsiteWidgetsObject
              }
              changeLangWidgetStyle={this.changeLangWidgetStyle}
              changeLangWidgetPlacement={this.changeLangWidgetPlacement}
              langWidgetChangesDetected={this.state.langWidgetChangesDetected}
              websites={this.props.websites}
            />
          </div>

          <Divider className="settings__divider" />

          <div className="settings-box__input">
            {/* this.state.isCookieWidgetActive === false because we do not want this spinner blink each time we save changes */}
            <h3 className="website_widgets__title">
              Cookie consent banner{" "}
              <Switch
                loading={
                  this.state.isCookieWidgetActive === false &&
                  this.state.waitingForServer
                }
                onChange={(event) => this.activateCookieWidget(event)}
                checkedChildren="on"
                unCheckedChildren="off"
                checked={this.state.isCookieWidgetActive}
              />
            </h3>
            <div className="website_widgets__item__wrapper">
              <div className="website_widgets__info">
                {this.state.isCookieWidgetActive && returnFields()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  checkIfSimpleCookieWidgetActive = (widgetsObject: any) => {
    if (checkIfObjectEmpty(widgetsObject)) {
      // no widgets object setup yet
      return false;
    } else {
      if (
        widgetsObject !== undefined &&
        widgetsObject["items"] !== undefined &&
        widgetsObject["items"]["simple_cookie"] !== undefined &&
        widgetsObject["items"]["simple_cookie"]["is_active"] === true
      ) {
        // has simple cookie widget and it is active
        return true;
      } else {
        // has simple cookie widget but it is NOT active
        return false;
      }
    }
  };
  checkIfLangWidgetActive = (widgetsObject: any) => {
    if (checkIfObjectEmpty(widgetsObject)) {
      // no widgets object setup yet
      return false;
    } else {
      if (
        widgetsObject !== undefined &&
        widgetsObject["items"] !== undefined &&
        widgetsObject["items"]["lang"] !== undefined &&
        widgetsObject["items"]["lang"]["is_active"] === true
      ) {
        // has lang widget and it is active
        return true;
      } else {
        // has lang widget but it is NOT active
        return false;
      }
    }
  };
  formInitialWidgetsObjectIfEmpty = (widgetsObject: any) => {
    // new websites has {} as widget object. when a widget is added, form an initial object with no widgets set up
    if (checkIfObjectEmpty(widgetsObject)) {
      let defaultWidgetsObject = {
        items: {},
      };
      return defaultWidgetsObject;
    } else {
      return widgetsObject;
    }
  };
  formInitialCookieWidgetObject = (currentWidgetsObject: any) => {
    // form an initial object with the default cookie widget set up
    let newWidgetsObject;
    let initialCookieWidgetsObject = {
      id: generateRandomNumerousId(),
      is_active: false,
      placement: "bottom_left", //bottom_left, top_left, bottom_right, top_right
      style: "midnight", //white, black, gray, midnight
      content: {
        has_icon: true,
        messageText: {
          default: "We use cookies to improve your experience on our site.",
          // japanese: ""
        },
        buttonText: {
          default: "Understand",
          // japanese: ""
        },
      },
    };

    if (checkIfObjectEmpty(currentWidgetsObject)) {
      // if currentWidgetsObject is empty ({}), add initial value ('items')
      newWidgetsObject = this.formInitialWidgetsObjectIfEmpty(
        currentWidgetsObject
      );
      // and add the default cookie widget setup
      newWidgetsObject["items"]["simple_cookie"] = {
        ...initialCookieWidgetsObject,
      };
      return newWidgetsObject;
    } else if (currentWidgetsObject["items"]["simple_cookie"] === undefined) {
      // and add the default cookie widget setup
      newWidgetsObject = { ...currentWidgetsObject };
      newWidgetsObject["items"]["simple_cookie"] = {
        ...initialCookieWidgetsObject,
      };
      return newWidgetsObject;
    } else {
      return currentWidgetsObject;
    }
  };
  formInitialLangWidgetObject = (currentWidgetsObject: any) => {
    // form an initial object with the default cookie widget set up
    let newWidgetsObject;
    let initialLangWidgetsObject = {
      id: generateRandomNumerousId(),
      is_active: false,
      placement: "bottom_right", //bottom_left, top_left, bottom_right, top_right
      style: "white", //white, black, gray, midnight
      content: {
        show_flags: true,
        thisWebsiteCode: "", // take from ['languages']
        options: [
          // {code: 'de', websiteHomePageURL: 'https://de.unicornplatform.page/',}
        ],
      },
    };

    if (checkIfObjectEmpty(currentWidgetsObject)) {
      // if currentWidgetsObject is empty ({}), add initial value ('items')
      newWidgetsObject = this.formInitialWidgetsObjectIfEmpty(
        currentWidgetsObject
      );
      // and add the default languages widget setup
      newWidgetsObject["items"]["lang"] = { ...initialLangWidgetsObject };
      return newWidgetsObject;
    } else if (currentWidgetsObject["items"]["lang"] === undefined) {
      // and add the default languages widget setup
      newWidgetsObject = { ...currentWidgetsObject };
      newWidgetsObject["items"]["lang"] = { ...initialLangWidgetsObject };
      return newWidgetsObject;
    } else {
      return currentWidgetsObject;
    }
  };
  activateLangWidget = (event) => {
    // event == true || false
    let currentWidgetsObject = {
      ...this.formInitialLangWidgetObject(this.props.currentWebsite.widgets),
    };
    currentWidgetsObject["items"]["lang"]["is_active"] = event;

    // save the current language in the widget object
    let thisWebsiteLanguageCode = undefined;
    if (
      checkIfObjectEmpty(this.props.currentWebsite.languages) === false &&
      this.props.currentWebsite.languages.settings &&
      this.props.currentWebsite.languages.settings.code &&
      this.props.currentWebsite.languages.code !== "none"
    ) {
      // learn why 'none' in <WebsiteLanguageSettings>
      thisWebsiteLanguageCode = this.props.currentWebsite.languages.settings
        .code;
    }
    if (thisWebsiteLanguageCode) {
      currentWidgetsObject["items"]["lang"]["content"][
        "thisWebsiteCode"
      ] = thisWebsiteLanguageCode;
    }

    // save the language options with links to the websites
    let thisWebsiteLanguageOptionsItems = [];
    let checkResult = checkIfThisWebsiteLanguageOptionOfAnotherWebsite(
      this.props.websites,
      this.props.currentWebsite
    );
    let isThisWebsiteLanguageOptionOfAnotherWebsite = checkResult["isTrue"];

    // if this website has options and is not a language option of another, list its options in the widget

    //clear the array of options and populate with the actual ones.
    currentWidgetsObject["items"]["lang"]["content"]["options"] = [];

    if (isThisWebsiteLanguageOptionOfAnotherWebsite === false) {
      if (
        checkIfObjectEmpty(this.props.currentWebsite.languages) === false &&
        this.props.currentWebsite.languages.items &&
        this.props.currentWebsite.languages.items &&
        this.props.currentWebsite.languages.items.length > 0
      ) {
        // if this website has any languages options or this website is a language option of another website
        thisWebsiteLanguageOptionsItems = this.props.currentWebsite.languages
          .items;
        /* the list looks like this: (from <WebsiteLanguageSettings>)
                * "items": [ // this website language options. default = []
                {
                    "id": "lang-38346",
                    "connectedOn": 1640358859378,
                    "code": "de",
                    "english": "German",
                    "flag": "🇩🇪",
                    "native": "Deutsch",
                    "websiteId": 1242, // the id of the website which is a language version
                    "websiteHomePageURL": "https://de.unicornplatform.com/"
                }
            ],
                *
                * */
        // let listOfLanguages = [];
        currentWidgetsObject["items"]["lang"]["content"]["options"] = [];
        for (let i = 0; i < thisWebsiteLanguageOptionsItems.length; i++) {
          if (
            thisWebsiteLanguageOptionsItems[i]["code"] &&
            thisWebsiteLanguageOptionsItems[i]["code"] !== "none"
          ) {
            currentWidgetsObject["items"]["lang"]["content"]["options"].push({
              code: thisWebsiteLanguageOptionsItems[i]["code"],
              websiteHomePageURL:
                thisWebsiteLanguageOptionsItems[i]["websiteHomePageURL"],
            });
          }
        }

        // currentWidgetsObject['items']['lang']['content']['options'] = {...listOfLanguages}
      }
    } else {
      // if this website is a language option of another website, list this another website options
      //    add the website which has currentWebsite as a language option to the languages switch widget
      let websiteWhichHasThisWebsiteAsLanguageOption = checkResult["website"];
      let URLOfTheWebsiteWhichHasThisWebsiteAsLanguageOption = getCurrentWebsiteLiveUrl(
        websiteWhichHasThisWebsiteAsLanguageOption
      );

      currentWidgetsObject["items"]["lang"]["content"]["options"] = [];

      if (
        checkIfObjectEmpty(
          websiteWhichHasThisWebsiteAsLanguageOption["languages"]
        ) === false &&
        websiteWhichHasThisWebsiteAsLanguageOption["languages"]["settings"] &&
        websiteWhichHasThisWebsiteAsLanguageOption["languages"]["settings"][
          "code"
        ] &&
        websiteWhichHasThisWebsiteAsLanguageOption["languages"]["settings"][
          "code"
        ] !== "none"
      )
        currentWidgetsObject["items"]["lang"]["content"]["options"].push({
          code:
            websiteWhichHasThisWebsiteAsLanguageOption["languages"]["settings"][
              "code"
            ],
          websiteHomePageURL: URLOfTheWebsiteWhichHasThisWebsiteAsLanguageOption,
        });

      //    add the languages options of the website which has currentWebsite as a language option, except currentWebsite
      let languageOptionsOfWebsiteWhichHasThisWebsiteAsLanguageOption =
        websiteWhichHasThisWebsiteAsLanguageOption["languages"]["items"];
      for (
        let i = 0;
        i < languageOptionsOfWebsiteWhichHasThisWebsiteAsLanguageOption.length;
        i++
      ) {
        if (
          languageOptionsOfWebsiteWhichHasThisWebsiteAsLanguageOption[i][
            "websiteId"
          ] !== this.props.currentWebsite.id &&
          languageOptionsOfWebsiteWhichHasThisWebsiteAsLanguageOption[i][
            "code"
          ] &&
          languageOptionsOfWebsiteWhichHasThisWebsiteAsLanguageOption[i][
            "code"
          ] !== "none"
        ) {
          currentWidgetsObject["items"]["lang"]["content"]["options"].push({
            code:
              languageOptionsOfWebsiteWhichHasThisWebsiteAsLanguageOption[i][
                "code"
              ],
            websiteHomePageURL:
              languageOptionsOfWebsiteWhichHasThisWebsiteAsLanguageOption[i][
                "websiteHomePageURL"
              ],
          });
        }
      }
    }
    this.saveChanges(currentWidgetsObject);
    this.setState({
      currentWebsiteWidgetsObject: currentWidgetsObject,
    });
  };
  activateCookieWidget = (event) => {
    // event == true || false
    let currentWidgetsObject = {
      ...this.formInitialCookieWidgetObject(this.props.currentWebsite.widgets),
    };
    currentWidgetsObject["items"]["simple_cookie"]["is_active"] = event;
    this.saveChanges(currentWidgetsObject);
    this.setState({
      currentWebsiteWidgetsObject: currentWidgetsObject,
    });
  };
  markCookieWidgetChangesDetected = () => {
    // // if(JSON.stringify(this.state.currentWebsiteWidgetsObject) === JSON.stringify(this.props.currentWebsite.widgets)){
    //   this.setState({
    //     cookieWidgetChangesDetected: false
    //   })
    // }else{
    this.setState({
      cookieWidgetChangesDetected: true,
    });
    // }
  };
  markLangWidgetChangesDetected = () => {
    // // if(JSON.stringify(this.state.currentWebsiteWidgetsObject) === JSON.stringify(this.props.currentWebsite.widgets)){
    //   this.setState({
    //     cookieWidgetChangesDetected: false
    //   })
    // }else{
    this.setState({
      langWidgetChangesDetected: true,
    });
    // }
  };
  changeSimpleCookieMessageText = (value) => {
    let newWidgetsObject = { ...this.props.currentWebsite.widgets };
    newWidgetsObject["items"]["simple_cookie"]["content"]["messageText"][
      "default"
    ] = value;
    this.setState({
      currentWebsiteWidgetsObject: newWidgetsObject,
    });
    this.markCookieWidgetChangesDetected();
  };
  changeSimpleCookieButtonText = (value) => {
    let newWidgetsObject = { ...this.props.currentWebsite.widgets };
    newWidgetsObject["items"]["simple_cookie"]["content"]["buttonText"][
      "default"
    ] = value;
    this.setState({
      currentWebsiteWidgetsObject: newWidgetsObject,
    });
    this.markCookieWidgetChangesDetected();
  };
  changeSimpleCookiePlacement = (event) => {
    let newWidgetsObject = { ...this.props.currentWebsite.widgets };
    newWidgetsObject["items"]["simple_cookie"]["placement"] =
      event.target.value;
    this.setState({
      currentWebsiteWidgetsObject: newWidgetsObject,
    });
    this.markCookieWidgetChangesDetected();
  };
  changeSimpleCookieStyle = (value) => {
    let newWidgetsObject = { ...this.props.currentWebsite.widgets };
    newWidgetsObject["items"]["simple_cookie"]["style"] = value;
    this.setState({
      currentWebsiteWidgetsObject: newWidgetsObject,
    });
    this.markCookieWidgetChangesDetected();
  };
  changeSimpleCookieIconVisibility = (value) => {
    let newWidgetsObject = { ...this.props.currentWebsite.widgets };
    newWidgetsObject["items"]["simple_cookie"]["content"]["has_icon"] = value;
    this.setState({
      currentWebsiteWidgetsObject: newWidgetsObject,
    });
    this.markCookieWidgetChangesDetected();
  };
  changeLangWidgetPlacement = (event) => {
    let newWidgetsObject = { ...this.props.currentWebsite.widgets };
    newWidgetsObject["items"]["lang"]["placement"] = event.target.value;
    this.setState({
      currentWebsiteWidgetsObject: newWidgetsObject,
    });
    this.markLangWidgetChangesDetected();
  };
  changeLangWidgetStyle = (value) => {
    let newWidgetsObject = { ...this.props.currentWebsite.widgets };
    newWidgetsObject["items"]["lang"]["style"] = value;
    this.setState({
      currentWebsiteWidgetsObject: newWidgetsObject,
    });
    this.markLangWidgetChangesDetected();
  };

  saveChanges = (newWidgetsObject) => {
    this.toggleWaitingForServer(true);

    let accessToken = this.props.auth.accessToken;
    let apiUrl =
      api.websites.updateWebsite.prefix +
      this.props.currentWebsite.subdomain +
      api.websites.updateWebsite.postfix;
    axios
      .patch(
        apiUrl,
        { widgets: newWidgetsObject },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then(() => {
        const dataToStore = {
          data: {
            ...this.props.currentWebsite,
            widgets: newWidgetsObject
          }
        }
        this.props.saveWebsiteDataInStore(
          dataToStore,
          this.props.currentWebsite.subdomain
        );
        this.setState({
          cookieWidgetChangesDetected: false,
        });

        fireAnalyticsEvent.fireCrisp(CrispEvents.changeWebsiteWidgets, {
          subdomain: this.props.currentWebsite.subdomain,
        });

        // openNotification('Done!', 'New widget(-s) set up has been succesfully saved.', 'Alright', 'success', 5);
      })
      .catch((error) => {
        // handle error
        if (error.response) {
          let errorData = error.response.data;

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);

          fireAnalyticsEvent.fireCrisp(
            CrispEvents.changeWebsiteWidgetsError,
            {
              error_type: errorObjectKey,
              error_message: errorObjectValue,
              subdomain: this.props.currentWebsite.subdomain,
            },
            true
          );

          handleBadWebsiteError(errorData);
          if (errorData.not_pro !== undefined) {
            let message = errorData.not_pro;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }
        }
      })
      .then((response) => {
        // always executed
        this.toggleWaitingForServer(false);
      });
  };

  render() {
    return (
      <div className="website_widgets">
        {this.displayWidgets()}
        {<CustomFontsStyles currentWebsite={this.props.currentWebsite} />}
      </div>
    );
  }
}

export default WebsiteWidgets;
